<template>
  <transition name="slide" appear>
    <div class="product">
      <basic-dialog
        :is-open="confirmAgeDialogOpen"
        @close="() => confirmAgeDialogOpen=false"
      >
        <template slot="dialog-body">
          <p v-html="$t('confirm_age_text')" />
          <button class="btn-confirm btn btn--primary" @click="handleConfirmAge">{{ $t('edit_order_confirm') }}</button>
        </template>
      </basic-dialog>
      <div class="background" @click="close" />
      <div class="product-content">
        <basic-loading v-if="isProductDataUpdating || isIngredientsUpdating" />
        <template v-else>
          <div v-if="userQr && productData.imageLg" class="product-image" :style="'background-image: url(' + productData.imageLg +')'" />
          <div class="product-header">
            <!-- <div :class="['text-container', userQr && productData.imageLg ? 'absolute' : 'simple']"> -->
            <div class="text-container">
              <div class="product-title">{{ $label(productData) }}</div>
              <div v-if="productData.description" class="product-desc">{{ productData.description }}</div>
              <div class="product-ingredients">{{ ingredientsArr.map((i)=>$label(i)).join(', ') }}</div>
            </div>
            <div class="close" @click="close" />
          </div>
          <div class="product-content-scroll">
            <div class="order-of-ticket-selector">
              <div class="label">{{ $t('order_of_ticket_row') }}</div>
              <div class="fake-select small">
                <select v-model="internalValue.serviceOrderId" class="selector">
                  <option v-for="serviceOrder in serviceOrders" :key="serviceOrder.id" :value="serviceOrder.id">{{ $label(serviceOrder) }}</option>
                </select>
                <span class="focus" />
              </div>
            </div>
            <div class="ingredient-modifiers">
              <modifier
                v-for="ingId in productData.ingredientModifiers"
                :id="ingId" 
                :key="ingId" 
                :type="'ing'"
                :ingredients="ingredientsArr"
                :value="getModifierValue(ingId,'ing')"
                @change="onModifierChange"
              />
            </div>
            <div class="option-modifiers">
              <modifier
                v-for="optId in productData.optionModifiers"
                :id="optId"
                :key="optId" 
                :type="'opt'"
                :value="getModifierValue(optId,'opt')"
                @change="onModifierChange"
              />
            </div>
            <div class="product-description">
              <p>{{ $description(productData) }}</p>
            </div>
            <div class="product-comments">
              <textarea v-model="internalValue.comment" :placeholder="$t('edit_order_add_comment')" />
            </div>
          </div>
        </template>
      </div>
      <basic-bottom-actions v-if="internalValue" class="product-bottom-actions">
        <basic-quantity-selector v-model="internalValue.quantity" />
        <div class="btn-confirm btn btn--primary" @click="addToCart">{{ $t('edit_order_confirm') }}</div>  
      </basic-bottom-actions>
    </div>
  </transition>
</template>

<script>
import BasicQuantitySelector from '@/components/basic/BasicQuantitySelector'
import Modifier from './Modifier.vue'
import BasicDialog from './basic/BasicDialog.vue'
import BasicBottomActions from './basic/BasicBottomActions.vue'
import BasicLoading from './basic/BasicLoading.vue'
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BasicQuantitySelector,
    Modifier,
    BasicBottomActions,
    BasicLoading,
    BasicDialog
  },
  props: ['productId', 'value'],
  data() {
    return {
      internalValue: null,
      confirmAgeDialogOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      userQr: 'userQr/getLogedQrData',
    }),
    ingredientsArr() {
      if (this.ingredients === null) {
        return null
      }
      return Array.isArray(this.ingredients) ? this.ingredients : [this.ingredients]
    }
  },
  watch: {
    productData: {
      handler() {
        if (!this.productData) return
        if (!this.value) {
          this.internalValue = {
            quantity: 1,
            ingredientModifiers: [],
            optionModifiers: [],
            serviceOrderId: this.productData.serviceOrderId,
          }
        } else {
          this.internalValue = JSON.parse(JSON.stringify(this.value))
        }
        if (!this.internalValue.quantity) Vue.set(this.internalValue, 'quantity', 1)
      },
      immediate: true,
    },
  },
  remoteComputed: {
    productData: {
      model: 'restaurant/products',
      method: 'getById',
      params: function() {
        return [this.productId]
      },
    },
    ingredients: {
      model: 'restaurant/ingredients',
      method: 'getById',
      params: function() {
        if (!this.productData) return []
        return [this.productData.ingredients]
      },
    },
    serviceOrders: {
      model: 'restaurant/serviceOrders',
      method: 'get',
    },
  },
  methods: {
    getModifierValue(id, type) {
      let typeArray = type === 'ing' ? 'ingredientModifiers' : 'optionModifiers'
      let value = this.internalValue[typeArray].find(m => String(m.id) === String(id))
      return value ? value.value : []
    },
    onModifierChange(id, value, type) {
      let typeArray = type === 'ing' ? 'ingredientModifiers' : 'optionModifiers'
      let newArray = [...this.internalValue[typeArray]]
      let candidate = newArray.find(m => String(m.id) === String(id))
      if (!candidate) {
        candidate = { id: id }
        newArray.push(candidate)
      }
      candidate.value = value
      this.internalValue[typeArray] = newArray.filter(d => d.value?.length > 0)
    },
    addToCart() {
      if (this.productData.isAgeRestricted) {
        this.confirmAgeDialogOpen = true
        return
      }
      this.$emit('confirm', this.internalValue)
    },
    handleConfirmAge() {
      this.confirmAgeDialogOpen = false
      this.$emit('confirm', this.internalValue)
    },
    close() {
      //habria que transicionar primero...no me esta gustando como esta quedando esto...
      this.$emit('close')
    },
  },
}
</script>

<style lang="stylus" scoped>
$bottom-actions-height = vw(90px)
$bottom-actions-margin = vw(-90px)
$margin-top = vh(180px)
$transition-duration = 0.6s

.product
  position: absolute
  top: 0
  display: flex
  flex-direction: column
  overflow: hidden
  height: 100%

  &.slide-enter-active,
  &.slide-leave-active
    transition: all $transition-duration ease-in-out

    .background
      transition: opacity ($transition-duration / 2) ease-in-out

    .product-content
      transition: transform ($transition-duration / 1.25) ease-in-out

    .product-bottom-actions
      transition: transform $transition-duration ease-in-out

  &.slide-enter,
  &.slide-enter-from,
  &.slide-leave-to
    transition: all $transition-duration ease-in-out

    .background
      opacity: 0

    .product-content
      transition: transform $transition-duration ease-in-out
      transform: translate(0, 100%)

    .product-bottom-actions
      transition: transform ($transition-duration / 2) ease-in-out
      transform: translateY($bottom-actions-height)

  .background
    position: fixed
    top: 0
    z-index: 1
    width: 100vw
    height: 100vh
    background-color: rgba(#333, 0.6)

  .close
    m-icon("close", 40)
    position: absolute
    top: vw(10px)
    right: vw(10px)
    cursor: pointer

    .is-client &
      m-icon("close-white", 40)

.product-content
  z-index: 2
  display: flex
  flex: 1 1 0%
  flex-direction: column
  overflow: hidden
  margin-bottom: $bottom-actions-margin
  width: 100%
  background-color: #fff

  .is-client &
    position: relative

.product-image
  width: 100%
  height: vw(200px)
  background-position: center center
  background-size: cover
  background-repeat: no-repeat

  .is-client &
    position: absolute
    top: 0
    left: 0

.product-header
  position: relative
  border-bottom: 2px solid #333

  .is-client &
    background-color: rgba(#000, 0.25)
    color: #fff

.text-container
  padding: vw(20px)

  .is-client &
    text-shadow: 0 1px 2px rgba(#000, 0.75)

  .product-title
    font-weight: 600
    m-font-size(22)
    margin-right: vw(40px)
    margin-bottom: vw(10px)

    .is-client &
      margin-top: vw(60px)

  .product-desc
    m-font-size(14, 20)
    margin-bottom: vw(10px)

  .product-ingredients
    m-font-size(14, 20)

.product-content-scroll
  display: flex
  flex: 1 1 0%
  flex-direction: column
  overflow-y: auto
  margin-bottom: $basic-bottom-actions-height

  .is-client &
    position: relative
    background-color: #fff

.order-of-ticket-selector
  display: flex
  align-items: center
  padding: vw(5px) vw(16px) 0

  .label
    m-font-size(14, 20)
    flex: 1 1 0%
    margin-right: vw(20px)
    color: #828282
    text-transform: uppercase
    white-space: nowrap

.product-comments
  padding: vw(2px) vw(20px) vw(16px) vw(20px)

.product-description
  padding: vw(2px) vw(20px) vw(2px) vw(20px)

.product-description
  m-font-size(12px, 12px)
  color: $aluminium

.product-bottom-actions
  position: relative
  z-index: 2
  display: flex
  flex: 0 0 $basic-bottom-actions-height
  flex-direction: row
  align-items: center

  .quantity-selector
    margin-right: vw(30px)

  .btn-confirm
    flex: 1 1 0%

.option-modifiers
  flex: 1 1 0%

.basic-dialog
  >>> .background
    z-index: 3
</style>