<template>
  <div class="cms-alerts">
    <ResourceConfigurator
      v-if="alerts"
      :edit-label-key="'cms_edit_alert'"
      :add-label-key="'cms_add_alert'"
      :resources="alerts"
      :resource-label-key="'cms_alerts'"
      :fields-info="fieldsInfo"
      :form-fields="formFields"
      :table-values="tableValues"
      :delete-resource-handler="deleteAlertHandler"
      :add-resource-handler="addAlertHandler"
      :edit-resource-handler="editAlertHandler"
      @onStartEditing="onStartEditingHandler"
      @onStartAdding="editingResource = null"
    />
  </div>
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator'
import { cmsController } from '../../../services/api'
import { mapActions } from 'vuex'
export default {
  components: {  ResourceConfigurator },
  data() {
    return {
      editingResource: null,
    }
  },
  computed: {
    fieldsInfo() {
      return [
        { type: 'default', name: this.$t('cms_name')},
      ]
    },
    tableValues() {
      return this.alerts?.map(alert => [alert.name])
    },
    formFields() {
      return [
      {
        type: 'string',
        label: this.$t('cms_name'),
        id: 'name',
        default: this.editingResource?.name
      }
      ]
    },
  },
  remoteComputed: {
    alerts: {
      model: 'cms/ticketRowAlerts',
      method: 'get',
    },
  },
  methods: {
    ...mapActions({
      invalidateAlerts: 'cms/ticketRowAlerts/invalidateAll',
    }),
    onStartEditingHandler(resource) {
      this.editingResource = resource
    },
    deleteAlertHandler({ id }) {
      cmsController.deleteResourceById('ticket-row-alert', id).then(this.invalidateAlerts)
    },
    addAlertHandler(alert) {
      return cmsController.addResource('ticket-row-alert', alert).then(this.invalidateAlerts)
    },
    editAlertHandler(alert) {
      return cmsController.updateResourceById('ticket-row-alert', this.editingResource.id, alert).then(this.invalidateAlerts)
    },
  },
}
</script>
<style lang="stylus" scoped>
</style>