// import { mapActions } from "vuex"
function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
/**
 * return the return of the funtion if func is a function or func if not
 * @param {(()=>T) | T} func
 * @param {import('vue').VueConstructor}
 * @return {T}
 * @template T
 */
function valueToBoundFunction(val, instance) {
  if (val instanceof Function) return val.bind(instance)
  return () => val
}
function getFields(VueInstance) {
  return Object.keys(VueInstance.$options.remoteComputed).map(field =>
    getOneField(VueInstance, field)
  )
}

// MAL, no debe saber nada de "get vs getById"
function getOneField(VueInstance, field) {
  const fetchObj = VueInstance.$options.remoteComputed[field]
  const method_getter = valueToBoundFunction(fetchObj.method, VueInstance)
  const model_getter = valueToBoundFunction(fetchObj.model, VueInstance)
  const params_getter = fetchObj.params && valueToBoundFunction(fetchObj.params, VueInstance)
  const isList_getter = valueToBoundFunction(fetchObj.isList, VueInstance)
  return {
    field,
    isList_getter: isList_getter ? isList_getter : () => false,
    method_getter: () => (method_getter() === 'get' || !method_getter() ? 'fetch' : 'fetchById'),
    getMethod_getter: () => (method_getter() === 'get' || !method_getter() ? 'get' : 'getById'),
    params_getter: params_getter
      ? params_getter
      : method_getter() === 'get' || !method_getter()
      ? () => []
      : () => [VueInstance.$route.params[model_getter()]],
    model_getter: model_getter ? model_getter : () => field,
  }
}
export default {
  /**
   *
   * @param {import('vue').VueConstructor & {$options:{fetch:{[field:string]:{
   *    model?:string,
   *    params?:()=>Array,
   *    method:'get' | 'getById'
   * }}}}} Vue
   */
  install(Vue) {
    //copiamos la merge strategy de "methods" que es la "normal" de "Object.extend"
    var strategies = Vue.config.optionMergeStrategies
    strategies.remoteComputed = strategies.methods

    Vue.mixin({
      beforeCreate() {
        if (!this.$options.remoteComputed) return
        // const fields = getFields(this)
        this.$options.computed = {
          ...this.$options.computed,
          ...Object.keys(this.$options.remoteComputed).reduce((obj, key) => {
            // const updatingKey = key.getMethod_getter()==='getById' ? 'updatingById' : 'updating'
            // console.log('KEY: ',key.field,`${key.model}/${key.getMethod_getter()}`);

            return {
              ...obj,
              [`is${capitalize(key)}Updating`]: function() {
                const { params_getter, model_getter, getMethod_getter } = getOneField(this, key)
                const updatingKey = getMethod_getter() === 'getById' ? 'updatingById' : 'updating'
                let getter = this.$store.getters[`${model_getter()}/${updatingKey}`]
                if (!getter) {
                  let candidates = Object.keys(this.$store.getters).filter(
                    k => k.indexOf(model_getter()) >= 0 && k.indexOf(updatingKey) >= 0
                  )
                  throw `[RemoteComputed error]: Getter '${model_getter()}/${updatingKey}' does not exist! Did you mean ${candidates
                    .map(c => `'${c}'`)
                    .join(' or ')}?`
                }

                let [ids, ...otherArgs] = params_getter()
                if (!Array.isArray(ids)) ids = [ids]
                return ids.reduce((result, id) => {
                  return result || getter(id, ...otherArgs) !== false //un poco "raro" pero es que el updating/updatingById es null al empezar y true o false luego
                }, false)
                // return getter(...params_getter())
              },
              [key]: function() {
                const {
                  params_getter,
                  isList_getter,
                  model_getter,
                  getMethod_getter,
                  method_getter,
                } = getOneField(this, key)
                let infoKey = getMethod_getter() === 'getById' ? 'getInfoById' : 'getInfo'
                let getter = this.$store.getters[`${model_getter()}/${infoKey}`]
                if (!getter) {
                  let candidates = Object.keys(this.$store.getters).filter(
                    k => k.indexOf(model_getter()) >= 0 && k.indexOf(getMethod_getter()) >= 0
                  )
                  throw `[RemoteComputed error]: Getter '${model_getter()}/${getMethod_getter()}'  does not exist! Did you mean ${candidates
                    .map(c => `'${c}'`)
                    .join(' or ')}?`
                }

                let [ids, ...otherArgs] = params_getter()
                if (!Array.isArray(ids)) ids = [ids]
                let arr = ids.map(id => {
                  this.$store.dispatch(`${model_getter()}/${method_getter()}`, [id, ...otherArgs])
                  return getter(id, ...otherArgs).result
                })

                //MAL, este if "no debe ser de arr" sino de (ids + isList) o algo asi...
                return arr.length === 1 && !isList_getter() ? arr[0] : arr

                // return getter(...params_getter())
              },
            }
          }, {}),
        }
        // this.$options.methods = {
        //   ...this.$options.methods,
        //   ...mapActions(Object.keys(this.$options.remoteComºted).reduce((obj,key) => {
        //     const {method_getter,model_getter} = getOneField(this,key)
        //     let action = this.$store._actions[`${model_getter()}/${method_getter()}`]
        //     if(!action){
        //       let candidates = Object.keys(this.$store._actions).filter((k)=>k.indexOf(model_getter())>=0 && k.indexOf(method_getter())>=0)
        //       let candidateModel = candidates[0] && candidates[0].replace(`/${method_getter()}`,'')
        //       let didyoumean = candidateModel ? `Did you mean:\n\n\t${key}: {\n\t\tmodel: '${candidateModel}'\n\t}\n\n` : ''
        //       throw(`[RemoteComputed error]: Action '${model_getter()}/${method_getter()}' does not exist! ${didyoumean}`)
        //     }
        //     return {
        //       ...obj,
        //       [`$${method_getter()}${key}`]: `${model_getter()}/${method_getter()}`,
        //     }
        //   }, {}))
        // }
      },
      mounted() {
        if (!this.$options.remoteComputed) return
        const fields = getFields(this)
        fields.forEach(field => {
          let [ids, ...otherArgs] = field.params_getter()
          if (!Array.isArray(ids)) ids = [ids]
          ids.forEach(id => {
            // this[`$${field.method_getter()}${field.field}`](id,...otherArgs)
            this.$store.dispatch(`${field.model_getter()}/${field.method_getter()}`, [
              id,
              ...otherArgs,
            ])
          })

          // this[`$${field.method}${field.field}`](...field.params_getter())
        })
      },
      updated() {
        if (!this.$options.remoteComputed) return
        const fields = getFields(this)
        fields.forEach(field => {
          let [ids, ...otherArgs] = field.params_getter()
          if (!Array.isArray(ids)) ids = [ids]
          ids.forEach(id => {
            // this[`$${field.method_getter()}${field.field}`](id,...otherArgs)
            this.$store.dispatch(`${field.model_getter()}/${field.method_getter()}`, [
              id,
              ...otherArgs,
            ])
          })
          // this[`$${field.method}${field.field}`](...field.params_getter())
        })
      },
    })
  },
}
