<template>
  <div v-if="restaurantData" class="restaurant-info">
    <div class="restaurant-info__section">
      <div class="restaurant-info__title">
        {{ $t('cms_restaurant_info') }}
        <div class="edit-icon" @click="() => handleEditClick('restaurant')" />
        <div v-if="editingRestaurant" class="restaurant-info__buttons">
          <Button :label-key="'cms_save'" @click="handleEditSave" />
          <Button
            v-if="editingRestaurant"
            :inverted="true"
            :label-key="'cms_cancel'"
            @click="handleEditCancel"
          />
        </div>
      </div>
      <div class="restaurant-info__input">
        <label>{{ $t('cms_name') }}: </label>
        <input v-model="state.name" :class="{ editable: editingRestaurant }" type="text">
      </div>
      <div class="restaurant-info__input">
        <label>{{ $t('cms_phone') }}: </label>
        <input v-model="state.phone" :class="{ editable: editingRestaurant }" type="text">
      </div>
      <div class="restaurant-info__input avatar">
        <label>{{ $t('cms_avatar') }}: </label>
        <div class="avatar__content">
          <RestaurantImage :url="restaurantData.avatar" :size="'md'" />
          <ImageInput v-if="editingRestaurant" v-model="imageFile" />
        </div>
      </div>
      <div class="restaurant-info__subtitle">{{ $t('cms_address') }}</div>
      <div v-if="state.address" class="address">
        <div class="restaurant-info__input">
          <label>{{ $t('cms_street') }}: </label>
          <input
            v-model="state.address.street"
            :class="{ editable: editingRestaurant }"
            type="text"
          >
        </div>
        <div class="restaurant-info__input">
          <label>{{ $t('cms_number') }}: </label>
          <input
            v-model="state.address.number"
            :class="{ editable: editingRestaurant }"
            type="number"
          >
        </div>
        <div class="restaurant-info__input">
          <label>{{ $t('cms_postal_code') }}: </label>
          <input
            v-model="state.address.postalCode"
            :class="{ editable: editingRestaurant }"
            type="string"
          >
        </div>
        <div class="restaurant-info__input">
          <label>{{ $t('cms_city') }}: </label>
          <input
            v-model="state.address.city"
            :class="{ editable: editingRestaurant }"
            type="string"
          >
        </div>
        <div class="restaurant-info__input">
          <label>{{ $t('cms_country') }}: </label>
          <input
            v-model="state.address.country"
            :class="{ editable: editingRestaurant }"
            type="string"
          >
        </div>
      </div>
    </div>
    <div class="restaurant-info__section">
      <div class="restaurant-info__title">
        {{ $t('cms_business_info') }}
        <div class="edit-icon" @click="() => handleEditClick('business')" />
        <div v-if="editingBusiness" class="restaurant-info__buttons">
          <Button :label-key="'cms_save'" @click="handleEditSave" />
          <Button
            v-if="editingBusiness"
            :inverted="true"
            :label-key="'cms_cancel'"
            @click="handleEditCancel"
          />
        </div>
      </div>
      <div class="restaurant-info__input">
        <label>{{ $t('cms_name') }}: </label>
        <input v-model="state.businessName" :class="{ editable: editingBusiness }" type="text">
      </div>
      <div class="restaurant-info__input">
        <label>{{ $t('cms_cif') }}: </label>
        <input v-model="state.nif" :class="{ editable: editingBusiness }" type="text">
      </div>
    </div>
  </div>
</template>
<script>
import Button from '../Button'
import { cmsController } from '@/services/api'
import RestaurantImage from '../../ImageThumbnail'
import ImageInput from '../dynamic-form/ImageInput'
import { mapActions } from 'vuex'
export default {
  components: { Button, RestaurantImage, ImageInput },
  remoteComputed: {
    restaurantData: {
      model: 'restaurant/restaurant',
      method: 'get',
    },
  },
  data() {
    return {
      editing: null,
      imageFile: null,
      state: {
        name: '',
        phone: '',
        address: {
          street: '',
          number: '',
          postalCode: '',
          city: '',
          country: '',
        },
        businessName: '',
        nif: '',
      },
    }
  },
  computed: {
    editingRestaurant() {
      return this.editing === 'restaurant'
    },
    editingBusiness() {
      return this.editing === 'business'
    },
  },
  watch: {
    restaurantData(newVal) {
      this.state.name = newVal.name
      this.state.phone = newVal.phone
      this.state.address = newVal.address
      this.state.businessName = newVal.businessName
      this.state.nif = newVal.nif
    },
  },
  mounted() {
    this.invalidateRestaurant()
  },
  methods: {
    ...mapActions({
      invalidateRestaurant: `restaurant/restaurant/invalidateAll`,
    }),
    handleEditClick(type) {
      this.editing = type
    },
    handleEditCancel() {
      this.editing = null
    },
    async handleEditSave() {
      this.editing = null
      if (this.imageFile) {
        try {
          const data = new FormData()
          data.append('file', this.imageFile, this.imageFile.name)
          await cmsController.patchRestaurantImage(data)
        } catch {
          this.invalidateRestaurant()
        }
      }
      try {
        await cmsController.updateRestaurant({
          ...this.state,
          restaurantBusinessName: this.state.businessName,
          restaurantName: this.state.name,
        })
      } catch {
        this.invalidateRestaurant()
      }
      this.invalidateRestaurant()
    },
  },
}
</script>
<style lang="stylus" scoped>
.restaurant-info__title
  m-font-size(26, 30)
  display: flex
  align-items: center
  margin-bottom: vw(20px)
  min-height: vh(36px)
  font-weight: 700
  font-family: "Titillium Web"
  gap: vw(10px)

.restaurant-info__subtitle
  m-font-size(20, 24)
  margin-top: vw(20px)
  margin-bottom: vw(20px)
  font-weight: 700
  font-family: "Titillium Web"

.edit-icon
  m-icon("edit", 20)

.restaurant-info__section
  margin-bottom: vh(40px)

.restaurant-info__input
  display: flex
  flex-direction: column
  margin-bottom: vh(8px)
  gap: vw(5px)

  &:not(.avatar)
    input
      padding: vw(5px) vw(10px)
      min-width: vw(100px)
      border: 1px solid $gray-5

  label
    m-font-size(14)
    text-align: left
    font-weight: $fw-thin

.restaurant-info__buttons
  display: flex
  gap: vw(10px)

input
  &:not(.editable)
    display: inline
    padding: 0
    padding: vw(10px)
    width: auto
    border: 1px solid #fff !important
    font-size: inherit
    font-family: inherit
    pointer-events: none

.address
  margin-left: vw(10px)

.avatar
  flex-direction: column
  align-items: flex-start

.avatar__content
  display: flex
  align-items: center
  margin-top: vh(10px)
  gap: vw(10px)

.image-thumbnail
  flex-shrink: 0
  margin: 0
</style>