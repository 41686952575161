<template>
  <preparation-zone-header v-if="isPreparationZone" />
  <employees-header v-else-if="isNotAdmin" />
</template>

<script>
import { ROUTE_NAMES } from '../../settings/routeNames'
import EmployeesHeader from './employees/EmployeesHeader'
import PreparationZoneHeader from './preparation-zone/PreparationZoneHeader'
export default {
  components: { EmployeesHeader, PreparationZoneHeader },
  computed: {
    isPreparationZone() {
      return this.$route.name === ROUTE_NAMES.PREPARATION_ZONE
    },
    isNotAdmin() {
      return (
        !this.$route ||
        (this.$route.matched[0].name !== ROUTE_NAMES.CMS.MAIN &&
          this.$route.name !== ROUTE_NAMES.CREATE_RESTAURANT)
      )
    },
  },
}
</script>