<template>
  <div class="basic-radio-button-group">
    <div v-if="title" class="basic-radio-button-title">{{ title }}</div>
    <basic-radio-button-row 
      v-for="item in options" 
      :key="item.id"
      v-model="selected"
      :data="item"
      :selected="selectedId === item.id" 
      :title-header="getTitleHeader(item.value)"
      :auxiliar-text="radioAuxiliarText"
    />
  </div>
</template>

<script>
import BasicRadioButtonRow from './BasicRadioButtonRow.vue'
export default {
  components: { BasicRadioButtonRow },
  model: {
    prop: 'selectedId',
    event: 'change',
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    showValueInTitle: {
      type: Boolean,
    },
    options: {
      type: Array,
      required: true,
    },
    radioAuxiliarText: {
      type: String,
      default: null,
    },
    radioTitleHeader: {
      type: String,
      default: null,
    },
    selectedId: {
      required: true,
    },
  },
  data() {
    return {
      selected: null,
    }
  },
  watch: {
    selected(newValue) {
      this.$emit('change', newValue ? newValue : null)
    },
  },
  methods: {
    getTitleHeader(value) {
      if (this.radioTitleHeader) {
        return this.radioTitleHeader
      } else if (this.showValueInTitle && value) {
        return `${value}%`
      }
      return null
    },
  },
}
</script>

<style lang="stylus" scoped>
.basic-radio-button-group
  display: flex
  flex-direction: column
  margin: 20px 0

.basic-radio-button-title
  m-font-size(12, 16)
  margin-bottom: vw(15px)
  color: #828282
  text-transform: uppercase
</style>