export const ORDER_TYPE = {
  TAKE_AWAY: {
    key: 'TAKE_AWAY',
  },
  REGULAR_TICKET: {
    key: 'REGULAR_TICKET',
  },
}

export const ORDER_STATUS = {
  TODO: {
    key: 'TODO',
    prior: 1,
    nextStatus: 'WIP',
  },
  WIP: {
    key: 'WIP',
    prior: 2,
    nextStatus: 'READY',
  },
  READY: {
    key: 'READY',
    prior: 3,
    nextStatus: 'DELIVERED',
  },
  DELIVERED: {
    key: 'DELIVERED',
    prior: 4,
    nextStatus: 'PAID',
  },
  PAID: {
    key: 'PAID',
    prior: 5,
    nextStatus: null,
  },
}

export const INDEX_OF_ORDERS = {
  APPETIZER: 0,
  FIRST: 1,
  SECOND: 2,
  DESSERT: 3,
  OTHER: -1,
}

export const PAY_SUCCESS_QUERY = 'pay-success'
export const STRIPE_PAY_SUCCESS_QUERY = 'stripe-pay-success'
export const PAY_FAILED_QUERY = 'pay-failed'
export const NOTIFICATION_QUERY = 'notification'
export const DISCOUNT_ROW_QUERY = 'discount-row'
export const DISCOUNT_ORDER_QUERY = 'discount-order'
export const DELETE_ROW_QUERY = 'delete-row'
export const EDIT_ROW_QUERY = 'edit-row'
