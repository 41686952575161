
<template>
  <nav class="tab-nav">
    <ul>
      <li v-for="tab in tabs" :key="tab.key">
        <a href="#" @click="handleClick(tab, $event)">{{ $t(tab.labelKey) }}</a>
        <div v-if="selectedTab === tab.id" class="indicator" />
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  props: {
    tabs: Array,
  },
  data() {
    return {
      selectedTab: null,
    }
  },
  watch: {
    $route(route) {
      route && this.setTab(route.name)
    }
  },
  mounted() {
    this.setTab(this.$route.name)
  },
  methods: {
    handleClick(tab, e) {
      e.preventDefault()
      this.selectedTab = tab.id
      this.$router.push({ name: tab.routeName })
    },
    setTab(routeName) {
      this.selectedTab = this.$props.tabs.find(t => t.routeName === routeName).id
    },
  },
}
</script>
<style lang="stylus" scoped>
.tab-nav
  ul
    display: flex
    gap: vw(40px)

    li
      a
        font-weight: 500
        m-font-size(14, 14)

      .indicator
        margin-top: vh(12px)
        width: 100%
        height: vh(3px)
        background-color: black
</style>