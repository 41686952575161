<template>
  <div class="invoice-qr-container">
    <invoice-header />
    <invoice-table :order="order" :order-id="order.id" />
  </div>
</template>

<script>
import InvoiceHeader from '../components/invoice/InvoiceHeader.vue'
import InvoiceTable from '../components/invoice/InvoiceTable.vue'
import OrderDataMixin from '../mixins/OrderDataMixin'

export default {
  components: { InvoiceHeader, InvoiceTable },
  mixins: [OrderDataMixin],
}
</script>

<style lang="stylus" scoped>
.invoice-qr-container
  display: flex
  flex-direction: column
</style>