<template>
  <transition name="slide" appear>
    <div class="preparation-zone-navigation-container">
      <div class="preparation-zone-navigation">
        <div class="preparation-zone-navigation-title">{{ $t('preparation_zone_navigation_title') }}</div>
        <div class="preparation-zone-navigation-options-container">
          <navigation-row
            :key="everyAreas.id"
            :preparation-area="everyAreas"
            :selected="everyAreas.id === selectedRow"
            @click="handleSelectRow"
          />
          <navigation-row
            v-for="preparationArea in preparationAreas"
            :key="preparationArea.id"
            :preparation-area="preparationArea"
            :selected="preparationArea.id === selectedRow"
            @click="handleSelectRow"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import QueryMixin from '../../../mixins/QueryMixin'
import { PREPARATION_ZONE_QUERY_KEY } from '../../../settings/navigationSettings'
import NavigationRow from './NavigationRow.vue'
export default {
  components: { NavigationRow },
  mixins: [QueryMixin],
  model: {
    prop: 'isShowNavigation',
    event: 'clickRow',
  },
  props: {
    isShowNavigation: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedRow: null,
    }
  },
  remoteComputed: {
    preparationAreas: {
      model: 'restaurant/preparationAreas',
      method: 'get',
      params: function() {
        return [this.$route.params.restaurantId]
      },
    },
  },
  computed: {
    everyAreas() {
      return {
        id: null,
        name: this.$t('preparation_zone_every_areas_title'),
      }
    },
  },
  created() {
    let queryValue = this.getQueryValue(PREPARATION_ZONE_QUERY_KEY)
    if (queryValue) this.selectedRow = parseInt(queryValue)
  },
  methods: {
    handleSelectRow(id) {
      this.selectedRow = id
      this.setQueryValue(PREPARATION_ZONE_QUERY_KEY, id)
      this.$emit('clickRow', false)
    },
  },
}
</script>

<style lang="stylus" scoped>
.preparation-zone-navigation-container
  position: absolute
  top: 0
  left: 0
  z-index: 1
  overflow: hidden
  width: 100%
  height: 100%

  &.slide-leave-active,
  &.slide-enter-active
    transition: 0.4s

  &.slide-enter,
  &.slide-leave-to
    transform: translateX(-100%)

.preparation-zone-navigation
  position: absolute
  top: 0
  left: 0
  display: flex
  flex-direction: column
  overflow-y: auto
  margin-left: vw(60px)
  padding: vw(20px)
  width: vw(300px)
  height: 100%
  background-color: #fff

.preparation-zone-navigation-title
  m-font-size(18, 24)
  margin-bottom: vw(30px)

.preparation-zone-navigation-footer-container
  margin-top: auto
</style>