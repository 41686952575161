<template>
  <div class="add-zone">
    <basic-selector
      v-model="selectedZone"
      :nullable="true"
      :options="serviceZones"
      key-attribute="id"
      :type="SELECTOR_TYPES.SMALL"
    />
    <basic-selector
      v-model="selectedTableGroup"
      :options="tableGroups"
      key-attribute="id"
      :type="SELECTOR_TYPES.SMALL"
      :nullable="true"
    />
    <div class="tables-container">
      <table-row
        v-for="(table, index) in tablesByGroups"
        :key="index"
        :table="table"
        :value="selectedTables.includes(table.id)"
        @change="handleChangeSelector($event, table.id)"
      />
    </div>
    <div class="footer-container">
      <basic-button :title="$t('navigation_add_zone')" @click.prevent="handleAdd" />
      <div class="btn-cancel btn btn--primary btn--ghost" @click="$emit('close')">{{ $t('cancel_title') }}</div>  
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BasicButton from '../../../../basic/BasicButton.vue'
import BasicSelector, { SELECTOR_TYPES } from '../../../../basic/BasicSelector.vue'
import TableRow from './TableRow.vue'
export default {
  components: { BasicSelector, TableRow, BasicButton },
  props: {
    zone: {
      type: Number,
    },
    group: {
      type: Number,
    },
  },
  data() {
    return {
      SELECTOR_TYPES,
      selectedZone: this.zone ? this.zone : null,
      selectedTableGroup: this.group ? this.group : null,
      selectedTables: [],
    }
  },
  remoteComputed: {
    serviceZones: {
      model: 'restaurant/serviceZones',
      method: 'get',
      params: function() {
        return [this.logedUser.restaurantId]
      },
    },
  },
  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
    }),
    tableGroups() {
      if (!this.selectedZone) return []
      const zoneIndex = this.serviceZones.findIndex(
        zone => parseInt(zone.id) === parseInt(this.selectedZone)
      )
      return zoneIndex !== -1 ? this.serviceZones[zoneIndex].tableGroup : []
    },
    tablesByGroups() {
      if (!this.selectedTableGroup) return []
      const groupIndex = this.tableGroups.findIndex(
        group => parseInt(group.id) === parseInt(this.selectedTableGroup)
      )
      return groupIndex !== -1 ? this.tableGroups[groupIndex].tables : []
    },
  },
  watch: {
    logedUser: {
      handler(user) {
        this.selectedTables = [...user.tables]
      },
      immediate: true,
    },
    selectedZone(newZone, oldZone) {
      if (newZone !== oldZone) this.selectedTableGroup = null
    },
  },
  methods: {
    ...mapActions({
      updateUser: 'user/updateUser',
    }),
    async handleAdd() {
      await this.updateUser({ tables: this.selectedTables })
      this.$emit('close')
    },
    handleChangeSelector(selected, tableId) {
      if (selected) this.selectedTables.push(tableId)
      else {
        const selectedIndex = this.selectedTables.findIndex(table => table === tableId)
        if (selectedIndex !== -1) this.selectedTables.splice(selectedIndex, 1)
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.add-zone
  display: flex
  flex: 1 1 0%
  flex-direction: column
  overflow: hidden
  height: 100%

  .basic-selector-container
    margin-bottom: vw(25px)

  .tables-container
    flex: 1 1 0%
    overflow-y: auto
    margin-top: vw(15px)
    margin-bottom: vw(25px)

    .table-row
      &:not(:last-child)
        margin-bottom: vw(20px)

      >>> .fake-checkbox-container
        margin-right: vw(15px)

    >>> .table-row-name
      margin-top: -0.1em

  .footer-container
    display: flex
    flex-direction: column
    align-items: center
    margin-top: auto
    margin-right: 0
    margin-bottom: 0
    margin-left: 0
    padding: 0

    .btn-cancel
      margin-bottom: vw(-14px)
      m-font-size(16, 20)
</style>