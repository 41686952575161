<template>
  <div class="cms-business-products">
    <div class="date-range-selector">
      <p>{{ $t('cms_from') }}</p>
      <FormDate v-model="from" />
      <p>{{ $t('cms_to') }}</p>
      <FormDate v-model="to" />
      <Button :label-key="'cms_update'" @click="fetchMovements" />
    </div>
    <ResourceConfigurator
      v-if="products"
      :resources="products"
      :resource-label-key="'cms_historic_products'"
      :fields-info="fieldsInfo"
      :table-values="tableValues"
    />
    
    <fav-csv-export
      :data="donwloadInfo" 
      :name-file="fileTitle"
    />
  </div>
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator.vue'
import { cmsController } from '@/services/api'
import FormDate from '../FormDate'
import Button from '../Button'
import FavCsvExport from '../FavCsvExport'
import { createArrayDataCSV } from '../../../utils/csvUtils'
export default {
  components: { ResourceConfigurator, FormDate, Button, FavCsvExport },
  data() {
    return {
      products: [],
      to: new Date().getTime(),
      from: new Date().setMonth(new Date().getMonth() - 1),
    }
  },
  computed: {
    fieldsInfo() {
      return [
        { type: 'default', name: this.$t('cms_name'), id: 'name' },
        { type: 'default', name: this.$t('cms_quantity'), id: 'quantity' },
        { type: 'money', name: this.$t('cms_total'), id: 'total' },
        { type: 'percentage', name: this.$t('cms_total_percentage'), id: 'totalPercentage' },
        { type: 'percentage', name: this.$t('cms_quantity_percentage'), id: 'quantityPercentage' },
        { type: 'default', name: this.$t('cms_category'), id: 'category' },
      ]
    },
    tableValues() {
      return this.products?.map(product => [
        this.$label(product),
        product.quantity,
        product.total,
        `${product.totalPercentage}`,
        `${product.quantityPercentage}`,
        this.$label(product.category),
      ])
    },
    donwloadInfo() {
      return createArrayDataCSV.bind(this)(this.fieldsInfo, this.tableValues)
    },
    fileTitle() {
      return `${this.$t('cms_historic_products')}___${new Date(
        this.to
      ).toLocaleString()}___${new Date(this.from).toLocaleString()}`
    },
  },
  mounted() {
    this.fetchMovements()
  },
  methods: {
    parseDate(date) {
      const d = new Date(date)
      const day = d.getDate().toString().padStart(2, 0)
      const month = d.getMonth() + 1
      return `${day}-${month.toString().padStart(2, 0)}-${d.getFullYear()}`
    },
    async fetchMovements() {
      if (!this.from || !this.to) {
        return []
      }
      try {
        const movements = await cmsController.getRestaurantCashRegisterProducts(
          this.parseDate(this.from),
          this.parseDate(this.to)
        )
        this.products = movements.map(movement => ({
          ...movement,
          deleteDisabled: !movement.movementId,
          editDisabled: !movement.movementId,
        }))
      } catch (err) {
        this.products = []
      }
    },
  },
}
</script>
<style lang="stylus" scoped>
.date-range-selector
  display: flex
  justify-content: flex-end
  align-items: center
  width: 100%
  gap: vw(10px)

  p
    margin: 0
    m-font-size(12, 12)
    color: $gray-4 // TODO replace for color var
    font-weight: 400
</style>