<template>
  <payment class="view" :order-id="orderId" />
</template>

<script>
import Payment from '../components/payment'
export default {
  components: {
    Payment
  },

  props: {
    orderId: { type: [String, Number], required: true }
  }
}
</script>
