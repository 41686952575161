<template>
  <div class="cms-business-current">
    <Dialog
      :is-open="cashDialog.isOpen"
      :closable="true"
      :scrollable-body="true"
      @close="closeCashDialog"
    >
      <template #dialog-body>
        <DynamicForm
          :title="cashFormTitle"
          :fields="cashFormFields"
          :save-action="handleCashSubmit"
          :submittable="checkboxValue || !cashRegister"
          @cancel="closeCashDialog"
        />
        <div v-if="cashDialog && cashDialog.type === 'CLOSE'" class="open-tickets-disclaimer">
          <BasicCheckbox v-model="checkboxValue" />
          {{ $t('cms_pending_tickets_disclaimer') }}
        </div>
      </template>
    </Dialog>
    <ResourceConfigurator
      v-if="dataReady"
      :edit-label-key="'cms_edit_movement'"
      :add-label-key="'cms_add_movement'"
      :extra-buttons="extraButtons"
      :resources="resources"
      :resource-label-key="'cms_current_balance'"
      :fields-info="fieldsInfo"
      :form-fields="formFields"
      :table-values="tableValues"
      :delete-resource-handler="deleteMovementHandler"
      :add-resource-handler="addMovementHandler"
      :edit-resource-handler="editMovementHandler"
      @onStartEditing="onStartEditingHandler"
      @onStartAdding="editingResource = null"
    />
    <fav-csv-export 
      :data="donwloadInfo" 
      :name-file="`${$t('cms_current_balance')}_${(new Date()).toLocaleDateString()}`"
    />
  </div>
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator.vue'
import BasicCheckbox from '../../basic/BasicCheckbox.vue'
import Dialog from '../../basic/BasicDialog'
import DynamicForm from '../dynamic-form/DynamicForm'
import { cmsController, cashController } from '../../../services/api'
import { mapActions } from 'vuex'
import { ROUTE_NAMES } from '../../../settings/routeNames'
import FavCsvExport from '../FavCsvExport'
import { createArrayDataCSV } from '../../../utils/csvUtils'
export default {
  components: { ResourceConfigurator, Dialog, DynamicForm, FavCsvExport, BasicCheckbox },
  remoteComputed: {
    movements: {
      model: 'cms/actualCashRegister',
      method: 'get',
    },
    cashRegister: {
      model: 'restaurant/cashRegister',
    },
  },
  data() {
    return {
      checkboxValue: false,
      editingResource: null,
      cashDialog: {
        isOpen: false,
        type: null,
      },
    }
  },
  computed: {
    dataReady() {
      return typeof this.cashRegister !== 'undefined' && typeof this.resources !== 'undefined'
    },
    fieldsInfo() {
      return [
        { type: 'datetime', name: this.$t('cms_date'), id: 'paymentDate' },
        { type: 'redirect', name: '', id: 'redirect' },
        { type: 'default', name: this.$t('cms_description'), id: 'description' },
        { type: 'default', name: this.$t('cms_type'), id: 'type' },
        { type: 'default', name: this.$t('cms_method'), id: 'method' },
        { type: 'default', name: this.$t('cms_user'), id: 'user' },
        { type: 'money', name: this.$t('cms_value'), id: 'value' },
      ]
    },
    donwloadInfo() {
      return createArrayDataCSV.bind(this)(this.fieldsInfo, this.tableValues)
    },
    cashFormFields() {
      return [
        {
          type: 'number',
          label: this.$t('cms_value'),
          id: 'value',
          default: 0,
        },
        {
          type: 'string',
          label: this.$t('cms_description'),
          id: 'description',
        },
      ]
    },
    tableValues() {
      return this.movements
        ? this.movements.map(movement => [
            movement.paymentDate,
            movement.invoiceId
              ? {
                  location: {
                    name: ROUTE_NAMES.INVOICE,
                    params: {
                      orderId: movement.invoiceId,
                    },
                    query: { isCurrentOrder: false },
                  },
                  label: this.$t('cms_go_to_invoice'),
                }
              : null,
            movement.description,
            movement.type,
            movement.method,
            movement.user,
            movement.value,
          ])
        : []
    },
    formFields() {
      return [
        {
          type: 'string',
          label: this.$t('cms_description'),
          id: 'description',
          default: this.editingResource ? this.editingResource.description : null,
        },
        {
          type: 'select',
          label: this.$t('cms_type'),
          id: 'type',
          options: ['ENTRADA', 'SALIDA', 'RECUENTO'].map(id => ({
            id: id,
            label: id,
            value: id,
          })),
          default: this.editingResource ? this.editingResource.type : 'ENTRADA',
        },
        {
          type: 'select',
          label: this.$t('cms_method'),
          id: 'method',
          options: ['CASH', 'CARD'].map(id => ({ id: id, label: id, value: id })),
          default: this.editingResource ? this.editingResource.method : 'CASH',
        },
        {
          type: 'number',
          label: this.$t('cms_value'),
          id: 'value',
          default: this.editingResource ? this.editingResource.value : 0,
        },
      ]
    },
    extraButtons() {
      if (this.cashRegister) {
        return [
          {
            labelKey: 'cms_close_cash',
            handler: () => {
              this.cashDialog = {
                isOpen: true,
                type: 'CLOSE',
              }
            },
          },
        ]
      }
      return [
        {
          labelKey: 'cms_open_cash',
          handler: () => {
            this.cashDialog = {
              isOpen: true,
              type: 'OPEN',
            }
          },
        },
      ]
    },
    cashFormTitle() {
      return this.cashDialog.type == 'CLOSE' ? this.$t('cms_close_cash') : this.$t('cms_open_cash')
    },
    resources() {
      if (!this.cashRegister || !this.movements) {
        return []
      }
      return this.movements.map(movement => ({
        ...movement,
        deleteDisabled: !movement.movementId || movement.type === 'APERTURA',
        editDisabled: !movement.movementId || movement.type === 'APERTURA',
      }))
    },
  },
  mounted() {
    this.invalidateMovements()
  },
  methods: {
    ...mapActions({
      invalidateMovements: `cms/actualCashRegister/invalidateAll`,
      invalidateCashRegister: `restaurant/cashRegister/invalidateAll`,
      invalidateCmsSummary: 'cms/summary/invalidateAll',
    }),
    onStartEditingHandler(resource) {
      this.editingResource = resource
    },
    handleCashSubmit(cash) {
      if (this.cashDialog.type === 'OPEN') {
        this.openCashRegistry(cash)
      }
      if (this.cashDialog.type === 'CLOSE') {
        this.closeCashRegistry(cash)
      }
    },
    closeCashDialog() {
      this.cashDialog.isOpen = false
    },
    async openCashRegistry({ value, description }) {
      await cashController.openCash({ value: Number(value), description })
      this.closeCashDialog()
      this.invalidateCashRegister()
      this.invalidateMovements()
      this.invalidateCmsSummary()
    },
    async closeCashRegistry({ value, description }) {
      await cashController.closeCash({ value: Number(value), description })
      this.closeCashDialog()
      this.invalidateCashRegister()
      this.invalidateMovements()
      this.invalidateCmsSummary()
    },
    deleteMovementHandler({ movementId }) {
      return cmsController.deleteResourceById('movement', movementId).then(this.invalidateMovements)
    },
    addMovementHandler(editedMovement) {
      if (typeof editedMovement.i18n !== undefined) {
        delete editedMovement.i18n
      }
      editedMovement.restaurantCashRegisterId = this.cashRegister.id
      editedMovement.value = Number(editedMovement.value)
      if (editedMovement.type === 'SALIDA') {
        editedMovement.value = -Math.abs(editedMovement.value)
      }
      return cmsController.addResource('movement', editedMovement).then(this.invalidateMovements)
    },
    editMovementHandler(editedMovement) {
      if (
        editedMovement.type === 'SALIDA' ||
        (this.editingResource.type === 'SALIDA' && !editedMovement.type)
      ) {
        if (
          editedMovement.value === undefined ||
          editedMovement.value === null ||
          isNaN(editedMovement.value)
        ) {
          editedMovement.value = this.editingResource.value
        }
        editedMovement.value = -Math.abs(editedMovement.value)
      }
      return cmsController
        .updateResourceById('movement', this.editingResource.movementId, editedMovement)
        .then(() => {
          this.editingResource = null
          this.invalidateMovements()
        })
    },
  },
}
</script>
<style lang="stylus" scoped>
.open-tickets-disclaimer
  display: flex
  gap: vw(10px)
  margin: vh(20px)
  m-font-size(16, 20)
</style>