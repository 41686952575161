export default {
  methods: {
    setQueryValue(key, value) {
      this.$router.replace({ query: { ...this.$route.query, [key]: value } })
    },
    getQueryValue(key) {
      return this.$route.query[key]
    },
  },
}
