<template>
  <div class="cms-view-container employees-view">
    <div class="cms-employees">
      <ResourceConfigurator
        v-if="users"
        :edit-label-key="'cms_edit_employee'"
        :add-label-key="'cms_add_employee'"
        :resources="filteredUsers"
        :resource-label-key="'cms_employees'"
        :fields-info="fieldsInfo"
        :form-fields="formFields"
        :table-values="tableValues"
        :delete-resource-handler="deleteUserHandler"
        :add-resource-handler="addUserHandler"
        :cell-click-handler="cellClickHandler"
        :edit-resource-handler="editUserHandler"
        @onStartEditing="r => editingResource = r"
        @onStartAdding="editingResource = null"
      />
      <fav-csv-export
        :data="donwloadInfo" 
        :name-file="`${$t('cms_employees')}_${(new Date()).toLocaleDateString()}`"
      />
    </div>
  </div>
</template>
<script>
import ResourceConfigurator from '@/components/cms/resource-configurator/ResourceConfigurator'
import { cmsController } from '@/services/api'
import { mapActions } from 'vuex'
import FavCsvExport from '../../components/cms/FavCsvExport'
import { createArrayDataCSV } from '../../utils/csvUtils'
import { getI18nRolName } from '../../settings/roles'
export default {
  components: { ResourceConfigurator, FavCsvExport },
  remoteComputed: {
    users: {
      model: 'cms/users',
      method: 'get',
    },
    roles: {
      model: 'cms/roles',
      method: 'get',
    },
  },
  data() {
    return {
      editingResource: null,
    }
  },
  computed: {
    filteredUsers() {
      return this.users.filter(({ isDeleted }) => !isDeleted)
    },
    rolesLabels() {
      return this.roles?.map(role => ({ ...role, label: this.$t(getI18nRolName(role.name)) })) ?? []
    },
    donwloadInfo() {
      return createArrayDataCSV.bind(this)(this.fieldsInfo, this.tableValues)
    },
    fieldsInfo() {
      return [
        { type: 'default', name: this.$t('cms_username'), id: 'username' },
        { type: 'default', name: this.$t('cms_first_name'), id: 'firstName' },
        { type: 'default', name: this.$t('cms_last_name'), id: 'lastName' },
        { type: 'boolean', name: this.$t('cms_status'), id: 'isActivated' },
        { type: 'image', name: this.$t('cms_image'), id: 'image' },
        { type: 'default', name: this.$t('cms_roles'), id: 'roles' },
      ]
    },
    tableValues() {
      return this.users?.map(user => [
        user.username,
        user.firstName,
        user.lastName,
        user.isActivated,
        user.image && user.image.url,
        user.roles.map(r => this.$t(getI18nRolName(r))).join(', '),
      ])
    },
    formFields() {
      return [
        {
          type: 'email',
          label: this.$t('cms_username'),
          id: 'username',
          required: true,
          default: this.editingResource?.username,
        },
        {
          type: 'string',
          label: this.$t('cms_first_name'),
          id: 'firstName',
          required: true,
          default: this.editingResource?.firstName,
        },
        {
          type: 'string',
          label: this.$t('cms_last_name'),
          id: 'lastName',
          required: true,
          default: this.editingResource?.lastName,
        },
        {
          type: 'password',
          id: 'password',
          required: !this.editingResource,
        },
        {
          type: 'image',
          label: this.$t('cms_image'),
          id: 'image',
          default: null,
        },
        {
          type: 'boolean',
          label: this.$t('cms_status'),
          id: 'isActivated',
          enabledLabel: this.$t('cms_active'),
          disabledLabel: this.$t('cms_not_active'),
          default: this.editingResource ? this.editingResource.isActivated : false,
        },
        {
          type: 'list',
          label: this.$t('cms_roles'),
          placeholder: this.$t('cms_roles_filter_placeholder'),
          id: 'roles',
          required: true,
          items: this.rolesLabels.map(({ label, id }) => ({ id, label: label })),
          default: this.editingResource
            ? this.editingResource.roles.map(name => this.rolesLabels.find(role => role.name === name)?.id)
            : [],
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      invalidateUsers: `cms/users/invalidateAll`,
    }),
    deleteUserHandler({ id }) {
      return cmsController
        .updateResourceById('users', id, { isDeleted: true })
        .then(this.invalidateUsers)
    },
    async cellClickHandler(fieldIndex, user) {
      if (this.fieldsInfo[fieldIndex].type !== 'boolean') {
        return
      }
      const fieldId = this.fieldsInfo[fieldIndex].id
      this.editUserHandler({ [fieldId]: !user[fieldId] }, user)
    },
    async addUserHandler(user) {
      if (user.password === null) {
        delete user.password
      }
      const { image, ...userData } = user
      const { id } = await cmsController.addResource('users', userData)
      if (image) {
        const formData = new FormData()
        formData.append('file', image, image.name)
        await cmsController.patchResourceImage('users', id, formData)
      }
      this.invalidateUsers()
    },
    async editUserHandler(editedUser) {
      const { image, ...userData } = editedUser
      if (image) {
        const formData = new FormData()
        formData.append('file', image, image.name)
        await cmsController.patchResourceImage('users', this.editingResource.id, formData)
      }
      await cmsController.updateResourceById('users', this.editingResource.id, userData)
      return this.invalidateUsers()
    },
  },
}
</script>
<style lang="stylus" scoped></style>
