<template>
  <div class="cms-categories">
    <ResourceConfigurator
      v-if="allCategories && preparationAreas && serviceOrders"
      :edit-label-key="'cms_edit_category'"
      :add-label-key="'cms_add_category'"
      :cell-click-handler="cellClickHandler"
      :resources="allCategories"
      :table-values="tableValues"
      :resource-label-key="'cms_categories'"
      :fields-info="fieldsInfo"
      :form-fields="formFields"
      :delete-resource-handler="deleteCategoryHandler"
      :add-resource-handler="addCategoryHandler"
      :edit-resource-handler="editCategoryHandler"
      @onStartEditing="onStartEditingHandler"
      @onStartAdding="editingResource = null"
    />
    <fav-csv-export 
      :data="donwloadInfo" 
      :name-file="`${$t('cms_categories')}_${(new Date()).toLocaleDateString()}`"
    />
  </div>
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator'
import CmsResourceConfigurationI18n from '@/mixins/CmsResourceConfiguratorI18n'
import { mapActions } from 'vuex'
import { cmsController } from '@/services/api'
import FavCsvExport from '../FavCsvExport'
import { createArrayDataCSV } from '../../../utils/csvUtils'
export default {
  components: { ResourceConfigurator, FavCsvExport },
  mixins: [CmsResourceConfigurationI18n],
  remoteComputed: {
    categories: {
      model: 'restaurant/categories',
      method: 'get',
    },
    preparationAreas: {
      model: 'restaurant/preparationAreas',
      method: 'get',
    },
    serviceOrders: {
      model: 'restaurant/serviceOrders',
      method: 'get',
    },
  },
  data() {
    return {
      editingResource: null,
    }
  },
  computed: {
    donwloadInfo() {
      return createArrayDataCSV.bind(this)(this.fieldsInfo, this.tableValues)
    },
    allCategories() {
      function flatten(a) {
        return a.reduce(function (flattened, { children, ...rest }) {
          return flattened.concat([rest]).concat(children ? flatten(children) : [])
        }, [])
      }
      return this.categories ? flatten(this.categories) : null
    },
    fieldsInfo() {
      const arrayFieldsParent = Array.from(new Array(this.lengthTreeCategoryParents)).map(
        (_, i) => ({
          type: 'default',
          name: this.$t('cms_parent_category') + (i ? ` ${i + 1}` : ''),
          id: 'parent_' + i,
        })
      )
      return [
        { type: 'default', name: this.$t('cms_name'), id: 'name' },
        ...arrayFieldsParent.reverse(),
        { type: 'default', name: this.$t('cms_preparation_area'), id: 'preparationArea' },
        { type: 'default', name: this.$t('cms_service_order'), id: 'serviceOrder' },
        { type: 'boolean', name: this.$t('cms_is_activated'), id: 'isActivated' },
        { type: 'default', name: this.$t('cms_category_order'), id: 'categoryOrder' },
      ]
    },

    tableValues() {
      return (
        this.allCategories &&
        this.allCategories.map(category => {
          const categoryPreparationArea = category.restaurantPreparationAreaId
            ? this.findById(this.preparationAreas, category.restaurantPreparationAreaId)
            : null

          const serviceOrderId = category.serviceOrderId
            ? this.findById(this.serviceOrders, category.serviceOrderId)
            : null
          return [
            this.$label(category),
            ...this.totalParent(category).reverse(),
            categoryPreparationArea ? categoryPreparationArea.name : null,
            serviceOrderId ? this.$label(serviceOrderId) : null,
            category.isActivated,
            category.categoryOrder,
          ]
        })
      )
    },
    formFields() {
      return [
        ...this.localizedFormFields(this.editingResource, 'name', 'cms_name'),
        {
          type: 'select',
          label: this.$t('cms_parent_category'),
          id: 'parentId',
          options: this.allCategories
            .filter(cat => this.findParentsRecursive(cat).length < 2)
            .map(category => ({
              id: category.id,
              label: this.$label(category),
              value: category.id,
            }))
            .filter(({ id }) => (this.editingResource ? id !== this.editingResource.id : true))
            .concat([{ id: null, label: this.$t('cms_none'), value: null }]),
          default: this.editingResource ? this.editingResource.parentId : null,
        },
        {
          type: 'select',
          label: this.$t('cms_preparation_area'),
          required: true,
          id: 'restaurantPreparationAreaId',
          options: this.preparationAreas
            .map(preparationArea => ({
              id: preparationArea.id,
              label: preparationArea.name,
              value: preparationArea.id,
            }))
            .concat([{ id: null, label: this.$t('cms_none'), value: null }]),
          default: this.editingResource ? this.editingResource.restaurantPreparationAreaId : null,
        },
        {
          type: 'select',
          label: this.$t('cms_service_order'),
          required: true,
          id: 'serviceOrderId',
          options: this.serviceOrders
            .map(serviceOrder => ({
              id: serviceOrder.id,
              label: this.$label(serviceOrder),
              value: serviceOrder.id,
            }))
            .concat([{ id: null, label: this.$t('cms_none'), value: null }]),
          default: this.editingResource ? this.editingResource.serviceOrderId : null,
        },
        {
          type: 'number',
          label: this.$t('cms_category_order'),
          id: 'categoryOrder',
          default: this.editingResource ? this.editingResource.categoryOrder : 1,
        },
      ]
    },
    lengthTreeCategoryParents() {
      if (!this.allCategories) return 0
      return this.allCategories.reduce(
        (max, category) => Math.max(max, this.findParentsRecursive(category).length),
        0
      )
    },
  },
  methods: {
    ...mapActions({
      invalidateCategories: `restaurant/categories/invalidateAll`,
      invalidateMenu: `restaurant/menu/invalidateAll`,
    }),
    /**
     *
     * @param {Object} category
     * @param {number[]} ids Ids in the cadene for avoid recusive infinity. The category cadene can be a cicle
     */
    findParentsRecursive(category, ids = []) {
      if (ids.includes(category.parent)) {
        console.warn('%c WE FOUND A CICLE IN CATEGORIES:' + ids, `color:red`)
        return []
      }
      if (!category.parentId) return []
      const parent = this.findById(this.allCategories, category.parentId)
      if (!parent) return []
      const nextParentsArray = this.findParentsRecursive(parent, [...ids, category.parentId])
      return [parent, ...nextParentsArray]
    },
    totalParent(category) {
      const length = this.lengthTreeCategoryParents
      const parents = this.findParentsRecursive(category)
      const l = parents.length
      return Array.from(new Array(length)).map((_, i) =>
        parents[l - i - 1] ? this.$label(parents[l - i - 1]) : ''
      )
    },
    onStartEditingHandler(category) {
      this.editingResource = category
    },
    deleteCategoryHandler({ id }) {
      return cmsController.deleteResourceById('categories', id).then(this.invalidateCategories)
    },
    findById(resources, id) {
      return resources.find(resource => resource.id === id)
    },
    addCategoryHandler(category) {
      return cmsController
        .addResource('categories', { dayOfTheWeek: 'FRIDAY', ...category })
        .then(this.invalidateCategories)
    },
    cellClickHandler(fieldIndex, resource) {
      if (this.fieldsInfo[fieldIndex].type !== 'boolean') {
        return
      }
      const fieldId = this.fieldsInfo[fieldIndex].id
      this.editCategoryHandler({ [fieldId]: !resource[fieldId] }, resource)
    },
    editCategoryHandler({ i18n, ...resource }) {
      let updateCategoryPromise = cmsController
        .updateResourceById('categories', this.editingResource.id, resource)
        .catch('Error editing category')
      return Promise.allSettled([
        i18n ? this.editI18ns(i18n, this.editingResource, 'categories') : Promise.resolve(),
        updateCategoryPromise,
      ]).then(() => {
        this.editingResource = null
        this.invalidateCategories()
        this.invalidateMenu()
      })
    },
  },
}
</script>
<style lang="stylus" scoped></style>