<template>
  <basic-dialog
    v-if="isAddZoneOpen || edit"
    is-fullscreen
    :title="$t('navigation_add_zone_dialog_title')"
    :is-open="isAddZoneOpen || !!edit "
    @close="closeZoneDialog"
  >
    <template slot="dialog-body">
      <add-zone
        v-if="edit"
        :zone="edit.serviceZone"
        :group="edit.group"
        @close="handleCloseEdit"
      />
      <add-zone v-else @close="closeZoneDialog" />
    </template>
  </basic-dialog>
  <basic-dialog
    v-else-if="open"
    is-fullscreen
    :title="$t('navigation_edit_zone_dialog_title')"
    :is-open="open"
    @close="$emit('change', false)"
  >
    <template slot="dialog-body">
      <edit-zone @close="$emit('change', false)" @add="handleSwitchAddDialog" @edit="handleEdit" />
    </template>
  </basic-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import BasicDialog from '../../../basic/BasicDialog.vue'
import AddZone from './add-zone'
import EditZone from './edit-zone'
export default {
  components: { BasicDialog, AddZone, EditZone },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isAddZoneOpen: false,
      edit: null,
    }
  },
  remoteComputed: {
    serviceZones: {
      model: 'restaurant/serviceZones',
      method: 'get',
    },
  },
  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
    }),
  },
  methods: {
    closeZoneDialog() {
      this.isAddZoneOpen = false
      this.handleCloseEdit()
    },
    handleSwitchAddDialog() {
      this.isAddZoneOpen = !this.isAddZoneOpen
      this.handleCloseEdit()
    },
    handleCloseEdit() {
      this.edit = null
    },
    handleEdit(groupId) {
      let serviceZoneIndex = this.serviceZones.findIndex(serviceZone => {
        return serviceZone.tableGroup.find(group => parseInt(group.id) === parseInt(groupId))
      })
      let serviceZoneId = this.serviceZones[serviceZoneIndex].id
      this.edit = {
        serviceZone: serviceZoneId,
        group: groupId,
      }
    },
  },
}
</script>

<style lang="stylus" scoped></style>