<template>
  <div class="cms-tables">
    <QrList v-if="tables" ref="qrList" :tables="qrList" />
    <ResourceConfigurator
      v-if="tables && tableGroups"
      :extra-buttons="extraButtons"
      :edit-label-key="'cms_tables'"
      :add-label-key="'cms_add_table'"
      :resources="tables"
      :resource-label-key="'cms_tables'"
      :fields-info="fieldsInfo"
      :form-fields="formFields"
      :table-values="tableValues"
      :download-resource-handler="downloadTableQr"
      :delete-resource-handler="deleteTableHandler"
      :add-resource-handler="addTableHandler"
      :cell-click-handler="cellClickHandler"
      :edit-resource-handler="editTableHandler"
      @onStartEditing="r => editingResource = r"
      @onStartAdding="editingResource = null"
    />
  </div>
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator'
import { cmsController } from '../../../services/api'
import { mapActions, mapGetters } from 'vuex'
import QrList from './QrList'
export default {
  components: { ResourceConfigurator, QrList },
  data() {
    return {
      tablesToQrs: [],
      editingResource: null,
    }
  },
  remoteComputed: {
    tables: {
      model: 'cms/tables',
      method: 'get',
    },
    serviceZones: {
      model: 'cms/serviceZones',
      method: 'get',
    },
    tableGroups: {
      model: 'cms/tableGroups',
      method: 'get',
    },
  },
  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
    }),
    fieldsInfo() {
      return [
        { type: 'default', name: this.$t('cms_name'), id: 'name' },
        { type: 'default', name: this.$t('cms_table_group'), id: 'tableGroupId' },
        { type: 'boolean', name: this.$t('cms_is_visible'), id: 'isVisible' },
      ]
    },
    formFields() {
      return [
        {
          type: 'string',
          label: this.$t('cms_name'),
          required: true,
          id: 'name',
          default: this.editingResource?.name,
        },
        {
          type: 'boolean',
          label: this.$t('cms_is_visible'),
          id: 'isVisible',
          enabledLabel: this.$t('cms_active'),
          disabledLabel: this.$t('cms_not_active'),
          default: (this.editingResource && this.editingResource.isVisible) || false,
        },
        {
          type: 'select',
          label: this.$t('cms_table_group'),
          required: true,
          id: 'tableGroupId',
          options: this.tableGroups.map(tg => ({ id: tg.id, label: tg.name, value: tg.id })),
          default: (this.editingResource && this.editingResource.tableGroupId) || null,
        },
      ]
    },
    tableValues(){
      return this.tables?.map(table => [
        table.name,
        this.tableGroups.find(({ id }) => id === table.tableGroupId).name,
        table.isVisible,
      ])
    },
    restaurantId() {
      if (this.logedUser) return this.logedUser.restaurantId
      return this.$route.params.restaurantId
    },
    qrList() {
      return this.tablesToQrs.map(table => {
        const tableGroup = this.getTableGroupByTable(table)
        const serviceZone = this.getServiceZoneByTableGroup(tableGroup)
        return {
          label: `${serviceZone.name} - ${tableGroup.name} - ${table.name}`,
          value: `${window.location.origin}${process.env.BASE_URL}loginQr/${this.restaurantId}/${table.id}/`,
        }
      })
    },
    extraButtons() {
      return [
        {
          labelKey: 'cms_generate_pdf',
          handler: () => {
            this.tablesToQrs = this.tables
            this.$refs.qrList.$refs.html2Pdf.generatePdf()
          },
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      invalidateTables: 'cms/tables/invalidateAll',
      invalidateTableGroups: 'cms/tableGroups/invalidateAll',
    }),
    getTableGroupByTable(table) {
      return this.tableGroups.find(({ tables }) => tables.find(({ id }) => id === table.id))
    },
    getServiceZoneByTableGroup(group) {
      return this.serviceZones.find(({ id }) => id === group.serviceZoneId)
    },
    downloadTableQr(table) {
      this.tablesToQrs = [table]
      this.$refs.qrList.$refs.html2Pdf.generatePdf()
    },
    cellClickHandler(fieldIndex, resource) {
      if (this.fieldsInfo[fieldIndex].type !== 'boolean') {
        return
      }
      const fieldId = this.fieldsInfo[fieldIndex].id
      this.editTableHandler({ [fieldId]: !resource[fieldId] }, resource)
    },
    async deleteTableHandler({ id }) {
      await cmsController.deleteResourceById('tables', id)
      this.invalidateTables()
      this.invalidateTableGroups()
    },
    async addTableHandler(table) {
      await cmsController.addResource('tables', table)
      this.invalidateTables()
      this.invalidateTableGroups()
    },
    async editTableHandler(table) {
      await cmsController.updateResourceById('tables', this.editingResource.id, table)
      this.invalidateTables()
      this.invalidateTableGroups()
    },
  },
}
</script>
<style lang="stylus" scoped></style>