<template>
  <label class="image-input">
    <input type="file" accept="image/png, image/gif, image/jpeg" @change="fileChange">
    <p>{{ inputText }}</p>
    <div class="image-icon" />
  </label>
</template>
<script>

export default {
  props: {
    value: {
      type: File,
      required: false,
    }
  },
  data() {
    return {
      localValue: this.value
    }
  },
  computed: {
    inputText() {
      return this.localValue ? this.localValue.name : this.$t('cms_choose_file')
    }
  },
  watch: {
    localValue (newValue) {
      this.$emit('input', newValue)
    },
    value (newValue) {
      this.localValue = newValue
    }
  },
  methods: {
    fileChange(e) {
      this.localValue = e.target.files[0]
    }
  }
}
</script>
<style lang="stylus" scoped>
input
  display: none
.image-input
  width: 100%
  display: flex
  cursor: pointer
  justify-content: space-between
  align-items: center
  height: vh(36px)
  padding: 0 10px
  outline: none
  border-radius: 0px
  border: 1px solid black
  height: vh(36px)
.image-icon
  m-icon('image', 15)
</style>