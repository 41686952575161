<template>
  <div class="pick-up-order-row-group">
    <pick-up-row
      v-model="collapsed"
      :data="data.representant"
      :collapsable="true"
      @check="() => handleChangeStatus(data)"
    />
    <template v-if="data.children.length > 1 && !collapsed" class="collapsed-row">
      <pick-up-row
        v-for="(child, indexChild) in data.children"
        :key="indexChild"  
        :data="child"
        @check="() => handleChangeStatus(child)"
      />
    </template>
  </div>
</template>

<script>
import OrderListDataMixin from '../../../mixins/OrderListDataMixin'
import { orderController } from '../../../services/api'
import PickUpRow from './PickUpRow.vue'

export default {
  components: { PickUpRow },
  mixins: [OrderListDataMixin],
  props: {
    order: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      collapsed: true,
    }
  },
  methods: {
    handleChangeStatus(data) {
      orderController.patchOrderMergedRowsStatus(this.orderById(this.order.id), data)
    },
    /*
    updateOrderStatus(order) {
      let status = getNextOrderStatus(order)
      orderController.patchOrder(order.id, { status })
    }
    */
  },
}
</script>

<style lang="stylus" scoped>
.pick-up-order-row-group
  &:not(:last-child)
    margin-bottom: vw(10px)

  .collapsed-row
    margin-left: auto
    width: 90%
</style>