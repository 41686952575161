import { ROUTE_NAMES } from '../../settings/routeNames'
export default [
  {
    id: 'general-settings',
    routeName: ROUTE_NAMES.CMS.SETTINGS.GENERAL_SETTINGS,
    labelKey: 'cms_general_settings',
  },
  {
    id: 'payments',
    routeName: ROUTE_NAMES.CMS.SETTINGS.PAYMENTS,
    labelKey: 'cms_payments',
  },
]
