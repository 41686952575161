<template>
  <div class="cms-section">
    <ServiceOrders class="cms-section__container" />
    <Alerts class="cms-section__container" />
    <Discounts class="cms-section__container" />
    <!-- <DeliveryRates />
    <TimeZones /> -->
  </div>
</template>
<script>
import Alerts from './Alerts'
import Discounts from './Discounts'
import ServiceOrders from './ServiceOrder.vue'
// import DeliveryRates from './DeliveryRates'
// import TimeZones from './TimeZones'
export default {
  components: {
    Discounts,
    Alerts,
    ServiceOrders,
    // DeliveryRates,
    // TimeZones,
  },
}
</script>
<style lang="stylus" scoped>
.cms-section__container
  &:not(:last-child)
    margin-bottom: vh(40px)
</style>