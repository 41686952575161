<template>
  <div class="different-payment">
    <client-payment
      v-for="(client, index) in clients"
      :key="index"
      editable
      :client="client"
      :max-price="maxPrice"
      :disabled="disabled"
      @change="dividePriceClient(index,$event)"
      @remove="$emit('remove',client)"
    /> 
    <slot />
  </div>
</template>

<script>
import ClientPayment from './ClientPayment.vue'
export default {
  components: { ClientPayment },
  model: {
    prop: 'clients',
    event: 'update:clients',
  },
  props: {
    clients: {
      type: Array,
      required: true,
    },
    maxPrice: {
      type: Number,
    },
    disabled: Boolean,
  },
  methods: {
    dividePriceClient(index, client) {
      const copy = [...this.clients]
      copy.splice(index, 1, client)
      this.$emit('update:clients', copy)
    },
  },
}
</script>

<style lang="stylus" scoped>
.different-payment
  width: 100%
</style>