<template>
  <form class="login-form-container" @submit="submitForm">
    <basic-input 
      v-model="username"
      :title="$t('login_input_user')"
      :placeholder="$t('login_input_user')"
    />
    <basic-input 
      v-model="password"
      :title="$t('login_input_password')"
      :placeholder="$t('login_input_password')"
      :type="passwordType"
    >
      <template slot="image">
        <div
          v-if="showPassword"
          class="basic-input-icon show"
          @click.prevent="switchShowPassword"
        />
        <div
          v-else
          class="basic-input-icon hide"
          @click.prevent="switchShowPassword"
        />
      </template>
    </basic-input>
    <span class="login-forgot-password">{{ $t("login_forgot_password") }}</span>
    <basic-button class="login-btn" :title="$t('login_button_title')" type="submit" />
  </form>
</template>

<script>
import BasicButton from '../basic/BasicButton.vue'
import BasicInput from '../basic/BasicInput.vue'
import { mapActions, mapGetters } from 'vuex'
import { ROUTE_NAMES } from '../../settings/routeNames'

export default {
  components: { BasicInput, BasicButton },
  data() {
    return {
      username: '',
      password: '',
      showPassword: false,
    }
  },
  computed: {
    passwordType() {
      return this.showPassword ? 'text' : 'password'
    },
  },
  methods: {
    ...mapGetters({
      userState: 'user/user',
    }),
    ...mapActions({
      login: 'user/login',
    }),
    async submitForm(e) {
      e.preventDefault()
      await this.login({ username: this.username, password: this.password })
      this.$router.push({ name: ROUTE_NAMES.ORDERS })
    },
    switchShowPassword() {
      this.showPassword = !this.showPassword
    },
  },
}
</script>

<style lang="stylus" scoped>
.login-form-container
  display: flex
  flex-direction: column

.login-forgot-password
  m-font-size(14, 20)
  margin-top: vw(10px)
  color: #828282

.basic-input-icon
  position: absolute
  top: 0
  right: 0
  margin-top: 0.1em

  &.show
    m-icon("eye-closed", 24)

  &.hide
    m-icon("eye", 24)

.login-btn
  margin-top: vw(30px)
</style>