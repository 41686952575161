import { v4 as uuidv4 } from 'uuid'
export default {
  namespaced: true,
  state: {
    data: null,
    uuid: {}, // save uuid by restaurant. with this we can trace the tickets for a user in each restaurant
  },
  mutations: {
    setData(state, data) {
      state.data = { ...data }
    },
    setUuid(state, { restaurantId, uuid }) {
      state.uuid = { ...state.uuid, [restaurantId]: uuid }
    },
    logout(state) {
      state.data = null
    },
  },
  actions: {
    setData({ commit, dispatch, state }, data) {
      dispatch('user/logout', {}, { root: true })
      const { restaurantId, tableId } = data
      commit('setData', { restaurantId, tableId })
      // create a uuid by restaurant
      if (!state.uuid[restaurantId]) {
        commit('setUuid', { restaurantId, uuid: uuidv4() })
      }
    },
    logout({ commit }) {
      commit('logout')
    },
  },
  getters: {
    getLogedQrData(state) {
      return state.data
    },
    getUuid(state) {
      if (state.data) return state.uuid[state.data.restaurantId]
      return null
    },
  },
}
