<template>
  <div class="invoice-container">
    <basic-dialog v-if="isCurrentOrder" :is-open="isDialogOpen" @close="switchIsDialogOpen">
      <template slot="dialog-body">
        <invoice-qr />
      </template>
    </basic-dialog>
    <div class="invoice-body">
      <invoice-header />
      <invoice-table
        v-if="dataLoaded"
        :order="orderData"
        :is-current-order="isCurrentOrder"
        :invoice="invoice"
      />
    </div>
    <div v-if="!userQr && isCurrentOrder" class="invoice-footer">
      <invoice-buttons :order="order" @openQR="switchIsDialogOpen" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BasicDialog from '../components/basic/BasicDialog.vue'
import InvoiceButtons from '../components/invoice/InvoiceButtons.vue'
import { cmsController, orderController } from '../services/api'
import InvoiceHeader from '../components/invoice/InvoiceHeader.vue'
import InvoiceQr from '../components/invoice/InvoiceQr'
import InvoiceTable from '../components/invoice/InvoiceTable.vue'
import OrderDataMixin from '../mixins/OrderDataMixin'
export default {
  components: {
    BasicDialog,
    InvoiceQr,
    InvoiceHeader,
    InvoiceTable,
    InvoiceButtons,
  },
  mixins: [OrderDataMixin],
  data() {
    return {
      isDialogOpen: false,
      orderInvoice: null,
      ticketInvoice: null
    }
  },
  computed: {
    ...mapGetters({
      userQr: 'userQr/getLogedQrData',
    }),
    dataLoaded() {
      return this.isCurrentOrder ? !!this.order : !!this.invoice
    },
    orderData() {
      return this.isCurrentOrder ? this.order : this.mappedInvoice
    },
    invoice() {
      return this.isCurrentOrder ? this.orderInvoice : this.ticketInvoice
    },
    mappedInvoice() {
      return {
        isPaid: true,
        calculatedPrice: {
          subTotal: this.ticketInvoice.subTotal,
          base: this.ticketInvoice.base,
          ivaCalculated: this.ticketInvoice.ivaCalculated,
          ivaUsedIntheCalculation: this.ticketInvoice.iva,
          totalCalculated: this.ticketInvoice.totalCalculated 
        },
        discountValue: this.ticketInvoice.discountCalculated,
        rows: this.ticketInvoice.rows.map(row => ({
          ...row,
          optionModifiers: [],
          ingredientModifiers: [],
          calculatedPrice: {
            price: row.unitPrice * row.quantity,
          }
        }))
      }
    },
    isCurrentOrder() {
      return this.$route.query.isCurrentOrder === true || this.$route.query.isCurrentOrder === 'true'
    }
  },
  mounted() {
    const id = this.$route.params.orderId
    if (this.isCurrentOrder) {
      orderController.getInvoiceByTicketId(id).then(inv => {
        this.orderInvoice = inv
      })
    } else {
      cmsController.getInvoiceById(id).then(inv => {
        this.ticketInvoice = inv
      })
    }
  },
  methods: {
    switchIsDialogOpen() {
      this.isDialogOpen = !this.isDialogOpen
    },
  },
}
</script>

<style lang="stylus" scoped>
.invoice-container
  position: fixed
  top: $header-height
  left: 0
  display: flex
  flex: 1 1 0%
  flex-direction: column
  overflow: hidden
  width: 100%
  height: "calc(100% - %s)" % $header-height

.invoice-body
  flex: 1 1 0%
  overflow-y: auto

.invoice-footer
  box-shadow: 0 -2px 10px 0 rgba(#000, 0.2)
</style>