<template>
  <div class="menu">
    <basic-loading v-if="isMenuUpdating && !!stripeConnectStatus" />
    <template v-else-if="sortedSelectionValues[0]">
      <div class="menu__header">
        <basic-selector
          class="main-category"
          :options="sortedSelectionValues[0].children"
          key-attribute="id"
          :type="SELECTOR_TYPES.BIG"
          :is-i18n="true"
          :value="sortedSelectionValues[1].id"
          @change="onChangeMainCategory"
        />
        <basic-tabs :value="sortedSelectionValues[2].id" @change="onChangeTabCategory">
          <basic-tab
            v-for="cat in sortedSelectionValues[1].children"
            :key="cat.id"
            :value="cat.id"
            :name="$label(cat)"
          />
        </basic-tabs>
      </div>
      <div class="menu__content">        
        <div class="subs-and-products">
          <div v-if="sortedSelectionValues[2].products" class="products">
            <product-row
              v-for="prod in sortedSelectionValues[2].products"
              :key="prod.id"
              :data="prod"
              :count="getProductCount(prod.id)"
              @click="onSelectProduct(prod.id)"
            />
          </div>
          <div v-for="subcat in sortedSelectionValues[2].children" :key="subcat.id" class="sub-cat">
            <div class="sub-cat-title">{{ $label(subcat) }}</div>
            <div class="products">
              <product-row
                v-for="prod in subcat.products"
                :key="prod.id"
                :data="prod"
                :count="getProductCount(prod.id)"
                @click="onSelectProduct(prod.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="selectedProduct">
      <edit-ticket-row 
        :product-id="selectedProduct" 
        @close="setQueryValue('product',undefined)" 
        @confirm="addToCart" 
      />
    </template>
  </div>
</template>

<script>
import QueryMixin from '../mixins/QueryMixin'
import BasicLoading from './basic/BasicLoading.vue'
import EditTicketRow from './EditTicketRow.vue'
import ProductRow from './ProductRow.vue'
import OrderDataMixin from '@/mixins/OrderDataMixin'
import BasicSelector, { SELECTOR_TYPES } from './basic/BasicSelector.vue'
import BasicTabs from './basic/BasicTabs.vue'
import BasicTab from './basic/BasicTab.vue'
import { cmsController } from '../services/api'
import { mapGetters } from 'vuex'
export default {
  components: { ProductRow, EditTicketRow, BasicLoading, BasicSelector, BasicTabs, BasicTab },
  mixins: [QueryMixin, OrderDataMixin],
  props: ['selectionIds', 'orderId', 'search'],
  data() {
    return { SELECTOR_TYPES }
  },
  asyncComputed: {
    stripeConnectStatus() {
      return cmsController.retrieveStripeAccountStatus(
        this.userQr ? this.userQr.restaurantId : undefined
      )
    },
  },
  computed: {
    ...mapGetters({
      userQr: 'userQr/getLogedQrData',
    }),
    //WARNING ME CARGUE EL "selectionIDs" al ponerle "orderId"
    //Convierte la lista de "selectionIds" a lista de "valores" de cada categoria (NOTA: los selection values empiezan con el "menu" que las selectionIds omiten...)
    /**
     * Se crea un array con cada una de las capas:
     * * Selector
     * * Tab
     * * Products and Section with Products
     *
     * la ultima capa de children son las secciones y dentro de cada uno tiene sus productos
     */
    selectionValues() {
      if (!this.menu)
        return [
          { children: [], products: [] },
          { children: [], products: [] },
          { children: [], products: [] },
        ]
      let listOfValues = [this.menu]
      while (
        listOfValues[listOfValues.length - 1].children &&
        listOfValues[listOfValues.length - 1].children.length
      ) {
        let selectionId = this.selectionIds[listOfValues.length - 1]
        let candidate = listOfValues[listOfValues.length - 1].children.find(
          c => String(c.id) === String(selectionId)
        )
        if (!candidate) candidate = listOfValues[listOfValues.length - 1].children[0]
        const products = candidate.products
        const sortedProducts = products.sort(this.sortCompareFunc)
        listOfValues.push({ ...candidate, products: sortedProducts })
      }
      return listOfValues
    },
    sortedSelectionValues() {
      const aux = this.selectionValues
      const products = this.selectionValues[2].products
      const sortedProducts = products.sort(this.sortByLabelCompareFunc).filter(p => p.isEnabled)
      const categories = this.selectionValues[1].children.filter(({ isActivated }) => isActivated)
      const sortedCategories = categories.sort(this.sortCategoriesCompareFunc)
      const subCategories = this.selectionValues[2].children.filter(
        ({ isActivated }) => isActivated
      )
      const sortedSubCategories = subCategories.sort(this.sortCategoriesCompareFunc)

      aux[1].children = sortedCategories
      aux[2].products = sortedProducts
      aux[2].children = sortedSubCategories.map(subCat => ({
        ...subCat,
        products: subCat.products.filter(p => p.isEnabled),
      }))
      if (this.search) {
        // Filtramos tanto en products (los productos sueltos) como en los productos de las secciones
        const filter = p => this.$label(p).toLowerCase().includes(this.search.toLowerCase())
        aux[2].products = aux[2].products.filter(filter)
        aux[2].children.forEach(child => {
          child.products = child.products.filter(filter)
        })
        aux[2].children = aux[2].children.filter(child => child.products.length > 0)
      }
      return aux
    },
    selectedProduct() {
      // return this.$route.query.product
      return this.getQueryValue('product')
    },
  },
  remoteComputed: {
    menu: {
      model: 'restaurant/menu',
    },
  },
  methods: {
    sortByLabelCompareFunc(a, b) {
      if (this.$label(a) < this.$label(b)) {
        return -1
      }
      if (this.$label(a) > this.$label(b)) {
        return 1
      }
      return 0
    },
    sortCategoriesCompareFunc(a, b) {
      if (a.categoryOrder < b.categoryOrder) {
        return -1
      }
      if (a.categoryOrder > b.categoryOrder) {
        return 1
      }
      return this.sortByLabelCompareFunc(a, b)
    },
    onChangeMainCategory(id) {
      this.$router.push({ params: { categoriesString: id } })
    },
    onChangeTabCategory(id) {
      this.$router.push({
        params: { categoriesString: `${this.selectionValues[1].id}_${id}` },
      })
    },
    onSelectProduct(id) {
      if (
        !this.userQr ||
        this.stripeConnectStatus.connectStatus === 'stripe_connect_status_verified'
      ) {
        this.setQueryValue('product', id)
      }
    },
    addToCart(value) {
      // QUITAMOS El .quantity del ticketRow y lo metemos tantas veces como haga falta en el order
      let { quantity, ...childValue } = value
      let auxRows = [...this.order.rows]
      Array.from(Array(quantity)).forEach(() => {
        auxRows.push({ productId: this.selectedProduct, ...childValue })
      })
      this.orderDispatch('patchOrder', { ...this.order, rows: auxRows })
      // this.$router.push({query:{product: null}})
      this.setQueryValue('product', undefined)
    },
    getProductCount(productId) {
      return this.order.rows
        .filter(d => String(d.productId) === String(productId))
        .reduce((count, p) => {
          return count + (p.quantity || 1)
        }, 0)
    },
  },
}
</script>
<style lang="stylus" scoped>
$bottom-actions-height = vh(240px)
$bottom-actions-padding = vh(-240px)

.menu
  display: flex
  flex-direction: column
  overflow: hidden
  height: 100%

.menu__header
  display: flex
  flex-direction: column

  .is-client &
    padding-top: vw(20px)

.tab-header
  position: relative
  display: flex
  overflow-x: auto

  &:after
    position: absolute
    bottom: 2px
    left: 0
    width: 100%
    height: 1px
    background-color: #CFD8DC
    content: ""

  .tab
    m-font-size(16, 20)
    position: relative
    overflow: hidden
    padding: vw(14px) vw(30px) vw(17px)
    font-weight: $fw-semi-bold

    &:after
      position: absolute
      bottom: 0
      left: 0
      z-index: 1
      width: 100%
      height: 5px
      background-color: #000
      content: ""
      transition: transform 0.25s ease-in-out
      transform: translateX(-110%)

    &.selected
      &:after
        transform: translateX(0)

.menu__content
  display: flex
  flex: 1 1 0%
  flex-direction: column
  justify-content: space-between
  overflow-y: auto

  .has-bottom-actions &
    margin-bottom: $basic-bottom-actions-height

  .subs-and-products
    .sub-cat
      margin: 10px
      border: solid 1px #fff

      .sub-cat-title
        m-font-size(20, 32)

    .product-row
      padding: vw(18px) vw(14px)
      border-bottom: 1px solid #E0E0E0
</style>