<template>
  <div class="basic-hour-input-container">
    <div class="basic-hour-input-title">{{ title }}</div>
    <div class="basic-hour-input-row">
      <div class="basic-hour-input-wrapper">
        <input
          type="number" 
          :value="dateHours" 
          min="0"
          max="24"
          @input="$emit('input', setHours($event.target.value))"
        >
      </div>
      <div class="basic-hour-input-separator">:</div>
      <div class="basic-hour-input-wrapper">
        <input 
          type="number" 
          :value="dateMinutes" 
          min="0"
          max="59"
          @input="$emit('input', setMinutes($event.target.value))"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'date',
    event: 'input',
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    date: {
      required: true,
    },
  },
  computed: {
    dateHours() {
      return this.date.getHours()
    },
    dateMinutes() {
      return this.date.getMinutes()
    },
  },
  methods: {
    setHours(hours) {
      let dateAux = new Date(this.date)
      if (hours && hours <= 24 && hours >= 0) dateAux.setHours(parseInt(hours))
      if (!hours) dateAux.setHours(0)
      return dateAux
    },
    setMinutes(minutes) {
      let dateAux = new Date(this.date)
      if (minutes && minutes < 60 && minutes >= 0) dateAux.setMinutes(parseInt(minutes))
      if (!minutes) dateAux.setMinutes(0)
      return dateAux
    },
  },
}
</script>

<style lang="stylus" scoped>
.basic-hour-input-container
  display: flex
  flex-direction: column
  align-items: center
  margin-top: vw(30px)
  margin-bottom: vw(40px)

.basic-hour-input-title
  m-font-size(16, 24)
  margin-bottom: vw(15px)
  color: #828282

.basic-hour-input-row
  display: flex
  justify-content: center
  align-items: center
  width: 100%

.basic-hour-input-wrapper
  display: flex
  justify-content: center
  align-items: center
  width: vw(100px)
  height: vw(80px)
  background-color: rgba(#2c3d5b, 0.15)

  > input
    m-font-size(24, 30)
    margin: 0
    padding: 0
    padding: vw(10px)
    width: 100%
    border: none
    background: none
    color: #828282
    text-align: center

    &:focus
      outline: none
      color: #000

.basic-hour-input-separator
  m-font-size(24)
  margin-right: vw(10px)
  margin-left: vw(10px)
  color: #828282
  font-weight: 600
</style>