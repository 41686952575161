export default {
  welcome_to_bestiario: 'This is a new Bestiario_ project',

  back: 'Volver',
  yes: 'Yes',
  no: 'No',
  accept_title: 'Aceptar',
  cancel_title: 'Cancelar',

  //Selectors
  select_option: 'Selecciona',

  //Login
  login_wellcome: 'Hello!',
  login_input_user: 'Usuario',
  login_input_password: 'Contraseña',
  login_forgot_password: 'Olvidaste la contraseña?',
  login_button_title: 'Ingresar',

  //Navigation
  navigation_button_waiter: 'Camarero',
  navigation_button_runner: 'Runner',
  navigation_button_cooker: 'Cocinero',
  navigation_button_rider: 'Repartidor',
  navigation_button_manager: 'Manager',
  navigation_button_admin: 'Admin',
  navigation_button_assigned_zone: 'Zona Asignada',
  navigation_button_orders: 'Ordenes',
  navigation_button_pick_up: 'Recoger',
  navigation_button_takeaways: 'Takeaways',
  navigation_button_cook: 'Cocina',
  navigation_logout: 'Cerrar sesión',
  navigation_edit_assigned_zone: 'Editar zona asignada',
  navigation_edit_zone_dialog_title: 'Editar zona asignada',
  navigation_edit_zone_tables_title: 'Mesas',
  navigation_add_zone_dialog_title: 'Agregar zona',
  navigation_add_zone: 'Agregar zona',

  //OrdersOptions
  order_option_label_my_zone: 'My zone',
  order_option_label_other_zones: 'All zones',
  order_option_label_takeaway: 'Takeaway',
  order_option_label_my_orders: 'Mis ordenes',
  order_option_label_pick_up: 'Para recoger',

  //Notifications
  notification_btn_send: 'Enviar notificación',
  notification_text_area_placeholder: 'Escriba la notificación',

  //Home
  home_button_create_order: 'Crear Orden',
  home_button_takeaway: 'Takeaway',
  //Takeaway
  take_away_radio_platform_delivery: 'Plataforma delivery',
  takeaway_input_platform_name: 'Nombre de la plataforma',
  takeaway_input_order_number: 'Num. pedido plataforma',
  takeaway_input_client_name: 'Nombre de Cliente',
  takeaway_input_client_phone: 'Tel. del Cliente',
  takeaway_input_client_email: 'Mail del Cliente',
  takeaway_input_hour: 'Programar hora de recogida',
  takeaway_button_take_note: 'Tomar pedido',

  //SetupOrder
  setup_order_assigned_zone_title: 'Zona asignada',
  setup_order_table_name: 'Mesa',
  setup_order_dinners_title: 'Comensales',
  setup_order_button_take_note: 'Tomar pedido',

  //EditOrder
  see_ticket: 'Ver pedido',
  order: 'Order',
  orders: 'Orders',
  edit_order_add_comment: 'Añadir comentarios',
  edit_order_confirm: 'Confirmar',

  //Order statuses
  order_paid: 'Pagado',
  order_status_paid: 'Pagado',
  order_status_ready: 'Listo',
  order_status_todo: 'Por Hacer',
  order_status_wip: 'En proceso',
  order_status_delivered: 'Servido',

  // Order Order of tickets
  order_of_ticket_row: 'Orden de salida',
  order_of_ticket_APPETIZER: 'Entrante',
  order_of_ticket_FIRST: 'Primero',
  order_of_ticket_SECOND: 'Segundo',
  order_of_ticket_DESSERT: 'Postre',
  order_of_ticket_OTHER: 'Otros',

  //PreparationZone
  preparation_zone_incoming_orders_title: 'Ordenes entrantes',
  preparation_zone_preparation_orders_title: 'En preparación',
  preparation_zone_ready_orders_title: 'Listo',
  preparation_zone_order_name: 'Orden',
  preparation_zone_order_num_table: 'Mesa',
  preparation_zone_customers: 'Customers',
  preparation_zone_take_away: 'Take away',
  preparation_zone_qr_client: 'Cliente QR',
  preparation_zone_note_title: 'Nota',
  preparation_zone_products_right_text: 'Productos',
  preparation_zone_navigation_title: 'Otras zonas',
  preparation_zone_every_areas_title: 'Todas las areas',

  //Invoice
  //Invoice table
  invoice_table_header_uds: 'Uds.',
  invoice_table_header_description: 'Descripción',
  invoice_table_header_price: 'Price',
  invoice_table_header_discount: 'Discount',
  invoice_table_header_total: 'Total',
  invoice_table_base: 'Base',
  invoice_table_iva: 'iva',
  invoice_table_total_taxes_included: 'Total (Impuestos incl.)',
  invoice_pay_button: 'Realizar cobro',
  invoice_qr_button: 'Generar QR',
  invoice_show_order_status: 'Ver estado de la orden',

  //Order
  order_title: 'Order',
  order_back_to_meu: 'Volver a la carta',
  order_dialog_success_description: 'Su pedido se ha creado con éxito',
  order_dialog_success_btn_title: 'Volver a la orden',
  order_dialog_warning_description: 'Seguro que quiere eliminar este item de la orden?',
  order_dialog_warning_btn_title: 'Eliminar',
  order_payment_ticket_button: 'Ver ticket de pago',
  order_cancel_button: 'Cancelar orden',
  order_validate_button: 'Confirmar orden',
  post_order_dialog_error:
    'The order could not be created. This table already has an active order.',
  order_confirm_button: 'Confirmar orden',
  order_payment_button: 'Pagar',
  order_ticket_button: 'Ver ticket',
  new_order: 'Nueva orden',
  cancel_order_dialog: 'Seguro que quieres cancelar esta orden?',
  order_payed_products: 'Productos pagados',
  order_unpayed_products: 'Productos sin pagar',
  order_menu_search_product: 'Buscar producto',
  //Payment
  card_number: 'Número de tarjeta',
  card_expiry: 'Caducidad',
  card_cvc: 'CVC',
  send_invoice: 'Enviar ticket al correo',
  send: 'Enviar',
  email: 'Email',
  pay_done: 'Se realizo el pago de',
  surplus: 'sobra',
  missing: 'falta',
  rest: 'resto',
  payment_error: 'There was an error processing the payment',
  pay: 'Pay',

  //Orders
  //tabs
  orders_tabs_orders: 'Orders',
  orders_tabs_pick_up: 'Recoger',

  //PickUp
  orders_header_table: 'Mesa',
  orders_header_takeaway: 'Takeaway',

  //Discounts
  apply_discount_title: 'Aplicar descuento',

  // Roles
  role_admin: 'Admin',
  role_cooker: 'Cook',
  role_manager: 'Manager',
  role_rider: 'Rider',
  role_runner: 'Runner',
  role_waiter: 'Waiter',

  //CMS
  cms_something_went_wrong: 'Something went wrong',
  cms_confirm_delete_message: 'Are you sure you want to delete this?',
  cms_confirm: 'Confirm',
  cms_navigation_activity: 'Activity',
  cms_navigation_administration: 'ADMINISTRATION',
  cms_navigation_business: 'Business',
  cms_navigation_restaurant: 'Restaurant',
  cms_navigation_menu: 'Menu',
  cms_navigation_employees: 'Employees',
  cms_navigation_clients: 'Clients',
  cms_navigation_settings: 'Settings',
  cms_save: 'Save',
  cms_cancel: 'Cancel',
  cms_none: '',
  cms_filter_placeholder: 'Search',
  //Activity
  cms_activity_summary: 'Activity summary',
  cms_activity_summary_current: 'Current activity summary',
  cms_from: 'From',
  cms_to: 'To',
  cms_sells_historic: 'Sells historic',
  cms_total_incomes: 'Total income',
  cms_credit_card_incomes: 'Credit card income',
  cms_cash_incomes: 'Cash income',
  cms_most_selled_products: 'Most selled products',
  cms_stripe_incomes: 'Stripe income',
  cms_datepicker_yesterday: 'Yesterday',
  cms_datepicker_last_week: 'last_week',
  cms_datepicker_this_week: 'this_week',
  cms_datepicker_this_month: 'this_month',
  cms_datepicker_last_month: 'last_month',
  cms_activity_comensals: 'Comensal number',
  cms_average_ticket: 'Average Ticket',

  //Business
  // Current
  cms_go_to_invoice: 'Go to invoice',
  cms_current_balance: 'Current balance',
  cms_current: 'Current',
  cms_historic: 'Historic',
  cms_liquidation: 'Liquidation',
  cms_date: 'Date',
  cms_method: 'Method',
  cms_user: 'User',
  cms_close_cash: 'Close cash register',
  cms_open_cash: 'Open cash register',
  cms_add_movement: 'Add movement',
  cms_edit_movement: 'Edit movement',
  cms_pending_tickets_disclaimer:
    'Es recomendable que no existan tickets abiertos antes de cerrar la caja.',
  // Historic
  cms_historic_balance: 'Historic balance',
  cms_open_date: 'Open date',
  cms_close_date: 'Close date',
  cms_closing_value: 'Closing value',
  cms_total_card_value: 'Total card  value',
  cms_total_cash_valie: 'Total cash value',
  // Liquidation
  cms_historic_liquidation: 'Historic liquidation',
  cms_online_incomes: 'QR incomes',
  cms_money_incomes: 'Cash incomes',
  cms_card_incomes: 'Card incomes',
  cms_total: 'Total',
  cms_update: 'Update',
  // Products
  cms_histoic_products: 'Historic by products',
  cms_quantity: 'Quantity',
  cms_total_percentage: 'Total percentage',
  cms_quantity_percentage: 'Quantity percentage',
  cms_ingredients_filter_placeholder: 'Search',

  //Settings
  cms_general_settings: 'General settings',
  cms_service_settings: 'Service settings',
  cms_restaurant_settings: 'Restaurant settings',
  cms_add_message: 'Add message',
  cms_edit_message: 'Edit message',
  cms_messages: 'Messages',
  cms_language: 'Language',
  cms_currency: 'Currency',
  cms_message: 'Message',
  cms_actions: 'Actions',
  cms_service_order: 'Service order',
  cms_than_kyou_message: 'Thank you message',
  cms_name: 'Name',
  cms_priority: 'Priority',
  cms_add_order: 'Add order',
  cms_edit_order: 'Edit order',
  cms_discounts: 'Discounts',
  cms_description: 'Description',
  cms_type: 'Type',
  cms_value: 'Value',
  cms_active: 'Active',
  cms_not_active: 'Not active',
  cms_edit_discount: 'Edit discount',
  cms_add_discount: 'Add discount',
  cms_edit_alert: 'Edit alert',
  cms_add_alert: 'Add alert',
  cms_alerts: 'Alerts',
  cms_restaurant_info: 'Restaurant info',
  cms_phone: 'Phone',
  cms_avatar: 'Avatar',
  cms_address: 'Address',
  cms_street: 'Street',
  cms_number: 'Number',
  cms_postal_code: 'Postal code',
  cms_city: 'City',
  cms_country: 'Country',
  cms_business_info: 'Business info',
  cms_cif: 'CIF',
  cms_add_delivery_rate: 'Add delivery rate',
  cms_edit_delivery_rate: 'Edit delivery rate',
  cms_cost: 'Cost',
  cms_limit_distance: 'Limit distance',
  cms_products_filter_placeholder: 'Search product',
  cms_minimum_cost_for_free: 'Minimum cost for free',
  cms_delivery_rate: 'Delivery rate',
  cms_time_zone: 'Time zone',
  cms_start_date: 'Start date',
  cms_end_date: 'End date',
  cms_is_visible: 'Is visible',
  cms_add_time_zone: 'Add time zone',
  cms_edit_time_zone: 'Edit time zone',
  cms_export: 'Export',

  //Payments
  cms_payments: 'Payments',
  cms_payments_title: 'Stripe',
  cms_payments_status: 'Status',
  cms_payments_details: 'Details',
  stripe_connect_action_connect: 'Connect',
  stripe_connect_action_verified: 'Verified',
  stripe_connect_action_review: 'Review',
  //Menu
  cms_categories: 'Categories',
  cms_category_order: 'Category order',
  cms_day_of_the_week: 'Day of the week',
  cms_is_menu: 'Is menu',
  cms_parent_category: 'Parent category',
  cms_preparation_area: 'Preparation area',
  cms_add_category: 'Add category',
  cms_edit_category: 'Edit category',
  cms_products: 'Products',
  cms_price: 'Price',
  cms_add_product: 'Add product',
  cms_edit_product: 'Edit product',
  cms_category: 'Category',
  cms_is_activated: 'Activated',
  cms_ingredient_modifier_title: 'Ingredient modifiers',
  cms_option_modifier_title: 'Option modifiers',
  cms_ingredient_modifier: 'INGREDIENT MODIFIER',
  cms_option_modifier: 'OPTION MODIFIER',
  cms_available: 'Available',
  cms_ingredients: 'Ingredients',
  cms_total_stripe_value: 'Total stripe value',
  cms_options: 'Optiones',
  cms_extra: 'Extra',
  cms_remove: 'Remove',
  //Products
  cms_add_options_modifier: 'Add options modifier',
  cms_add_ingredients_modifier: 'Add ingredients modifier',
  cms_choose_file: 'Choose file',
  cms_image: 'Image',
  cms_ingredient_filter_placeholder: 'Search ingredient',
  cms_age_restricted: 'Requieres minimum age',
  //Ingredient modifiers
  cms_edit_ingredients_modifier: 'Edit ingredient modifier',
  //Option modifiers
  cms_is_required: 'Is required',
  cms_options_filter_placeholder: 'Search option',
  cms_add_option_modifier: 'Add option modifier',
  cms_edit_option_modifier: 'Edit option modifier',
  //Ingredients
  cms_add_ingrdient: 'Add ingredient',
  cms_edit_ingredient: 'Edit ingredient',
  //Options
  cms_add_option: 'Add option',
  cms_edit_option: 'Edit option',

  //Employees
  cms_employees: 'Employees',
  cms_username: 'Username (email)',
  cms_first_name: 'First name',
  cms_last_name: 'Last name',
  cms_status: 'Status',
  cms_roles: 'Roles',
  cms_roles_filter_placeholder: 'Search role',
  cms_add_employee: 'Add employee',
  cms_edit_employee: 'Edit employee',
  cms_password: 'Password',
  cms_edit_password: 'Edit password',
  cms_short_password_error: 'The password should have at least 9 characters',
  cms_password_missmatch_error: 'The passwords do not match',

  //Clients
  cms_clients: 'Clients',
  cms_email: 'Email',
  cms_add_client: 'Add client',
  cms_edit_client: 'Edit client',

  //Restaurant
  //Preparation areas
  cms_preparation_areas: 'Preparation areas',
  cms_restaurant_layout: 'Restaurant layout',
  cms_icon: 'Icon',
  cms_add_area: 'Add area',
  cms_edit_area: 'Edit area',
  // Service zones
  cms_service_zones: 'Service zones',
  cms_add_zone: 'Add zone',
  cms_edit_zone: 'Edit zone',
  // Table groups
  cms_table_groups: 'Table groups',
  cms_complete_name: 'Complete name',
  cms_service_zone: 'Service zone',
  cms_is_not_visible: 'Is not available',
  cms_add_group: 'Add group',
  cms_edit_group: 'Edit group',
  // Tables
  cms_tables: 'Tables',
  cms_table_group: 'Table group',
  cms_generate_pdf: 'Generate pdf',
  cms_add_table: 'Add table',
  cms_edit_table: 'Edit table',

  // CMS ERRORS
  cms_preparation_area_linked_to_products_error:
    'No se puede eliminar esta zona porque tiene productos asociados.', // The RestaurantPreparationArea cannot be deleted. There are products linked to this area.
  cms_preparation_area_linked_to_categories_error:
    'No se puede  eliminar esta zona porque tiene categorias asociadas.', // The RestaurantPreparationArea cannot be deleted. There are categories linked to this area.
  cms_option_linked_to_option_modifiers_error:
    'No se puede eliminar esta opcion porque tiene modificadores asociados.', // The Option cannot be deleted. There are OptionModifiers linked to it.
  cms_service_zone_linked_to_table_groups_error:
    'No se puede eliminar  esta  zona porque tiene grupos de mesas asociados.', // Cannot be deleted, There are table groups linked to this service zone { %s }
  cms_ingredient_modifiers_linked_to_product_error:
    'No se puede eliminar este modificador porque tiene productos asociados.', // The ingredientModifier cannot be deleted. There are Products linked to it.
  cms_ingredient_linked_to_ingredient_modifiers_error:
    'No se puede eliminar este ingredientee porque tiene modificadores asociados.', // The ingredient cannot be deleted. There are IngredientModifiers linked to it.
  cms_ingredient_linked_to_products_error:
    'No se puede eliminar este ingrediente porque tiene productos asociados.', // The ingredient cannot be deleted. There are Products linked to it.
  cms_option_modifiers_linked_to_products_error:
    'No se puede eliminar este modificador porque tiene productos asociados.', // The optionModifier cannot be deleted. There are Products linked to it.
  cms_table_groups_linked_to_tables_error:
    'No  se puede borrar este grupo porque tiene mesas asociadas.', // The TableGroup cannot be deleted. There are tables linked to it.
  cms_service_order_linked_to_products_error:
    'No se puede eliminar este orden porque tiene productos asociados.', // The serviceOrder cannot be deleted. There are products linked to it.
  cms_service_order_linked_to_categories_error:
    'No se puede elminar este orden porque tiene categorias asociadas.', // The serviceOrder cannot be deleted. There are categories linked to it.
  cms_service_order_linked_to_ticket_row_error:
    'No se puede eliminar este orden porque tiene tickets asociados.', // The serviceOrder cannot be deleted. There are ticketRows linked to it.
  cms_category_linked_to_products_error:
    'No se puede eliminar esta categoria porque tiene productos asociados.', // The Category cannot be deleted. There are products linked to this category.
  cms_category_linked_to_child_categories_error:
    'No se puede eliminar esta categoria porque tiene subcategorias asociadas.', // The Category cannot be deleted. There are other categories below this.
  cms_product_linked_to_ticket_row_error:
    'No se puede eliminar este producto porque tiene tickets asociados.', // The product cannot be deleted. There are TicketRows linked to this product.
}
