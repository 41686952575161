<template>
  <div class="cms-view-container cms-tabs-view">
    <div class="cms-tabs-view__nav">
      <TabNav :tabs="tabs" />
    </div>
    <router-view />
  </div>
</template>
<script>
import TabNav from '../../components/cms/TabNavigation'
export default {
  components: { TabNav },
  props: {
    tabs: {
      type: Array,
      required: true
    }
  },
}
</script>
<style lang="stylus">
.cms-tabs-view__nav
  margin-bottom: vw(40px)

.cms-tabs-view__header
  display: flex
  justify-content: space-between
  align-items: flex-end

.cms-tabs-view__title
  margin: 0
  margin-top: vh(30px)
  m-font-size(26, 26)
  word-spacing: vw(-5px)
  font-weight: 700

.cms-tabs-view__buttons
  display: flex
  gap: vw(15px)
</style>
