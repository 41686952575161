<template>
  <div class="employees-header-container">
    <div class="header">
      <burger-icon v-model="isShowNavigation" />
      <basic-button class="orders-shortcut" @click.prevent="handleClickOrders">Orders</basic-button>
    </div>
    <navigation v-if="isShowNavigation" v-model="isShowNavigation" /> 
  </div>
</template>

<script>
import { ROUTE_NAMES } from '../../../settings/routeNames'
import BasicButton from '../../basic/BasicButton.vue'
import BurgerIcon from '../BurgerIcon.vue'
import Navigation from './Navigation.vue'

export default {
  components: { Navigation, BurgerIcon, BasicButton },
  data() {
    return {
      isShowNavigation: false,
    }
  },
  methods: {
    handleClickOrders() {
      this.$router.push('/' + ROUTE_NAMES.ORDERS)
    },
  },
}
</script>

<style lang="stylus" scoped>
.header
  position: fixed
  top: 0
  left: 0
  display: flex
  z-index: 1
  justify-content: space-between
  align-items: center
  padding: vw(15px)
  width: 100%
  height: $header-height

  .burger-icon
    $children = 3
    $width = vw(20px)
    $height = "calc(%s - (%s / %s) + %s)" % ($width $width $children $children)
    $height = vw(16px)
    $border-height = 2px
    position: relative
    width: $width
    height: $height

    &.open
      > span
        &:nth-child(1)
          top: "calc(%s / %s * -1)" % ($border-height $children)
          left: "calc(%s - 1px)" % $border-height
          transform: rotate(45deg)

        &:nth-child(2)
          opacity: 0

        &:nth-child(3)
          left: "calc(%s - 1px)" % $border-height
          transform: rotate(-45deg)

    > span
      position: absolute
      left: 0
      display: block
      width: 100%
      height: $border-height
      border-radius: $border-height
      background-color: #000
      transition: all 0.25s ease-in-out

      &:nth-child(1)
        top: 0
        transform-origin: left center

      &:nth-child(2)
        top: 50%
        transform: translateY(-50%)

      &:nth-child(3)
        top: "calc(%s - %s)" % ($height $border-height)
        transform-origin: left center

  .orders-shortcut
    m-font-size(14, 16)
    padding: 0.5em 0.75em
    padding-top: 0.45em
    width: auto
</style>