<template>
  <div class="ticket-row-notification">
    <span v-if="!alerts">Loading</span>
    <template v-else>
      <ticket-row :data="row" :editable="false" />
      <basic-selector 
        v-model="notificationSelector"
        :nullable="true"
        :options="alerts"
        :type="SELECTOR_TYPES.SMALL"
        key-attribute="id" 
      />
      <textarea
        v-model="notificationTextArea"
        class="ticket-row-textarea"
        :placeholder="$t('notification_text_area_placeholder')"
        :maxlength="150"
      />
      <div class="buttons">
        <basic-button :title="$t('notification_btn_send')" @click="sendNotification" />
        <basic-button class="btn-cancel btn btn--primary btn--ghost" :title="$t('cancel_title')" @click="$emit('close')" />
        <!-- <span class="cancel" @click="$emit('close')">{{ $t('cancel_title') }}</span>   -->
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BasicButton from '../basic/BasicButton.vue'
import BasicSelector, { SELECTOR_TYPES } from '../basic/BasicSelector.vue'
import TicketRow from './TicketRow.vue'
export default {
  components: { BasicSelector, TicketRow, BasicButton },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      SELECTOR_TYPES,
      notificationSelector: this.row.ticketRowAlertId ? this.row.ticketRowAlertId : null,
      notificationTextArea: this.row.ticketRowAlertComment ? this.row.ticketRowAlertComment : null,
    }
  },
  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
    }),
  },
  remoteComputed: {
    alerts: {
      model: 'restaurant/alerts',
      method: 'get',
    },
  },
  methods: {
    sendNotification() {
      let notification = { ticketRowAlertId: null, ticketRowAlertComment: null }
      if (this.notificationSelector || this.notificationTextArea) {
        if (this.notificationSelector) {
          notification.ticketRowAlertId = parseInt(this.notificationSelector)
        }
        if (this.notificationTextArea) {
          notification.ticketRowAlertComment = this.notificationTextArea
        }
      }
      this.$emit('confirm', notification)
    },
  },
}
</script>

<style lang="stylus" scoped>
.ticket-row-notification
  display: flex
  flex-direction: column

  .basic-selector-container
    margin-top: vw(10px)
    margin-bottom: vw(30px)

  .ticket-row-textarea
    margin-bottom: vw(30px)
    max-height: 25vh

  .buttons
    display: flex
    flex-direction: column
    align-items: center

  .btn-cancel
    margin-top: vw(-20px)
    margin-bottom: vw(-10px)
</style>