<template>
  <div class="password-input">
    <div v-if="!required" class="password-input__checkbox">
      <input v-model="enabled" type="checkbox"><label>{{ $t('cms_change_password') }}</label>
    </div>
    <div v-if="enabled" class="password-input__inputs">
      <div class="password-input__field">
        <label>{{ `${$t('cms_password')} ${required ? ' *' : ''}` }}</label>
        <input v-model="password" type="password">
        <p v-if="shortPassword" class="password-error-message">{{ this.$t('cms_short_password_error') }}</p>
      </div>
      <div class="password-input__field">
        <label>{{ `${$t('cms_confirm_password')} ${required ? ' *' : ''}` }}</label>
        <input v-model="confirmPassword" type="password">
        <p v-if="passwordMissmatch" class="password-error-message">{{ this.$t('cms_password_missmatch_error') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    required: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      enabled: this.$props.required,
      password: null,
      confirmPassword: null
    }
  },
  computed: {
    isValid() {
      return !this.enabled || !this.passwordMissmatch && !this.shortPassword
    },
    shortPassword() {
      return this.password && this.password.length <= 8
    },
    passwordMissmatch() {
      return this.password !== this.confirmPassword
    },
  },
  watch: {
    password(newVal) {
      this.$emit('input', { password: newVal, confirmPassword: this.confirmPassword, isValid: this.isValid })
    },
    confirmPassword(newVal) {
      this.$emit('input', { password: this.password, confirmPassword: newVal, isValid: this.isValid })
    },
  },
}
</script>
<style lang="stylus" scoped>
.password-input
  margin-bottom: vh(15px)
.password-input__inputs
  display: flex
  gap: vw(20px)
  flex-direction: column
.password-input__field
  display: flex
  gap: vw(5px)
  position: relative
  flex-direction: column
.password-error-message
  position: absolute
  margin: 0
  top: vh(60px)
  m-font-size(10, 10)
  color: red
.password-input__checkbox
  display: flex
  justify-content: flex-start
  gap: vw(5px)
  align-items: center
  margin-bottom: vh(10px)
input[type="checkbox"]
  width: vw(10px)
  height: vw(10px)
</style>