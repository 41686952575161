<template>
  <div v-if="ticketSettings" class="invoice-message">
    <div v-if="ticketSettings.i18n" class="thankyou-message">{{ $description(ticketSettings) }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  remoteComputed: {
    ticketSettings: {
      model: 'restaurant/ticketSettings',
      method: 'get',
    },
  },
  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
    }),
  },
}
</script>

<style lang="stylus" scoped>
.invoice-message
  m-font-size(16, 20)
  display: flex
  flex-direction: column
  align-items: center
  margin-top: vw(10px)
  margin-bottom: vw(30px)
  padding: 0 vw(16px)
  text-align: center
  text-transform: uppercase
  font-weight: 500

  &:empty
    display: none
</style>