<template>
  <div class="basic-collapse-component">
    <basic-collapsable-bar
      v-model="open"
      :title="title"
      :right-text="rightText"
      :is-dark="isDark"
    />
    <collapse-transition>
      <div v-show="open" class="collapse-container">
        <slot />
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import { CollapseTransition } from '@ivanv/vue-collapse-transition'
import BasicCollapsableBar from './BasicCollapsableBar.vue'

export default {
  name: 'BasicCollapseComponent',
  components: {
    CollapseTransition,
    BasicCollapsableBar,
  },
  props: {
    defaultOpen: { type: Boolean, default: true },
    title: { type: String, required: true },
    rightText: { type: String, required: false },
    isDark: {
      type: Boolean,
    },
  },
  data() {
    return {
      open: this.defaultOpen,
    }
  },
}
</script>

<style lang="stylus" scoped>
.basic-collapse-component
  border-bottom: 1px solid #e0e0e0
</style>
