<template>
  <div class="basic-tabs" :class="[{'full-width': fullWidth, 'disabled': disabled}]">
    <div class="basic-tabs-inner">
      <slot v-bind="{ value }" />
    </div>
  </div>
</template>

<script>
import { ReactiveProvideMixin } from 'vue-reactive-provide'

export default {
  name: 'BasicTabs',
  mixins: [
    ReactiveProvideMixin({
      name: 'basicTabs',
      include: ['value', 'handleChange'],
    }),
  ],
  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: [String, Number],
    disabled: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
  },
  methods: {
    handleChange(value) {
      if (!this.disabled) this.$emit('change', value)
    },
  },
}
</script>

<style lang="stylus" scoped>
.basic-tabs
  position: relative

  &:after
    position: absolute
    bottom: 2px
    left: 0
    width: 100%
    height: 1px
    background-color: #CFD8DC
    content: ""

  &.full-width
    .basic-tabs-inner
      overflow: hidden

    >>> .basic-tab
      flex: 1 1 0%
      text-align: center

.basic-tabs-inner
  display: flex
  align-items: center
  overflow-x: auto
  overflow-y: hidden
  padding-top: vh(30px)
</style>
