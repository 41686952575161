<template>
  <div :class="['basic-notification-dot', type]" />
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="stylus" scoped>
.basic-notification-dot
  position: relative
  width: vw(20px)
  height: vw(20px)
  border-radius: 50%

  &:before
    position: absolute
    top: 50%
    left: 50%
    display: block
    content: ""
    transform: translate(-50%, -50%)

  &.discount
    background-color: #333

    &:before
      m-icon("discount-white", 15)

  &.notification
    background-color: #87a

    &:before
      m-icon("notification-white", 15)
</style>