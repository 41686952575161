<template>
  <div v-if="order.calculatedPrice && ticketSettings" class="basic-price-summary">
    <div v-if="!order.isPaid && showDiscount" class="basic-price-icon" @click="handleClickDiscount" />
    <div class="basic-price-list">
      <div v-if="order.calculatedPrice.subTotal" class="basic-price-item base">
        <div class="basic-price-item-title">Subtotal:</div>
        <div class="basic-price-item-number">{{ order.calculatedPrice.subTotal }}{{ parsedCurrency(ticketSettings.currency) }}</div>
      </div>
      <div v-if="discount || discount === 0 " class="basic-price-item base">
        <div class="basic-price-item-title">Discount:</div>
        <div class="basic-price-item-number">{{ `${discount.value || discount}%` }}</div>
      </div>
      <div class="basic-price-item base">
        <div class="basic-price-item-title">Base:</div>
        <div class="basic-price-item-number">{{ order.calculatedPrice.base }}{{ parsedCurrency(ticketSettings.currency) }}</div>
      </div>
      <div class="basic-price-item iva">
        <div class="basic-price-item-title">IVA ({{ order.calculatedPrice.ivaUsedIntheCalculation }}%):</div>
        <div class="basic-price-item-number">{{ order.calculatedPrice.ivaCalculated }}{{ parsedCurrency(ticketSettings.currency) }}</div>
      </div>
      <div class="basic-price-item base">
        <div class="basic-price-item-title">Total (Impuesto Incl.):</div>
        <div class="basic-price-item-number">{{ order.calculatedPrice.totalCalculated }}{{ parsedCurrency(ticketSettings.currency) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { parseCurrency } from '../../utils/ticketUtils'
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
    showDiscount: {
      type: Boolean,
    },
  },
  remoteComputed: {
    discounts: {
      model: 'restaurant/discounts',
      method: 'get',
    },
    ticketSettings: {
      model: 'restaurant/ticketSettings',
      method: 'get',
    },
  },
  computed: {
    ...mapGetters({
      getDiscountById: 'restaurant/discounts/getById',
    }),
    discount() {
      return this.order.restaurantDiscountId
        ? this.getDiscountById(this.order.restaurantDiscountId)
        : this.order.discountValue
    },
    
  },
  methods: {
    handleClickDiscount() {
      this.$emit('clickDiscount')
    },
    parsedCurrency(currency) {
      return parseCurrency(currency)
    },
  },
}
</script>

<style lang="stylus" scoped>
.basic-price-summary
  display: flex
  justify-content: space-between
  align-items: center
  padding: vw(20px) vw(16px)
  width: 100%
  box-shadow: 0 4px 10px 0 rgba(#000, 0.2)

.basic-price-icon
  m-icon("percentage", 48)

.basic-price-list
  display: flex
  flex-direction: column
  align-items: flex-end
  margin-left: auto

.basic-price-item
  display: flex
  align-items: center
  margin-bottom: vw(2px)

.basic-price-item-title
  m-font-size(12, 14)
  margin-right: vw(15px)

.basic-price-item-number
  m-font-size(14, 20)
  width: vw(75px)
  text-align: right
</style>