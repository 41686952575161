<template>
  <div
    class="FormDate"
    @keyup.capture="updateValue"
  >
    <input
      v-if="showDay"
      ref="day"
      v-model="day"
      class="FormDate__input FormDate__input--day"
      type="number"
      placeholder="dd"
      @input="updateDay"
      @blur="day = day.padStart(2, 0)"
    >
    <span
      v-if="showDay && showMonth"
      class="FormDate__divider"
    >/</span>
    <input
      v-if="showMonth"
      ref="month"
      v-model="month"
      class="FormDate__input FormDate__input--month"
      type="number"
      placeholder="mm"
      @input="updateMonth"
      @blur="month = month.padStart(2, 0)"
    >
    <span
      v-if="showYear && (showDay || showMonth)"
      class="FormDate__divider"
    >/</span>
    <input
      v-if="showYear"
      ref="year"
      v-model="year"
      class="FormDate__input FormDate__input--year"
      type="number"
      placeholder="yyyy"
      @blur="year = year.padStart(4, 0)"
    >
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    showDay: {
      type: Boolean,
      default: true,
    },
    showMonth: {
      type: Boolean,
      default: true,
    },
    showYear: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      day: `${this.value ? new Date(this.value).getDate() : ``}`,
      month: `${this.value ? new Date(this.value).getMonth() + 1 : ``}`,
      year: `${this.value ? new Date(this.value).getFullYear() : ``}`,
    }
  },
  watch: {
    year(current, prev) {
      if (current > 9999) this.year = prev
    },
  },
  methods: {
    updateDay() {
      if (!this.day.length || parseInt(this.day, 10) < 4) return
      if (this.showMonth) this.$refs.month.select()
      else if (this.showYear) this.$refs.year.select()
    },
    updateMonth() {
      if (!this.month.length || parseInt(this.month, 10) < 2) return
      if (this.showYear) this.$refs.year.select()
    },
    updateValue() {
      const timestamp = Date.parse(`${this.year.padStart(4, 0)}-${this.month}-${this.day}`)

      if (Number.isNaN(timestamp)) return

      this.$emit(`input`, timestamp)
    },
  },
}
</script>
<style lang="stylus">
.FormDate
  position: relative
  display: flex
  align-items: center
  overflow: hidden
  padding-right: vw(80px)
  padding-left: vw(10px)
  max-width: vw(165px)
  height: vw(34px)
  border: 1px solid $gray-5
  border-radius: 1px

  &:after
    m-icon("calendar", 20)
    position: absolute
    top: 50%
    right: vw(10px)
    content: ""
    transform: translateY(-50%)

// 1. Hide the spinner button in Chrome, Safari and Firefox.
.FormDate__input
  border: none !important
  text-align: center
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: textfield // 1

  &::-webkit-inner-spin-button
    display: none // 1

  &:focus
    outline: none

.FormDate__input--day,
.FormDate__input--month
  m-font-size(14, 20)
  margin-top: -0.1em
  width: 2.5ch

.FormDate__input--year
  m-font-size(14, 20)
  margin-top: -0.1em
  width: 4.5ch

.FormDate__divider
  pointer-events: none
</style>