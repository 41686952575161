<template>
  <div :class="['navigation-row', {'selected': selected}]" @click="handleSelect">
    <div class="navigation-row-title">{{ preparationArea.name }}</div>
  </div>
</template>

<script>
export default {
  props: {
    preparationArea: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
    },
  },
  methods: {
    handleSelect() {
      this.$emit('click', this.preparationArea.id)
    },
  },
}
</script>

<style lang="stylus" scoped>
.navigation-row
  display: flex
  align-items: center

  &:not(:last-child)
    margin-bottom: vw(30px)

  // height: vw(50px)
  &.selected
    position: relative

    &:before
      position: absolute
      top: 0
      left: vw(-20px)
      width: vw(4px)
      height: 100%
      background-color: #333
      content: ""

.navigation-row-title
  m-font-size(28, 32)
  font-weight: 700
</style>