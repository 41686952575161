<template>
  <!-- <div v-if="!orders || isFetchingOrders" class="loader">Loading</div> -->
  <basic-loading v-if="!orders || isFetchingOrders" />
  <div v-else class="setup-order-container">
    <div class="setup-order-title">{{ $t("setup_order_assigned_zone_title") }}</div>
    <basic-selector
      v-model="selectedZone"
      :options="serviceZonesFiltered"
      key-attribute="id"
      :type="SELECTOR_TYPES.SMALL"
    />
    <basic-selector
      v-model="selectedTableGroup"
      :options="tableGroups"
      key-attribute="id"
      :type="SELECTOR_TYPES.SMALL"
    />
    <div v-if="tablesByTableGroupsFiltered.length" class="setup-order-title">Mesas del grupo</div>
    <ul v-if="tablesByTableGroupsFiltered.length" class="setup-order-list">
      <li v-for="table in tablesByTableGroupsFiltered" :key="table.id" class="setup-order-item">
        <table-row
          :table="table"
          :selected="isSelected(table.id)"
          :order="ordersByTables([table.id])[0]"
          @click.prevent="selectTable"
        />
      </li>
    </ul>
    <div v-if="selectedTable" class="footer">
      <div v-if="quantitySelector" class="quantity-selector-container">
        <div class="quantity-selector-title">{{ $t('setup_order_dinners_title') }}</div>
        <basic-quantity-selector v-model="totalCustomersAtTable" />
      </div>
      <basic-button :title="submitLabel" @click.prevent="handleSubmit" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrderListDataMixin from '@/mixins/OrderListDataMixin'
import BasicButton from '@/components/basic/BasicButton.vue'
import BasicQuantitySelector from '@/components/basic/BasicQuantitySelector.vue'
import BasicSelector, { SELECTOR_TYPES } from '@/components/basic/BasicSelector.vue'
import BasicLoading from '@/components/basic/BasicLoading.vue'
import TableRow from './TableRow.vue'
export default {
  components: { TableRow, BasicQuantitySelector, BasicButton, BasicSelector, BasicLoading },
  mixins: [OrderListDataMixin],
  props: {
    filterByLoggedUser: {
      type: Boolean,
      required: false,
      default: false,
    },
    submitLabel: {
      type: String,
      required: true,
    },
    quantitySelector: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      SELECTOR_TYPES,
      selectedZone: null,
      selectedTableGroup: null,
      selectedTable: null,
      totalCustomersAtTable: 1,
    }
  },
  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
    }),
    serviceZonesFiltered() {
      return this.serviceZones.filter(sz => {
        const serviceZoneTables = sz.tableGroup.reduce((acc, curr) => {
          return [...acc, ...curr.tables.map(t => t.id)]
        }, [])
        const userTables = this.logedUser.tables
        const matchingTables = serviceZoneTables.filter(t => userTables.includes(t))
        return (!this.filterByLoggedUser || matchingTables.length > 0)
      })
    },
    tableGroups() {
      if (!this.selectedZone) return []
      const zoneIndex = this.serviceZones.findIndex(
        zone => parseInt(zone.id) === parseInt(this.selectedZone)
      )
      return zoneIndex !== -1 ? this.serviceZones[zoneIndex].tableGroup : []
    },
    tablesByTableGroupsFiltered() {
      if (!this.selectedTableGroup) return []
      const groupIndex = this.tableGroups.findIndex(
        group => parseInt(group.id) === parseInt(this.selectedTableGroup)
      )
      if (this.selectedTableGroup !== -1) {
        if (this.tableGroups[groupIndex].tables || this.tableGroups[groupIndex].tables > 0) {
          return this.tableGroups[groupIndex].tables.filter(table =>
            this.logedUser.tables.includes(table.id)
          )
        }
        return []
      } else {
        return []
      }
    },
  },
  remoteComputed: {
    serviceZones: {
      model: 'restaurant/serviceZones',
      method: 'get',
      // params: function () {
      //   return [this.logedUser.restaurantId]
      // },
    },
  },
  watch: {
    serviceZonesFiltered: {
      handler(serviceZones) {
        if (serviceZones.length > 0 && !this.selectedZone) this.selectedZone = serviceZones[0].id
      },
      immediate: true,
    },
    selectedZone: {
      handler(selectedZone) {
        if (selectedZone && this.tableGroups.length > 0)
          this.selectedTableGroup = this.tableGroups[0].id
      },
      immediate: true,
    },
  },
  methods: {
    selectTable(event) {
      const tableValue = parseInt(event.target.value)
      let element = document.querySelector('.basic-dialog-body')
      setTimeout(() => {
        this.scrollToBottom(element)
      }, 1)
      this.scrollToBottom(element)
      this.selectedTable = tableValue
    },
    isSelected(id) {
      return this.selectedTable === id
    },
    handleSubmit() {
      this.$emit('selectedTable', {
        selectedTable: this.selectedTable,
        totalCustomersAtTable: this.totalCustomersAtTable,
      })
    },
    scrollToBottom(node) {
      node.scrollTop = node.scrollHeight
    },
  },
}
</script>

<style lang="stylus" scoped>
.setup-order-container
  >>> .basic-selector-container
    margin-bottom: vw(30px)

// .setup-order-inner
// overflow-x: hidden
// overflow-y: auto
// max-height: "calc(90vh - (%s * 4))" % vw(25px)
.setup-order-title
  m-font-size(32, 48)
  margin-top: vw(20px)
  margin-bottom: vw(20px)
  text-align: center
  font-weight: 700

.setup-order-subtitle
  m-font-size(16, 20)
  text-align: center

.setup-order-list
  flex: 1 1 0%

.setup-order-item
  padding-top: vw(15px)
  padding-bottom: vw(15px)
  border-bottom: 1px solid #E0E0E0

.quantity-selector-container
  display: flex
  align-items: center
  margin-bottom: vw(10px)

.quantity-selector-title
  m-font-size(18, 24)
  margin-right: vw(15px)

.quantity-selector
  margin-left: auto

.footer
  margin-top: vw(20px)
</style>