<template>
  <ResourceConfigurator
    v-if="resources"
    :edit-label-key="'cms_edit_option'"
    :add-label-key="'cms_add_option'"
    :resources="resources"
    :resource-label-key="'cms_options'"
    :fields-info="fieldsInfo"
    :form-fields="formFields"
    :table-values="tableValues"
    :delete-resource-handler="deleteResourceHandler"
    :edit-resource-handler="editResourceHandler"
    :add-resource-handler="addResourceHandler"
    @onStartEditing="r => editingResource = r"
    @onStartAdding="editingResource = null"
  />
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator'
import CmsResourceConfiguratorI18n from '@/mixins/CmsResourceConfiguratorI18n'
import { cmsController } from '../../../services/api'
import { mapActions } from 'vuex'
export default {
  components: { ResourceConfigurator },
  mixins: [CmsResourceConfiguratorI18n],
  data() {
    return {
      editingResource: null,
    }
  },
  computed: {
    fieldsInfo() {
      return [
        ...this.localizedFieldsInfo('cms_name'),
        { type: 'money', name: this.$t('cms_price')}
      ]
    },
    formFields() {
      return [
        ...this.localizedFormFields(this.editingResource, 'label', 'cms_name'),
        {
          type: 'number',
          label: this.$t('cms_price'),
          id: 'price',
          required: true,
          default: this.editingResource ? this.editingResource.price : 0
        }
      ]
    },
    tableValues() {
      return this.resources?.map(resource => [
        ...this.localizedFieldToRowValues(resource, 'label'),
        resource.price
      ])
    }
  },
  remoteComputed: {
    resources: {
      model: 'restaurant/options',
      method: 'get',
    },
  },
  methods: {
    ...mapActions({
      invalidateResources: 'restaurant/options/invalidateAll',
      invalidateOptions: 'cms/options/invalidateAll'
    }),
    deleteResourceHandler({ id }) {
      cmsController.deleteResourceById('options', id).then(() => {
        this.invalidateResources()
        this.invalidateOptions()
      })
    },
    addResourceHandler(resource) {
      return cmsController.addResource('options', resource)
        .then(() => {
          this.invalidateResources()
          this.invalidateOptions()
        })
        .catch(() => console.error('Error adding service order'))
    },
    async editResourceHandler( { i18n, ...resource }) {
      await this.editI18ns(i18n, this.editingResource, 'options')
      await cmsController.updateResourceById('options', this.editingResource.id, resource)
      this.invalidateResources()
      this.invalidateOptions()
    }
  },
}
</script>
<style lang="stylus" scoped>
</style>