<template>
  <div v-click-outside="close" class="date-range-picker">
    <div :class="open ? 'toggle toggle__opened' : 'toggle'" @click="open=!open">
      <span v-if="currentOption" :class="open ? 'option-selected' : 'option-selected option-selected__opened'">{{ prettyOption(currentOption) }}</span>
      <span class="range-selected">{{ prettyRange(internalRange) }}</span>
      <div :class="open ? 'drop-down-arrow drop-down-arrow__opened' : 'drop-down-arrow'" />
    </div>
    <div v-if="open" class="popup">
      <div class="left">
        <div class="options">
          <div
            v-for="option in rangeOptions"
            :key="option.id"
            class="range-option"
            :class="{current: currentOption === option, disabled: option.disabled}"
            @click="setOption(option)"
          >
            {{ option.label }}
          </div>
        </div>
      </div>
      <div class="right">
        <v-date-picker
          v-model="internalRange"
          is-range
          :rows="2"
          :step="1"
          :min-date="minmaxRange.start"
          :first-day-of-week="2"
          :max-date="minmaxRange.end"
          :attributes="calendarAttributes"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import ClickOutside from 'vue-click-outside'

dayjs.Ls.en.weekStart = 1

export default {
  directives: {
    ClickOutside,
  },
  model: {
    prop: 'range',
    event: 'change',
  },
  props: {
    range: {
      type: Object,
    },
    minmaxRange: Object,
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    calendarAttributes() {
      return [
        {
          key: 'today',
          highlight: {
            color: 'red',
            fillMode: 'outline',
            contentClass: 'italic',
          },
          dates: this.today,
        },
      ]
    },
    yesterday() {
      return {
        id: 'yesterday',
        label: this.$t('cms_datepicker_yesterday'),
        disabled: false,
        start: dayjs().subtract(1, 'day').toDate(),
        end: dayjs().subtract(1, 'day').toDate(),
      }
    },
    thisWeek() {
      const start = this.getFirstAvailableDate(dayjs().startOf('week').toDate())

      return {
        id: 'this_week',
        label: this.$t('cms_datepicker_this_week'),
        disabled: dayjs().day() === 1,
        start,
        end: dayjs().subtract(1, 'day').toDate(),
      }
    },
    lastWeek() {
      const start = this.getFirstAvailableDate(dayjs().startOf('week').subtract(1, 'week').toDate())

      const end = this.getFirstAvailableDate(dayjs().startOf('week').subtract(1, 'days').toDate())
      return {
        id: 'last_week',
        label: this.$t('cms_datepicker_last_week'),
        disabled: end <= this.minmaxRange.start,
        start,
        end,
      }
    },
    thisMonth() {
      const start = this.getFirstAvailableDate(dayjs().startOf('month').toDate())
      const end = this.getFirstAvailableDate(dayjs().subtract(1, 'day').toDate())
      return {
        id: 'this_month',
        label: this.$t('cms_datepicker_this_month'),
        disabled: end <= this.minmaxRange.start,
        start,
        end,
      }
    },
    lastMonth() {
      const start = this.getFirstAvailableDate(
        dayjs().startOf('month').subtract(1, 'month').toDate()
      )
      const end = this.getFirstAvailableDate(
        dayjs(this.today).startOf('month').subtract(1, 'day').toDate()
      )
      return {
        id: 'last_month',
        label: this.$t('cms_datepicker_last_month'),
        disabled: end <= this.minmaxRange.start,
        start,
        end,
      }
    },
    rangeOptions() {
      const rangeOptions = [
        this.yesterday,
        this.thisWeek,
        this.lastWeek,
        this.thisMonth,
        this.lastMonth,
      ]
      return rangeOptions
    },
    internalRange: {
      get() {
        return {
          start:
            this.range.start.getTime() > this.minmaxRange.start.getTime()
              ? this.range.start
              : this.minmaxRange.start,
          end:
            this.range.end.getTime() < this.minmaxRange.end.getTime()
              ? this.range.end
              : this.minmaxRange.end,
        }
      },
      set(ob) {
        // FUCKOFF, esta "bien" pero no veo manera de "cancelar el +1" de la timezone
        let normalized = {
          start: dayjs(ob.start)
            .startOf('day')
            .add(1, 'hours')
            // .subtract(ob.start.getTimezoneOffset(), 'minutes')
            .toDate(),
          end: dayjs(ob.end)
            .endOf('day')
            .subtract(1, 'hours')
            // .subtract(ob.end.getTimezoneOffset(), 'minutes')
            .toDate(),
        }
        this.$emit('change', normalized)
      },
    },
    currentOption() {
      return this.rangeOptions.find(o => {
        return o.start === this.range.start && o.end === this.range.end
      })
    },
    maxDate() {
      return dayjs(this.today).endOf('month').toDate()
    },
  },
  watch: {
    minmaxRange: {
      handler() {
        // this.setOption(this.rangeOptions[1])
        //MENTIRA, pero por ahora "mejor" creo...
        // this.internalRange = {
        //   end: this.minmaxRange.end,
        //   // start: dayjs(this.minmaxRange.end)
        //   //   .subtract(7, 'day')
        //   //   .toDate(),
        //   start: this.minmaxRange.start,
        // }
        let allOpt = this.rangeOptions.find(o => o.id === 'this_month')
        allOpt.start = this.minmaxRange.start
        allOpt.end = this.minmaxRange.end
      },
      immediate: true,
    },
    internalRange() {
      this.open = false
    },
  },
  mounted() {
    // this.setOption(this.lastWeek)
  },
  methods: {
    setOption(o) {
      // this.$emit('change', { start: o.start, end: o.end })
      this.internalRange = { start: o.start, end: o.end }
    },
    getFirstAvailableDate(date) {
      return date < this.minmaxRange.start ? this.minmaxRange.start : date
    },
    prettyOption(o) {
      return o.label
    },
    prettyRange(r) {
      const now = new Date()
      const startLabel =
        r.start.getFullYear() !== now.getFullYear()
          ? dayjs(r.start).format('MMM D, YYYY')
          : dayjs(r.start).format('MMM D')
      const endLabel =
        r.end.getFullYear() !== now.getFullYear()
          ? dayjs(r.end).format('MMM D, YYYY')
          : dayjs(r.end).format('MMM D')
      return startLabel + ' - ' + endLabel
    },
    close() {
      this.open = false
    },
  },
}
</script>

<style lang="stylus" scoped>
.date-range-picker
  position: relative
  background-color: #fff

  .drop-down-arrow
    position: absolute
    top: 50%
    right: 0
    width: vw(40px)
    height: vw(40px)
    transform: translateY(-50%)

    &:before
      m-icon("drop-down", 30)
      position: absolute
      top: 50%
      left: 50%
      content: ""
      transition: transform 0.2s
      transform: translate(-50%, -50%)

  .drop-down-arrow__opened
    &:before
      transform: rotate(180deg) translate(50%, 50%)

  .toggle
    display: flex
    align-items: center
    padding-right: vw(40px)
    padding-left: 8px
    height: 100%
    border: 1px solid #000

  .toggle__opened
    background-color: $alabaster
    color: black

  .option-selected
    m-font-size(12, 15)
    margin-right: 10px

  .range-selected
    m-font-size(16, 20)
    font-weight: 300

  .popup
    position: absolute
    top: 100%
    right: 0
    z-index: 10
    display: flex
    border-radius: 7px
    background-color: $mercury
    box-shadow: 0 1px 4px #bbb

    .left,
    .right
      min-width: 150px
      width: auto

    .right
      >>> .vc-container
        font-family: $base-font-family

    .left
      .options
        .range-option
          m-font-size(20, 22)
          padding: 15px

          &.current
            font-weight: bold

          &.disabled
            opacity: 0.5
            pointer-events: none

        .range-option:hover
          border-radius: 7px
          background-color: $alto

  >>> .italic
    font-style: italic
</style>