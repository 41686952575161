<template>
  <div class="cms-option-modifiers">
    <ResourceConfigurator
      v-if="optionModifiers && options"
      :edit-label-key="'cms_edit_option_modifier'"
      :add-label-key="'cms_add_option_modifier'"
      :resources="optionModifiers"
      :resource-label-key="'cms_option_modifier_title'"
      :fields-info="fieldsInfo"
      :form-fields="formFields"
      :table-values="tableValues"
      :delete-resource-handler="deleteOptionModifierHandler"
      :add-resource-handler="addOptionModifierHandler"
      :cell-click-handler="cellClickHandler"
      :edit-resource-handler="editOptionModifierHandler"
      @onStartEditing="r => editingResource = r"
      @onStartAdding="editingResource = null"
    />
  </div>
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator'
import CmsResourceConfiguratorI18n from '../../../mixins/CmsResourceConfiguratorI18n'
import { cmsController } from '../../../services/api'
import { mapActions } from 'vuex'
export default {
  components: {  ResourceConfigurator },
  mixins: [CmsResourceConfiguratorI18n],
  data() {
    return {
      editingResource: null,
    }
  },
  computed: {
    fieldsInfo() {
      return [
        { type: 'default', name: this.$t('cms_name'), id: 'name'},
        { type: 'default', name: this.$t('cms_type'), id: 'type'},
        { type: 'boolean', name: this.$t('cms_is_required'), id: 'isRequired' },
      ]
    },
    formFields() {
      return [
        ...this.localizedFormFields(this.editingResource, 'label', 'cms_name'),
        {
          type: 'select',
          label: this.$t('cms_type'),
          id: 'type',
          options: ['SINGLE', 'MULTI'].map(type => ({ id: type, label: type, value: type })),
          default: this.editingResource && this.editingResource.type || 'SINGLE'
        },
        {
          type: 'boolean',
          label: this.$t('cms_is_required'),
          id: 'isRequired',
          enabledLabel: this.$t('cms_is_required'),
          disabledLabel: this.$t('cms_is_not_required'),
          default: this.editingResource && this.editingResource.isRequired || false
        },
        {
          type: 'list',
          label: this.$t('cms_options'),
          placeholder: this.$t('cms_options_filter_placeholder'),
          id: 'options',
          items: this.options.map(opt => ({ id: opt.id, label: this.$label(opt) })),
          default: this.editingResource ? this.editingResource.options : []
        },
      ]
    },
    tableValues() {
      return this.optionModifiers?.map(modifier => [this.$label(modifier), modifier.type, modifier.isRequired])
    }
  },
  remoteComputed: {
    optionModifiers: {
      model: 'restaurant/optionModifiers',
      method: 'get',
    },
    options: {
      model: 'cms/options',
      method: 'get'
    }
  },
  methods: {
    ...mapActions({
      invalidateModifiers: 'restaurant/optionModifiers/invalidateAll',
    }),
    deleteOptionModifierHandler({ id }) {
      return cmsController.deleteResourceById('option-modifiers', id).then(this.invalidateModifiers)
    },
    async cellClickHandler(fieldIndex, modifier) {
      if (this.fieldsInfo[fieldIndex].type !== 'boolean') {
        return
      }
      const fieldId = this.fieldsInfo[fieldIndex].id
      this.editOptionModifierHandler({ [fieldId]: !modifier[fieldId], options: [] }, modifier)
    },
    async addOptionModifierHandler({ options, ...modifier }) {
      const { id } = await cmsController.addResource('option-modifiers', modifier)
      await Promise.allSettled(options.map(opt => cmsController.createRelationship('option', opt, 'option-modifier', id))) 
      this.invalidateModifiers()
    },
    async editOptionModifierHandler({ i18n, options, ...modifier }) {
      if (options) {
        const optionsToAdd = options.filter(opt => !this.editingResource.options.includes(opt))
        await optionsToAdd.map(opt => cmsController.createRelationship('option', opt, 'option-modifier', this.editingResource.id))
        const optionsToDelete =  this.editingResource.options.filter(opt => !options.includes(opt))
        await optionsToDelete.map(opt => cmsController.deleteRelationship('option', opt, 'option-modifier', this.editingResource.id))
      }
      await cmsController.updateResourceById('option-modifiers', this.editingResource.id, modifier)
      await i18n && this.editI18ns(i18n, this.editingResource, 'option-modifiers'),
      this.invalidateModifiers()
    },
  },
}
</script>
<style lang="stylus" scoped>
</style>