import i18n from './modules/i18n'
import restaurant from './modules/restaurant'
import otherModule from './modules/otherModule'
import listModule from './modules/listModule'
import newOrder from './modules/newOrder'
import orders from './modules/orders'
import activeOrder from './modules/activeOrder'
import user from './modules/user'
import userQr from './modules/userQr'
import Vue from 'vue'
import RemoteVuex from './lib/remoteVuex'
import createPersistedState from 'vuex-persistedstate'
import Socket from '../sockets/socket'
import cms from './modules/cms'
// import { getOrders } from '@/services/api'
import { RECEIVED_SOCKET_DATA, SET_TOPIC } from './mutationTypes/socketMutationTypes'

Vue.use(RemoteVuex)

function createWebSocketPlugin(socket) {
  return store => {
    store.subscribe(mutation => {
      if (mutation.type === `orders/${SET_TOPIC}`) {
        let topic = mutation.payload
        if (socket.isConnected && socket.topic !== topic) {
          console.debug('[WebSocket] DISCONNECT SOCKET')
          socket.disconnect()
        }
        if (!socket.isConnecting && !socket.isConnected) {
          socket.connect(topic, msg => {
            console.log('[WebSocket] RECEIVED_SOCKET_DATA', msg)
            store.commit(`orders/${RECEIVED_SOCKET_DATA}`, msg)
            let updatedActiveOrder = store.getters['orders/orders'].find(
              o => String(o.id) === String(store.state.activeOrder.id)
            )
            store.dispatch(`activeOrder/set`, updatedActiveOrder)
          })
        }
      }
    })
  }
}

const persistancePlugin = createPersistedState({
  paths: ['newOrder', 'user', 'userQr'],
})

export default new RemoteVuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    i18n,
    user,
    userQr,
    restaurant,
    otherModule,
    listModule,
    newOrder,
    orders,
    activeOrder,
    cms,
  },
  plugins: [
    persistancePlugin,
    createWebSocketPlugin(
      new Socket({
        url: './api/v1/order-socket',
        topicTag: 'restaurant-topic',
      })
    ),
  ],
})
