import axios from 'axios'
import dayjs from 'dayjs'
import { loginInterceptor } from '../index'
class CmsController {
  /**
   *
   * @param {import('axios').AxiosInstance} axiosInstance
   * @param {*} validateResponse
   */
  constructor() {
    this.instance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    this.imagesInstance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: 'application/json',
      },
    })
    this.imagesInstance.interceptors.request.use(loginInterceptor)
    this.instance.interceptors.request.use(loginInterceptor)
    this.formDataInstance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
    this.formDataInstance.interceptors.request.use(loginInterceptor)
    this.validateResponse = function validateResponse(responsePromise) {
      return responsePromise
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(response.data)
          }
          return Promise.resolve(response)
        })
        .catch(err => {
          return Promise.reject(err.response ? err.response.data || err.response : err)
        })
    }
  }

  async getDashboardProducts(from, to) {
    return this.validateResponse(
      this.formDataInstance.get('/dashboard/products', {
        params: {
          from,
          to,
        },
      })
    )
  }

  retrieveStripeAccountStatus(restaurantId) {
    return this.validateResponse(
      this.instance.get(
        `/stripe/connected_account/retrieve${restaurantId ? `?restaurantId=${restaurantId}` : ''}`
      )
    )
  }

  connectStripeAccount(urls) {
    return this.validateResponse(this.instance.post('/stripe/connected_account/connect', urls))
  }
  /**
   * Return summary cms info
   * if from and to are undefined, then return the summary of open cash
   * else return the summary of the range time
   * @param {Date | undefined} from
   * @param {Date | undefined} to
   * @returns
   */
  async getDashboardSummary(from, to) {
    const qs = new URLSearchParams()
    if (from && to) {
      qs.set('from', dayjs(from).format('DD-MM-YYYY'))
      qs.set('to', dayjs(to).format('DD-MM-YYYY'))
    }
    return this.validateResponse(this.instance.get('/dashboard/summary?' + qs))
  }

  async getTicketSettings() {
    return this.validateResponse(this.instance.get('/ticket-settings'))
  }

  async updateTicketSettings(currency) {
    return this.validateResponse(
      this.instance.patch('/ticket-settings', {
        currency,
      })
    )
  }

  async getServiceZones() {
    return this.validateResponse(this.instance.get('/service-zones'))
  }

  async deleteTicketSettingsMessageById(messageId) {
    return this.validateResponse(
      this.instance.delete(`/ticket-settings/delete-message/${messageId}`)
    )
  }

  async addTicketSettingsMessageById(messageText, languageId) {
    return this.validateResponse(
      this.instance.post(`/ticket-settings/add-message/`, {
        message: messageText,
        restaurantLanguageId: languageId,
      })
    )
  }

  async addServiceOrder(i18n, prior) {
    return this.validateResponse(
      this.instance.post(`/service-order`, {
        prior,
        i18n: i18n.filter(i18n => !!i18n.title),
      })
    )
  }

  async updateServiceOrderById(orderId, i18n, prior) {
    return this.validateResponse(
      this.instance.patch(`/service-order/${orderId}`, {
        prior,
        i18n,
      })
    )
  }

  async addServiceOrderI18nById(orderId, restaurantLanguageId, title) {
    return this.validateResponse(
      this.instance.post(`/service-order/${orderId}/add-i18n`, {
        restaurantLanguageId,
        title,
      })
    )
  }

  async deleteServiceOrderById(orderId) {
    return this.validateResponse(this.instance.delete(`/service-order/${orderId}`))
  }

  async getTicketRowAlerts() {
    return this.validateResponse(this.instance.get('/ticket-row-alert'))
  }

  async deleteResourceById(resourceName, resourceId) {
    return this.validateResponse(this.instance.delete(`/${resourceName}/${resourceId}`))
  }

  async updateResourceById(resourceName, resourceId, resource) {
    return this.validateResponse(this.instance.patch(`/${resourceName}/${resourceId}`, resource))
  }

  async updateRestaurant(restaurant) {
    return this.validateResponse(this.instance.patch(`/restaurants/`, restaurant))
  }

  async getResource(resourceName) {
    return this.validateResponse(this.instance.get(`/${resourceName}/`))
  }

  async addResource(resourceName, resource) {
    return this.validateResponse(this.instance.post(`/${resourceName}/`, resource))
  }

  async updateResourceI18nById(resource, resourceId, i18nId, i18n) {
    return this.validateResponse(
      this.instance.patch(`/${resource}/${resourceId}/update-i18n/${i18nId}`, i18n)
    )
  }

  async addResourceI18nById(resource, resourceId, i18n) {
    return this.validateResponse(this.instance.post(`/${resource}/${resourceId}/add-i18n/`, i18n))
  }

  async deleteResourceI18nById(resource, resourceId, i18nId) {
    return this.validateResponse(
      this.instance.delete(`/${resource}/${resourceId}/delete-i18n/${i18nId}`)
    )
  }

  async addModifierOnProductById(modifierId, productId, modifierResource) {
    return this.validateResponse(
      this.instance.post(`/products/${productId}/${modifierResource}/${modifierId}`)
    )
  }

  async deleteModifierOnProductById(modifierId, productId, modifierResource) {
    return this.validateResponse(
      this.instance.delete(`/products/${productId}/${modifierResource}/${modifierId}`)
    )
  }

  async getUsers() {
    return this.validateResponse(this.instance.get('/users'))
  }

  async getRoles() {
    return this.validateResponse(this.instance.get('/roles'))
  }

  async getOptions() {
    return this.validateResponse(this.instance.get('/options'))
  }

  async getInvoiceById(invoiceId) {
    return this.validateResponse(this.instance.get(`/invoices/${invoiceId}`))
  }

  async getIngredients() {
    return this.validateResponse(this.instance.get('/ingredients'))
  }

  async createRelationship(entity1, id1, entity2, id2) {
    return this.validateResponse(
      this.instance.post(`/relationships/${entity1}/${id1}/${entity2}/${id2}`)
    )
  }

  async deleteRelationship(entity1, id1, entity2, id2) {
    return this.validateResponse(
      this.instance.delete(`/relationships/${entity1}/${id1}/${entity2}/${id2}`)
    )
  }

  async patchResourceImage(resource, resourceId, formData) {
    return this.validateResponse(
      this.imagesInstance.patch(`/${resource}/${resourceId}/images`, formData)
    )
  }

  async patchRestaurantImage(formData) {
    return this.validateResponse(this.imagesInstance.patch(`/restaurants/images`, formData))
  }

  getRestaurantCashRegister(type) {
    return this.validateResponse(this.instance.get(`/restaurant-cash-register/${type}`))
  }

  getRestaurantCashRegisterLiquidation(from, to) {
    return this.validateResponse(
      this.formDataInstance.get(`/restaurant-cash-register/liquidation`, { params: { from, to } })
    )
  }

  getRestaurantCashRegisterProducts(from, to) {
    return this.validateResponse(
      this.formDataInstance.get(`/restaurant-cash-register/products`, { params: { from, to } })
    )
  }
  /**
   *
   * @param {string} from format 25/12/2021
   * @param {string} to format 25/12/2021
   * @returns {Promise<{day:number,sales:number}[]>}
   */
  async getGraphInfo(from, to) {
    return this.validateResponse(
      this.instance.get('/dashboard/chart', {
        params: {
          from,
          to,
        },
      })
    )
  }
}

export default CmsController
