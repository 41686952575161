<template>
  <table class="basic-table">
    <slot />
  </table>
</template>

<script>
export default {}
</script>

<style lang="stylus" scoped>
.basic-table
  m-font-size(16, 20)
  margin-right: vw((-($table-gap)))
  margin-left: vw((-($table-gap)))
  width: 100%
  width: "calc(100% + (%s * 2))" % vw($table-gap)
  border-collapse: collapse

  >>> th,
  >>> td
    m-font-size(12, 14)
    padding: vw($table-gap)

    &.left
      text-align: left

    &.center
      text-align: center

    &.right
      text-align: right
</style>