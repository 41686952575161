<template>
  <div v-if="!isProductUpdating" class="ticket-row">
    <component :is="containerMapped">
      <template>
        <div class="ticket-row-content" @click="onRowClicked">
          <div class="ticket-row-left-container">
            <basic-product-icon :product="product" :count="data.quantity ? data.quantity : 1" @click.stop="$emit('toggleOpen')" />
            <div class="ticket-row-notifications-container">
              <basic-notification-dot
                v-if="showDiscountDot && isSwipeable"
                type="discount"
                class="ticket-row-notification-dot"
              />
              <basic-notification-dot
                v-if="showNotificationDot && isSwipeable"
                type="notification"
                class="ticket-row-notification-dot"
              />
            </div>
          </div>
          <div class="ticket-row-body">
            <div class="ticket-row-label">{{ $label(product) }}</div>
            <div class="ticket-row-modifiers">
              <ticket-row-modifier
                v-for="mod in modifiersList"
                :id="mod.id"
                :key="mod.type+mod.id"
                :type="mod.type"
                :value="mod.value"
              />
            </div>
          </div>
          <template v-if="isRepresentant">
            <div class="ticket-row-right-container">
              <template v-if="isEditable">
                <basic-quantity-selector
                  v-if="!isPaid"
                  :quantity="quantity"
                  :zero-is-remove="true"
                  :disabled="disableOrderActions"
                  @change="handleChangeQuantity"
                />
              </template>
              <div class="ticket-row-status-container">
                <status-order v-if="showStatus" :status="data.status" />
              </div>
              <div v-if="productPrice" class="price">{{ productPrice }}</div>
              <div v-if="calculatedPrice" class="price" :style="{color: 'green'}">{{ calculatedPrice }}</div>
            </div>
          </template>
        </div>
      </template>
      <template v-slot:action-1>
        <div v-if="isActiveOrder && !userQr && !isPaid" class="action-icon icon-1" @click.stop="onClickDiscount" />
        <span v-else />
      </template>
      <template v-slot:action-2>
        <div v-if="isActiveOrder && !userQr && !isPaid" class="action-icon icon-2" @click.stop="onClickNotification" />
        <span v-else />
      </template>
      <template v-slot:action-3>
        <div v-if="isDeletable && !isPaid" class="action-icon icon-3" @click.stop="onDeleteGroup" />
        <span v-else />
      </template>
      <template v-slot:action-4>
        <div v-if="isActiveOrder && !isPaid" class="action-icon icon-4" @click.stop="onMoveTicketRow" />
        <span v-else />
      </template>
    </component>
  </div>
  <basic-loading v-else type="small" />
</template>

<script>
// import { ROUTE_NAMES } from '@/router'
import TicketRowModifier from './TicketRowModifier.vue'
import BasicNumberTag from '../basic/BasicNumberTag.vue'
import { mapActions, mapGetters } from 'vuex'
import BasicQuantitySelector from '../basic/BasicQuantitySelector.vue'
import BasicSwipeActions from '../basic/BasicSwipeActions.vue'
import StatusOrder from '../StatusOrder.vue'
import { ORDER_STATUS } from '../../settings/orderSettings'
import BasicNotificationDot from '../basic/BasicNotificationDot.vue'
import BasicProductIcon from '../basic/BasicProductIcon.vue'
import BasicLoading from '../basic/BasicLoading.vue'
import { parseCurrency } from '../../utils/ticketUtils'
import { ROLES } from '../../settings/roles'

export default {
  components: {
    TicketRowModifier,
    BasicNumberTag,
    BasicQuantitySelector,
    BasicSwipeActions,
    StatusOrder,
    BasicNotificationDot,
    BasicProductIcon,
    BasicLoading,
  },
  props: {
    data: {
      required: true,
    },
    isSwipeable: {
      required: false,
      default: false,
    },
    isOpen: {
      required: false,
      default: false,
    },
    showQuantity: {
      required: false,
      default: true,
    },
    isRepresentant: {
      type: Boolean,
    },
    isActiveOrder: {
      type: Boolean,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
    disableOrderActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      quantity: this.data.quantity,
    }
  },
  computed: {
    ...mapGetters({
      userQr: 'userQr/getLogedQrData',
    }),
    modifiersList() {
      let ingMods = this.data.ingredientModifiers.map(im => ({
        ...im,
        type: 'ing',
      }))
      let optMods = this.data.optionModifiers.map(im => ({
        ...im,
        type: 'opt',
      }))
      return [...ingMods, ...optMods]
    },
    containerMapped() {
      return this.isSwipeable ? BasicSwipeActions : 'div'
    },
    isStatusToDo() {
      return this.data.status === ORDER_STATUS.TODO.key
    },
    isStatusDelivered() {
      return this.data.status === ORDER_STATUS.DELIVERED.key
    },
    isEditable() {
      return this.editable && (!this.data.status || this.isStatusToDo)
    },
    userRoles() {
      return this.$store.state.user.user.roles
    },
    isAdminOrManager() {
      return this.userRoles.includes(ROLES.ADMIN) || this.userRoles.includes(ROLES.MANAGER)
    },
    isDeletable() {
      return this.editable && (!this.data.status || this.isStatusToDo || this.isAdminOrManager)
    },
    isNotificable() {
      return !this.userQr && this.data.status && !this.isStatusDelivered
    },
    productPrice() {
      if (!this.product.price) return null
      return `${this.product.price}${parseCurrency(this.ticketSettings?.currency)}`
    },
    calculatedPrice() {
      if (!this.data.calculatedPrice) return null
      return `${this.data.calculatedPrice.price}${parseCurrency(this.ticketSettings?.currency)}`
    },
    showNotificationDot() {
      return this.isRepresentant
        ? this.data.haveTicketRowAlertComment
        : this.data.ticketRowAlertComment || this.data.ticketRowAlertId
    },
    showDiscountDot() {
      return this.isRepresentant ? this.data.haveTicketRowDiscount : this.data.restaurantDiscountId
    },
    showStatus() {
      return this.data.status && (!this.userQr || (this.userQr && this.isPaid))
    },
  },
  remoteComputed: {
    product: {
      model: 'restaurant/products',
      method: 'getById',
      params: function () {
        return [this.data.productId]
      },
    },
    ticketSettings: {
      model: 'restaurant/ticketSettings',
      method: 'get',
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if (this.data.quantity === 0) {
          alert('do you really want to remove')
          this.removeFromOrder(this.data)
        } else if (this.data.quantity !== this.quantity) {
          this.quantity = this.data.quantity
        }
      },
    },
  },

  methods: {
    ...mapActions({
      removeFromOrder: 'newOrder/removeTicketRow',
    }),
    onRowClicked() {
      if (this.isEditable) this.$emit('click', this.data.id)
    },
    onClickNotification() {
      this.$emit('clickNotify')
    },
    onMoveTicketRow() {
      this.$emit('moveTicketRow', this.data)
    },
    onDeleteGroup() {
      this.$emit('clickDeleteRow')
    },
    addOrDeleteOne(action) {
      this.$emit(action)
    },
    onClickDiscount() {
      this.$emit('clickDiscountRow')
    },
    handleChangeQuantity(value) {
      value > this.quantity
        ? this.addOrDeleteOne('clickAddOne')
        : this.addOrDeleteOne('clickDeleteOne')
      this.quantity = value
    },
  },
}
</script>

<style lang="stylus" scoped>
.ticket-row
  position: relative
  border-bottom: solid 1px #e0e0e0

.ticket-row-left-container
  display: flex
  flex-direction: column
  align-items: center

  >>> .basic-product-icon
    margin-top: -0.1em

    .number-tag
      margin-top: -0.15em

.ticket-row-notifications-container
  display: flex
  flex-direction: column
  align-items: center
  margin-right: vw(10px)

.ticket-row-notification-dot
  margin-top: vw(5px)

.ticket-row-content
  display: flex
  padding: vw(10px)
  width: 100%
  height: 100%

  .basic-dialog-body &
    padding-right: 0
    padding-bottom: vw(20px)
    padding-left: 0

.ticket-row-body
  flex: 1 1 0%

.ticket-row-label
  m-font-size(16, 20)
  flex: 1 1 0%
  margin-top: -0.1em
  margin-right: vw(15px)
  margin-bottom: vw(10px)
  font-weight: 500

  .basic-dialog-body &
    margin-right: vw(30px)
    margin-bottom: vw(5px)

.ticket-row-modifiers
  flex: 1 1 0%
  margin-right: vw(15px)

.ticket-row-modifier
  &:not(:last-child)
    margin-bottom: vw(5px)

.ticket-row-right-container
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: flex-end
  margin-top: -0.1em
  margin-left: auto

  .price
    m-font-size(14, 20)
    margin-top: vw(5px)

  .quantity-selector
    margin-top: -0.1em
    margin-bottom: vw(10px)

    >>> .less-button,
    >>> .more-button
      width: vw(24px)
      height: vw(24px)

.ticket-row-status-container
  display: flex
  align-items: center

.action-icon
  position: relative
  width: 100%
  height: 100%

  &:before
    position: absolute
    top: 50%
    left: 50%
    display: block
    content: ""
    transform: translate(-50%, -50%)

  &.icon-1
    background-color: #333

    &:before
      m-icon("discount-white", 34)

  &.icon-2
    background-color: #87a

    &:before
      m-icon("notification-white", 34)

  &.icon-3
    background-color: #EB5757

    &:before
      m-icon("bin-white", 34)

  &.icon-4
    background-color: #34A1EB

    &:before
      m-icon("swap-vertical-white", 34)
      transform: translate(-50%, -50%) rotate(180deg)
</style>