<template>
  <div class="basic-bottom-actions">
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style lang="stylus" scoped>
.basic-bottom-actions
  position: absolute
  bottom: 0
  display: flex
  flex-direction: column
  padding: vw(18px) vw(15px)
  width: 100vw
  background: #fff
  box-shadow: 0 -2px 10px 0 rgba(#000, 0.2)
</style>