<template>
  <div :class="['burger-icon', {'open': value}]" @click.prevent="switchValue">
    <span />
    <span />
    <span />
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    switchValue() {
      this.$emit('change', !this.value)
    },
  },
}
</script>

<style lang="stylus" scoped>
.burger-icon
  $children = 3
  $width = vw(20px)
  $height = "calc(%s - (%s / %s) + %s)" % ($width $width $children $children)
  $height = vw(16px)
  $border-height = 2px
  position: relative
  width: $width
  height: $height

  &.open
    > span
      &:nth-child(1)
        top: "calc(%s / %s * -1)" % ($border-height $children)
        left: "calc(%s - 1px)" % $border-height
        transform: rotate(45deg)

      &:nth-child(2)
        opacity: 0

      &:nth-child(3)
        left: "calc(%s - 1px)" % $border-height
        transform: rotate(-45deg)

  > span
    position: absolute
    left: 0
    display: block
    width: 100%
    height: $border-height
    border-radius: $border-height
    background-color: #000
    transition: all 0.25s ease-in-out

    &:nth-child(1)
      top: 0
      transform-origin: left center

    &:nth-child(2)
      top: 50%
      transform: translateY(-50%)

    &:nth-child(3)
      top: "calc(%s - %s)" % ($height $border-height)
      transform-origin: left center
</style>