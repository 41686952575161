<template>
  <div>
    <ResourceConfigurator
      v-if="resources"
      :edit-label-key="'cms_edit_ingredient'"
      :add-label-key="'cms_add_ingredient'"
      :resources="resources"
      :resource-label-key="'cms_ingredient'"
      :fields-info="fieldsInfo"
      :form-fields="formFields"
      :table-values="tableValues"
      :delete-resource-handler="deleteResourceHandler"
      :edit-resource-handler="editResourceHandler"
      :add-resource-handler="addResourceHandler"
      @onStartEditing="r => editingResource = r"
      @onStartAdding="editingResource = null"
    />
  
    <fav-csv-export
      :data="donwloadInfo" 
      :name-file="`${$t('cms_ingredient')}_${(new Date()).toLocaleDateString()}`"
    />
  </div>
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator'
import CmsResourceConfiguratorI18n from '@/mixins/CmsResourceConfiguratorI18n'
import { cmsController } from '../../../services/api'
import { mapActions } from 'vuex'
import FavCsvExport from '../FavCsvExport'
import { createArrayDataCSV } from '../../../utils/csvUtils'
export default {
  components: { ResourceConfigurator, FavCsvExport },
  mixins: [CmsResourceConfiguratorI18n],
  data() {
    return {
      editingResource: null,
    }
  },
  remoteComputed: {
    resources: {
      model: 'restaurant/ingredients',
      method: 'get',
    },
  },
  computed: {
    fieldsInfo() {
      return [
        ...this.localizedFieldsInfo('cms_name'),
        { type: 'money', name: this.$t('cms_price') },
      ]
    },
    tableValues() {
      return this.resources?.map(resource => [
        ...this.localizedFieldToRowValues(resource, 'label'),
        resource.price,
      ])
    },
    formFields() {
      return [
        ...this.localizedFormFields(this.editingResource, 'label', 'cms_name'),
        {
          type: 'number',
          label: this.$t('cms_price'),
          id: 'price',
          required: true,
          default: this.editingResource?.price,
        },
      ]
    },
    donwloadInfo() {
      return createArrayDataCSV.bind(this)(this.fieldsInfo, this.tableValues)
    },
  },
  methods: {
    ...mapActions({
      invalidateResources: 'restaurant/ingredients/invalidateAll',
      invalidateMenu: 'restaurant/menu/invalidateAll',
    }),
    deleteResourceHandler({ id }) {
      cmsController.deleteResourceById('ingredients', id).then(this.invalidateResources)
    },
    addResourceHandler(resource) {
      return cmsController
        .addResource('ingredients', resource)
        .then(this.invalidateResources)
        .catch(() => console.error('Error adding service order'))
    },
    async editResourceHandler({ i18n, ...resource }) {
      await this.editI18ns(i18n, this.editingResource, 'ingredients')
      await cmsController.updateResourceById('ingredients', this.editingResource.id, resource)
      this.invalidateResources()
      this.invalidateMenu()
    },
  },
}
</script>
<style lang="stylus" scoped></style>