<template>
  <div class="cms-table-groups">
    <QrList v-if="tableGroups" ref="qrList" :tables="qrList" />
    <ResourceConfigurator
      v-if="tableGroups && serviceZones"
      :edit-label-key="'cms_table_groups'"
      :add-label-key="'cms_add_group'"
      :resources="tableGroups"
      :resource-label-key="'cms_table_groups'"
      :fields-info="fieldsInfo"
      :form-fields="formFields"
      :table-values="tableValues"
      :delete-resource-handler="deleteGroupHandler"
      :download-resource-handler="downloadTableGroupQr"
      :add-resource-handler="addGroupHandler"
      :cell-click-handler="cellClickHandler"
      :edit-resource-handler="editGroupHandler"
      @onStartEditing="r => editingResource = r"
      @onStartAdding="editingResource = null"
    />
  </div>
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator'
import { cmsController } from '../../../services/api'
import { mapActions } from 'vuex'
import QrList from './QrList'
export default {
  components: { ResourceConfigurator, QrList },
  data() {
    return {
      editingResource: null,
      tablesToQrs: [],
    }
  },
  computed: {
    fieldsInfo() {
      return [
        { type: 'default', name: this.$t('cms_name'), id: 'name' },
        { type: 'default', name: this.$t('cms_service_zone'), id: 'serviceZoneId' },
        { type: 'boolean', name: this.$t('cms_is_visible'), id: 'isVisible' },
      ]
    },
    formFields() {
      return [
        {
          type: 'string',
          label: this.$t('cms_name'),
          id: 'name',
          default: this.editingResource?.name,
        },
        {
          type: 'select',
          label: this.$t('cms_service_zone'),
          id: 'serviceZoneId',
          options: this.serviceZones.map(sz => ({ id: sz.id, label: sz.name, value: sz.id })),
          default: (this.editingResource && this.editingResource.serviceZoneId) || null,
        },
        {
          type: 'boolean',
          label: this.$t('cms_is_visible'),
          id: 'isVisible',
          enabledLabel: this.$t('cms_active'),
          disabledLabel: this.$t('cms_not_active'),
          default: (this.editingResource && this.editingResource.isVisible) || false,
        },
      ]
    },
    tableValues() {
      return this.tableGroups?.map(group => [
        group.name,
        this.getServiceZoneByTableGroup(group).name,
        group.isVisible,
      ])
    },
    qrList() {
      return this.tablesToQrs.map(table => {
        const tableGroup = this.getTableGroupByTable(table)
        const serviceZone = this.getServiceZoneByTableGroup(tableGroup)
        return {
          label: `${serviceZone.name} - ${tableGroup.name} - ${table.name}`,
          value: `${window.location.origin}/loginQr/${this.restaurantId}/${table.id}/`,
        }
      })
    },
  },
  remoteComputed: {
    serviceZones: {
      model: 'cms/serviceZones',
      method: 'get',
    },
    tableGroups: {
      model: 'cms/tableGroups',
      method: 'get',
    },
  },
  methods: {
    ...mapActions({
      invalidateGroups: 'cms/tableGroups/invalidateAll',
    }),
    getTableGroupByTable(table) {
      return this.tableGroups.find(({ tables }) => tables.find(({ id }) => id === table.id))
    },
    getServiceZoneByTableGroup(group) {
      return this.serviceZones.find(({ id }) => id === group.serviceZoneId)
    },
    downloadTableGroupQr(tableGroup) {
      this.tablesToQrs = tableGroup.tables
      this.$refs.qrList.$refs.html2Pdf.generatePdf()
    },
    cellClickHandler(fieldIndex, resource) {
      if (this.fieldsInfo[fieldIndex].type !== 'boolean') {
        return
      }
      const fieldId = this.fieldsInfo[fieldIndex].id
      this.editGroupHandler({ [fieldId]: !resource[fieldId] }, resource)
    },
    deleteGroupHandler({ id }) {
      return cmsController.deleteResourceById('table-group', id).then(this.invalidateGroups)
    },
    addGroupHandler(group) {
      return cmsController.addResource('table-group', group).then(this.invalidateGroups)
    },
    editGroupHandler(group) {
      return cmsController
        .updateResourceById('table-group', this.editingResource.id, group)
        .then(this.invalidateGroups)
    },
  },
}
</script>
<style lang="stylus" scoped></style>