<template>
  <basic-confirmation-dialog :is-open="isOpen" @close="$emit('close')">
    <template #image>
      <div class="icon-successful-order" />
    </template>
    <template #description>
      <div class="successful-order-text">{{ description }}</div>
    </template>
    <template v-if="title" #title>
      <div class="successful-order-order-number">{{ title }}</div>
    </template>
    <template #action-buttons>
      <basic-button :title="btnTitle" @click="$emit('accept')" />
    </template>
    <slot />
  </basic-confirmation-dialog>
</template>

<script>
import BasicButton from '../../basic/BasicButton.vue'
import BasicConfirmationDialog from '../../basic/BasicConfirmationDialog.vue'
export default {
  components: { BasicConfirmationDialog, BasicButton },
  props: {
    isOpen: {
      type: Boolean,
    },
    description: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    btnTitle: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="stylus">
.icon-successful-order
  m-icon("successful-order", 180)
  margin-top: vw(30px)
  margin-bottom: vw(30px)

.successful-order-text
  m-font-size(20, 28)
  margin-bottom: vw(10px)
  font-weight: 500

.successful-order-order-number
  m-font-size(22, 28)
  margin-bottom: vw(50px)
  font-weight: 600
</style>