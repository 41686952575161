<template>
  <div class="cms-business-liquidation">
    <div class="date-range-selector">
      <div class="date-range-selector__wrapper">
        <div class="date-range-selector__text">{{ $t('cms_from') }}</div>
        <FormDate v-model="from" />
      </div>
      <div class="date-range-selector__wrapper">
        <div class="date-range-selector__text">{{ $t('cms_to') }}</div>
        <FormDate v-model="to" />
      </div>
      <Button :label-key="'cms_update'" @click="fetchMovements" />
    </div>
    <ResourceConfigurator
      v-if="liquidation"
      :resources="liquidation"
      :has-invoices="true"
      :resource-label-key="'cms_historic_liquidation'"
      :fields-info="fieldsInfo"
      :table-values="tableValues"
    />
    <fav-csv-export
      :data="donwloadInfo" 
      :name-file="fileTitle"
    />
  </div>
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator.vue'
import { cmsController } from '@/services/api'
import Button from '../Button'
import FormDate from '../FormDate'
import { createArrayDataCSV } from '../../../utils/csvUtils'
import FavCsvExport from '../FavCsvExport'
export default {
  components: { ResourceConfigurator, FormDate, Button, FavCsvExport },
  data() {
    return {
      liquidation: [],
      to: new Date().getTime(),
      from: new Date().setMonth(new Date().getMonth() - 1),
    }
  },
  computed: {
    fieldsInfo() {
      return [
        { type: 'timestamp', name: this.$t('cms_date'), id: 'date' },
        { type: 'money', name: this.$t('cms_card_incomes'), id: 'cardIncomes' },
        { type: 'money', name: this.$t('cms_money_incomes'), id: 'moneyIncomes' },
        { type: 'money', name: this.$t('cms_online_incomes'), id: 'onlineIncomes' },
        { type: 'money', name: this.$t('cms_total'), id: 'total' },
      ]
    },
    tableValues() {
      return this.liquidation?.map(movement => [
        movement.date,
        movement.cardIncomes,
        movement.moneyIncomes,
        movement.onlineIncomes,
        movement.total,
      ])
    },
    donwloadInfo() {
      return createArrayDataCSV.bind(this)(this.fieldsInfo, this.tableValues)
    },
    fileTitle() {
      return `${this.$t('cms_historic_liquidation')}___${new Date(
        this.to
      ).toLocaleString()}___${new Date(this.from).toLocaleString()}`
    },
  },
  mounted() {
    this.fetchMovements()
  },
  methods: {
    parseDate(date) {
      const d = new Date(date)
      const day = d.getDate().toString().padStart(2, 0)
      const month = d.getMonth() + 1
      return `${day}-${month.toString().padStart(2, 0)}-${d.getFullYear()}`
    },
    async fetchMovements() {
      if (!this.from || !this.to) {
        return []
      }
      try {
        const movements = await cmsController.getRestaurantCashRegisterLiquidation(
          this.parseDate(this.from),
          this.parseDate(this.to)
        )
        this.liquidation = movements.map(movement => ({
          ...movement,
          deleteDisabled: !movement.movementId,
          editDisabled: !movement.movementId,
        }))
      } catch (err) {
        this.liquidation = []
      }
    },
  },
}
</script>
<style lang="stylus" scoped>
.date-range-selector
  display: flex
  justify-content: flex-end
  align-items: flex-end
  margin-top: vw(-20px)
  margin-left: auto
  gap: vw(20px)

.date-range-selector__text
  m-font-size(12, 12)
  margin-bottom: vw(5px)
  color: $gray-4
  font-weight: 400
</style>