export const ROLE_PREFIX = 'ROLE_'
export const ROLES = {
  WAITER: `${ROLE_PREFIX}WAITER`,
  RUNNER: `${ROLE_PREFIX}RUNNER`,
  COOKER: `${ROLE_PREFIX}COOKER`,
  RIDER: `${ROLE_PREFIX}RIDER`,
  MANAGER: `${ROLE_PREFIX}MANAGER`,
  ADMIN: `${ROLE_PREFIX}ADMIN`,
}
const ROLES_I18N_KEYS = {
  [ROLES.WAITER]: 'role_waiter',
  [ROLES.RUNNER]: 'role_runner',
  [ROLES.COOKER]: 'role_cooker',
  [ROLES.RIDER]: 'role_rider',
  [ROLES.MANAGER]: 'role_manager',
  [ROLES.ADMIN]: 'role_admin',
}
/**
 * function for geting i18n key
 * @param {string} role rol name
 */
export function getI18nRolName(role) {
  const str = ROLES_I18N_KEYS[role]
  return str ?? role
}
