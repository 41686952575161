<template>
  <div class="basic-product-icon" @click="$emit('click', $event)">
    <div v-if="userQr" class="client-product-container">
      <div v-if="product.imageSm" class="client-product-image" :style="'background-image: url(' + product.imageSm + ')'">
        <basic-number-tag
          v-if="count"
          class="client-product-count-tag"
          :value="count"
        />
      </div>
    </div>
    <template v-else>
      <basic-number-tag v-if="count" :value="count" :inverted-color="true" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BasicNumberTag from './BasicNumberTag.vue'
export default {
  components: { BasicNumberTag },
  props: {
    product: {
      type: Object,
      required: true,
    },
    count: {
      type: Number,
    },
    invertedColor: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      userQr: 'userQr/getLogedQrData',
    }),
  },
}
</script>

<style lang="stylus" scoped>
.basic-product-icon
  // margin-top: -0.1em
  margin-right: vw(10px)

  &:empty
    display: none

.client-product-container
  position: relative

  .client-product-image
    width: vw(65px)
    height: vw(65px)
    border-radius: 10px
    background-position: center center
    background-size: cover
    background-repeat: no-repeat

  .client-product-count-tag
    position: absolute
    top: vw(-5px)
    right: vw(-5px)
    border: 1px solid #fff
</style>