import { render, staticRenderFns } from "./MoveTicketRows.vue?vue&type=template&id=dacebefe&scoped=true&"
import script from "./MoveTicketRows.vue?vue&type=script&lang=js&"
export * from "./MoveTicketRows.vue?vue&type=script&lang=js&"
import style0 from "./MoveTicketRows.vue?vue&type=style&index=0&id=dacebefe&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dacebefe",
  null
  
)

export default component.exports