<template>
  <div class="invoice-qr">
    <!-- <div v-if="restaurant" class="image" :style="'background-image: url(' + restaurant.avatar + ')'" /> -->
    <qrcode-vue class="qr" :value="qrValue" size="200" />
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { mapGetters } from 'vuex'
export default {
  components: { QrcodeVue },
  remoteComputed: {
    restaurant: {
      model: 'restaurant/restaurant',
      method: 'get',
      params: function() {
        return [this.logedUser.restaurantId]
      },
    },
  },
  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
    }),
    qrValue() {
      return `${window.location.origin}/invoiceQr/${this.$route.params.orderId}/${this.logedUser.restaurantId}`
    },
  },
}
</script>

<style lang="stylus" scoped>
.invoice-qr
  display: flex
  flex-direction: column
  align-items: center

  .image
    margin-top: vw(40px)
    width: vw(100px)
    height: vw(100px)
    border-radius: 50%
    background-position: center center
    background-size: cover
    background-repeat: no-repeat

  .qr
    margin-top: vw(40px)
    margin-bottom: vw(40px)
</style>