import store from '../../store';

const { HttpError } = require('../../utils/HttpError');

/**
 * 
 * @param {import('axios').AxiosError} error 
 */
function handleError(error){
  /** @type {XMLHttpRequest} */
  const request = error.request
  //const response = error.response
  const statusCode = request.status
    switch(statusCode){
      case HttpError.FORBIDEN:
        store.dispatch('user/logout') // TODO: Revisar que funcion deberíamos usar
        break
      case HttpError.UNAUTHORIZED:  
        store.dispatch('user/logout') // TODO: Revisar que funcion deberíamos usar  
        break
      case HttpError.NOT_FOUND:
        //console.warn(`[handleError] path ${response.config.url} not found`)  
        break
    }
    console.error(`[handleError] `,error)
    return Promise.reject (new HttpError(statusCode,error.message));
  
}
export default handleError