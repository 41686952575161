<template>
  <div class="create-restaurant view">
    <form v-if="!created" :class="{ isMobile }" @submit="handleSubmit">
      <h1>Create restaurant</h1>
      <label>Restaurant name</label>
      <input v-model="formData.restaurantName" type="text">
      <label>Business name</label>
      <input v-model="formData.restaurantBusinessName" type="text">
      <label>Phone</label>
      <input v-model="formData.phone" type="text">
      <label>City</label>
      <input v-model="formData.address.city" type="text">
      <label>Country</label>
      <input v-model="formData.address.country" type="text">
      <label>Number</label>
      <input v-model="formData.address.number" type="text">
      <label>Postal Code</label>
      <input v-model="formData.address.postalCode" type="text">
      <label>Street</label>
      <input v-model="formData.address.street" type="text">
      <label>IVA</label>
      <input v-model="formData.iva" type="number">
      <label>NIF</label>
      <input v-model="formData.nif" type="text">
      <label>First Name</label>
      <input v-model="formData.user.firstName" type="text">
      <label>Last Name</label>
      <input v-model="formData.user.lastName" type="text">
      <label>username</label>
      <input v-model="formData.user.username" type="text">
      <label>Password</label>
      <input v-model="formData.user.password" type="password">
      <label>Confirm password</label>
      <input v-model="confirmPassword" type="password">
      <p v-if="!passwordMatch" class="error-message">Password confirmation missmatch</p>
      <p v-if="!dataIsValid" class="error-message">There are empty fields</p>
      <button type="submit">CREATE</button>
    </form>
    <p v-if="created">Restaurant created successfully</p>
    <p v-if="createError">Something went wrong</p>
  </div>
</template>

<script>
import { createRestaurant } from '@/services/api'
export default {
  data() {
    return {
      createError: false,
      formData: {
        restaurantName: null,
        restaurantBusinessName: null,
        phone: null,
        address: {
          city: null,
          country: null,
          number: null,
          postalCode: null,
          street: null,
        },
        iva: 0,
        nif: null,
        user: {
          firstName: null,
          lastName: null,
          username: null,
          password: null,
        },
      },
      confirmPassword: null,
      created: false,
    }
  },
  computed: {
    passwordMatch() {
      return (
        this.isSet(this.formData.user.password) &&
        this.isSet(this.confirmPassword) &&
        this.formData.user.password === this.confirmPassword
      )
    },
    dataIsValid() {
      return (
        this.isSet(this.formData.restaurantName) &&
        this.isSet(this.formData.restaurantBusinessName) &&
        this.isSet(this.formData.phone) &&
        this.isSet(this.formData.address.city) &&
        this.isSet(this.formData.address.country) &&
        this.isSet(this.formData.address.number) &&
        this.isSet(this.formData.address.postalCode) &&
        this.isSet(this.formData.address.street) &&
        this.isSet(this.formData.iva) &&
        this.isSet(this.formData.nif) &&
        this.isSet(this.formData.user.firstName) &&
        this.isSet(this.formData.user.lastName) &&
        this.isSet(this.formData.user.username) &&
        this.isSet(this.formData.user.password) &&
        this.isSet(this.confirmPassword)
      )
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      if (!this.dataIsValid || !this.passwordMatch) {
        return
      }
      createRestaurant(this.formData)
        .then(() => (this.created = true))
        .catch(() => (this.createError = true))
    },
    isSet(val) {
      return val !== null && val !== undefined && val !== ''
    },
  },
}
</script>

<style lang="stylus" scoped>
.error-message
  color: red

.create-restaurant
  display: flex
  justify-content: center
  overflow: scroll !important
  padding: vw(20px)

form
  display: flex
  flex-direction: column
  width: vw(500px)

  input
    margin-top: vh(5px)

  label
    margin-top: vh(15px)

  &.isMobile
    width: 100%

  button
    margin-top: vw(20px)
    margin-bottom: vw(20px)
    padding: 0
    min-height: vh(40px)
</style>