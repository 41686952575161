import * as d3 from '../../utils/importsD3'
/**
 *
 * @param {HTMLElement} ref
 * @param {T[]} data
 * @param {Object} param2
 * @param {number} param2.width
 * @param {number} param2.height
 * @param {(e:T)=>any} param2.x
 * @param {(e:T)=>any} param2.y
 * @param {(e:T)=>any} param2.z
 * @param {import('d3').scaleLinear} param2.scaleX
 * @param {import('d3').scaleLinear} param2.scaleY
 * @param {number} param2.marginTop
 * @param {number} param2.marginLeft
 * @param {number} param2.marginRight
 * @param {number} param2.marginBottom
 * @param {(e:any)=>Object} param2.zStyle
 *
 * @template T
 */
export function LineChart(
  ref,
  data,
  {
    width = ref.clientWidth,
    height = ref.clientHeight,
    x = e => e.x,
    y = e => e.y,
    z = () => 1,
    scaleX = d3.scaleLinear,
    scaleY = d3.scaleLinear,
    marginTop = 30,
    marginLeft = 30,
    marginRight = 30,
    marginBottom = 30,
    zStyle = () => ({}),
    tooltip = e => e.y,
  }
) {
  const MARGIN = {
    TOP: marginTop,
    LEFT: marginLeft,
    RIGHT: marginRight,
    BOTTOM: marginBottom,
  }
  const svg = d3.select(ref)

  /************ DEFINITIONS ************/
  const X = d3.map(data, x)
  const Y = d3.map(data, y)
  const Z = d3.map(data, z)
  const xRange = [MARGIN.LEFT, width - MARGIN.RIGHT]
  const yRange = [height - MARGIN.BOTTOM, MARGIN.TOP]

  // Compute default domains.
  /** @type {[number,number]} */
  const xDomain = d3.extent(X)
  /** @type {[number,number]} */
  const yDomain = [0, d3.max(Y)]
  const zDomain = new d3.InternSet(Z)
  const I = d3.range(X.length).filter(i => zDomain.has(Z[i]))

  // Construct scales and axes.
  const xScale = scaleX(xDomain, xRange)
  const yScale = scaleY(yDomain, yRange)
  const xAxis = d3
    .axisBottom(xScale)
    .ticks(width / 80)
    .tickSizeOuter(0)
  const yAxis = d3.axisLeft(yScale).ticks(height / 40)

  /************ ROOT ************/
  var root = svg.selectAll('.root').data([null])
  const rootEnter = root.enter().append('g').attr('class', 'root')
  root = root.merge(rootEnter)

  /************ ITEMS ************/
  const groupedData = d3.group(I, index => Z[index])
  var items = root.selectAll('.item').data(groupedData)
  const itemsEnter = items.enter().append('g').attr('class', 'item')
  const itemsExit = items.exit()
  // ENTER
  itemsEnter.append('path')
  itemsEnter.append('g').attr('class', 'dot')
  // EXIT
  itemsExit.remove()
  // UPDATE
  items = items.merge(itemsEnter)

  const line = d3
    .line()
    .curve(d3.curveMonotoneX)
    .x(i => xScale(X[i]))
    .y(i => yScale(Y[i]))

  const paths = items.select('path')
  paths
    .attr('fill', 'none')
    .attr('id', e => e[0])
    .attr('stroke', 'black')
    .attr('stroke-width', 1)
    .attr('stroke-linejoin', 'round')
    .attr('stroke-linecap', 'round')
    .style('transition', 'all 0.2s ease-in-out')
    .attr('d', element => line(element[1]))
    .each(function (e) {
      const selectElement = d3.select(this)
      selectElement.attr('style', null)
      // Objecto style que viene de una funcion de fuera
      const obj = zStyle(e[0])
      // Setear el style
      Object.keys(obj).forEach(key => selectElement.style(key, obj[key]))
    })

  /************ ToolTip *************/
  rootEnter.append('g').attr('class', 'dot')
  var dot = root.select('.dot')
  dot.attr('x', 0).attr('y', 0)
  dot.append('circle').attr('r', 2.5)
  dot.append('text').attr('font-size', 10).attr('text-anchor', 'middle').attr('y', -8)

  /************ EJES ************/

  // Eje X
  rootEnter.append('g').attr('class', 'xAxis')
  root
    .select('.xAxis')
    .attr('transform', `translate(0,${yRange[0]})`)
    .call(xAxis)
    .call(g => g.select('.domain').remove())

  // Eje Y
  rootEnter.append('g').attr('class', 'yAxis')
  rootEnter.append('g').attr('class', 'yAxis-lines')
  root
    .select('.yAxis')
    .call(yAxis)
    .call(g => g.select('.domain').remove())
    .attr('transform', `translate(${xRange[0]},0)`)

  root
    .select('.yAxis-lines')
    .call(yAxis)
    .call(g => g.select('.domain').remove())
    .call(g => g.select('text').remove())
    .call(g =>
      g
        .selectAll('.tick line')
        .attr('x2', width - marginLeft - marginRight)
        .attr('stroke-opacity', 0.1)
    )
    .attr('transform', `translate(${xRange[0]},0)`)

  /************ MOUSE EVENTS **********/
  svg
    .on('pointerenter', pointerentered)
    .on('pointermove', pointermoved)
    .on('pointerleave', pointerleft)
    .on('touchstart', event => event.preventDefault())

  function pointermoved(event) {
    const [xm, ym] = d3.pointer(event)
    const i = d3.least(I, i => Math.hypot(xScale(X[i]) - xm, yScale(Y[i]) - ym)) // closest point
    paths
      .attr('stroke', ([z]) => (Z[i] === z ? '#000' : '#ddd'))
      .filter(([z]) => Z[i] === z)
      .raise()
    dot.attr('transform', `translate(${xScale(X[i])},${yScale(Y[i])})`)
    dot.select('text').text(tooltip(data[i]))
    dot.attr('display', 'unset')
    //if (T) dot.select("text").text(T[i]);
    //svg.property("value", O[i]).dispatch("input", {bubbles: true});
  }

  function pointerentered() {
    dot.attr('display', null)
  }

  function pointerleft() {
    paths.style('mix-blend-mode', 'multiply').attr('stroke', '#000')
    dot.attr('display', 'none')
    //svg.node().value = null;
    //svg.dispatch("input", {bubbles: true});
  }
}
