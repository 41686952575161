<template>
  <div v-if="order.calculatedPrice" class="invoice-buttons">
    <basic-button v-if="!order.isPaid" :title="`${$t('invoice_pay_button')} - ${totalCalculated}`" @click.prevent="handlePayment" />
    <basic-button v-else :title="`${$t('invoice_show_order_status')} - ${totalCalculated}`" @click.prevent="handleBackToOrder" />
    <span class="btn-qr btn btn--primary btn--ghost" @click="$emit('openQR')">{{ $t('invoice_qr_button') }}</span>
  </div>
</template>

<script>
import { ROUTE_NAMES } from '../../settings/routeNames'
import { parseCurrency } from '../../utils/ticketUtils'
import BasicButton from '../basic/BasicButton.vue'
export default {
  components: { BasicButton },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  remoteComputed: {
    ticketSettings: {
      model: 'restaurant/ticketSettings',
      method: 'get',
    },
  },
  computed: {
    currency() {
      return this.ticketSettings?.currency
    },
    totalCalculated() {
      return `${this.order.calculatedPrice.totalCalculated}${parseCurrency(this.currency)}`
    },
  },
  methods: {
    handlePayment() {
      this.$router.replace({ name: ROUTE_NAMES.PAYMENT, params: { orderId: this.order.id } })
    },
    handleBackToOrder() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="stylus" scoped>
.invoice-buttons
  display: flex
  flex-direction: column
  align-items: center
  padding: vw(18px) vw(15px)

  .btn-qr
    margin-bottom: vw(-15px)
</style>