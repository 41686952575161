// import { v4 as uuid } from 'uuid'

import { ORDER_TYPE } from '../../../settings/orderSettings'

let idCounter = 0
function uuid() {
  idCounter += 1
  return idCounter
}
/**
 * takeAway: {
    name: string,
    phone: string,
    email: string,
    pickUpDate: Date
  }
 * table: {id: number, totalCustomersAtTable: number}
 */
export default {
  namespaced: true,
  state: {
    takeAway: null,
    table: null,
    id: null,
    rows: [],
    calculatedPrice: null,
    restaurantDiscountId: null,
    isPaid: false,
    isClient: false,
  },
  mutations: {
    reset(state) {
      state.takeAway = null
      state.table = null
      state.id = null
      state.rows = []
      state.calculatedPrice = null
      state.restaurantDiscountId = null
    },
    set(state, payload) {
      state.takeAway = payload.takeAway
      state.table = payload.table
      state.rows = payload.rows
      state.id = payload.id
      state.calculatedPrice = payload.calculatedPrice
      state.restaurantDiscountId = payload.restaurantDiscountId
      state.type = payload.takeAway ? ORDER_TYPE.TAKE_AWAY : ORDER_TYPE.REGULAR_TICKET
      state.isClient = payload.isClient
      state.isPaid = payload.isPaid
    },
    addTicketRow(state, productConfig) {
      state.rows.push(productConfig)
    },
    addTicketRows(state, productConfigs) {
      state.rows = state.rows.concat(productConfigs)
    },
    updateTicketRow(state, productConfig) {
      state.rows.splice(
        state.rows.findIndex(p => String(p.id) === String(productConfig.id)),
        1,
        productConfig
      )
    },
    updateTicketRows(state, productConfigs) {
      let newRows = state.rows.filter(r => {
        return !productConfigs.find(pc => String(pc.id) === String(r.id))
      })
      newRows = newRows.concat(productConfigs)
      state.rows = newRows
    },
    removeTicketRow(state, productConfig) {
      state.rows.splice(
        state.rows.findIndex(p => String(p.id) === String(productConfig.id)),
        1
      )
    },
    removeTicketRows(state, productConfigs) {
      let newRows = state.rows.filter(r => {
        return !productConfigs.find(pc => String(pc.id) === String(r.id))
      })
      state.rows = newRows
    },
    addTakeAway(state, takeAway) {
      state.takeAway = takeAway
    },
    addTableSetup(state, table) {
      state.table = table
    },
  },
  actions: {
    addTicketRow({ commit }, productConfig) {
      commit('addTicketRow', {
        ...productConfig,
        id: uuid(),
      })
    },
    addTicketRows({ commit }, productConfigs) {
      commit(
        'addTicketRows',
        productConfigs.map(productConfig => {
          return {
            ...productConfig,
            id: uuid(),
          }
        })
      )
    },
    patchOrder({ commit }, newOrder) {
      let rowsAux = [...newOrder.rows.map(row => (row.id ? row : { ...row, id: uuid() }))]
      commit('set', { ...newOrder, rows: rowsAux })
    },
    updateTicketRow({ commit }, productConfig) {
      commit('updateTicketRow', {
        ...productConfig,
      })
    },
    updateTicketRows({ commit }, productConfigs) {
      commit('updateTicketRows', productConfigs)
    },
    removeTicketRow({ commit }, productConfig) {
      commit('removeTicketRow', {
        ...productConfig,
      })
    },
    removeTicketRows({ commit }, productConfigs) {
      commit('removeTicketRows', productConfigs)
    },
    addTakeAway({ commit }, takeAway) {
      commit('addTakeAway', {
        ...takeAway,
      })
    },
    addTableSetup({ commit }, table) {
      commit('addTableSetup', {
        ...table,
      })
    },
    set({ commit }, orderData) {
      if (!orderData) commit('reset')
      else commit('set', orderData)
    },
    reset({ commit }) {
      commit('reset')
    },
  },
  getters: {
    ticketRows(state) {
      return state.rows
    },
    ticketRowsCount(state) {
      return state.rows.length
    },
    calculatedPrice(state) {
      return state.calculatedPrice
    },
    data(state) {
      return state
    },
  },
}
