<template>
  <div v-if="stripeStatus" class="payments">
    <div class="payments-header">
      <h1>{{ $t('cms_payments_title') }}</h1>
      <button class="payments-button" :class="`${stripeStatus.connectStatus}`" @click="connect">{{ $t(stripeStatus.action) }}</button>
    </div>
    <div class="payments-content">
      <p class="payments-status-label">{{ $t('cms_payments_status') }}:</p>
      <p class="payments-status">{{ $t(stripeStatus.connectStatus) }}</p>
      <p v-if="stripeStatus.disabledReason" class="payments-details-label">{{ $t('cms_payments_details') }}:</p>
      <p v-if="stripeStatus.disabledReason" class="payments-details">{{ $t(stripeStatus.disabledReason) }}</p>
    </div>
  </div>
</template>
<script>
import { cmsController } from '@/services/api'
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      locale: 'lang',
    }),
    ...mapActions({
      invalidateStripeStatus: `cms/stripeStatus/invalidateAll`,
    }),
  },
  remoteComputed: {
    stripeStatus: {
      model: 'cms/stripeStatus',
      method: 'get',
    },
  },
  methods: {
    acction() {
      if (
        this.stripeStatus.disabledReason === 'requirements.pending_verification' ||
        this.stripeStatus.disabledReason === 'under_review' ||
        this.stripeStatus.disabledReason === 'other'
      ) {
        this.invalidateStripeStatus()
      } else {
        this.connect()
      }
    },
    connect() {
      return cmsController
        .connectStripeAccount({
          refreshUrl: window.location.href,
          returnUrl: window.location.href,
        })
        .then(res => {
          window.location.replace(res.redirectUrl)
        })
    },
  },
}
</script>
<style lang="stylus" scoped>
$verified-color = rgb(78, 124, 55)
$disabled-color = rgb(206, 33, 76)

.payments-header
  display: flex
  align-items: center
  gap: vw(20px)

.payments-button
  height: vw(20px)
  border: 1px solid black
  border-radius: 3px
  background: white
  color: black

  &.stripe_connect_status_disabled
    border: 1px solid $disabled-color
    color: $disabled-color

  &.stripe_connect_status_verified
    border: 1px solid $verified-color
    color: $verified-color
    pointer-events: none

.payments-status-label,
.payments-details-label
  m-font-size(14)
  text-align: left
  font-weight: 100

.payments-details,
.payments-status
  padding: 0 vw(20px)
</style>