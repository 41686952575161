<template>
  <div class="view models-test">
    <template v-if="isMenuUpdating">LOADING restaurant.menu</template>
    <template v-else><b>restaurant.menu:</b> {{ menu }}</template>
    <br>
    <br>
    <template v-if="isModifiersUpdating">LOADING restaurant.modifiers</template>
    <template v-else><b>restaurant.modifiers:</b> {{ modifiers }}</template>
    <br>
    <br>
    <template v-if="isRestaurantUpdating">LOADING restaurant</template>
    <template v-else><b>FULL restaurant:</b> {{ restaurant }}</template>
    <br>
    <br>
    <br>
    
    
    <template v-if="isListModuleUpdating">LOADING ListModule</template>
    <template v-else><b>LIST MODULE: </b>{{ listModule }}</template>
    <br>
    <br>
    <template v-if="isListElementUpdating">LOADING ListElement</template>
    <template v-else><b>LIST ELEMENT: </b>{{ listElement }}</template>
    <br>
    <br>
    
    
    <div class="buttons">
      <div class="button" @click="$store.dispatch('otherModule/changeTheStaticModel')">Change otherModule/aStaticModel</div>
      <div class="button" @click="changeRemoteTest">Change otherModule/aRemoteModel</div>
    </div>
    <br>
    <template v-if="isOtherModuleUpdating">LOADING otherModule</template>
    <template v-else><b>OTHER MODULE: </b>{{ otherModule }}</template>
    <br>
  </div>
</template>

<script>
export default {
  remoteComputed: {
    restaurant: {
      model: 'restaurant',
    },
    menu: {
      model: 'restaurant/menu',
      params: function() {
        return [this.$route.params.restaurantId]
      },
    },
    modifiers: {
      model: 'restaurant/optionModifiers',
    },
    otherModule: {
      model: 'otherModule',
    },
    listModule: {
      model: 'listModule', //tambien funciona con 'listModule/list
    },
    listElement: {
      model: 'listModule/list', //NO funciona con 'listModule
      method: 'getById',
      params: [3],
    },
  },
  methods: {
    changeRemoteTest() {
      this.$store.dispatch('otherModule/remoteTest/invalidateById')
    },
  },
}
</script>

<style lang="stylus" scoped>
.models-test
  .buttons
    display: flex
    margin: auto
</style>
