import { getLangNameFromCode } from 'language-name-map'
import { cmsController } from '@/services/api'
export default {
  remoteComputed: {
    languages: {
      model: 'restaurant/languages',
      method: 'get',
    },
  },
  data () {
    return {
      localizedFormFields: (editingResource, field, labelKey) => {
        if (!this.languages) return []
        return this.languages.map(({ locale }) => ({
          type: 'string',
          label: `${this.$t(labelKey)}: ${this.langFieldLabelByLocale(locale)}`,
          id: `i18n_${field}_${locale}`,
          required: field === 'name',
          default:
            editingResource && editingResource.i18n.find(i18n => i18n.locale === locale)
              ? editingResource.i18n.find(i18n => i18n.locale === locale)[field]
              : null,
        }))
      },
      localizedFieldsInfo: labelKey => {
        if (!this.languages) return []
        return this.languages.map(({ locale }) => ({
          type: 'default',
          name: `${this.$t(labelKey)}: ${this.langFieldLabelByLocale(locale)}`,
        }))
      },
      localizedFieldToRowValues: (resource, field) => {
        if (!this.languages) return []
        const languages = this.languages.map(({ locale }) => ({ locale }))
        return languages.reduce((acc, curr) => {
          const match = resource.i18n.find(({ locale }) => locale === curr.locale)
          return match ? [...acc, match[field]] : [...acc, null]
        }, [])
      },
    }
  },
  methods: {
    langFieldLabelByLocale (locale) {
      return `(${getLangNameFromCode(locale).native})`
    },
    getLanguageIdByLocale (locale) {
      return this.languages.find(lang => lang.locale === locale).id
    },
    i18nChanged (i18n, editingI18ns) {
      const match = editingI18ns.find(
        i => i18n.restaurantLanguageId === this.getLanguageIdByLocale(i.locale)
      )
      if (!match) return true
      const keys = Object.keys(match).filter(key => key !== 'id' && key !== 'locale')
      return keys.some(key => i18n[key] !== match[key])
    },
    editI18ns (editedI18ns, editingResource, resource) {
      const i18nsToDelete = editingResource.i18n
        .filter(
          ({ locale }) =>
            !editedI18ns.find(
              i18n => i18n.restaurantLanguageId === this.getLanguageIdByLocale(locale)
            )
        )
        .map(({ id }) => id)
      const i18nsToAdd = editedI18ns.filter(
        i18n =>
          !editingResource.i18n.find(
            ({ locale }) => this.getLanguageIdByLocale(locale) === i18n.restaurantLanguageId
          )
      )
      const i18nsToUpdate = editedI18ns
        .filter(
          i18n =>
            !i18nsToAdd.find(
              ({ restaurantLanguageId }) => i18n.restaurantLanguageId === restaurantLanguageId
            )
        )
        .filter(i18n => this.i18nChanged(i18n, editingResource.i18n))
      const deleteI18nPromises = i18nsToDelete.map(id =>
        cmsController
          .deleteResourceI18nById(resource, editingResource.id, id)
          .catch('Error deleting i18n message with id: ', id)
      )
      const addI18nPromises = i18nsToAdd.map(i18n =>
        cmsController
          .addResourceI18nById(resource, editingResource.id, i18n)
          .catch('Error adding i18n message')
      )
      const updateI18nPromises = i18nsToUpdate.map(i18n => {
        const i18nId = editingResource.i18n.find(
          i => this.getLanguageIdByLocale(i.locale) === i18n.restaurantLanguageId
        ).id
        return cmsController
          .updateResourceI18nById(resource, editingResource.id, i18nId, i18n)
          .catch('Error deleting i18n message with id: ', i18nId)
      })
      return Promise.allSettled([...deleteI18nPromises, ...updateI18nPromises, ...addI18nPromises])
    },
    formStateToI18n (resource) {
      let i18n = []
      Object.keys(resource).map(key => {
        const subKeys = key.split('_')
        if (resource[key] === null || resource[key] === '') return
        if (subKeys[0] === 'i18n') {
          const restaurantLanguageId = this.getLanguageIdByLocale(subKeys[2])
          const match = i18n.find(i => i.restaurantLanguageId === restaurantLanguageId)
          if (match) {
            i18n = [
              ...i18n.filter(i => i.restaurantLanguageId !== restaurantLanguageId),
              { ...match, [subKeys[1]]: resource[key] },
            ]
          } else {
            i18n = [
              ...i18n,
              {
                restaurantLanguageId,
                [subKeys[1]]: resource[key],
              },
            ]
          }
        }
      })
      return i18n
    },
  },
}
