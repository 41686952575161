<template>
  <div 
    v-if="ticketSettings && languages"
    class="cms-section"
  >
    <RestaurantInfo />
    <ResourceConfigurator
      :edit-label-key="'cms_edit_message'"
      :add-label-key="'cms_add_message'"
      :resources="messages"
      :resource-label-key="'cms_messages'"
      :fields-info="fieldsInfo"
      :form-fields="formFields"
      :table-values="tableValues"
      :delete-resource-handler="deleteMessageHandler"
      :add-resource-handler="addMessageHandler"
      @onStartEditing="r => editingResource = r"
      @onStartAdding="editingResource = null"
    />
    <div class="currency-settings">
      <h2> {{ $t('cms_currency') }}</h2>
      <div class="currency-settings__controls">
        <Select
          v-model="currency"
          :options="currencies()"
        />
        <Button
          :label-key="'cms_save'"
          :disabled="ticketSettings.currency === currency"
          @click="updateCurrency"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Button from '../Button'
import Select from '../Select'
import { getLangNameFromCode } from 'language-name-map'
import CmsResourceConfiguratorI18n from '@/mixins/CmsResourceConfiguratorI18n'
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator'
import RestaurantInfo from './RestaurantInfo'
import { cmsController } from '../../../services/api'
import { mapActions } from 'vuex'
export default {
  components: { ResourceConfigurator, Button, Select, RestaurantInfo },
  mixins: [CmsResourceConfiguratorI18n],
  data() {
    return {
      currency: null,
      editingResource: null,
    }
  },
  remoteComputed: {
    ticketSettings: {
      model: 'restaurant/ticketSettings',
      method: 'get',
    },
  },
  computed: {
    messages() {
      return this.ticketSettings.i18n.map(message => ({
        ...message,
        editDisabled: true
      }))
    },
    fieldsInfo() {
      return [
        { type: 'language', name: this.$t('cms_language')},
        { type: 'default', name: this.$t('cms_currency')},
        { type: 'default', name: this.$t('cms_message')}
      ]
    },
    formFields() {
      return [{
        type: 'select',
        label: this.$t('cms_language'),
        options: this.languages.map(({ locale }) => ({value: locale, id: locale, label: getLangNameFromCode(locale).native})),
        id: 'locale',
        default: this.editingResource?.locale
      }, {
        type: 'textarea',
        label: this.$t('cms_thank_you_message'),
        id: 'message',
        default: this.editingResource?.message
      }]
    },
    tableValues() {
      return this.messages?.map(message => [message.locale, this.ticketSettings.currency.toUpperCase(), message.message])
    }
  }, 
  methods: {
    ...mapActions({
      invalidateTicketSettings: 'restaurant/ticketSettings/invalidateAll',
    }),
    currencies() {
      return [
        { id: 'eur', label: 'EUR', value: 'eur' },
        { id: 'usd', label: 'USD', value: 'usd' },
      ]
    },
    addMessageHandler({ message, locale }) {
      return cmsController.addTicketSettingsMessageById(message, this.getLanguageIdByLocale(locale))
        .then(this.invalidateTicketSettings)
    },
    async updateCurrency() {
      await cmsController.updateTicketSettings(this.currency)
      this.invalidateTicketSettings()
    },
    deleteMessageHandler ({ id }) {
      return cmsController.deleteTicketSettingsMessageById(id).then(this.invalidateTicketSettings)
    }
  },
}
</script>
<style lang="stylus" scoped>
.currency-settings
  margin-top: vh(30px)
.currency-settings__controls
    width: vw(300px)
    display: flex
    gap: vw(20px)
</style>