import { getOrderById, orderController } from '../../../services/api'
import newOrder from '@/store/modules/newOrder'

export default {
  ...newOrder,
  // state: null,
  actions: {
    ...newOrder.actions,
    ...Object.keys(newOrder.actions).reduce((newActions, actionKey) => {
      //Create new action for every newOrder actions
      newActions[actionKey] = async function(ctx, payload) {
        //Execute newOrder default action
        newOrder.actions[actionKey](ctx, payload)
        //Execite specific action depending actionKey
        if (actionKey === 'addTicketRow') {
          return orderController.postTicketRow(ctx.state.id, payload).then(() => {
            if (ctx.rootGetters['userQr/getLogedQrData'])
              return getOrderById(ctx.state.id).then(order => {
                return ctx.dispatch('patchOrder', order)
              })
          })
        } else if (actionKey === 'removeTicketRow') {
          return orderController.deleteTicketRow(payload.id).then(() => {
            if (ctx.rootGetters['userQr/getLogedQrData'])
              return getOrderById(ctx.state.id).then(order => {
                return ctx.dispatch('patchOrder', order)
              })
          })
        } else if (actionKey === 'updateTicketRow') {
          return orderController.patchTicketRow(payload.id, payload)
        } else if (actionKey === 'patchOrder') {
          return orderController.patchOrder(payload.id, payload)
        } else if (actionKey === 'reset') {
          return orderController.deleteOrder(payload)
        }
      }
      return newActions
    }, {}),
    async fetchOrderById({ commit, state }, id) {
      if (parseInt(state.id) === parseInt(id)) return
      const data = await getOrderById(id)
      commit('set', { ...data })
    },
    invalidate({ commit, dispatch }, id) {
      console.error('INVALDITA ACTIVE ORDER')
      commit('reset')
      dispatch('fetchOrderById', id)
    },
  },
}
