import { ROUTE_NAMES } from '../../settings/routeNames'
import Activity from '../../views/cms/Activity.vue'
import Employees from '../../views/cms/Employees.vue'
import Clients from '../../views/cms/Clients.vue'
import GeneralSettings from '../../components/cms/settings/GeneralSettings.vue'
import PaymentsVue from '../../components/cms/settings/Payments.vue'
import CurrentVue from '../../components/cms/business/Current.vue'
import HistoricVue from '../../components/cms/business/Historic.vue'
import LiquidationVue from '../../components/cms/business/Liquidation.vue'
import ProductsVue from '../../components/cms/business/Products.vue'
import MenuProducts from '../../components/cms/menu/Products.vue'
import PreparationAreasVue from '../../components/cms/restaurant/PreparationAreas.vue'
import ServiceZonesVue from '../../components/cms/restaurant/ServiceZones.vue'
import TableGroupsVue from '../../components/cms/restaurant/TableGroups.vue'
import TablesVue from '../../components/cms/restaurant/Tables.vue'
import CategoriesVue from '../../components/cms/menu/Categories.vue'
import IngredientsVue from '../../components/cms/menu/Ingredients.vue'
import OptionsVue from '../../components/cms/menu/Options.vue'
import IngredientModifiersVue from '../../components/cms/menu/IngredientModifiers.vue'
import OptionModifiersVue from '../../components/cms/menu/OptionModifiers.vue'
import ServiceSettingsVue from '../../components/cms/menu/ServiceSettings.vue'
import CmsTabsViewVue from '../../views/cms/CmsTabsView.vue'
import menuTabs from '../../views/cms/menuViewTabs.js'
import restaurantTabs from '../../views/cms/restaurantViewTabs'
import settingsTabs from '../../views/cms/settingsViewTabs'
import businessTabs from '../../views/cms/businessViewTabs'

const protectedTypes = {
  LOGIN_PROTECTED: 'LOGIN_PROTECTED',
  QR_OR_LOGIN_PROTECTED: 'QR_OR_LOGIN_PROTECTED',
}

const routes = [
  {
    path: 'activity',
    name: ROUTE_NAMES.CMS.ACTIVITY,
    component: Activity,
    meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
  },
  {
    path: 'business',
    name: ROUTE_NAMES.CMS.BUSINESS.MAIN,
    component: CmsTabsViewVue,
    props: { tabs: businessTabs },
    meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
    children: [
      {
        path: 'current',
        name: ROUTE_NAMES.CMS.BUSINESS.CURRENT_CASH_REGISTRY,
        component: CurrentVue,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
      {
        path: 'historic',
        name: ROUTE_NAMES.CMS.BUSINESS.HISTORIC_CASH_REGISTRY,
        component: HistoricVue,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
      {
        path: 'liquidation',
        name: ROUTE_NAMES.CMS.BUSINESS.LIQUIDATION_CASH_REGISTRY,
        component: LiquidationVue,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
      {
        path: 'product',
        name: ROUTE_NAMES.CMS.BUSINESS.PRODUCT_CASH_REGISTRY,
        component: ProductsVue,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
    ],
    redirect: { name: ROUTE_NAMES.CMS.BUSINESS.CURRENT_CASH_REGISTRY },
  },
  {
    path: 'clients',
    name: ROUTE_NAMES.CMS.CLIENTS,
    component: Clients,
    meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
  },
  {
    path: 'employees',
    name: ROUTE_NAMES.CMS.EMPLOYEES,
    component: Employees,
    meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
  },
  {
    path: 'menu',
    name: ROUTE_NAMES.CMS.MENU.MAIN,
    component: CmsTabsViewVue,
    props: { tabs: menuTabs },
    meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
    children: [
      {
        path: 'categories',
        name: ROUTE_NAMES.CMS.MENU.CATEGORIES,
        component: CategoriesVue,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
      {
        path: 'products',
        name: ROUTE_NAMES.CMS.MENU.PRODUCTS,
        component: MenuProducts,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
      {
        path: 'ingredients',
        name: ROUTE_NAMES.CMS.MENU.INGREDIENTS,
        component: IngredientsVue,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
      {
        path: 'options',
        name: ROUTE_NAMES.CMS.MENU.OPTIONS,
        component: OptionsVue,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
      {
        path: 'ingredient-modifiers',
        name: ROUTE_NAMES.CMS.MENU.INGREDIENT_MODIFIERS,
        component: IngredientModifiersVue,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
      {
        path: 'option-modifiers',
        name: ROUTE_NAMES.CMS.MENU.OPTION_MODIFIERS,
        component: OptionModifiersVue,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
      {
        path: 'service-settings',
        name: ROUTE_NAMES.CMS.MENU.SERVICE_SETTINGS,
        component: ServiceSettingsVue,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
    ],
    redirect: { name: ROUTE_NAMES.CMS.MENU.CATEGORIES },
  },
  {
    path: 'restaurant',
    name: ROUTE_NAMES.CMS.RESTAURANT.MAIN,
    component: CmsTabsViewVue,
    props: { tabs: restaurantTabs },
    meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
    children: [
      {
        path: 'preparation-areas',
        name: ROUTE_NAMES.CMS.RESTAURANT.PREPARATION_AREAS,
        component: PreparationAreasVue,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
      {
        path: 'service-zones',
        name: ROUTE_NAMES.CMS.RESTAURANT.SERVICE_ZONES,
        component: ServiceZonesVue,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
      {
        path: 'table-groups',
        name: ROUTE_NAMES.CMS.RESTAURANT.TABLE_GROUPS,
        component: TableGroupsVue,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
      {
        path: 'tables',
        name: ROUTE_NAMES.CMS.RESTAURANT.TABLES,
        component: TablesVue,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
    ],
    redirect: { name: ROUTE_NAMES.CMS.RESTAURANT.PREPARATION_AREAS },
  },
  {
    path: 'settings',
    name: ROUTE_NAMES.CMS.SETTINGS.MAIN,
    component: CmsTabsViewVue,
    props: { tabs: settingsTabs },
    meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
    children: [
      {
        path: 'general-settings',
        name: ROUTE_NAMES.CMS.SETTINGS.GENERAL_SETTINGS,
        component: GeneralSettings,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
      {
        path: 'payments',
        name: ROUTE_NAMES.CMS.SETTINGS.PAYMENTS,
        component: PaymentsVue,
        meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
      },
    ],
    redirect: { name: ROUTE_NAMES.CMS.SETTINGS.GENERAL_SETTINGS },
  },
]

export default routes
