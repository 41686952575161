<template>
  <div v-if="order.takeAway" class="take-away-card" @click="handleClickCard">
    <div class="take-away-row main-row">
      <template v-if="order.takeAway.clientName">
        <div class="take-away-title">{{ order.takeAway.clientName }}</div>
      </template>
      <template v-else>
        <div class="take-away-title">{{ `${order.takeAway.platform} #${order.takeAway.orderNumber}` }}</div>
      </template>
      <div class="take-away-status">
        <!-- <div v-if="order.isPaid">{{ $t('order_paid') }}</div> -->
        <status-order v-if="orderStatus" class="take-away-status" :status="orderStatus" />
        <status-order v-if="order.isPaid" class="take-away-status" :status="'paid'" />
      </div>  
    </div>
    <div class="take-away-row">
      <div class="take-away-details">
        <div v-if="orderIdentifier" class="take-away-detail detail-order">{{ orderIdentifier }}</div>
        <div class="take-away-detail detail-phone">{{ order.takeAway.phone }}</div>
        <div class="take-away-detail detail-date">{{ formatedDate }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ROUTE_NAMES } from '../../../settings/routeNames'
import { getOrderStatus } from '../../../utils/ordersUtils'
import StatusOrder from '../../StatusOrder.vue'

export default {
  components: { StatusOrder },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    orderStatus() {
      return getOrderStatus(this.order)
    },
    orderIdentifier() {
      if (!this.order.id) return null
      return `${this.$t('order')} #${this.order.id}`
    },
    formatedDate() {
      const date = new Date(this.order.takeAway.pickUpDate)
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    },
  },
  methods: {
    handleClickCard() {
      if (this.order.id) {
        this.$router.push({
          name: ROUTE_NAMES.ORDER,
          params: { orderId: this.order.id },
        })
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.take-away-card
  margin-bottom: vw(10px)
  padding: 0 vw(16px) vw(10px)
  border-bottom: 1px solid #e0e0e0

.take-away-status
  display: flex
  align-items: center

  &:not(:last-child)
    margin-right: vw(10px)

.take-away-status-order
  margin-left: auto

.take-away-row
  display: flex
  align-items: center
  width: 100%

  &.main-row
    margin-bottom: vw(5px)

.take-away-title
  m-font-size(16, 20)
  flex: 1 1 0%
  text-transform: capitalize
  font-weight: 600
  font-family: "IBM Plex Sans"

.take-away-details
  m-font-size(14, 20)
  display: flex
  color: #828282

.take-away-detail
  display: flex
  align-items: center

  &:not(:last-child)
    &:after
      margin-right: 0.5em
      margin-left: 0.5em
      content: "·"

  for $detail in "phone" "date"
    &.detail-{$detail}
      &:before
        m-icon($detail, 16)
        margin-right: 0.25em
        content: ""
</style>