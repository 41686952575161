import en from './lang/en.js'
import es from './lang/es.js'

import store from '@/store'

const DEFAULT_APP_LANG = 'es'

let storeInstance = store
let allStrings = {
  es,
  en,
}

export function initLang() {
  let lang = navigator.language.split('-')[0] || DEFAULT_APP_LANG
  if (Object.keys(allStrings).indexOf(lang) < 0) lang = Object.keys(allStrings)[0]

  storeInstance.dispatch('setLanguage', lang)
  return storeInstance.state.i18n.lang
}

export function t(key) {
  let lang = storeInstance.state.i18n.lang || initLang()
  // hace "fallback" a idiomas "parceidos" (es <-> es-ES)
  let availableLang =
    Object.keys(allStrings).indexOf(lang) >= 0
      ? lang
      : Object.keys(allStrings).find(l => l.indexOf(lang) >= 0 || lang.indexOf(l) >= 0)
  let aux = availableLang && allStrings[availableLang][key]
  return aux != undefined ? aux : '[' + key + ']'
}
/**
 *
 * @param {string} field Field in Object
 */
export function i18nCreateLabel(fields) {
  return object => {
    if(typeof object === undefined) return 'Unknown'
    if (!Array.isArray(fields)) fields = [fields]
    //fallbakc para los fake_Data, sino no deberia ocurrir NUNCA
    if (!object.i18n) return object.label || object.id
    let i18n = object.i18n.find(i => i.locale === storeInstance.state.i18n.lang)
    if (!i18n) i18n = object.i18n[0]
    return fields.reduce((first, field) => {
      return first || (i18n ? i18n[field] : 'Unknown')
    }, null)
  }
}

export default {
  methods: {
    $t: t,
    $label: i18nCreateLabel(['name', 'label', 'title']),
    $description: i18nCreateLabel(['description', 'message']),
  },
}
