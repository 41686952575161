<template>
  <orders />
</template>

<script>
import Orders from "../components/orders"

export default {
  components: { Orders }
}
</script>