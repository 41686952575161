<template>
  <div class="cms-resource">
    <Dialog
      :is-open="isDialogOpen"
      :closable="true"
      :scrollable-body="true"
      @close="toggleDialog"
    >
      <template #dialog-body>
        <div v-if="errorMessage">{{ errorMessage }}</div>
        <ConfirmDialog
          v-else-if="confirmDialog"
          :confirm-callback="confirmDialog.confirmCallback"
          :cancel-callback="confirmDialog.cancelCallback"
          :message="$t('cms_confirm_delete_message')"
          :confirm-label-key="'cms_confirm'"
          :cancel-label-key="'cms_cancel'"
        />
        <DynamicForm
          v-else
          :title="formTitle"
          :fields="formFields"
          :save-action="handleSaveResource"
          @cancel="toggleDialog()"
        />
      </template>
    </Dialog>
    <div class="cms-resource__header">
      <div class="cms-resource__header__title">{{ $t(resourceLabelKey) }}</div>
      <div class="cms-resource__buttons">
        <Button
          v-if="formFields"
          :label-key="addLabelKey"
          :icon="'add'"
          @click="onAddResource"
        />
        <Button
          v-for="(button, i) in extraButtons"
          :key="`extra-button_${i}`"
          :label-key="button.labelKey"
          :icon="button.icon"
          :inverted="true"
          @click="button.handler"
        />
      </div>
    </div>
    <Table v-if="resources" :fields-info="fieldsInfo" :rows="rows" />
  </div>
</template>
<script>
import Dialog from '../../basic/BasicDialog'
import Table from './ResourceConfigruratorTable'
import Button from '../Button'
import ErrorMessages from './errorMessages'
import CmsResourceConfiguratorI18n from '@/mixins/CmsResourceConfiguratorI18n'
import DynamicForm from '../dynamic-form/DynamicForm'
import ConfirmDialog from '../ConfirmDialog'
import { ROUTE_NAMES } from '../../../settings/routeNames'
export default {
  components: { Dialog, Table, DynamicForm, Button, ConfirmDialog },
  mixins: [CmsResourceConfiguratorI18n],
  props: {
    extraButtons: {
      type: Array,
      required: false,
    },
    editLabelKey: {
      type: String,
      required: false,
    },
    addLabelKey: {
      type: String,
      required: false,
    },
    resourceLabelKey: {
      type: String,
      required: true,
    },
    resources: {
      type: Array,
      required: true,
    },
    fieldsInfo: {
      type: Array,
      required: true,
    },
    tableValues: {
      type: Array,
      required: true,
    },
    formFields: {
      type: Array,
      required: false,
    },
    deleteResourceHandler: {
      type: Function,
      required: false,
    },
    addResourceHandler: {
      type: Function,
      required: false,
    },
    downloadResourceHandler: {
      type: Function,
      required: false,
    },
    editResourceHandler: {
      type: Function,
      required: false,
    },
    cellClickHandler: {
      type: Function,
      required: false,
    },
    isProducts: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasInvoices: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      confirmDialog: null,
      errorMessage: null,
      isDialogOpen: false,
      editingResource: null,
      editedResource: null,
    }
  },
  computed: {
    formTitle() {
      return this.editingResource
        ? this.$t(this.$props.editLabelKey)
        : this.$t(this.$props.addLabelKey)
    },
    rows() {
      return this.resources.map(({ editDisabled, deleteDisabled, ...resource }, i) => {
        return {
          values: this.$props.tableValues[i],
          productId: this.$props.isProducts && resource.id,
          invoices:
            this.$props.hasInvoices &&
            resource.invoices.map(({ id, created, amountPaid }) => ({
              location: {
                name: ROUTE_NAMES.INVOICE,
                params: {
                  orderId: id,
                },
                query: { isCurrentOrder: false },
              },
              created,
              amountPaid,
            })),
          onEdit: editDisabled ? null : () => this.onEdit(resource),
          onDownload: this.downloadResourceHandler
            ? () => this.downloadResourceHandler(resource)
            : null,
          onDelete: deleteDisabled ? null : () => this.handleDeleteResource(resource),
          onCellClick: index => {
            this.$emit('onStartEditing', resource)
            this.handleCellClick(index, resource)
          },
        }
      })
    },
    resourceModifiedFields() {
      if (!this.editedResource || !this.editingResource) {
        return {}
      }
      const res = {}
      Object.keys(this.editedResource).forEach(resKey => {
        if (resKey.split('_')[0] === 'i18n') return
        if (this.editedResource[resKey] !== this.editingResource[resKey]) {
          res[resKey] = this.editedResource[resKey]
        }
      })
      const i18n = this.formStateToI18n(this.editedResource)
      if (i18n.length > 0) {
        res.i18n = i18n
      }
      return res
    },
  },
  methods: {
    onEdit(resource) {
      this.editingResource = resource
      this.$emit('onStartEditing', resource)
      this.errorMessage = null
      this.toggleDialog()
    },
    onAddResource() {
      this.editingResource = null
      this.$emit('onStartAdding')
      this.errorMessage = null
      this.toggleDialog()
    },
    handleDeleteResource(resource) {
      if (this.$props.deleteResourceHandler) {
        this.confirmDialog = {
          confirmCallback: async () => {
            try {
              await this.$props.deleteResourceHandler(resource)
              this.isDialogOpen = false
              this.confirmDialog = null
            } catch (err) {
              this.confirmDialog = null
              this.handleError(err)
            }
          },
          cancelCallback: () => {
            this.isDialogOpen = false
            this.confirmDialog = null
          },
        }
        this.isDialogOpen = true
      }
    },
    handleCellClick(index, resource) {
      if (this.$props.cellClickHandler) {
        this.$props.cellClickHandler(index, resource)
      }
    },
    handleSaveResource(resource) {
      this.editedResource = resource
      if (this.editingResource) {
        return this.$props
          .editResourceHandler(this.resourceModifiedFields)
          .then(this.toggleDialog)
          .catch(this.handleError)
      }
      return this.$props
        .addResourceHandler({ i18n: this.formStateToI18n(resource), ...resource })
        .then(this.toggleDialog)
        .catch(this.handleError)
    },
    handleError(err) {
      if (Object.keys(ErrorMessages).includes(err.message)) {
        this.errorMessage = this.$t(ErrorMessages[err.message])
      } else {
        this.errorMessage = err.message || this.$t('cms_something_went_wrong')
      }
      this.isDialogOpen = true
      this.confirmDialog = null
    },
    toggleDialog() {
      this.isDialogOpen = !this.isDialogOpen
    },
  },
}
</script>
<style lang="stylus" scoped>
.cms-resource__header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: vh(30px)

.cms-resource__header__title
  m-font-size(26, 30)
  font-weight: 700
  font-family: "Titillium Web"

.cms-resource__buttons
  display: flex
  align-items: flex-end
  gap: vw(10px)
</style>