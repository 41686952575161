import Vue from 'vue'
import { awaitAsynWrapper } from './utils'

/**
 * @typedef SaveReqType
 * @property {T} result
 * @property {boolean} updating
 * @property {Error | null} error
 * @property {boolean} invalidate
 * @template T
 */
/**
 * @typedef { {[lang:string]:{[id: string]:SaveReqType<T>}} } ObjectsStoreType
 * @template T
 */
/**
 * @typedef {{[path:string]:SaveReqType<number[]>}} RequestStoreType
 */
/**
 * @typedef {{objects:ObjectsStoreType<T>,requests:RequestStoreType}} StateType
 * @template T
 */
/**
 * @typedef remoteModelsType
 * @property {(obj:T)=>string} getId
 * @property {(...args:ArgsGet)=>Promise<T[]>} get
 * @property {(id:string|number,...args:ArgsGetById)=>Promise<T>} getById
 * @property {(...args:ArgsGet)=>string} getLang
 * @property {(...args:ArgsGetById)=>string} getLangById
 * @property {()=>string} lang
 * @template T, ArgsGet, ArgsGetById
 */
/**
 * @param {import('vuex').Module & {remoteModels:{[name:string]:remoteModelsType<T,ArgsGet,ArgsGetById>}}} store
 * @template T, ArgsGet, ArgsGetById
 */
function createRemoteModels(remoteModel, name) {
  const langGetter = remoteModel.lang ?? remoteModel.getLang ?? (() => 'defaultLang')
  const langGetterById = remoteModel.lang ?? remoteModel.getLangById ?? (() => 'defaultLang')
  const getId = remoteModel.getId ? remoteModel.getId : d => d.id
  const isList = !!remoteModel.getById || remoteModel.isList
  const needFallback = !remoteModel.getById && isList
  /**
   * return the id if is a list and the
   * @param  {...any} argsById
   * @return {[string |number,any[]]}
   */
  function getIdFromArgsById(...argsById) {
    const [id, ...otherArgs] = argsById
    if (isList) return [id, otherArgs]
    return [createPath(...argsById), argsById]
  }
  /**
   * Converts args to string to use in a path (Objects are JSON.stringified)
   * @param  {any} arg
   */
  let stringifyArg = arg => {
    if (typeof arg === 'object') return JSON.stringify(arg)
    else return String(arg)
  }

  /**
   * TODO: revisar el filter... no se si puede probocar casos raros
   * @param  {ArgsGet | ArgsGetById} args
   */
  let createPath = (...args) => {
    return args
      .filter(e => e !== undefined)
      .map(arg => stringifyArg(arg))
      .join('-')
  }

  // Esto "funciona" pero no debería de pasar por aquí nunca. Añadimos un console.error para advertirnos del error (pero que al menos funcione)
  let fallbackGetById = (context, id, ...args) => {
    console.error(
      `[RemoteModules internal error] model ${name} try to fetch getById but it doesn't have implemented.`
    )
    return remoteModel.get(args).then(resp => {
      let candidate = resp.find(d => String(d.id) === String(id))
      if (candidate) return candidate
      else throw ` id: ${id} not found!`
    })
  }
  // añadimos un wrapper, además si la peticion retorna data == null || undefiend se propaga un error
  let api = {
    get: awaitAsynWrapper(remoteModel.get),
    getById: awaitAsynWrapper(remoteModel.getById || (isList ? fallbackGetById : remoteModel.get)),
  }

  const SET_LANGUAGE = 'SET_LANGUAGE_' + name
  const SET_OBJECTS = 'SET_OBJECT_' + name
  const SET_REQUEST = 'SET_REQUEST_' + name
  const FAILURE_REQUEST = 'FAILURE_REQUEST_' + name
  const SET_UPDATING = 'SET_UPDATING_' + name
  const SET_INVALIDATE = 'SET_INVALIDATE_' + name
  const SET_INVALIDATE_ALL = 'SET_INVALIDATE_ALL_' + name
  const SET_INVALIDATE_BY_ID = 'SET_INVALIDATE_BY_ID_' + name
  const SET_UPDATING_BY_ID = 'SET_UPDATING_BY_ID_' + name
  const FAILURE_REQUEST_BY_ID = 'FAILURE_REQUEST_BY_ID_' + name
  const SET_REQUEST_BY_ID = 'SET_REQUEST_BY_ID_' + name

  return {
    namespaced: true,
    state: {
      objects: {},
      requests: {},
    },
    getters: {
      /**
       * @param {StateType<T>} state
       */
      get:
        (state, getters, rootState) =>
        (...args) => {
          if (!isList) {
            // necesary for gets
            const [id, newArgs] = getIdFromArgsById(...args)
            return getters.getById(id, ...newArgs)
          }
          const lang = langGetter(rootState, ...args)
          const path = createPath(...args, lang)
          if (
            state.requests &&
            state.objects && //TODO: chapu para que "escuche" cuando se añade un lenguaje
            state.requests[path] &&
            state.objects[lang] &&
            state.requests[path].result &&
            state.requests[path].result.every(id => state.objects[lang][id] !== undefined)
            // && !state.requests[path].invalidate // TODO: no esta claro que "sea lo correcto" por si quieres invalidar sin mostrar loading...
          ) {
            return state.requests[path].result.map(id => state.objects[lang][id].result)
          } else {
            return []
          }
        },
      /**
       * @param {StateType<T>} state
       */
      getById:
        (state, getters, rootState) =>
        (id, ...args) => {
          let lang = langGetterById(rootState, ...args)
          if (
            state.objects &&
            state.objects[lang] &&
            state.objects[lang][id]
            // && !state.objects[lang][id].invalidate // TODO: no esta claro que "sea lo correcto" por si quieres invalidar sin mostrar loading...
          )
            return state.objects[lang][id].result
          return null
        },
      /**
       * @param {StateType<T>} state
       */
      updating:
        (state, getters, rootState) =>
        (...args) => {
          if (!isList) {
            // necesary for gets
            const [id, newArgs] = getIdFromArgsById(...args)
            return getters.updatingById(id, ...newArgs)
          }
          const lang = langGetter(rootState, ...args)
          const path = createPath(...args, lang)

          if (state.requests[path]) {
            return state.requests[path].updating
          } else {
            return null
          }
        },
      /**
       * @param {StateType<T>} state
       */
      updatingById:
        (state, getters, rootState) =>
        (id, ...args) => {
          const lang = langGetterById(rootState, ...args)
          if (state.objects && state.objects[lang] && state.objects[lang][id]) {
            return state.objects[lang][id].updating
          } else {
            return null
          }
        },
      /**
       * @param {StateType<T>} state
       */

      isInvalidate:
        (state, getters, rootState) =>
        (...args) => {
          if (!isList) {
            // necesary for gets
            const [id, newArgs] = getIdFromArgsById(...args)
            return getters.isInvalidateById(id, ...newArgs)
          }
          const lang = langGetter(rootState, ...args)
          const path = createPath(...args, lang)

          if (state && state.requests && state.requests[path])
            return state.requests[path].invalidate
          return null
        },
      /**
       * @param {StateType<T>} state
       */

      isInvalidateById:
        (state, getters, rootState) =>
        (id, ...args) => {
          const lang = langGetterById(rootState, ...args)
          if (state.objects[lang] && state.objects[lang][id])
            return state.objects[lang][id].invalidate
          return null
        },
      /**
       * @param {StateType<T>} state
       */
      error:
        (state, getters, rootState) =>
        (...args) => {
          if (!isList) {
            // necesary for gets
            const [id, newArgs] = getIdFromArgsById(...args)
            return getters.errorById(id, ...newArgs)
          }
          const lang = langGetter(rootState, ...args)
          const path = createPath(...args, lang)

          if (state.requests[path]) {
            return state.requests[path].error
          } else {
            return null
          }
        },
      /**
       * @param {StateType<T>} state
       */
      errorById:
        (state, getters, rootState) =>
        (id, ...args) => {
          const lang = langGetterById(rootState, ...args)
          if (state.objects && state.objects[lang] && state.objects[lang][id]) {
            return state.objects[lang][id].error
          } else {
            return null
          }
        },

      /**
       * @param {StateType<T>} state
       */
      getInfo:
        (state, getters) =>
        (...args) => {
          return {
            invalidate: getters.isInvalidate(...args),
            result: getters.get(...args),
            error: getters.error(...args),
            updating: getters.updating(...args),
          }
        },
      /**
       * @param {StateType<T>} state
       */
      getInfoById:
        (state, getters, rootState) =>
        (...argsById) => {
          const [id, args] = getIdFromArgsById(...argsById)
          const lang = langGetterById(rootState, ...args)
          if (state.objects[lang] && state.objects[lang][id]) return state.objects[lang][id]
          else
            return {
              result: null,
              updating: false,
              invalidate: false,
              error: null,
            }
        },
    },
    actions: {
      // ...store.actions,
      /**
       *
       * @param  {ArgsGet} args
       */
      get({ dispatch }, args) {
        return dispatch('fetch', args)
      },
      /**
       *
       * @param  {ArgsGetById} args
       */
      getById({ dispatch }, argsById) {
        const [id, args] = getIdFromArgsById(...argsById)
        return dispatch('fetchById', id, args)
      },
      fetchfallbackById({ dispatch, commit, getters, rootState }, argsById) {
        const [id, args] = getIdFromArgsById(...argsById)
        const updating = getters.updating(...args)
        const invalidate = getters.isInvalidate(...args)
        const data = getters.get(...args)
        const error = getters.error(...args)
        const lang = langGetter(rootState, ...args)
        // si ya se está actualizando, poner el updating de este id a true
        // pasará a false cuando se reciba "todo"
        if (updating) {
          commit(SET_UPDATING_BY_ID, { id, lang })
          return data
          // si data es null o undefined probocará un bucle
        } else if ((!data == null && !error) || invalidate !== false) {
          return dispatch('update', args)
        } else return data
      },
      fetch({ dispatch, getters }, args) {
        if (!isList) return dispatch('fetchById', args)
        const updating = getters.updating(...args)
        const invalidate = getters.isInvalidate(...args)
        const data = getters.get(...args)
        const error = getters.error(...args)
        if (updating) return data
        // si data es null o undefined probocará un bucle
        else if ((!data == null && !error) || invalidate !== false) return dispatch('update', args)
        else return data
      },

      fetchById({ dispatch, getters }, argsById) {
        const [id, args] = getIdFromArgsById(...argsById)
        // en caso de no existir "fetch by id" pedimos el fetch "de todo" y el get del plugin ya buscará el id que toca
        // se soluciona el bucle de que si un "id" no existe, no vuelve a pedir ese id hasta que aparezca
        if (needFallback) {
          return dispatch('fetchfallbackById', argsById)
        }

        const updating = getters.updatingById(id, ...args)
        const invalidate = getters.isInvalidateById(id, ...args)
        const data = getters.getById(id, ...args)
        const error = getters.errorById(id, ...args)
        if (updating) return data
        // si data es null o undefined probocará un bucle
        else if ((!data == null && !error) || invalidate !== false)
          return dispatch('updateById', argsById)
        else return data
      },
      update({ commit, state, dispatch, rootState, getters, rootGetters }, args) {
        if (!isList) return dispatch('updateById', args)
        const lang = langGetter(rootState, ...args)
        const path = createPath(...args, lang)
        commit(SET_UPDATING, { path })
        return api
          .get({ state, rootState, getters, rootGetters }, ...args)
          .then(objects => {
            dispatch('save', { lang, objects, path })
            return objects
          })
          .catch(error => commit(FAILURE_REQUEST, { error, path }))
      },

      updateById({ commit, state, dispatch, rootState, getters, rootGetters }, argsById) {
        const [id, args] = getIdFromArgsById(...argsById)
        const lang = langGetterById(rootState, ...args)
        commit(SET_UPDATING_BY_ID, { id, lang })
        return api
          .getById({ state, rootState, getters, rootGetters }, id, ...args)
          .then(object => {
            dispatch('saveById', { lang, id, object })
          })
          .catch(error => commit(FAILURE_REQUEST_BY_ID, { error, id, lang }))
      },
      save({ commit, state }, { lang, path, objects }) {
        if (!state.objects[lang]) commit(SET_LANGUAGE, { lang })
        let noCache = objects.reduce((obj, data) => {
          let id = getId(data)
          obj[id] = data
          return obj
        }, {})
        if (Object.keys(noCache).length > 0) commit(SET_OBJECTS, { lang, objects: noCache })
        commit(SET_REQUEST, { objects: Object.values(objects), path })
      },
      saveById({ commit }, { lang, id, object }) {
        let objects = {
          [id]: object,
        }
        commit(SET_OBJECTS, { lang, objects })
      },
      invalidate({ commit, rootState, dispatch }, args) {
        if (!isList) return dispatch('invalidateById', args)
        const lang = langGetter(rootState, ...args)
        commit(SET_INVALIDATE, { path: createPath(...args, lang) })
      },
      invalidateById({ commit, rootState }, argsById) {
        const [id, args] = getIdFromArgsById(...argsById)
        commit(SET_INVALIDATE_BY_ID, {
          lang: langGetterById(rootState, ...args),
          id,
        })
      },
      invalidateAll({ commit }) {
        commit(SET_INVALIDATE_ALL)
      },
    },
    mutations: {
      // ...store.mutations,
      [SET_LANGUAGE](state, { lang }) {
        let obs = {
          ...state.objects,
          [lang]: {},
        }
        Vue.set(state.objects, lang, obs)
      },
      /**
       * @param {StateType<T>} state
       */
      [SET_OBJECTS](state, { lang, objects }) {
        var obs
        if (state.objects) obs = state.objects[lang] ?? {}
        obs = {
          ...obs,
          ...Object.keys(objects).reduce(
            (obj, key) => ({
              ...obj,
              [key]: {
                result: objects[key],
                updating: false,
                invalidate: false,
                error: null,
              },
            }),
            {}
          ),
        }
        Vue.set(state.objects, lang, obs)
      },
      /**
       * @param {StateType<T>} state
       */
      [SET_REQUEST](state, { path, objects }) {
        const req = {
          result: objects.map(e => getId(e)),
          updating: false,
          invalidate: false,
          error: null,
        }
        Vue.set(state.requests, path, req)
        // state = {...state}
      },
      /**
       * @param {StateType<T>} state
       */
      [SET_REQUEST_BY_ID](state, { id, lang, object }) {
        const obj = {
          result: object,
          updating: false,
          invalidate: false,
          error: null,
        }
        Vue.set(state.objects[lang], id, obj)
        // state = {...state}
      },
      /**
       * @param {StateType<T>} state
       */
      [FAILURE_REQUEST](state, { path, error }) {
        console.warn(FAILURE_REQUEST, error)
        const lastReq = state.requests[path] ?? {}
        const req = {
          ...lastReq,
          result: null,
          updating: false,
          invalidate: false,
          error: error,
        }
        Vue.set(state.requests, path, req)
      },
      /**
       * @param {StateType<T>} state
       */
      [FAILURE_REQUEST_BY_ID](state, { id, lang, error }) {
        console.warn(FAILURE_REQUEST_BY_ID, error)
        if (!state.objects[lang]) {
          const obj = {
            result: null,
            updating: false,
            invalidate: false,
            error: error,
          }
          Vue.set(state.objects, lang, { [id]: obj })
        } else {
          const lastObj = state.objects[lang] ? state.objects[lang][id] ?? {} : {}
          const obj = {
            ...lastObj,
            result: null,
            updating: false,
            invalidate: false,
            error: error,
          }
          Vue.set(state.objects[lang], id, obj)
        }
      },
      /**
       * @param {StateType<T>} state
       */
      [SET_UPDATING](state, { path }) {
        if (state.requests[path]) state.requests[path].updating = true
        else
          state.requests = {
            ...state.requests,
            [path]: {
              result: null, //TODO
              error: null,
              updating: true,
              invalidate: false,
            },
          }
      },
      /**
       * @param {StateType<T>} state
       */
      [SET_UPDATING_BY_ID](state, { lang, id }) {
        if (state.objects[lang] && state.objects[lang][id]) {
          state.objects[lang][id].updating = true
        } else if (!state.objects[lang]) {
          state.objects = {
            [lang]: {
              [id]: {
                result: null, //TODO
                error: null,
                updating: true,
                invalidate: false,
              },
            },
          }
        } else {
          const obj = {
            result: null,
            error: null,
            updating: true,
            invalidate: false,
          }
          Vue.set(state.objects[lang], id, obj)
        }
      },
      /**
       * @param {StateType<T>} state
       */
      [SET_INVALIDATE_BY_ID](state, { lang, id }) {
        if (state.objects[lang] && state.objects[lang][id])
          state.objects[lang][id].invalidate = true
        else
          state.objects[lang][id] = {
            result: null,
            error: null,
            updating: false,
            invalidate: true,
          }
      },
      /**
       * @param {StateType<T>} state
       */
      [SET_INVALIDATE](state, { path }) {
        if (state.requests[path])
          state.requests[path] = {
            ...state.requests[path],
            invalidate: true,
          }
        else
          state.requests[path] = {
            result: null,
            error: null,
            updating: false,
            invalidate: true,
          }
      },
      /**
       * @param {StateType<T>} state
       */
      [SET_INVALIDATE_ALL](state) {
        Object.keys(state.requests).forEach(path => {
          state.requests[path].invalidate = true
        })
        Object.keys(state.objects).forEach(lang => {
          Object.keys(state.objects[lang]).forEach(id => {
            state.objects[lang][id].invalidate = true
          })
        })
      },
    },
  }
}

const setupRemoteModels = options => {
  Object.keys(options.modules).forEach(moduleKey => {
    if (options.modules[moduleKey].remoteModels) {
      options.modules[moduleKey].namespaced = true
      Object.keys(options.modules[moduleKey].remoteModels).forEach(remoteModelKey => {
        let newConfig = createRemoteModels(
          options.modules[moduleKey].remoteModels[remoteModelKey],
          remoteModelKey
        )
        options.modules[moduleKey] = {
          ...options.modules[moduleKey],
          modules: {
            ...options.modules[moduleKey].modules,
            [remoteModelKey]: newConfig,
          },
        }
      })
    }
    // Proof Of Concept:
    // MAL. "todo muy manual..." y DEBERIAN estar "todos" no? (tambien el invalidate por ejemplo...)
    if (options.modules[moduleKey].remoteModels) {
      //habria que añadir (solo) getters a la store
      // /moduleKey/
      options.modules[moduleKey].getters = options.modules[moduleKey].getters || {}
      options.modules[moduleKey].getters.get = (state, getters) => () => {
        let candidate = Object.keys(options.modules[moduleKey].remoteModels).reduce(
          (val, modelKey) => {
            val[modelKey] = getters[`${modelKey}/get`]()
            return val
          },
          {}
        )
        // Un poco arbitrario que los "remoteModels" no pueden ser "null" pero el resto de atributos del state si...
        if (Object.values(candidate).some(v => v === null)) return null
        else {
          Object.keys(options.modules[moduleKey].state)
            .filter(k => !options.modules[moduleKey].remoteModels[k])
            .forEach(staticKey => {
              candidate[staticKey] = options.modules[moduleKey].state[staticKey]
            })
          return candidate
        }
      }
      options.modules[moduleKey].getters.updating = (state, getters) => () => {
        return Object.keys(options.modules[moduleKey].remoteModels).reduce((val, modelKey) => {
          return val || getters[`${modelKey}/updating`]()
        }, false)
      }
      options.modules[moduleKey].getters.error = (state, getters) => () => {
        return Object.keys(options.modules[moduleKey].remoteModels).reduce((val, modelKey) => {
          return val || getters[`${modelKey}/error`]()
        }, false)
      }
      options.modules[moduleKey].actions.fetch = ({ dispatch }) => {
        return Object.keys(options.modules[moduleKey].remoteModels).forEach(modelKey => {
          dispatch(`${modelKey}/fetch`)
        })
      }
      options.modules[moduleKey].actions.get = ({ dispatch }) => {
        return Object.keys(options.modules[moduleKey].remoteModels).forEach(modelKey => {
          dispatch(`${modelKey}/get`)
        })
      }
    }
  }, {})
  return options
}

export default setupRemoteModels
