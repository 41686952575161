<template>
  <div class="qr-list-view">
    <VueHtml2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1200"
      :filename="filename"
      :pdf-quality="1"
      :manual-pagination="false"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="650px"
    >
      <section slot="pdf-content" class="pdf-content">
        <section
          v-for="(row, i) in items"
          :key="`row-${i}`"
          :class="{'html2pdf__page-break': (i+1) % 4 === 0}"
          :style="{ display: 'flex', gap: '25px' }"
        >
          <section
            v-for="(elem, j) in row"
            :key="`elem-${i}-${j}`"
            class="pdf-item"
            :style="{ maxWidth: '600px', overflow: 'hidden' }"
          >
            <h6 :style="{ margin: '35px 0px 5px 0px', fontSize: '10px', fontWeight: '300', whiteSpace: 'nowrap', textOverflow: 'elipsis' }">{{ elem.label }}</h6>
            <QR :value="elem.value" size="200" />
          </section>
        </section>
      </section>
    </VueHtml2pdf>
  </div>
</template>
<script>
import QR from 'qrcode.vue'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: { VueHtml2pdf, QR },
  props: {
    tables: {
      type: Array,
      required: true
    },
    filename: {
      type: String,
      required: false,
      default: 'tables-qr-list'
    }
  },
  computed: {
    items() {
      let i, j;
      const splited = []
      const chunk = 3
      for (i = 0, j = this.tables.length; i < j; i += chunk) {
          splited.push(this.tables.slice(i, i + chunk))
      }
      return splited
    },
    htmlToPdfOptions() {
      return {
        margin: [10, 15]
      }
    },
  },
}
</script>
<style lang="stylus">
.pdf-content:
  display: flex !important
  flex-wrap: wrap !important
  gap: 25px !important
</style>