<template>
  <div class="cms-view-container activity-view">
    <template v-if="restaurant && restaurant.cashRegisterIsOpen">
      <!-- Current -->
      <div class="activity-view__header">
        <div class="activity-view__header__title">{{ $t('cms_activity_summary_current') }}</div>
      </div>
      <div class="incomes top">
        <box-info
          v-for="(income, i) in incomesTop"
          :key="`income_${i}`"
          class="income-top"
          :amount="income.amount"
          :label="income.label"
          :variation="income.variation"
          :disabled="income.disabled"
        />
      </div>
      <hr>
    </template>
    <!-- ACTIVITy -->
    <div class="activity-view__header">
      <div class="activity-view__header__title">{{ $t('cms_activity_summary') }}</div>
      <div class="date-range-selector">
        <basic-date-range-picker v-model="internalDateRange" class="range-picker" :minmax-range="minmaxRange" />
      </div>
    </div>
    <div class="summary-content">
      <div class="summary-content__left">
        <div v-if="summary" class="incomes">
          <box-info 
            v-for="(income, i) in incomes"
            :key="`income_${i}`"
            :amount="income.amount"
            :label="income.label"
            :variation="income.variation"
          />
        </div>
        <div class="card chart">
          <div class="chart__header">{{ $t('cms_sells_historic') }}</div>
          <line-chart v-if="from && to" :from="parseDate(from)" :to="parseDate(to)" />
        </div>
      </div>
      <div class="summary-content__right">
        <div class="card best-sellers">
          <div class="best-sellers__header">
            <div class="best-sellers__header__title">{{ $t('cms_most_selled_products') }}</div>
          </div>
          <ul class="best-sellers__list">
            <li v-for="product in products" :key="product.id" class="best-sellers__item">
              <div class="best-seller">
                <div class="best-seller__img" :style="{ backgroundImage: `url('${product.image}')`}" />
                <div class="best-seller__name">{{ $label(product) }}</div>
                <div class="best-seller__value">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(product.total || 0) }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from '../../components/charts/LineChart.vue'
import { cmsController } from '@/services/api'
import BasicDateRangePicker from '../../components/basic/BasicDateRangePicker.vue'
import BoxInfo from '../../components/cms/activity/BoxInfo.vue'
export default {
  components: { LineChart, BasicDateRangePicker, BoxInfo },
  data() {
    const from = new Date()
    from.setMonth(new Date().getMonth() - 1)
    return {
      products: [],
      to: new Date(),
      from,
    }
  },
  remoteComputed: {
    restaurant: {
      model: 'restaurant/restaurant',
    },
    summary: {
      model: 'cms/summary',
      params: function () {
        return [this.from, this.to, false]
      },
    },
    globalSummary: {
      model: 'cms/summary',
    },
  },
  computed: {
    internalDateRange: {
      get() {
        return { start: this.from, end: this.to }
      },
      set(ob) {
        this.from = ob.start
        this.to = ob.end
      },
    },
    minmaxRange() {
      return { end: new Date(), start: new Date(0) }
    },
    incomes() {
      return [
        {
          label: 'cms_total_incomes',
          amount: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
            this.summary.total || 0
          ),
          variation: 0,
        },
        {
          label: 'cms_credit_card_incomes',
          amount: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
            this.summary.totalCardAmount || 0
          ),
          variation: 0,
        },
        {
          label: 'cms_cash_incomes',
          amount: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
            this.summary.totalCashAmount || 0
          ),
          variation: 0,
        },
        {
          label: 'cms_stripe_incomes',
          amount: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
            this.summary.totalStripeAmount || 0
          ),
          variation: 0,
        },
      ]
    },
    incomesTop() {
      if (!this.globalSummary) return []
      return [
        {
          label: 'cms_total_incomes',
          amount: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
            this.globalSummary.total || 0
          ),
          variation: 0,
        },
        {
          label: 'cms_credit_card_incomes',
          amount: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
            this.globalSummary.totalCardAmount || 0
          ),
          variation: 0,
        },
        {
          label: 'cms_cash_incomes',
          amount: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
            this.globalSummary.totalCashAmount || 0
          ),
          variation: 0,
        },
        {
          label: 'cms_stripe_incomes',
          amount: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
            this.globalSummary.totalStripeAmount || 0
          ),
          variation: 0,
        },
        {
          label: 'cms_activity_comensals',
          amount: 0,
          variation: 0,
          disabled: true,
        },
        {
          label: 'cms_average_ticket',
          amount: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(0),
          variation: 0,
          disabled: true,
        },
      ]
    },
  },
  watch: {
    internalDateRange() {
      this.updateData()
    },
  },
  mounted() {
    this.updateData()
  },
  methods: {
    parseDate(date) {
      const d = new Date(date)
      const day = d.getDate().toString().padStart(2, 0)
      const month = d.getMonth() + 1
      return `${day}-${month.toString().padStart(2, 0)}-${d.getFullYear()}`
    },
    onChange({ to, from }) {
      this.to = to
      this.from = from
    },
    async updateData() {
      if (!this.from || !this.to) {
        return []
      }
      try {
        this.products = await cmsController.getDashboardProducts(
          this.parseDate(this.from),
          this.parseDate(this.to)
        )
      } catch (err) {
        this.products = []
      }
    },
  },
}
</script>
<style lang="stylus" scoped>
.activity-view__header
  display: flex
  align-items: flex-end
  margin-bottom: vh(30px)

.activity-view__header__title
  m-font-size(26, 30)
  font-weight: 700
  font-family: "Titillium Web"

.date-range-selector
  display: flex
  align-items: flex-end
  margin-left: auto
  gap: vw(20px)

  .range-picker
    height: vh(36px)

.date-range-selector__text
  m-font-size(12, 12)
  margin-bottom: vw(5px)
  color: $gray-4
  font-weight: 400

.summary-content
  display: flex
  justify-content: space-between
  gap: vw(22px)

.incomes
  display: flex
  margin-bottom: vh(20px)
  gap: vw(20px)

  &.top
    // flex-wrap: wrap
    .income-top
      flex: 1 1 100%

.card
  display: flex
  flex-direction: column
  min-width: vw(200px)
  border: 1px solid $gray-5
  border-radius: 4px

.income
  padding: vw(10px) vw(20px)

.income__title
  m-font-size(14, 20)
  margin-bottom: vw(5px)
  line-height: vh(20px)

.income__value
  m-font-size(26, 30)
  margin-top: auto
  font-weight: 700

.income__variation
  m-font-size(12, 16)
  margin-top: vw(5px)
  height: vw(16px)
  color: #6FCF97 // TODO hardcoded color

.income__variation--negative
  color: #EB5757 // TODO hardcoded color

.best-sellers
  width: vw(380px)

.best-sellers__header
  padding: vw(25px)
  height: vw(80px)

.best-sellers__header__title
  m-font-size(20, 28)
  font-weight: 600
  font-family: "Titillium Web"

.best-seller
  display: flex
  align-items: center
  padding: vw(15px) vw(25px)
  min-height: vw(60px)
  border-top: 1px solid $gray-5
  gap: vw(15px)

.best-seller__img
  flex-shrink: 0
  width: vw(34px)
  height: vw(34px)
  border-radius: 4px
  background-size: cover

.best-seller__name
  m-font-size(16, 20)
  flex: 1 1 0%

.best-seller__value
  m-font-size(16, 20)
  font-weight: 500

.line-chart
  width: vw(780px)
  height: vh(300px)

.chart
  padding: vw(25px)

.chart__header
  m-font-size(20, 28)
  font-weight: 600
  font-family: "Titillium Web"
</style>
