<template>
  <div class="ticket-row-discount">
    <span v-if="!productDiscounts">Loading</span>
    <template v-else>
      <ticket-row :data="row" :editable="false" />
      <basic-radio-button-group 
        v-model="selectedDiscount"
        :options="productDiscounts"
        :title="$t('apply_discount_title')"
        :show-value-in-title="true"
      />
      <div class="buttons">
        <basic-button :title="$t('apply_discount_title')" class="btn-submit" @click="handleSubmitDiscount" />
        <basic-button :title="$t('cancel_title')" class="btn-cancel btn btn--primary btn--ghost" @click="$emit('close')" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BasicButton from '../basic/BasicButton.vue'
import BasicRadioButtonGroup from '../basic/BasicRadioButtonGroup.vue'
import TicketRow from './TicketRow.vue'
import { DISCOUNT_TYPES } from './../../settings/discountSettings'
export default {
  components: { BasicButton, BasicRadioButtonGroup, TicketRow },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedDiscount: this.row.restaurantDiscountId,
    }
  },
  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
    }),
    productDiscounts() {
      return this.discounts
        ? this.discounts.filter(discount => discount.discountType === DISCOUNT_TYPES.PRODUCT)
        : null
    },
  },
  remoteComputed: {
    discounts: {
      model: 'restaurant/discounts',
      method: 'get',
    },
  },
  methods: {
    handleSubmitDiscount() {
      this.$emit('confirm', this.selectedDiscount)
    },
  },
}
</script>

<style lang="stylus" scoped>
.ticket-row-discount
  display: flex
  flex-direction: column

  .buttons
    display: flex
    flex-direction: column
    align-items: center

  .btn-cancel
    margin-top: vw(-20px)
    margin-bottom: vw(-15px)
</style>