<template>
  <div class="cms-view-container clients-view">
    <div class="cms-clients">
      <ResourceConfigurator
        v-if="clients"
        :edit-label-key="'cms_edit_client'"
        :add-label-key="'cms_add_client'"
        :resources="clients"
        :resource-label-key="'cms_clients'"
        :fields-info="fieldsInfo"
        :form-fields="formFields"
        :table-values="tableValues"
        :delete-resource-handler="deleteClientHandler"
        :add-resource-handler="addClientHandler"
        :edit-resource-handler="editClientHandler"
        @onStartEditing="r => editingResource = r"
        @onStartAdding="editingResource = null"
      />
      <fav-csv-export
        :data="donwloadInfo" 
        :name-file="`${$t('cms_clients')}_${(new Date()).toLocaleDateString()}`"
      />
    </div>
  </div>
</template>
<script>
import ResourceConfigurator from '@/components/cms/resource-configurator/ResourceConfigurator'
import { cmsController } from '@/services/api'
import { mapActions } from 'vuex'
import FavCsvExport from '../../components/cms/FavCsvExport.vue'
import { createArrayDataCSV } from '../../utils/csvUtils'
export default {
  components: { ResourceConfigurator, FavCsvExport },
  remoteComputed: {
    clients: {
      model: 'cms/clients',
      method: 'get',
    },
  },
  data() {
    return {
      editingResource: null,
    }
  },
  computed: {
    fieldsInfo() {
      return [
        { type: 'default', name: this.$t('cms_email'), id: 'email' },
        { type: 'default', name: this.$t('cms_name'), id: 'name' },
        { type: 'default', name: this.$t('cms_phone'), id: 'phone' },
      ]
    },
    tableValues() {
      return this.clients?.map(client => [client.email, client.name, client.phone])
    },
    formFields() {
      return [
        {
          type: 'email',
          label: this.$t('cms_email'),
          id: 'email',
          required: true,
          default: this.editingResource?.email,
        },
        {
          type: 'string',
          label: this.$t('cms_name'),
          id: 'name',
          required: true,
          default: this.editingResource?.name,
        },
        {
          type: 'string',
          label: this.$t('cms_phone'),
          id: 'phone',
          required: true,
          default: this.editingResource?.phone,
        },
      ]
    },
    donwloadInfo() {
      return createArrayDataCSV(this.fieldsInfo, this.tableValues)
    },
  },
  methods: {
    ...mapActions({
      invalidateClients: `cms/clients/invalidateAll`,
    }),
    deleteClientHandler({ id }) {
      return cmsController.deleteResourceById('clients', id).then(this.invalidateClients)
    },
    async addClientHandler(client) {
      await cmsController.addResource('clients', client)
      return this.invalidateClients()
    },
    async editClientHandler(editedClient) {
      await cmsController.updateResourceById('clients', this.editingResource.id, editedClient)
      return this.invalidateClients()
    },
  },
}
</script>
<style lang="stylus" scoped></style>
