export default {
  welcome_to_bestiario: 'This is a new Bestiario_ project',
  back: 'Volver',
  yes: 'Si',
  no: 'No',
  accept_title: 'Aceptar',
  cancel_title: 'Cancelar',
  //Selectors
  select_option: 'Selecciona',
  //Login
  login_wellcome: '¡Hola!',
  login_input_user: 'Usuario',
  login_input_password: 'Contraseña',
  login_forgot_password: '¿Olvidaste la contraseña?',
  login_button_title: 'Ingresar',
  //Navigation
  navigation_button_waiter: 'Camarero',
  navigation_button_runner: 'Runner',
  navigation_button_cooker: 'Cocinero',
  navigation_button_rider: 'Repartidor',
  navigation_button_manager: 'Manager',
  navigation_button_admin: 'Admin',
  navigation_button_assigned_zone: 'Zona Asignada',
  navigation_button_orders: 'Ordenes',
  navigation_button_pick_up: 'Recoger',
  navigation_button_takeaways: 'Takeaway',
  navigation_button_cook: 'Cocina',
  navigation_logout: 'Cerrar sesión',
  navigation_edit_assigned_zone: 'Editar zona asignada',
  navigation_edit_zone_dialog_title: 'Editar zona asignada',
  navigation_edit_zone_tables_title: 'Mesas',
  navigation_add_zone_dialog_title: 'Agregar zona',
  navigation_add_zone: 'Agregar zona',
  //OrdersOptions
  order_option_label_my_zone: 'Mi zona',
  order_option_label_other_zones: 'Todas las zonas',
  order_option_label_takeaway: 'Takeaway',
  order_option_label_my_orders: 'Mis órdenes',
  order_option_label_pick_up: 'Para recoger',
  order_menu_search_product: 'Buscar producto',
  //Notifications
  notification_btn_send: 'Enviar notificación',
  notification_text_area_placeholder: 'Escribe la notificación',
  //Home
  home_button_create_order: 'Crear orden',
  home_button_takeaway: 'Takeaway',
  //Takeaway
  take_away_radio_platform_delivery: 'Plataforma delivery',
  takeaway_input_platform_name: 'Nombre de la plataforma',
  takeaway_input_order_number: 'Núm. pedido plataforma',
  takeaway_input_client_name: 'Nombre de cliente',
  takeaway_input_client_phone: 'Tel. del cliente',
  takeaway_input_client_email: 'Mail del cliente',
  takeaway_input_hour: 'Programar hora de recogida',
  takeaway_button_take_note: 'Tomar pedido',
  //SetupOrder
  setup_order_assigned_zone_title: 'Zona asignada',
  setup_order_table_name: 'Mesa',
  setup_order_dinners_title: 'Comensales',
  setup_order_button_take_note: 'Tomar pedido',
  //EditOrder
  see_ticket: 'Ver pedido',
  order: 'Orden',
  orders: 'Órdenes',
  edit_order_add_comment: 'Añadir comentarios',
  edit_order_confirm: 'Confirmar',
  confirm_age_text:
    'Por favor, confirma que eres mayor de edad.<br/>¡Disfruta con responsabilidad!',
  //Order statuses
  order_paid: 'Pagado',
  order_status_paid: 'Pagado',
  order_status_ready: 'Listo',
  order_status_todo: 'Por Hacer',
  order_status_wip: 'En proceso',
  order_status_delivered: 'Servido',
  // Order Order of tickets
  order_of_ticket_row: 'Orden de salida',
  order_of_ticket_APPETIZER: 'Entrante',
  order_of_ticket_FIRST: 'Primero',
  order_of_ticket_SECOND: 'Segundo',
  order_of_ticket_DESSERT: 'Postre',
  order_of_ticket_OTHER: 'Otros',
  //PreparationZone
  preparation_zone_incoming_orders_title: 'Órdenes entrantes',
  preparation_zone_preparation_orders_title: 'En preparación',
  preparation_zone_ready_orders_title: 'Listo',
  preparation_zone_order_name: 'Orden',
  preparation_zone_order_num_table: 'Mesa',
  preparation_zone_customers: 'Clientes',
  preparation_zone_take_away: 'Takeaway',
  preparation_zone_qr_client: 'Cliente QR',
  preparation_zone_note_title: 'Nota',
  preparation_zone_products_right_text: 'Productos',
  preparation_zone_navigation_title: 'Otras zonas',
  preparation_zone_every_areas_title: 'Todas las áreas',
  //Invoice
  //Invoice table
  invoice_table_header_uds: 'Uds.',
  invoice_table_header_description: 'Descripción',
  invoice_table_header_price: 'Precio',
  invoice_table_header_discount: 'Descuento',
  invoice_table_header_total: 'Total',
  invoice_table_base: 'Base',
  invoice_table_iva: 'IVA',
  invoice_table_total_taxes_included: 'Total (Impuestos incl.)',
  invoice_pay_button: 'Realizar cobro',
  invoice_qr_button: 'Generar QR',
  invoice_show_order_status: 'Ver estado de la orden',
  //Order
  order_title: 'Order',
  order_back_to_meu: 'Volver a la carta',
  order_dialog_success_description: 'Tu pedido se ha creado con éxito',
  order_dialog_success_btn_title: 'Volver a la orden',
  order_dialog_warning_description: '¿Seguro que quieres eliminar este item de la orden?',
  order_dialog_warning_btn_title: 'Eliminar',
  order_payment_ticket_button: 'Ver ticket de pago',
  order_cancel_button: 'Cancelar orden',
  order_validate_button: 'Confirmar orden',
  post_order_dialog_error: 'No se pudo crear el orden. Esta mesa ya tiene otra orden activa',
  order_confirm_button: 'Confirmar orden',
  order_payment_button: 'Pagar',
  order_ticket_button: 'Ver ticket',
  new_order: 'Nueva orden',
  cancel_order_dialog: '¿Seguro que quieres cancelar esta orden?',
  order_payed_products: 'Productos pagados',
  order_unpayed_products: 'Productos sin pagar',
  //Payment
  card_number: 'Número de tarjeta',
  card_expiry: 'Caducidad',
  card_cvc: 'CVC',
  send_invoice: 'Enviar ticket al correo',
  send: 'Enviar',
  email: 'Email',
  pay_done: 'Se ha realizado el pago de',
  surplus: 'sobra',
  missing: 'falta',
  rest: 'resto',
  payment_error: 'Ha habido un error realizando el pago',
  pay: 'Pagar',

  //Orders
  //tabs
  orders_tabs_orders: 'Órdenes',
  orders_tabs_pick_up: 'Recoger',
  //PickUp
  orders_header_table: 'Mesa',
  orders_header_takeaway: 'Takeaway',
  //Discounts
  apply_discount_title: 'Aplicar descuento',

  // Roles
  role_admin: 'Admin',
  role_cooker: 'Cook',
  role_manager: 'Manager',
  role_rider: 'Rider',
  role_runner: 'Runner',
  role_waiter: 'Waiter',

  //CMS
  cms_something_went_wrong: 'Ha ocurrido un error',
  cms_confirm_delete_message: 'Esta seguro que quiere eliminar este elemento?',
  cms_confirm: 'Confirmar',
  cms_navigation_activity: 'Actividad',
  cms_navigation_administration: 'ADMINISTRACIÓN',
  cms_navigation_business: 'Negocio',
  cms_navigation_restaurant: 'Restaurante',
  cms_navigation_menu: 'Menú',
  cms_navigation_employees: 'Empleados',
  cms_navigation_clients: 'Clientes',
  cms_navigation_settings: 'Configuración',
  cms_save: 'Guardar',
  cms_cancel: 'Cancelar',
  cms_filter_placeholder: 'Buscar',
  cms_none: '',
  //Activity
  cms_activity_summary: 'Resumen Global',
  cms_activity_summary_current: 'Caja Activa',
  cms_from: 'De',
  cms_to: 'A',
  cms_update: 'Actualizar',
  cms_total_incomes: 'Ingresos totales',
  cms_credit_card_incomes: 'Ingresos con tarjeta',
  cms_cash_incomes: 'Ingresos en efectivo',
  cms_stripe_incomes: 'Ingresos con QR',
  cms_most_selled_products: 'Productos más vendidos',
  cms_sells_historic: 'Facturación',
  cms_datepicker_yesterday: 'Ayer',
  cms_datepicker_last_week: 'Semana pasada',
  cms_datepicker_this_week: 'Esta semana',
  cms_datepicker_this_month: 'Este mes',
  cms_datepicker_last_month: 'Mes pasado',
  cms_activity_comensals: 'Nº Comensales',
  cms_average_ticket: 'Media ticket',
  //Business
  // Current
  cms_go_to_invoice: 'Factura',
  cms_current_balance: 'Balance actual',
  cms_current: 'Caja activa',
  cms_historic: 'Histórico',
  cms_liquidation: 'Liquidaciones',
  cms_date: 'Fecha',
  cms_method: 'Medio de pago',
  cms_user: 'Usuario',
  cms_close_cash: 'Cerrar caja',
  cms_open_cash: 'Abrir caja',
  cms_add_movement: 'Añadir movimiento',
  cms_edit_movement: 'Editar movimiento',
  cms_pending_tickets_disclaimer:
    'Es recomendable que no existan tickets abiertos antes de cerrar la caja.',
  // Historic
  cms_historic_balance: 'Histórico de caja',
  cms_open_date: 'Fecha apertura',
  cms_close_date: 'Fecha cierre',
  cms_closing_value: 'Importe cierre',
  cms_total_card_value: 'Importe tarjeta',
  cms_total_cash_value: 'Importe efectivo',
  total_stripe_value: 'Importe QR',
  // Liquidation
  cms_historic_liquidation: 'Liquidaciones diarias',
  cms_online_incomes: 'Ingresos Qr',
  cms_money_incomes: 'Ingresos efectivo',
  cms_card_incomes: 'Ingresos tarjeta',
  cms_total: 'Total',
  // Products
  cms_historic_products: 'Ventas por producto',
  cms_quantity: 'Volumen',
  cms_total_income: 'Ingresos',
  cms_total_percentage: '% Ingresos',
  cms_quantity_percentage: '% Volumen',
  //Settings
  cms_general_settings: 'Configuración general',
  cms_service_settings: 'Configuración del servicio',
  cms_restaurant_settings: 'Configuracioin del restaurante',
  cms_add_message: 'Añadir mensaje',
  cms_edit_message: 'Editar mensaje',
  cms_messages: 'Mensajes',
  cms_language: 'Lengua',
  cms_currency: 'Moneda',
  cms_message: 'Mensaje',
  cms_actions: 'Acciones',
  cms_service_order: 'Ordenes',
  cms_thank_you_message: 'Mensaje de agradecimiento',
  cms_name: 'Nombre',
  cms_priority: 'Prioridad',
  cms_add_order: 'Añadir orden',
  cms_edit_order: 'Editar orden',
  cms_discounts: 'Descuentos',
  cms_description: 'Descripción',
  cms_type: 'Tipo',
  cms_value: 'Valor',
  cms_active: 'Activo',
  cms_not_active: 'No activo',
  cms_edit_discount: 'Editar descuento',
  cms_add_discount: 'Añadir descuento',
  cms_edit_alert: 'Editar alerta',
  cms_add_alert: 'Añadir alerta',
  cms_alerts: 'Alertas',
  cms_restaurant_info: 'Editar información',
  cms_phone: 'Teléfono',
  cms_avatar: 'Logo',
  cms_choose_file: 'Seleccionar imagen',
  cms_address: 'Dirección',
  cms_street: 'Calle',
  cms_number: 'Número',
  cms_postal_code: 'Código postal',
  cms_city: 'Ciudad',
  cms_country: 'País',
  cms_business_info: 'Datos fiscales',
  cms_cif: 'CIF',
  cms_add_delivery_rate: 'Añadir ratio de entrega',
  cms_edit_delivery_rate: 'Editar ratio de entrega',
  cms_cost: 'Coste',
  cms_limit_distance: 'Distancia limite',
  cms_products_filter_placeholder: 'Buscar productos',
  cms_minimum_cost_for_free: 'Coste minimo de entrega',
  cms_delivery_rate: 'Ratio de entrega',
  cms_time_zone: 'Zona horaria',
  cms_start_date: 'Fecha de inicio',
  cms_end_date: 'Fecha de finalización',
  cms_is_visible: 'Visible',
  cms_add_time_zone: 'Añadir zona horaria',
  cms_edit_time_zone: 'Editar zona horaria',
  //Payments
  cms_payments: 'Pagos',
  cms_payments_title: 'Stripe',
  cms_payments_status: 'Estado',
  cms_payments_details: 'Detalles',
  stripe_connect_action_connect: 'Contectar',
  stripe_connect_action_verified: 'Verificado',
  stripe_connect_action_review: 'Revisar',
  //Menu
  cms_categories: 'Categorías',
  cms_category_order: 'Orden de la categoría',
  cms_day_of_the_week: 'Día de la semana',
  cms_is_menu: 'Is menu',
  cms_parent_category: 'Categoría padre',
  cms_preparation_area: 'Área de preparación',
  cms_add_category: 'Añadir categoría',
  cms_edit_category: 'Editar categoría',
  cms_products: 'Productos',
  cms_price: 'Precio',
  cms_add_product: 'Añadir producto',
  cms_edit_product: 'Editar producto',
  cms_category: 'Categoría',
  cms_is_activated: 'Activada',
  cms_ingredient_modifier_title: 'Ingredient modifiers',
  cms_option_modifier_title: 'Option modifiers',
  cms_ingredient_modifier: 'INGREDIENT MODIFIER',
  cms_option_modifier: 'OPTION MODIFIER',
  cms_available: 'Disponible',
  cms_ingredients: 'Ingredientes',
  cms_options: 'Opciones',
  //Products
  cms_add_ingredients_modifier: 'Añadir ingredient modifier',
  cms_add_options_modifier: 'Añadir options modifier',
  cms_image: 'Imagen',
  cms_ingredients_filter_placeholder: 'Buscar',
  cms_age_restricted: 'Requiere edad mínima',
  //Ingredient modifiers
  cms_ingredients_modifier: 'Ingredient modifiers',
  cms_edit_ingredients_modifier: 'Editar',
  cms_extra: 'Extra',
  cms_remove: 'Quitar',
  //Option modifiers
  cms_is_required: 'Obligatorio',
  cms_is_not_required: 'Opcional',
  cms_options_filter_placeholder: 'Buscar opciones',
  cms_add_option_modifier: 'Añadir',
  cms_edit_option_modifier: 'Editar',
  //Ingredients
  cms_ingredient: 'Listado de ingredientes',
  cms_add_ingredient: 'Añadir',
  cms_edit_ingredient: 'Editar',
  //Options
  cms_add_option: 'Añadir',
  cms_edit_option: 'Editar',
  //Employees
  cms_employees: 'Empleados',
  cms_username: 'Usuario (email)',
  cms_first_name: 'Nombre',
  cms_last_name: 'Apellido',
  cms_status: 'Estado',
  cms_roles: 'Rol',
  cms_roles_filter_placeholder: 'Buscar',
  cms_add_employee: 'Añadir',
  cms_edit_employee: 'Editar',
  cms_password: 'Contraseña',
  cms_edit_password: 'Editar contraseña',
  cms_short_password_error: 'Error - contraseña mín. 9 caracteres',
  cms_password_missmatch_error: 'Error - las contrasñeas no coinciden',
  //Clients
  cms_clients: 'Clientes',
  cms_email: 'Email',
  cms_add_client: 'Añadir cliente',
  cms_edit_client: 'Editar cliente',
  //Restaurant
  //Preparation areas
  cms_preparation_areas: 'Áreas',
  cms_restaurant_layout: 'Áreas de preparación',
  cms_icon: 'Icono',
  cms_add_area: 'Añadir',
  cms_edit_area: 'Editar',
  // Service zones
  cms_service_zones: 'Zonas de servicio',
  cms_prefix: 'Prefijo',
  cms_add_zone: 'Añadir',
  cms_edit_zone: 'Editar',
  // Table groups
  cms_table_groups: 'Grupos de mesas',
  cms_complete_name: 'Nombre',
  cms_service_zone: 'Zona de servicio',
  cms_is_not_visible: 'Activo',
  cms_add_group: 'Añadir',
  cms_edit_group: 'Editar',
  // Tables
  cms_tables: 'Mesas',
  cms_table_group: 'Grupo de mesas',
  cms_generate_pdf: 'Generar PDF',
  cms_add_table: 'Añadir',
  cms_edit_table: 'Editar',
  cms_export: 'Exportar',

  // CMS ERRORS
  cms_preparation_area_linked_to_products_error:
    'No se puede eliminar esta zona porque tiene productos asociados.', // The RestaurantPreparationArea cannot be deleted. There are products linked to this area.
  cms_preparation_area_linked_to_categories_error:
    'No se puede  eliminar esta zona porque tiene categorias asociadas.', // The RestaurantPreparationArea cannot be deleted. There are categories linked to this area.
  cms_option_linked_to_option_modifiers_error:
    'No se puede eliminar esta opcion porque tiene modificadores asociados.', // The Option cannot be deleted. There are OptionModifiers linked to it.
  cms_service_zone_linked_to_table_groups_error:
    'No se puede eliminar  esta  zona porque tiene grupos de mesas asociados.', // Cannot be deleted, There are table groups linked to this service zone { %s }
  cms_ingredient_modifiers_linked_to_product_error:
    'No se puede eliminar este modificador porque tiene productos asociados.', // The ingredientModifier cannot be deleted. There are Products linked to it.
  cms_ingredient_linked_to_ingredient_modifiers_error:
    'No se puede eliminar este ingredientee porque tiene modificadores asociados.', // The ingredient cannot be deleted. There are IngredientModifiers linked to it.
  cms_ingredient_linked_to_products_error:
    'No se puede eliminar este ingrediente porque tiene productos asociados.', // The ingredient cannot be deleted. There are Products linked to it.
  cms_option_modifiers_linked_to_products_error:
    'No se puede eliminar este modificador porque tiene productos asociados.', // The optionModifier cannot be deleted. There are Products linked to it.
  cms_table_groups_linked_to_tables_error:
    'No  se puede borrar este grupo porque tiene mesas asociadas.', // The TableGroup cannot be deleted. There are tables linked to it.
  cms_service_order_linked_to_products_error:
    'No se puede eliminar este orden porque tiene productos asociados.', // The serviceOrder cannot be deleted. There are products linked to it.
  cms_service_order_linked_to_categories_error:
    'No se puede elminar este orden porque tiene categorias asociadas.', // The serviceOrder cannot be deleted. There are categories linked to it.
  cms_service_order_linked_to_ticket_row_error:
    'No se puede eliminar este orden porque tiene tickets asociados.', // The serviceOrder cannot be deleted. There are ticketRows linked to it.
  cms_category_linked_to_products_error:
    'No se puede eliminar esta categoria porque tiene productos asociados.', // The Category cannot be deleted. There are products linked to this category.
  cms_category_linked_to_child_categories_error:
    'No se puede eliminar esta categoria porque tiene subcategorias asociadas.', // The Category cannot be deleted. There are other categories below this.
  cms_product_linked_to_ticket_row_error:
    'No se puede eliminar este producto porque tiene tickets asociados.', // The product cannot be deleted. There are TicketRows linked to this product.

  // Stripe literals
  stripe_connect_status_not_connected: 'La cuenta de Stripe no ha sido conectada aún.',
  stripe_connect_status_verified: 'La cuenta de Stripe ha sido conectada.',
  stripe_connect_status_disabled: 'Hay errores en la cuenta conectada.',

  stripe_disabled_reason_action_required_requested_capabilities:
    'Se necesita pedir las capacidades para la cuenta conectada.',
  stripe_disabled_reason_requirements_past_due:
    'Se requiere información adicional para habilitar las capacidades de pago o cobro en esta cuenta.',
  stripe_disabled_reason_requirements_pending_verification:
    'La cuenta conectada está actualmente siendo verificada por Stripe.',
  stripe_disabled_reason_rejected_fraud:
    'La cuenta ha sido rechazada por supuesto fraude o actividad ilegal.',
  stripe_disabled_reason_rejected_terms_of_service:
    'La cuenta ha sido rechazada por supuesta violación de los términos y condiciones.',
  stripe_disabled_reason_rejected_listed:
    'La cuenta ha sido rechazada porque se encuentra en una lista externa de personas o empresas prohibidas (proveedores de servicios financieros o gobiernos).',
  stripe_disabled_reason_rejected_other: 'La cuenta ha sido rechazada por otros motivos.',
  stripe_disabled_reason_listed:
    'La cuenta puede estar en una lista de personas o empresas prohibidas (Stripe investigará y rechazará o readmitirá la cuenta acordemente).',
  stripe_disabled_reason_under_review: 'La cuenta está bajo revisión por parte de Stripe.',
  stripe_disabled_reason_other:
    'La cuenta no ha sido rechazada pero ha sido deshabilitada por otros motivos mientas se revisaba.',
}
