<template>
  <div class="login view">
    <Login />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Login from '../components/login'

export default {
  components: { Login },
  methods: {
    ...mapActions({
      resetOrder: 'newOrder/reset',
      invalidateActiveOrder: 'activeOrder/invalidate',
    }),
  },
}
</script>

<style lang="stylus" scoped></style>