<template>
  <div v-if="restaurant" class="invoice-header">
    <restaurant-image :url="restaurant.avatar" />
    <div class="restaurant-name">{{ restaurant.name }}</div>
    <div class="restaurant-info-list">
      <template v-if="restaurant.address">
        <div class="restaurant-info-item restaurant-address">
          <div>{{ `${restaurant.address.street}, ${restaurant.address.number}` }}</div>
          <div>{{ restaurant.address.city }}</div>
        </div>
      </template>
      <div class="restaurant-info-item restaurant-business-name">{{ restaurant.businessName }}</div>
      <div class="restaurant-info-item restaurant-nif">{{ restaurant.nif }}</div>
      <div class="restaurant-info-item restaurant-phone">{{ `TLF ${restaurant.phone}` }}</div>
    </div>
  </div>
  <basic-loading v-else />
</template>

<script>
import { mapGetters } from 'vuex'
import BasicLoading from '../basic/BasicLoading.vue'
import RestaurantImage from '../ImageThumbnail'
export default {
  components: {
    BasicLoading,
    RestaurantImage
  },
  remoteComputed: {
    restaurant: {
      model: 'restaurant/restaurant',
      method: 'get',
      params: function() {
        return [this.restaurantId]
      },
    },
  },
  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
    }),
    restaurantId() {
      if (this.logedUser) return this.logedUser.restaurantId
      return this.$route.params.restaurantId
    },
  },
}
</script>

<style lang="stylus" scoped>
.loading
  height: 50%

.invoice-header
  display: flex
  flex-direction: column
  align-items: center
  padding-right: vw(16px)
  padding-left: vw(16px)

.restaurant-name
  m-font-size(30, 34)
  margin-bottom: vw(16px)
  font-weight: bold

.restaurant-info-list
  m-font-size(16, 20)
  margin-bottom: vw(16px)
  color: #828282
  text-align: center

.restaurant-info-item
  &:not(:last-child)
    margin-bottom: vw(5px)
</style>