import Vue from 'vue'
import Login from '../views/Login'
import VueRouter from 'vue-router'
import ModelsTest from '../views/ModelsTest'
import Order from '../views/Order'
// import ActiveOrder from '../views/ActiveOrder'
import Orders from '../views/Orders'
import Menu from '../views/Menu'
import PreparationZone from '../views/PreparationZone.vue'
import Payment from '../views/Payment.vue'
import Invoice from '../views/Invoice'
import InvoiceQr from '../views/InvoiceQr'
import CashRegisterClosed from '../views/CashRegisterClosed'
import CreateRestaurant from '../views/CreateRestaurant'
import Cms from '../views/cms/CMS.vue'
import cmsRoutes from './cms/index'
import store from '../store'
import { ROUTE_NAMES } from '../settings/routeNames'
// import Product from '../views/Product'
// import EditRow from '../views/EditRow'
Vue.use(VueRouter)

const protectedTypes = {
  LOGIN_PROTECTED: 'LOGIN_PROTECTED',
  QR_OR_LOGIN_PROTECTED: 'QR_OR_LOGIN_PROTECTED',
}

const routes = [
  {
    path: '/login',
    name: ROUTE_NAMES.LOGIN,
    component: Login,
  },
  {
    path: '/models',
    name: ROUTE_NAMES.MODELS_TEST,
    component: ModelsTest,
    meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
  },
  {
    path: '/orders',
    name: ROUTE_NAMES.ORDERS,
    component: Orders,
    meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
  },
  {
    path: '/menu/:categoriesString?',
    name: ROUTE_NAMES.MENU,
    //component: Menu,
    //props: true,
    redirect: '/order/new/menu/:categoriesString?',
  },
  // {
  //   path: '/product/:productId?',
  //   name: ROUTE_NAMES.PRODUCT,
  //   component: Product,
  //   props: true
  // },
  // {
  //   path: '/edit-row/:rowId?',
  //   name: ROUTE_NAMES.EDIT_ROW,
  //   component: EditRow,
  //   props: true
  // },
  {
    path: '/order/menu',
    redirect: '/order/new/menu',
  },
  {
    path: '/order/editRow',
    redirect: '/order/new/editRow',
  },
  {
    path: '/order/notifyRow',
    redirect: '/order/new/notifyRow',
  },
  {
    path: '/order/deleteRow',
    redirect: '/order/new/deleteRow',
  },
  {
    path: '/order/discount',
    redirect: '/order/new/discount',
  },
  {
    path: '/order/deleteRow',
    redirect: '/order/new/deleteRow',
  },
  {
    path: '/order/:orderId',
    name: ROUTE_NAMES.ORDER,
    component: Order,
    props: true,
    meta: { protectedRoute: protectedTypes.QR_OR_LOGIN_PROTECTED },
  },
  {
    path: '/table/:tableId?/:orderId?',
    name: ROUTE_NAMES.TABLE,
    component: Order,
    props: route => {
      return {
        isTableView: true,
        tableIdExternal: route.params.tableId,
        orderId: route.params.orderId,
      }
    },
    meta: { protectedRoute: protectedTypes.QR_OR_LOGIN_PROTECTED },
  },
  {
    path: '/order/:orderId/editRow/:row',
    name: ROUTE_NAMES.ORDER_EDIT_ROW,
    component: Order,
    props: true,
    meta: { protectedRoute: protectedTypes.QR_OR_LOGIN_PROTECTED },
  } /*
  {
    path: '/order/:orderId/notifyRow/:row',
    name: ROUTE_NAMES.ORDER_NOTIFY_ROW,
    component: Order,
    props: true,
    meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
  },*/,
  {
    path: '/order/:orderId/deleteRow/:row',
    name: ROUTE_NAMES.ORDER_DELETE_ROW,
    component: Order,
    props: true,
    meta: { protectedRoute: protectedTypes.QR_OR_LOGIN_PROTECTED },
  },
  {
    path: '/order/:orderId/discount/:row',
    name: ROUTE_NAMES.ORDER_DISCOUNT_ROW,
    component: Order,
    props: true,
    meta: { protectedRoute: protectedTypes.QR_OR_LOGIN_PROTECTED },
  },
  {
    path: '/order/:orderId/discount',
    name: ROUTE_NAMES.ORDER_DISCOUNT,
    component: Order,
    props: true,
    meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
  },
  {
    path: '/order/:orderId/menu/:categoriesString?',
    name: ROUTE_NAMES.ORDER_MENU,
    component: Menu,
    props: true,
    meta: { protectedRoute: protectedTypes.QR_OR_LOGIN_PROTECTED },

    replace: true,
  },
  // {
  //   path: '/active-order',
  //   name: ROUTE_NAMES.ACTIVE_ORDER,
  //   component: ActiveOrder,
  //   props: true
  // },
  {
    path: '/preparationZone',
    name: ROUTE_NAMES.PREPARATION_ZONE,
    component: PreparationZone,
    meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
    // props: true
  },
  {
    path: '/order/:orderId/payment',
    name: ROUTE_NAMES.PAYMENT,
    component: Payment,
    props: true,
    meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
  },
  {
    path: '/invoice/:orderId',
    name: ROUTE_NAMES.INVOICE,
    component: Invoice,
    props: true,
    meta: { protectedRoute: protectedTypes.QR_OR_LOGIN_PROTECTED },
  },
  {
    path: '/invoiceQr/:orderId/:restaurantId',
    name: ROUTE_NAMES.INVOICE_QR,
    component: InvoiceQr,
    props: true,
  },
  {
    path: '/cashRegisterClosed',
    name: ROUTE_NAMES.CASH_REGISTER_CLOSED,
    component: CashRegisterClosed,
  },
  {
    path: '/loginQr/:restaurantId/:tableId/',
    name: ROUTE_NAMES.LOGIN_QR,
    // Only for capturing in before each
  },
  {
    path: '/cms',
    name: ROUTE_NAMES.CMS.MAIN,
    component: Cms,
    meta: { protectedRoute: protectedTypes.LOGIN_PROTECTED },
    children: cmsRoutes,
    redirect: { name: ROUTE_NAMES.CMS.ACTIVITY },
  },
  {
    path: '/',
    redirect: { name: ROUTE_NAMES.ORDERS },
  },
  {
    path: '/createRestaurant',
    name: ROUTE_NAMES.CREATE_RESTAURANT,
    component: CreateRestaurant,
  },
]

const router = new VueRouter({
  routes,
  base: process.env.BASE_URL,
  mode: 'history',
})

router.beforeEach((to, from, next) => {
  //Limit back from usrQr
  if (
    store.getters['userQr/getLogedQrData'] &&
    from.name === ROUTE_NAMES.TABLE &&
    to.name === ROUTE_NAMES.ORDER
  ) {
    return next(from)
  }
  //Check if login QR and update userQr store
  if (to.name === ROUTE_NAMES.LOGIN_QR) {
    const auxUserQr = store.getters['userQr/getLogedQrData']
    if (
      !auxUserQr ||
      parseInt(auxUserQr.restaurantId) !== parseInt(to.params.restaurantId) ||
      parseInt(auxUserQr.tableId) !== to.params.tableId
    ) {
      console.debug(
        '[LOGIN QR]',
        'SET PARAMS',
        '\nRestaurant:',
        to.params.restaurantId,
        '\ntable:',
        to.params.tableId
      )
      store.dispatch('userQr/setData', {
        restaurantId: to.params.restaurantId,
        tableId: to.params.tableId,
      })
    }
    //store.dispatch('newOrder/reset')
    //store.dispatch('activeOrder/invalidate')
    next(ROUTE_NAMES.MENU)
  }

  //Check if its protected by login or by qr
  if (to.meta.protectedRoute) {
    if (to.meta.protectedRoute === protectedTypes.LOGIN_PROTECTED) {
      if (store.getters['user/getLogedUser']) next()
      else next(ROUTE_NAMES.LOGIN)
    } else if (to.meta.protectedRoute === protectedTypes.QR_OR_LOGIN_PROTECTED) {
      if (store.getters['user/getLogedUser'] || store.getters['userQr/getLogedQrData']) next()
      else next(ROUTE_NAMES.LOGIN) //TODO REDIRECT ERROR PAGE
    } else if (to.meta.protectedRoute === protectedTypes.THANOS) {
      if (store.getters['user/getLogedUser'].roles.includes('ROLE_THANOS')) next()
      else next(ROUTE_NAMES.LOGIN)
    }
  } else next()
})

export default router
