import SockJS from 'sockjs-client'
import Stomp from 'webstomp-client'

/**
 *  Backend dont set the iframe protocol for internat explorer 7-9 and Opera 10.70+
 *  https://github.com/sockjs/sockjs-client#supported-transports-by-browser-html-served-from-http-or-https
 */
const sockJsProtocols = ['xhr-streaming', 'xhr-polling']
class Socket {
  connected = false
  connecting = false
  topic = null

  constructor(props) {
    this.url = props.url
    this.topicTag = props.topicTag
  }

  get isConnected() {
    return this.connected
  }

  get isConnecting() {
    return this.connecting
  }

  get topic() {
    return this.topic
  }

  connect(topic, callback) {
    this.connecting = true
    this.topic = topic
    this.socket = new SockJS(this.url, null, { transports: sockJsProtocols })
    this.stompClient = Stomp.over(this.socket, { debug: process.env.NODE_ENV !== 'production' })
    // change the to debug level
    this.stompClient.debug = function (message) {
      console.debug(message)
    }

    this.stompClient.connect(
      {},
      () => {
        this.connected = true
        this.stompClient.subscribe(`/topic/${this.topicTag}/${topic}`, tick => {
          callback(JSON.parse(tick.body))
        })
      },
      error => {
        console.error(error)
        this.connected = false
        this.connecting = false
        this.topic = null
      }
    )
  }
  disconnect() {
    if (this.stompClient) {
      this.stompClient.disconnect()
    }
    this.connected = false
    this.connecting = false
    this.topic = null
  }
}

export default Socket
