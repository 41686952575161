<template>
  <tr class="invoice-row">
    <td class="left">{{ row.quantity }}</td>
    <td class="left">
      <div class="invoice-row-product">
        {{ getProductById(row.productId) ? $label(getProductById(row.productId)) : '' }}
      </div>
      <ticket-row-modifier
        v-for="mod in modifiersList"
        :id="mod.id"
        :key="mod.type+mod.id"
        :type="mod.type"
        :value="mod.value"
      />
    </td>
    <td v-if="addDiscountColumn" class="right">{{ discountText }}</td>
    <td class="right">
      <div class="invoice-row-price">{{ `${row.calculatedPrice.price}${parsedCurrency}` }}</div>
    </td>
    <td class="right">{{ priceText }}</td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'
import { parseCurrency } from '../../utils/ticketUtils'
import TicketRowModifier from '../ticket-row/TicketRowModifier.vue'
export default {
  components: { TicketRowModifier },
  props: {
    order: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  remoteComputed: {
    product: {
      model: 'restaurant/products',
      method: 'getById',
    },
    discounts: {
      model: 'restaurant/discounts',
      method: 'get',
    },
    ticketSettings: {
      model: 'restaurant/ticketSettings',
      method: 'get',
    },
  },
  computed: {
    ...mapGetters({
      getProductById: 'restaurant/products/getById',
      getDiscountById: 'restaurant/discounts/getById',
    }),
    discountText() {
      const discount = this.getDiscountById(this.row.restaurantDiscountId)
      return discount ? `${discount.value}%` : ''
    },
    priceText() {
      return `${(this.row.calculatedPrice.price * (this.row.quantity || 1)).toFixed(2)}${this.parsedCurrency}`
    },
    parsedCurrency() {
      return parseCurrency(this.currency)
    },
    modifiersList() {
      let ingMods = this.row.ingredientModifiers.map(im => ({
        ...im,
        type: 'ing',
      }))
      let optMods = this.row.optionModifiers.map(im => ({
        ...im,
        type: 'opt',
      }))
      return [...ingMods, ...optMods]
    },
    currency() {
      return this.ticketSettings ? this.ticketSettings.currency : null
    },
    basePrice() {
      return `${this.order.calculatedPrice.base}${this.parsedCurrency}`
    },
    ivaUsed() {
      return this.order.calculatedPrice.ivaUsedInTheCalculation
    },
    ivaCalculated() {
      return `${this.order.calculatedPrice.ivaCalculated}${this.parsedCurrency}`
    },
    totalCalculated() {
      return `${this.order.calculatedPrice.totalCalculated}${this.parsedCurrency}`
    },
    addDiscountColumn() {
      return this.order.rows.find(row => !!row.restaurantDiscountId)
    },
  },
}
</script>

<style lang="stylus" scoped>
.invoice-row
  vertical-align: top

.invoice-row-product
  &:not(:only-child)
    margin-bottom: 0.5em
</style>