export default {
  TICKET_ROW_ALERT_LINKED_TO_TICKET_ROW: 'cms_ticket_row_alert_linked_to_ticket_row_error', // 'The TicketRowAlert cannot be deleted. There are ticketRows linked to it.'
  PREPARATION_AREA_LINKED_TO_PRODUCTS: 'cms_preparation_area_linked_to_products_error', // The RestaurantPreparationArea cannot be deleted. There are products linked to this area.
  PREPARATION_AREA_LINKED_TO_CATEGORIES: 'cms_preparation_area_linked_to_categories_error', // The RestaurantPreparationArea cannot be deleted. There are categories linked to this area.
  OPTION_LINKED_TO_OPTION_MODIFIERS: 'cms_option_linked_to_option_modifiers_error', // The Option cannot be deleted. There are OptionModifiers linked to it.
  SERVICE_ZONE_LINKED_TO_TABLE_GROUPS: 'cms_service_zone_linked_to_table_groups_error', // Cannot be deleted, There are table groups linked to this service zone { %s }
  INGREDIENT_MODIFIERS_LINKED_TO_PRODUCT: 'cms_ingredient_modifiers_linked_to_product_error', // The ingredientModifier cannot be deleted. There are Products linked to it.
  INGREDIENT_LINKED_TO_INGREDIENT_MODIFIERS: 'cms_ingredient_linked_to_ingredient_modifiers_error', // The ingredient cannot be deleted. There are IngredientModifiers linked to it.
  INGREDIENT_LINKED_TO_PRODUCTS: 'cms_ingredient_linked_to_products_error', // The ingredient cannot be deleted. There are Products linked to it.
  OPTION_MODIFIERS_LINKED_TO_PRODUCTS: 'cms_option_modifiers_linked_to_products_error', // The optionModifier cannot be deleted. There are Products linked to it.
  TABLE_GROUPS_LINKED_TO_TABLES: 'cms_table_groups_linked_to_tables_error', // The TableGroup cannot be deleted. There are tables linked to it.
  SERVICE_ORDER_LINKED_TO_PRODUCTS: 'cms_service_order_linked_to_products_error', // The serviceOrder cannot be deleted. There are products linked to it.
  SERVICE_ORDER_LINKED_TO_CATEGORIES: 'cms_service_order_linked_to_categories_error', // The serviceOrder cannot be deleted. There are categories linked to it.
  SERVICE_ORDER_LINKED_TO_TICKET_ROW: 'cms_service_order_linked_to_ticket_row_error', // The serviceOrder cannot be deleted. There are ticketRows linked to it.
  CATEGORY_LINKED_TO_PRODUCTS: 'cms_category_linked_to_products_error', // The Category cannot be deleted. There are products linked to this category.
  CATEGORY_LINKED_TO_CHILD_CATEGORIES: 'cms_category_linked_to_child_categories_error', // The Category cannot be deleted. There are other categories below this.
  PRODUCT_LINKED_TO_TICKET_ROW: 'cms_product_linked_to_ticket_row_error', // The product cannot be deleted. There are TicketRows linked to this product.
}