<template>
  <div class="order-discount">
    <!-- <span v-if="productDiscounts">Loading</span> -->
    <basic-loading v-if="!productDiscounts" />
    <template v-else>
      <take-away-card 
        v-if="order.takeAway"
        :order="order"
      />
      <generic-table-order-row
        v-else
        :data="order"
      />
      <basic-radio-button-group 
        v-model="selectedDiscount"
        :options="productDiscounts"
        :title="$t('apply_discount_title')"
        :show-value-in-title="true"
      />
      <div class="buttons">
        <basic-button :title="$t('apply_discount_title')" class="btn-submit btn btn--primary" @click="handleSubmitDiscount" />
        <basic-button :title="$t('cancel_title')" class="btn-cancel btn btn--primary btn--ghost" @click="$emit('close')" />
        <!-- <span class="cancel" @click="$emit('close')">{{ $t('cancel_title') }}</span>   -->
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BasicButton from '../basic/BasicButton.vue'
import BasicRadioButtonGroup from '../basic/BasicRadioButtonGroup.vue'
import { DISCOUNT_TYPES } from './../../settings/discountSettings'
import GenericTableOrderRow from './GenericTableOrderRow.vue'
import TakeAwayCard from './take-away/TakeAwayCard.vue'
import BasicLoading from '../basic/BasicLoading.vue'

export default {
  components: {
    BasicButton,
    BasicRadioButtonGroup,
    GenericTableOrderRow,
    TakeAwayCard,
    BasicLoading,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedDiscount: this.order.restaurantDiscountId,
    }
  },
  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
    }),
    productDiscounts() {
      return this.discounts
        ? this.discounts.filter(discount => discount.discountType === DISCOUNT_TYPES.RESTAURANT)
        : null
    },
  },
  remoteComputed: {
    discounts: {
      model: 'restaurant/discounts',
      method: 'get',
    },
  },
  methods: {
    handleSubmitDiscount() {
      this.$emit('confirm', this.selectedDiscount)
    },
  },
}
</script>

<style lang="stylus" scoped>
.order-discount
  display: flex
  flex-direction: column

  .buttons
    display: flex
    flex-direction: column
    align-items: center

  .btn-cancel
    margin-top: vw(-20px)
    margin-bottom: vw(-15px)
</style>