<template>
  <div class="list-input">
    <input v-model="filterValue" class="filter-input" :placeholder="placeholder">
    <div class="list-input-items">  
      <div
        v-for="item in filteredItems"
        :key="item.id"
        class="list-input__item"
        @click="handleItemClick(item.id)"
      >
        <div :class="{ isChecked: localValue.includes(item.id), isNotChecked: !localValue.includes(item.id) }" />
        <p>{{ item.label }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filterValue: '',
      localValue: this.value,
    }
  },
  computed: {
    filteredItems() {
      return this.items
        .filter(({ label }) => label.toLowerCase().includes(this.filterValue.toLowerCase()))
        .sort((a, b) => {
          const labelA = a.label.toLowerCase()
          const labelB = b.label.toLowerCase()
          return labelA < labelB ? -1 : labelA > labelB ? 1 : 0
        })
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit('input', newValue)
    },
    value(newValue) {
      this.localValue = newValue
    },
  },
  methods: {
    handleItemClick(id) {
      if (this.value.includes(id)) {
        this.localValue = this.localValue.filter(currId => currId !== id)
      } else {
        this.localValue = [...this.localValue, id]
      }
    },
  },
}
</script>
<style lang="stylus" scoped>
.list-input-items
  overflow: scroll
  max-height: vh(500px)

.filter-input
  top: 0
  margin-top: vh(10px)
  margin-bottom: vh(10px)

.list-input__item
  display: flex
  align-items: center
  height: vh(48px)
  gap: vw(10px)

  .isChecked
    m-icon("check", 32)

  .isNotChecked
    m-icon("uncheck", 32)
</style>