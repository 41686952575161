<template>
  <div class="order" :class="[{'is-loading': !mergedOrder}, {'with-cancel-btn': isNew || isTableView && !(mergedOrder ? mergedOrder.isPaid : order.isPaid)}]">
    <basic-dialog
      v-if="!isNoTable"
      :is-open="stripeDialogOpen && !!orderId /** for Ensure it!!*/"
      @close="() =>stripeDialogOpen=false"
    >
      <template slot="dialog-body">
        <stripe-payment :order="order" @paymentFailed="handleStripePaymentFailed" />
      </template>
    </basic-dialog>
    <template v-if="!isNew && !isTableView && !!orderId" && order.calculatedPrice>
      <confirm-payment-dialog
        v-if="order.calculatedPrice && order.calculatedPrice.totalCalculated"
        :is-open="paymentDialogOpen"
        :success="paymentSuccess"
        :payment-error-code="paymentErrorCode"
        :order-id="parseInt(orderId)"
        :description="$t('pay_done')"
        :title="`${order.calculatedPrice.totalCalculated}${ticketSettings ? parsedCurrency(ticketSettings.currency) : ''}`"
        :btn-title="$t('turn_order')"
        @close="handleClosePaymentDialog"
      />
    </template>
    <success-dialog
      :is-open="orderCreatedDialogOpen && !!orderId /** for Ensure it!!*/"
      :description="$t('order_dialog_success_description')"
      :title="`${$t('order_title')} # ${order.id}`"
      :btn-title="$t('order_dialog_success_btn_title')"
      @close="handleSwitchOrderCreatedDialogOpen"
      @accept="handleSwitchOrderCreatedDialogOpen"
    />
    <warning-dialog
      :is-open="deleteRowDialogOpen && !!orderId /** for Ensure it!!*/"
      :description="$t('order_dialog_warning_description')"
      :btn-title="$t('order_dialog_warning_btn_title')"
      @close="onCloseQueryDialogs(DELETE_ROW_QUERY)"
      @accept="deleteRows"
    />
    <basic-dialog
      v-if="selectTicketDialogOpen"
      :is-open="selectTicketDialogOpen"
      @close="onCloseSelectTicketDialog()"
    >
      <template slot="dialog-body">
        <move-ticket-rows :ticket-rows-to-move="[ticketRowToMove]" :current-order="order" @close="onCloseSelectTicketDialog()" />
      </template>
    </basic-dialog>
    <basic-dialog
      v-if="postOrderError"
      :is-open="postOrderError"
      @close="onClosePostOrderErrorDialog()"
    >
      <template slot="dialog-body">
        <div>{{ $t('post_order_dialog_error') }}</div>
      </template>
    </basic-dialog>
    <basic-dialog
      v-if="!!selectedRowQuery(NOTIFICATION_QUERY)"
      :is-open="notificationDialogOpen"
      @close="onCloseQueryDialogs(NOTIFICATION_QUERY)"
    >
      <template slot="dialog-body">
        <ticket-row-notification :row="selectedRowQuery(NOTIFICATION_QUERY)" @close="onCloseQueryDialogs(NOTIFICATION_QUERY)" @confirm="submitNotify" />
      </template>
    </basic-dialog>
    <basic-dialog
      v-if="!!selectedRowQuery(DISCOUNT_ROW_QUERY)"
      :is-open="discountRowDialogOpen"
      @close="onCloseQueryDialogs(DISCOUNT_ROW_QUERY)"
    >
      <template slot="dialog-body">
        <ticket-row-discount :row="selectedRowQuery(DISCOUNT_ROW_QUERY)" @close="onCloseQueryDialogs(DISCOUNT_ROW_QUERY)" @confirm="submitDiscountRow" />
      </template>
    </basic-dialog>
    <basic-dialog
      v-if="discountOrderDialogOpen"
      :is-open="discountOrderDialogOpen"
      @close="onCloseQueryDialogs(DISCOUNT_ORDER_QUERY)"
    >
      <template slot="dialog-body">
        <order-discount :order="order" @close="onCloseQueryDialogs(DISCOUNT_ORDER_QUERY)" @confirm="submitDiscountOrder" />
      </template>
    </basic-dialog>
    <basic-loading v-if="!mergedOrder" />
    <template v-else>
      <template v-if="!isNoTable">
        <div class="back-link" @click="onHandleClickMenu">
          <div class="back-link-icon" />
          <div class="back-link-text">{{ $t('order_back_to_meu') }}</div>
        </div>
      </template>
      <generic-table-order-row
        v-if="meanTableOrders.table && (!userQr || isTableView)"
        :data="meanTableOrders"
        :show-status="true"
        @changeTable="onTableChanged"
      />
      <template v-else>
        <take-away-card :order="mergedOrder" />
      </template>
      <div v-if="isWaiter && isTableView" class="lala">
        <template>
          <template v-if="rowGroupsNotPaid.length>0">
            <div class="ticket-row-title">{{ $t('order_unpayed_products') }}</div>
            <div class="ticket-rows-list active">
              <div v-for="group in rowGroupsNotPaid" :key="group.id" class="status-group">
                <ticket-row-group
                  v-for="row in group.rows"
                  :key="row.id"
                  :data="row"
                  :is-active-order="isActive"
                  :editable="!order.isPaid"
                  :is-paid="false"
                  :disable-order-actions="disableOrderActions"
                  @click="onRowClicked"
                  @clickNotify="onNotifyClicked"
                  @moveTicketRow="moveTicketRow"
                  @clickDelete="onDeleteClicked"
                  @clickDeleteOne="onDeleteOneRowClicked"
                  @clickAddOne="onAddOneRowClicked"
                  @clickDiscount="onRowDiscountClicked"
                />
              </div>
            </div>
            <div v-if="order.calculatedPrice" class="calculated-price">
              <basic-price-summary :order="order" :show-discount="!userQr" @clickDiscount="onOrderDiscountClicked(order.id)" />
            </div>
          </template>
          <template v-if="rowGroupsPaid.length>0">
            <div class="ticket-row-title">{{ $t('order_payed_products') }}</div>    
            <div class="ticket-rows-list paid">
              <div v-for="group in rowGroupsPaid" :key="group.id" class="status-group">
                <ticket-row-group
                  v-for="row in group.rows"
                  :key="row.id"
                  :data="row"
                  :is-active-order="isActive"
                  :editable="!order.isPaid"
                  :is-paid="true"
                  :disable-order-actions="disableOrderActions"
                  @clickNotify="onNotifyClicked"
                  @clickDelete="onDeleteClicked"
                  @moveTicketRow="moveTicketRow"
                  @clickDeleteOne="onDeleteOneRowClicked"
                  @clickAddOne="onAddOneRowClicked"
                  @clickDiscount="onRowDiscountClicked"
                />
              </div>
            </div>
          </template>
        </template>
      </div>
      <div v-else class="ticket-rows-list">
        <template v-if="isActive">
          <div v-for="group in rowGroups" :key="group.id" class="status-group">
            <ticket-row-group
              v-for="row in group.rows"
              :key="row.id"
              :data="row"
              :is-active-order="isActive"
              :editable="!order.isPaid"
              :is-paid="order.isPaid"
              :disable-order-actions="disableOrderActions"
              @click="onRowClicked"
              @clickNotify="onNotifyClicked"
              @moveTicketRow="moveTicketRow"
              @clickDelete="onDeleteClicked"
              @clickDeleteOne="onDeleteOneRowClicked"
              @clickAddOne="onAddOneRowClicked"
              @clickDiscount="onRowDiscountClicked"
            />
          </div>
        </template>
        <template v-else>
          <ticket-row-group
            v-for="row in mergedTicketRows"
            :key="row.id"
            :data="row"
            :disable-order-actions="disableOrderActions"
            :editable="!mergedOrder.isPaid"
            :is-paid="mergedOrder.isPaid"
            @click="onRowClicked"
            @clickNotify="onNotifyClicked"
            @clickDelete="onDeleteClicked"
            @clickDeleteOne="onDeleteOneRowClicked"
            @moveTicketRow="moveTicketRow"
            @clickAddOne="onAddOneRowClicked"
            @clickDiscount="onRowDiscountClicked"
          />
        </template>
      </div>
      <order-footer-actions
        :order-id="orderId"
        :is-table-view="isTableView && !activeOrderId"
        @onCancelOrder="cancelDialogOpen=true"
        @onOpenStripePay="handleOpenStripePay"
        @newOrder="onHandleClickNewOrder"
      />
      <template v-if="editRowOpen">
        <edit-ticket-row
          :product-id="selectedRowQuery(EDIT_ROW_QUERY).productId"
          :value="selectedRowQuery(EDIT_ROW_QUERY)"
          @close="onCloseQueryDialogs(EDIT_ROW_QUERY)"
          @confirm="updateSelected"
        />
      </template>
    </template>
    <basic-dialog :is-open="cancelDialogOpen" @close="cancelDialogOpen=false">
      <template slot="dialog-body">
        <div class="dialog-body-text">{{ $t('cancel_order_dialog') }}</div>
        <div class="dialog-buttons">
          <div class="btn-yes btn btn--primary" @click="onCancel">{{ $t('accept_title') }}</div>
          <div class="btn-no btn btn--secondary" @click="cancelDialogOpen=false">{{ $t('cancel_title') }}</div>
        </div>
      </template>
    </basic-dialog>
  </div>
</template>

<script>
import BasicDialog from '../components/basic/BasicDialog.vue'
import BasicLoading from '@/components/basic/BasicLoading.vue'
import { orderController } from '../services/api'
import EditTicketRow from '../components/EditTicketRow.vue'
import QueryMixin from '../mixins/QueryMixin'
import OrderListDataMixin from '@/mixins/OrderListDataMixin'
import OrderDataMixin from '@/mixins/OrderDataMixin'
import BasicPriceSummary from '../components/basic/BasicPriceSummary.vue'
import {
  mergeTicketRows,
  getMergedTicketRowsWithChildren,
  sortTicketRows,
} from '@/utils/ticketUtils'
import { NEW_ORDER, ROUTE_NAMES } from '../settings/routeNames'
import TicketRowNotification from '../components/ticket-row/TicketRowNotification.vue'
import TicketRowDiscount from '../components/ticket-row/TicketRowDiscount.vue'
import SuccessDialog from '../components/orders/dialogs/SuccessDialog.vue'
import WarningDialog from '../components/orders/dialogs/WarningDialog.vue'
import MoveTicketRows from '../components/MoveTicketRows.vue'
import TakeAwayCard from '../components/orders/take-away/TakeAwayCard.vue'
import GenericTableOrderRow from '../components/orders/GenericTableOrderRow.vue'
import TicketRowGroup from '../components/ticket-row/TicketRowGroup.vue'
import OrderDiscount from '../components/orders/OrderDiscount.vue'
import { mapActions, mapGetters } from 'vuex'
import OrderFooterActions from '../components/orders/OrderFooterActions.vue'
import StripePayment from '../components/stripe/PaymentRequest.vue'
import ConfirmPaymentDialog from '../components/ConfirmPaymentDialog.vue'
import {
  DELETE_ROW_QUERY,
  DISCOUNT_ORDER_QUERY,
  DISCOUNT_ROW_QUERY,
  EDIT_ROW_QUERY,
  NOTIFICATION_QUERY,
  PAY_SUCCESS_QUERY,
  STRIPE_PAY_SUCCESS_QUERY,
} from '../settings/orderSettings'
import { parseCurrency } from '../utils/ticketUtils'

export default {
  components: {
    EditTicketRow,
    BasicPriceSummary,
    BasicDialog,
    BasicLoading,
    MoveTicketRows,
    TicketRowNotification,
    SuccessDialog,
    WarningDialog,
    TakeAwayCard,
    GenericTableOrderRow,
    TicketRowDiscount,
    TicketRowGroup,
    OrderDiscount,
    OrderFooterActions,
    StripePayment,
    ConfirmPaymentDialog,
  },
  mixins: [QueryMixin, OrderDataMixin, OrderListDataMixin],
  props: ['orderId', 'tableIdExternal', 'isTableView'],
  data() {
    return {
      cancelDialogOpen: false,
      orderCreatedDialogOpen: false,
      selectTicketDialogOpen: false,
      stripeDialogOpen: false,
      postOrderError: false,
      disableOrderActions: false,
      paymentErrorCode: null,
      ticketRowToMove: null,
      NOTIFICATION_QUERY,
      DISCOUNT_ROW_QUERY,
      DISCOUNT_ORDER_QUERY,
      DELETE_ROW_QUERY,
      EDIT_ROW_QUERY,
    }
  },
  remoteComputed: {
    ticketSettings: {
      model: 'restaurant/ticketSettings',
      method: 'get',
    },
  },
  computed: {
    ...mapGetters({
      userQr: 'userQr/getLogedQrData',
      logedUser: 'user/getLogedUser',
    }),
    isWaiter() {
      return !!this.logedUser
    },
    isNoTable() {
      return this.tableId === 'NO_TABLE'
    },
    tableId() {
      if (this.userQr) return this.userQr.tableId
      else if (this.isWaiter) return this.tableIdExternal
      else return null
    },
    paymentDialogOpen() {
      return this.paymentSuccess || !!this.paymentErrorCode
    },
    paymentSuccess() {
      return (
        !!this.getQueryValue(PAY_SUCCESS_QUERY) || !!this.getQueryValue(STRIPE_PAY_SUCCESS_QUERY)
      )
    },
    activeOrderId() {
      if (this.isTableView) {
        const orders = this.getOrderByTable(false)
        return orders.length > 0 ? orders[0].id : null
      } else return this.orderId
    },
    /**
     * Usamos este mergedOrder para controlar tanto los orders de una mesa, como el order "unico" mientras se está haciendo el pedido
     * add:ids
     * concat: rows
     * sum: calculatedPrice
     */
    mergedOrder() {
      return this.getMergedOrder(this.isTableView && this.isWaiter ? undefined : true)
    },
    rowGroupsPaid() {
      if (this.isNoTable && !this.order.isPaid) {
        return []
      }
      return this.getRowGroups(true)
    },
    rowGroupsNotPaid() {
      if (this.isNoTable && this.order.isPaid) {
        return []
      }
      return this.getRowGroups(false)
    },
    orderByTable() {
      if (this.tableId)
        return this.ordersByTables([Number(this.tableId)]).filter(order => order.isPaid)
      else return []
    },
    restaurantId() {
      return this.userQr ? this.userQr.restaurantId : this.logedUser.restaurantId
    },
    notificationDialogOpen() {
      return !!this.getQueryValue(NOTIFICATION_QUERY)
    },
    discountRowDialogOpen() {
      return !!this.getQueryValue(DISCOUNT_ROW_QUERY)
    },
    discountOrderDialogOpen() {
      return !!this.getQueryValue(DISCOUNT_ORDER_QUERY)
    },
    editRowOpen() {
      return !!this.getQueryValue(EDIT_ROW_QUERY)
    },
    deleteRowDialogOpen() {
      return !!this.getQueryValue(DELETE_ROW_QUERY)
    },
    rowGroups() {
      return Object.values(
        this.mergedTicketRows.reduce((groups, row) => {
          if (!groups[row.status]) groups[row.status] = { id: row.status, rows: [] }
          groups[row.status].rows.push(row)
          return groups
        }, {})
      )
    },
    mergedTicketRows() {
      return sortTicketRows(
        getMergedTicketRowsWithChildren(this.mergedOrder ? this.mergedOrder.rows : [], ['comment'])
      )
    },
    rowsPayedAndNotPayedReduced() {
      let rowGroup = this.isTableView
        ? [...this.rowGroupsPaid, ...this.rowGroupsNotPaid]
        : [...this.rowGroupsNotPaid]
      return rowGroup
        .reduce((groupAccumulator, groupCurrent) => {
          groupAccumulator.push(
            groupCurrent.rows.reduce((rowAccumulator, rowCurrent) => {
              rowAccumulator.push([...rowCurrent.children])
              return rowAccumulator.flat()
            }, [])
          )
          return groupAccumulator
        }, [])
        .flat()
    },
    meanTableOrders() {
      let meanOrder = {}
      if (this.tableId) {
        this.ordersByTables([Number(this.tableId)]).every(order => {
          const { isPaid } = order
          if (!isPaid) {
            meanOrder = { ...order }
            return false
          }
          meanOrder = {
            isPaid: true,
          }
          return true
        }, {})
      }
      meanOrder = {
        ...this.mergedOrder,
        isPaid: meanOrder.isPaid,
        price: meanOrder.price,
      }
      return meanOrder
    },
    noOrders() {
      const orders = this.$store.state.orders.orders
      return !orders || orders.length === 0
    },
  },
  watch: {
    '$route.query': {
      handler() {
        // case boolean and string in case of refresh view
        if (this.$route.query[STRIPE_PAY_SUCCESS_QUERY]?.toString() === 'true') {
          this.stripeDialogOpen = false
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.noOrders && !this.isNew) {
      this.$router.push({ name: ROUTE_NAMES.ORDERS })
    }
  },
  mounted() {
    document.addEventListener('backbutton', this.backbuttonpressed, false)
    document.addEventListener('backbutton', this.backRoute, false)
    // cada vez que un usuario entra a esta vista se crea una order nueva!
    if (this.userQr && this.isNew) {
      this.onSubmitOrder()
    }
    if (this.tableId && !this.isWaiter) {
      // el fetch de order by table REEMPLAZA las orders del socket. SOLO FUNCIONARÁ CON CLIENTE!!! (porque el socket no va con cliente)
      this.fetchOrdersByTable(this.tableId)
    }
  },
  beforeDestroy() {
    document.addEventListener('backbutton', this.backRoute)
  },
  methods: {
    ...mapActions({
      fetchOrdersByTable: 'orders/fetchOrdersByTable',
      addTableSetup: 'newOrder/addTableSetup',
    }),
    parsedCurrency(currency) {
      return parseCurrency(currency)
    },
    backRoute() {
      console.log('Back Button press')
    },
    selectedQueryRowIds(query_key) {
      let rowId = this.getQueryValue(query_key)
      if (rowId) {
        return String(rowId).split(',')
      }
      return null
    },
    selectedRowQuery(query_key) {
      const rowIds = this.selectedQueryRowIds(query_key)

      if (rowIds) {
        if (rowIds.length === 1) {
          return this.rowsPayedAndNotPayedReduced.find(d => String(d.id) === rowIds[0])
        } else {
          return mergeTicketRows(
            this.rowsPayedAndNotPayedReduced.filter(d => rowIds.includes(String(d.id)))
          )
        }
      } else {
        return null
      }
    },
    getRowGroups(isPaid) {
      return Object.values(
        this.getMergedTicketRows(isPaid).reduce((groups, row) => {
          if (!groups[row.status]) groups[row.status] = { id: row.status, rows: [] }
          groups[row.status].rows.push(row)
          return groups
        }, {})
      )
    },
    getMergedTicketRows(isPaid) {
      const mergedOrder = this.getMergedOrder(isPaid)
      return sortTicketRows(
        getMergedTicketRowsWithChildren(mergedOrder ? mergedOrder.rows : [], ['comment'])
      )
    },
    /** TODO: no entiendo porque si entras a /order/<id> se muestran todos los ticket-rows de la mesa... */
    getMergedOrder(isPaid) {
      //console.log('getMergedOrder', isPaid, this.getOrderByTable(isPaid))
      if (this.isTableView) {
        return this.getOrderByTable(isPaid).reduce((newOrder, order) => {
          if (newOrder === null)
            return {
              // for creating a real copy. If we change something of observable then we create a inifinity loop
              ...JSON.parse(JSON.stringify(order)),
              ids: [order.id],
            }
          newOrder.ids.push(order.id)
          newOrder.calculatedPrice.base = newOrder.calculatedPrice.base + order.calculatedPrice.base
          newOrder.calculatedPrice.discountCalculated =
            newOrder.calculatedPrice.discountCalculated + order.calculatedPrice.discountCalculated
          newOrder.calculatedPrice.ivaCalculated =
            newOrder.calculatedPrice.ivaCalculated + order.calculatedPrice.ivaCalculated
          newOrder.calculatedPrice.totalCalculated =
            newOrder.calculatedPrice.totalCalculated + order.calculatedPrice.totalCalculated
          // newOrder.isPaid = newOrder.isPaid && order.isPaid // debería de filtrarse antes??
          newOrder.rows = newOrder.rows.concat(order.rows.map(row => ({ ...row, isPaid })))
          return newOrder
        }, null)
      } else return this.order
    },
    getOrderByTable(isPaid) {
      if (this.noOrders) {
        return []
      }
      if (this.tableId) {
        if (this.isNoTable) {
          const orders = [
            this.$store.state.orders.orders.find(order => {
              return parseInt(order.id) === parseInt(this.orderId)
            }),
          ]
          return orders.filter(order => order.isPaid === this.order.isPaid)
        }
        const result = this.ordersByTables([Number(this.tableId)])
        if (isPaid === undefined) return result
        const filteredOrders = result.filter(order => order.isPaid === isPaid)
        if (!isPaid && filteredOrders.length > 0) {
          const orderId = filteredOrders[0].id
          if (parseInt(this.$route.params.orderId) !== parseInt(orderId)) {
            this.$router.replace({ params: { ...this.$route.params, orderId } })
          }
        }
        return filteredOrders
      } else return []
    },
    handleStripePaymentFailed(stripeError) {
      this.stripeDialogOpen = false
      this.paymentErrorCode = stripeError.code
    },
    handleOpenStripePay() {
      this.paymentErrorCode = null
      this.stripeDialogOpen = true
    },
    handleClosePaymentDialog() {
      // console.log(this.userQr, this.getQueryValue(PAY_SUCCESS_QUERY))
      if (!this.userQr) {
        this.orderDispatch('invalidate', this.orderId)
        this.setQueryValue(PAY_SUCCESS_QUERY, undefined)
        this.$router.push({ name: ROUTE_NAMES.ORDERS })
      } else if (this.paymentSuccess) {
        this.orderDispatch('invalidate', this.orderId)
        this.$router.replace({ name: ROUTE_NAMES.MENU, params: { orderId: NEW_ORDER } })
      } else {
        this.paymentErrorCode = null
      }
    },
    async updateSelected(value) {
      let { quantity, ...childValue } = value
      const rowParams = this.$route.query[EDIT_ROW_QUERY]?.toString()
      if (rowParams)
        console.warn('no ids for updating. No params in', EDIT_ROW_QUERY, 'query string key')
      let rowsIds = rowParams?.split(',') ?? []
      let rows = this.order.rows.filter(row => !rowsIds.includes(row.id?.toString()))
      let diff = rowsIds.length - quantity

      //Remove deleted rows
      if (diff > 0) rowsIds.splice(0, diff)

      for (let i = 0; i < quantity; i++) {
        let { ...row } = childValue
        delete row.id
        //Add id if it exists in order to update row
        if (rowsIds[i]) row.id = parseInt(rowsIds[i])
        rows.push({ ...row })
      }
      await this.orderDispatch('patchOrder', { ...this.order, rows })
      await this.orderDispatch('invalidate', this.order.id)
      await this.onCloseQueryDialogs(DELETE_ROW_QUERY)
    },
    /** Only if orderId */
    deleteRows() {
      let rowsIds = this.$route.query[DELETE_ROW_QUERY]?.toString().split(',') ?? []
      if (rowsIds.length === 0) {
        console.warn('no ids fot deleing. Not found in ', DELETE_ROW_QUERY, 'query string key')
      } else if (rowsIds.length > 1) {
        let rows = this.order.rows.filter(row => !rowsIds.includes(row.id?.toString()))
        this.orderDispatch('patchOrder', { ...this.order, rows })
      } else {
        this.onDeleteOneRowClicked(rowsIds[0])
      }
      this.onCloseQueryDialogs(DELETE_ROW_QUERY)
    },
    moveTicketRow(row) {
      this.selectTicketDialogOpen = true
      this.ticketRowToMove = row
    },
    submitNotify(notification) {
      let rowsIds = this.$route.query[NOTIFICATION_QUERY]?.toString().split(',') ?? []
      let rowsAux = [...this.rowsPayedAndNotPayedReduced]
      if (rowsIds.length === 0) {
        console.warn(
          'no ids in submit notify. Not found in ',
          NOTIFICATION_QUERY,
          'query string key'
        )
      }
      if (rowsIds.length > 1) {
        for (let i = 0; i < rowsAux.length; i++) {
          if (rowsIds.includes(rowsAux[i].id.toString())) {
            rowsAux[i].ticketRowAlertId = notification.ticketRowAlertId
            rowsAux[i].ticketRowAlertComment = notification.ticketRowAlertComment
          }
        }
      } else {
        let index = rowsAux.findIndex(row => row.id.toString() === rowsIds[0])
        rowsAux[index].ticketRowAlertId = notification.ticketRowAlertId
        rowsAux[index].ticketRowAlertComment = notification.ticketRowAlertComment
      }

      this.orderDispatch('patchOrder', { ...this.order, rows: rowsAux })
      this.onCloseQueryDialogs(NOTIFICATION_QUERY)
    },
    submitDiscountRow(discount) {
      let rowsIds = this.$route.query[DISCOUNT_ROW_QUERY]?.toString().split(',') ?? []
      let rowsAux = [...this.rowsPayedAndNotPayedReduced]
      if (rowsIds.length === 0) {
        console.warn(
          'no ids in submit Discount Row. Not found in ',
          DISCOUNT_ROW_QUERY,
          'query string key'
        )
      }
      if (rowsIds.length > 1) {
        for (let i = 0; i < rowsAux.length; i++) {
          if (rowsIds.includes(rowsAux[i].id.toString())) {
            rowsAux[i].restaurantDiscountId = discount
          }
        }
      } else {
        let index = rowsAux.findIndex(row => row.id.toString() === rowsIds[0])
        rowsAux[index].restaurantDiscountId = discount
      }

      this.orderDispatch('patchOrder', { ...this.order, rows: rowsAux })
      this.onCloseQueryDialogs(DISCOUNT_ROW_QUERY)
    },
    submitDiscountOrder(discount) {
      let orderAux = { ...this.order }
      orderAux.restaurantDiscountId = discount

      this.orderDispatch('patchOrder', orderAux)
      this.onCloseQueryDialogs(DISCOUNT_ORDER_QUERY)
    },
    onSubmitOrder() {
      const order = JSON.parse(JSON.stringify(this.order))
      orderController
        .validateOrder(this.restaurantId, order)
        .then(() =>
          orderController
            .postOrder(this.restaurantId, { isQR: !!this.userQr, ...order })
            .then(id => {
              this.$router.replace({
                name: ROUTE_NAMES.ORDER,
                params: { orderId: id },
              })
              if (!this.userQr) this.handleSwitchOrderCreatedDialogOpen()
              this.resetNewOrder()
            })
        )
        .catch(err => {
          console.error(err)
          if (err.message === 'PENDING_TICKET_FOR_TABLE') {
            this.postOrderError = true
          }
        })
    },
    onViewTicket() {
      //TODO, habria que validarlo TAMBIEN , no?
      this.$router.push({
        name: ROUTE_NAMES.INVOICE,
        orderId: this.order.id,
        query: { isCurrentOrder: true },
      })
    },
    onCancel() {
      this.cancelDialogOpen = false
      this.orderDispatch('reset', this.order.id)
        .then(() => {
          if (this.userQr) {
            if (this.orderByTable.length > 0)
              this.$router.replace({
                name: ROUTE_NAMES.TABLE,
                params: { tableId: this.userQr.tableId, orderId: this.order.id },
              })
            else this.$router.replace({ name: ROUTE_NAMES.MENU, params: { orderId: NEW_ORDER } })
          } else this.$router.replace({ name: ROUTE_NAMES.ORDERS })
        })
        .catch(err => console.error(err.message))
    },
    onRowClicked(id) {
      this.setQueryValue(EDIT_ROW_QUERY, id)
    },
    onNotifyClicked(id) {
      this.setQueryValue(NOTIFICATION_QUERY, id)
    },
    onRowDiscountClicked(id) {
      this.setQueryValue(DISCOUNT_ROW_QUERY, id)
    },
    onOrderDiscountClicked(orderId) {
      this.setQueryValue(DISCOUNT_ORDER_QUERY, orderId)
    },
    onDeleteClicked(id) {
      this.setQueryValue(DELETE_ROW_QUERY, id)
    },
    handleSwitchOrderCreatedDialogOpen() {
      this.orderCreatedDialogOpen = !this.orderCreatedDialogOpen
    },
    onDeleteOneRowClicked(id) {
      this.disableOrderActions = true
      this.orderDispatch('removeTicketRow', { id }).finally(
        () => (this.disableOrderActions = false)
      )
    },
    onAddOneRowClicked(id) {
      let rowAux = { ...this.order.rows.find(row => row.id.toString() === id.toString()) }
      delete rowAux.id
      this.disableOrderActions = true
      this.orderDispatch('addTicketRow', { ...rowAux }).finally(
        () => (this.disableOrderActions = false)
      )
    },
    onHandleClickNewOrder() {
      this.$store.dispatch('newOrder/reset')
      this.onHandleClickMenu()
    },
    onHandleClickMenu() {
      if (this.isTableView && this.isWaiter) {
        this.addTableSetup({
          id: this.tableId,
          totalCustomersAtTable: this.mergedOrder
            ? this.mergedOrder.table.totalCustomersAtTable
            : 1, //TODO: Review: siempre tendremos mergedOrder??
        })
      }
      if (this.isTableView && !!this.activeOrderId && this.isWaiter) {
        this.$router.push({ name: ROUTE_NAMES.ORDER_MENU, params: { orderId: this.activeOrderId } })
      } else if (this.isNew || this.isTableView) this.$router.push({ name: ROUTE_NAMES.MENU })
      else this.$router.push({ name: ROUTE_NAMES.ORDER_MENU, params: { orderId: this.order.id } })
    },
    onClosePostOrderErrorDialog() {
      this.postOrderError = false
    },
    onCloseSelectTicketDialog() {
      this.selectTicketDialogOpen = false
      this.ticketRowToMove = null
    },
    onCloseQueryDialogs(query_key) {
      let query = null
      if (query_key) {
        const query = Object.assign({ ...this.$route.query })
        delete query[query_key]
      }
      this.$router.replace({ query })
    },
    onCloseDialogs() {
      this.$router.go(-1)
    },
    async onTableChanged(newTable) {
      this.$router.push({ name: ROUTE_NAMES.TABLE, params: { tableId: newTable } })
    },
  },
}
</script>

<style lang="stylus" scoped>
.order
  position: fixed
  top: $header-height
  left: 0
  display: flex
  flex: 1 1 0%
  flex-direction: column
  padding-bottom: $basic-bottom-actions-height
  width: 100%
  height: "calc(100% - %s)" % $header-height
  background-color: #fff

  &.with-cancel-btn
    padding-bottom: $basic-bottom-actions-height-big

  &.is-loading
    padding-bottom: 0

  .is-client &
    top: 0
    padding-top: vw(15px)
    height: 100%

  .lala
    flex: 1 1 0%
    overflow-x: hidden
    overflow-y: auto
    padding-top: vw(20px)

    .ticket-rows-list
      overflow: hidden
      // border-top: none

    .calculated-price
      margin-bottom: vw(20px)

    .basic-price-summary
      padding: vw(10px)
      // padding-bottom: vw(10px)
      // border-top: 1px solid #e0e0e0
      border-bottom: 1px solid #e0e0e0
      box-shadow: none

      >>> .basic-price-item-title
        m-font-size(10, 12)

      >>> .basic-price-item-number
        m-font-size(12, 16)

  .back-link
    display: flex
    align-items: center
    margin-bottom: vw(15px)
    padding-right: vw(15px)
    padding-left: vw(12px)
    color: $primary-theme-color

    .back-link-icon
      m-icon("back", 18)
      margin-right: vw(5px)
      margin-left: vw(-2px)

    .back-link-text
      m-font-size(14, 20)
      font-weight: 500

  .menu-link
    font-weight: bold
    m-font-size(14)
    margin-bottom: vw(5px)

  .meta-info
    margin: vw(10px)
    padding: vw(10px)
    border: 1px solid #e0e0e0

    .title
      m-font-size(22, 33)
      margin-bottom: vw(5px)
      font-weight: 600

      &:empty
        display: none

    .details
      display: flex
      m-font-size(14, 20)

      .detail
        display: flex
        align-items: center

        &:not(:last-child)
          &:after
            margin: 0 vw(5px)
            content: "·"

      .icon
        margin-right: vw(2px)

      .telf
        .icon
          width: vw(16px)
          height: vw(16px)
          background-color: #ddd

      .time
        .icon
          width: vw(16px)
          height: vw(16px)
          background-color: #ddd

  .ticket-row-title
    m-font-size(12, 16)
    padding: 0 vw(15px) vw(12px)
    color: #828282
    text-transform: uppercase

  .ticket-rows-list
    flex: 1 1 0%
    overflow-x: hidden
    overflow-y: auto

    // border-top: 1px solid #e0e0e0
    >>> .ticket-row-group
      margin-bottom: vw(10px)

      &:last-child
        margin-bottom: 0

  .dialog-body-text
    m-font-size(16, 20)
    margin-top: vw(40px)
    margin-bottom: vw(40px)

  .dialog-buttons
    display: flex
    justify-content: space-around
    justify-content: space-evenly
    align-items: center
</style>