<template>
  <div class="table-group-row" @click="$emit('edit', group.id)">
    <div class="table-group-content">
      <div class="table-group-name">{{ group.name }}</div>
      <div class="table-group-info">
        <div class="table-group-title">{{ `${$t('navigation_edit_zone_tables_title')}:` }}</div>
        <div class="table-group-list">
          <div v-for="(table, index) in group.tables" :key="index" class="table-group-item">{{ table.name }}</div>
        </div>
      </div>
    </div>
    <button class="table-group-btn-delete btn-fake" @click.stop="deleteAssignedZone" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
/**
 * @params group: [{group: string, tables: [Table]}]
 */
export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
    }),
  },
  methods: {
    ...mapActions({
      updateUser: 'user/updateUser',
    }),
    deleteAssignedZone() {
      const tablesMapped = this.group.tables.map(table => table.id)
      const filteredUserTables = this.logedUser.tables.filter(
        table => !tablesMapped.includes(table)
      )
      this.updateUser({ tables: filteredUserTables })
    },
  },
}
</script>

<style lang="stylus" scoped>
.table-group-row
  display: flex
  align-items: center
  padding: vw(10px) vw(15px)
  border: 1px solid #E0E0E0
  border-radius: 2px

.table-group-content
  display: flex
  flex-direction: column

.table-group-name
  m-font-size(16, 20)
  margin-bottom: vw(5px)
  font-weight: 600
  font-family: "IBM Plex Sans"

.table-group-info
  m-font-size(16, 24)
  display: flex

.table-group-title
  margin-right: 0.5em

.table-group-list
  display: flex
  flex-wrap: wrap

.table-group-item
  &:not(:last-child)
    &:after
      margin-right: 0.25em
      margin-left: 0.25em
      content: "·"

.table-group-btn-delete
  m-icon("bin", 34px)
  flex: 0 0 vw(34px)
  margin-left: auto
</style>