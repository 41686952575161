<template>
  <div class="pick-up-order-row-container">
    <basic-swipe-actions :complete-swipe="true" @swipe="handleChangeStatus">
      <div class="info-container">
        <div class="info-title-container">
          <basic-number-tag class="info-number" :value="data.quantity ? data.quantity : 1" @click="handleCollapse" />
          <div class="info-title">{{ product && $label(product) }}</div>
        </div>
        <div class="ticket-row-modifiers">
          <ticket-row-modifier
            v-for="(ing, index) in data.ingredientModifiers"
            :id="ing.id"
            :key="index"
            type="ing"
            :value="ing.value"
          />
        </div>
        <div class="ticket-row-modifiers">
          <ticket-row-modifier
            v-for="(opt, index) in data.optionModifiers"
            :id="opt.id"
            :key="index"
            type="opt"
            :value="opt.value"
          />
          <!-- <div v-if="preparationZone" class="preparation-zone">{{ preparationZone.name }}</div> -->
          <ticket-row-modifier
            v-if="preparationZone"
            type="opt"
            :text="preparationZone.name"
            :icon="'location'"
          />
        </div>
        <div v-if="data.comment " class="note">{{ `${$t('preparation_zone_note_title')}: ${data.comment}` }}</div>
      </div>
      <template v-slot:action-1>
        <div class="action-icon icon-1" @click.stop="handleChangeStatus" />
      </template>
    </basic-swipe-actions>
  </div>
</template>

<script>
import TicketRowModifier from './../../ticket-row/TicketRowModifier'
import BasicNumberTag from './../../basic/BasicNumberTag.vue'
import BasicSwipeActions from '../../basic/BasicSwipeActions.vue'
import { mapGetters } from 'vuex'
export default {
  components: { BasicNumberTag, TicketRowModifier, BasicSwipeActions },
  model: {
    prop: 'collapsed',
    event: 'collapse',
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    collapsed: {
      type: Boolean,
    },
    collapsable: {
      type: Boolean,
    },
  },
  remoteComputed: {
    product: {
      model: 'restaurant/products',
      method: 'getById',
      params: function () {
        return [this.data.productId]
      },
    },
    preparationAreas: {
      model: 'restaurant/preparationAreas',
      method: 'get',
      params: function () {
        return [this.$route.params.restaurantId]
      },
    },
  },
  computed: {
    ...mapGetters({
      getPreparationAreaById: 'restaurant/preparationAreas/getById',
    }),
    preparationZone() {
      if (!this.product) return null
      let preparationAreaId = this.product.restaurantPreparationAreaId
        ? this.product.restaurantPreparationAreaId
        : this.product.category.restaurantPreparationAreaId
      return this.getPreparationAreaById(preparationAreaId)
    },
  },
  methods: {
    handleCollapse() {
      if (this.collapsable) this.$emit('collapse', !this.collapsed)
    },
    handleChangeStatus() {
      this.$emit('check')
    },
  },
}
</script>

<style lang="stylus" scoped>
.pick-up-order-row-container
  display: flex
  align-items: center
  // border-bottom: 1px solid #E0E0E0
  background-color: #fff

  .info-container
    padding: 0 vw(15px) vw(10px)

  .info-title-container
    display: flex
    align-items: center

    .info-number
      margin-right: vw(10px)

    .info-title
      m-font-size(16, 20)
      flex: 1 1 0%

    .order-title
      margin-left: vw(10px)

  .ticket-row-modifiers
    display: flex
    align-items: center
    margin-top: vw(10px)

    &:empty
      display: none

  .ticket-row-modifier
    &:not(:last-child)
      &:after
        margin-right: 0.5em
        margin-left: 0.5em
        content: "·"

  .note
    m-font-size(14, 20)
    display: flex
    align-items: center
    margin-top: vw(10px)

    &:before
      m-icon("clipboard", 14)
      display: block
      margin-top: 0.2em
      margin-right: 0.5em
      margin-left: -0.2em
      content: ""

  .check
    margin-left: auto

  .action-icon
    position: relative
    width: 100%
    height: 100%

    &:before
      position: absolute
      top: 50%
      left: 50%
      display: block
      content: ""
      transform: translate(-50%, -50%)

    &.icon-1
      background-color: #27AE60

      &:before
        m-icon("thumb-up-white", 34)

    &.icon-2
      background-color: #87a
</style>