import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AsyncComputed from 'vue-async-computed'
import DeviceDetect from './mixins/DeviceDetect'
import i18n from './i18n'
import remoteComputed from './store/lib/remoteComputed'
import VCalendar from 'v-calendar'

import './registerServiceWorker'
Vue.mixin(DeviceDetect)
Vue.mixin(i18n)
Vue.config.productionTip = false
Vue.use(remoteComputed)
Vue.use(AsyncComputed)
Vue.use(VCalendar)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
