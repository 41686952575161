<template>
  <basic-dialog :is-open="isOpen" @close="$emit('close')">
    <template slot="dialog-body">
      <div class="basic-confirmation-dialog">
        <div class="image"><slot name="image" /></div>
        <div><slot class="description" name="description" /></div>
        <div><slot class="title" name="title" /></div>
        <div><slot class="action-buttons" name="action-buttons" /></div>
        <div><slot /></div>
      </div>
    </template>
  </basic-dialog>
</template>

<script>
const { default: BasicDialog } = require('./BasicDialog.vue')

export default {
  components: { BasicDialog },
  props: {
    isOpen: {
      type: Boolean,
    },
  },
}
</script>

<style lang="stylus" scoped>
.basic-confirmation-dialog
  display: flex
  flex-direction: column

  // align-items: center
  // text-align: center
  .image
    margin: 0 auto
    width: vw(180px)
    height: vw(180px)
</style>