<template>
  <div :class="['ticket-row-modifier', modifierClass]">
    <div v-if="icon" :class="'icon-' + icon" />
    <template v-if="!isDataUpdating && !isOptionsUpdating && modifiers">{{ modifiers }}</template>
    <template v-else>{{ text }}</template>
  </div>
</template>

<script>
export default {
  props: ['id', 'icon', 'value', 'type', 'text'],
  remoteComputed: {
    data: {
      model: function() {
        return this.type == 'ing' ? 'restaurant/ingredientModifiers' : 'restaurant/optionModifiers'
      },
      method: 'getById',
      params: function() {
        return [this.id]
      },
    },
    options: {
      model: function() {
        return this.type == 'ing' ? 'restaurant/ingredients' : 'restaurant/options'
      },
      method: 'getById',
      params: function() {
        return this.value ? [this.value] : []
      },
      isList: true,
    },
  },
  computed: {
    modifierClass() {
      if (this.type === 'opt') return 'simple-modifier'
      return this.data.isRemove ? 'delete-modifier' : 'add-modifier'
    },
    modifiers() {
      return `${this.$label(this.data)}: ${this.options.map(d => this.$label(d)).join()}`
    },
  },
}
</script>

<style lang="stylus" scoped>
.ticket-row-modifier
  m-font-size(10, 12)
  display: flex
  align-items: center

  &.add-modifier
    color: #6FCF97

  &.delete-modifier
    color: #EB5757

  &.simple-modifier
    color: #828282

  for $icon in "location"
    .icon-{$icon}
      m-icon($icon, 14)
      margin-right: 0.5em
</style>