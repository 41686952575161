<template>
  <div class="navigation-container">
    <assigned-zone-dialogs v-model="isZonesDialogOpen" />
    <div class="cross-icon" @click.prevent="closeNavigation" />
    <user-avatar />
    <ul class="navigation-list">
      <li
        v-for="(navigation, index) in navigationsPaths"
        :key="index"
        class="navigation-item"
        @click="handleNavigation(navigation)"
      >
        <div class="text">{{ navigation.name }}</div>
        <!-- <div v-if="getCountByNav(navigation)" class="dot-count">{{ getCountByNav(navigation) }}</div> -->
      </li>
    </ul>
    <div class="footer-container">
      <basic-button
        :title="$t('navigation_edit_assigned_zone')"
        @click="handleSwitchOpenZonesDialog"
      />
      <div class="logout btn btn--primary btn--ghost" @click.prevent="handleLogout">
        {{ $t('navigation_logout') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserAvatar from './UserAvatar.vue'
import AssignedZoneDialogs from './assigned-zone-dialogs'
import BasicButton from '../../basic/BasicButton.vue'
import OrderListDataMixin from '../../../mixins/OrderListDataMixin'
import { NAVIGATIONS, NAVIGATIONS_KEYS } from '../../../settings/navigationSettings'
import { ROUTE_NAMES } from '../../../settings/routeNames'

export default {
  components: { UserAvatar, BasicButton, AssignedZoneDialogs },
  mixins: [OrderListDataMixin],
  model: {
    prop: 'showNavigation',
    event: 'close',
  },
  props: ['showNavigation'],
  data() {
    return {
      isZonesDialogOpen: false,
    }
  },
  computed: {
    logedUser() {
      return this.getLogedUser()
    },
    navigationsPaths() {
      return NAVIGATIONS.filter(navigation => {
        return navigation.roles.some(element => this.logedUser.roles.includes(element))
      })
    },
  },
  methods: {
    ...mapGetters({
      getLogedUser: 'user/getLogedUser',
    }),
    ...mapActions({
      logout: 'user/logout',
      invalidateAllRestaurant: 'restaurant/invalidateAll',
      invalidateAllCms: 'cms/invalidateAll',
    }),
    closeNavigation() {
      this.$emit('close', false)
    },
    handleLogout() {
      this.logout()
      this.invalidateAllRestaurant()
      this.invalidateAllCms()
      this.closeNavigation()
      this.$router.push({ name: ROUTE_NAMES.LOGIN })
      window.location.reload()
    },
    handleSwitchOpenZonesDialog() {
      this.isZonesDialogOpen = !this.isZonesDialogOpen
    },
    /**
     * @param {{route:{name:String,query?:{}}}} navigation
     */
    handleNavigation(navigation) {
      this.$router.push({
        name: navigation.route.name,
        query: navigation.route.query,
      })
      this.closeNavigation()
    },
    getCountByNav(navigation) {
      if (this.isFetchingOrders) return null

      switch (navigation.key) {
        case NAVIGATIONS_KEYS.ORDERS:
          return this.orders.length
        case NAVIGATIONS_KEYS.TAKEAWAY:
          return this.takeAwayOrders.length
        case NAVIGATIONS_KEYS.PICK_UP:
          return this.takeAwayOrders.length // TODO
      }
    },
  },
}
</script>

<style lang="stylus">
.navigation-container
  position: fixed
  top: 0
  left: 0
  z-index: 2
  display: flex
  flex-direction: column
  overflow: hidden
  width: 100%
  height: 100%
  background-color: #fff

  .cross-icon
    m-icon("close", 40)
    position: absolute
    top: vw(7.5px)
    right: vw(7.5px)
    cursor: pointer

.navigation-list
  flex: 1 1 0%
  overflow-y: auto
  font-family: "IBM Plex Sans"

.navigation-item
  display: flex
  padding: vh(45px) vw(25px)
  width: 100%
  border-bottom: 1px solid #E0E0E0

  .text
    m-font-size(16, 20)
    flex: 1 1 100%
    text-align: left
    font-weight: 600

  .dot-count
    margin-right: vw(10px)

.footer-container
  display: flex
  flex-direction: column
  padding: vw(18px) vw(15px)

  .logout
    margin-bottom: vw(-15px)
</style>
