<template>
  <div class="fav" @click="handleDownload">
    <div>{{ $t("cms_export") }}</div>
    <div class="download-icon" />
  </div>
</template>

<script>
import { convertToCSV, downloadFile } from '../../utils/csvUtils'

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    nameFile: String,
  },
  methods: {
    handleDownload() {
      const csv = convertToCSV(this.data) //.replace(/"/g, '')
      downloadFile(csv, this.nameFile + '.csv')
    },
  },
}
</script>

<style lang="stylus" scoped>
.fav
  position: fixed
  right: vw(10px)
  bottom: vh(10px)
  display: flex
  flex-direction: column
  align-items: center

  .download-icon
    cursor: pointer
    m-icon("download", 50)
</style>