import {
  getMenu,
  getIngredients,
  getIngredientModifiers,
  getOptions,
  getOptionModifiers,
  getRestaurantById,
  getTicketSettings,
  getLanguages,
  getCategories,
} from '@/services/api'
import {
  getAlertsByRestaurant,
  getPreparationAreasByRestaurant,
  getServiceZones,
  getTakeAwayById,
  getDiscountsByRestaurant,
  getServiceOrdersByRestaurant,
  orderController,
  getCashRegister,
} from '../../../services/api'

function getRestaurantId(context) {
  return (
    context.rootGetters['userQr/getLogedQrData']?.restaurantId ??
    context.rootGetters['user/getRestaurantId']
  )
}

function extractProducts(menu) {
  return [
    ...(menu.products?.map(product => ({
      ...product,
      category: {
        id: menu.id,
        restaurantPreparationAreaId: menu.restaurantPreparationAreaId,
      },
    })) || []),
    ...(menu.children || []).map(c => extractProducts(c)),
  ].flat()
}

const remoteModels = {
  restaurant: {
    get: (context, restaurantId) => {
      if (restaurantId) {
        return getRestaurantById(restaurantId)
      } else {
        const restaurantIdContext = getRestaurantId(context)
        return restaurantIdContext ? getRestaurantById(restaurantIdContext) : Promise.resolve({})
      }
    },
    lang: () => 'en',
  },
  preparationAreas: {
    get: () => getPreparationAreasByRestaurant(),
    lang: () => 'en',
    isList: true,
  },
  serviceOrders: {
    get: context => getServiceOrdersByRestaurant(getRestaurantId(context)),
    lang: () => 'en',
    isList: true,
  },
  invoice: {
    get: (context, ticketId) => orderController.getInvoiceByTicketId(ticketId),
    lang: () => 'en',
  },
  ticketSettings: {
    get: context => getTicketSettings(getRestaurantId(context)),
    lang: () => 'en',
    isList: false,
  },
  cashRegister: {
    get: () => getCashRegister(),
  },
  discounts: {
    get: context => getDiscountsByRestaurant(getRestaurantId(context)),
    lang: () => 'en',
    isList: true,
  },
  takeAway: {
    get: (context, id) => (id ? getTakeAwayById(id) : {}),
    lang: () => 'en',
  },
  alerts: {
    get: context => getAlertsByRestaurant(context.rootGetters['user/getRestaurantId']),
    lang: () => 'en',
    isList: true,
  },
  languages: {
    get: () => getLanguages(),
  },
  categories: {
    get: () => getCategories(),
  },
  menu: {
    get: context => getMenu(getRestaurantId(context)),
    lang: () => 'en',
  },
  products: {
    get: context => {
      return getMenu(getRestaurantId(context)).then(menu => extractProducts(menu))
    },
    lang: () => 'en',
    isList: true,
  },
  ingredients: {
    get: context => getIngredients(getRestaurantId(context)),
    lang: () => 'en',
    isList: true,
  },
  serviceZones: {
    get: context => getServiceZones(getRestaurantId(context)),
    lang: () => 'en',
    isList: true,
  },
  ingredientModifiers: {
    get: (context, restaurantId) =>
      getIngredientModifiers(restaurantId ?? getRestaurantId(context)),
    lang: () => 'en',
    isList: true,
  },
  options: {
    get: context => getOptions(getRestaurantId(context)),
    lang: () => 'en',
    isList: true,
  },
  optionModifiers: {
    get: context => getOptionModifiers(getRestaurantId(context)),
    lang: () => 'en',
    isList: true,
  },
}

export default {
  state: {
    metadata: null,
  },
  remoteModels,
  mutations: {},
  actions: {
    invalidateAll({ dispatch }) {
      Object.keys(remoteModels).forEach(key => {
        dispatch(`restaurant/${key}/invalidateAll`)
      })
    },
  },
}
