<template>
  <div class="cms-business-by-product">
    <ResourceConfigurator
      v-if="movements"
      :resources="resources"
      :resource-label-key="'cms_historic_balance'"
      :fields-info="fieldsInfo"
      :table-values="tableValues"
    />
    <fav-csv-export
      :data="donwloadInfo" 
      :name-file="`${$t('cms_historic_balance')}_${(new Date()).toLocaleDateString()}`"
    />
  </div>
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator.vue'
import { mapActions } from 'vuex'
import FavCsvExport from '../FavCsvExport'
import { createArrayDataCSV } from '../../../utils/csvUtils'
export default {
  components: { ResourceConfigurator, FavCsvExport },
  remoteComputed: {
    movements: {
      model: 'cms/historicCashRegister',
      method: 'get',
    },
  },
  computed: {
    fieldsInfo() {
      return [
        { type: 'datetime', name: this.$t('cms_open_date'), id: 'openDate' },
        { type: 'datetime', name: this.$t('cms_close_date'), id: 'closeDate' },
        { type: 'default', name: this.$t('cms_closing_value'), id: 'closingValue' },
        { type: 'default', name: this.$t('cms_total_card_value'), id: 'totalCardValue' },
        { type: 'default', name: this.$t('cms_total_cash_value'), id: 'totalCashValue' },
        { type: 'default', name: this.$t('cms_stripe_incomes'), id: 'totalStripeValue' },
      ]
    },
    tableValues() {
      return this.resources?.map(movement => [
        movement.openDate,
        movement.closeDate,
        movement.closingValue,
        movement.totalCardValue,
        movement.totalCashValue,
        movement.totalStripeValue,
      ])
    },
    donwloadInfo() {
      return createArrayDataCSV.bind(this)(this.fieldsInfo, this.tableValues)
    },
    resources() {
      return (
        this.movements?.map(movement => ({
          ...movement,
          deleteDisabled: !movement.movementId,
          editDisabled: !movement.movementId,
        })) ?? []
      )
    },
  },
  mounted() {
    this.invalidateResources()
  },
  methods: {
    ...mapActions({
      invalidateResources: 'cms/historicCashRegister/invalidateAll',
    }),
  },
}
</script>
<style lang="stylus" scoped></style>