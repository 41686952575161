<template>
  <div class="basic-input-container">
    <!-- <div v-if="title" class="basic-input-title">{{ title }}</div> -->
    <div class="basic-input-row">
      <input
        :type="type" 
        :placeholder="placeholder" 
        :value="value" 
        :required="required"
        @input="$emit('input', $event.target.value)"
      >
      <slot name="image" />
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    title: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      required: true,
    },
    required: {
      type: Boolean,
    },
  },
}
</script>

<style lang="stylus" scoped>
.basic-input-container
  position: relative

  &:not(:last-of-type)
    margin-bottom: vw(30px)

  input
    m-font-size(16, 24)
    padding-bottom: vw(10px)
    width: 100%
    border: none
    border-bottom: 1px solid #e0e0e0

    &:focus
      outline: none
      border-bottom-color: #000

.basic-input-title
  m-font-size(18, 24)

.basic-input-row
  position: relative
  display: flex
</style>