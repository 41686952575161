export const ROUTE_NAMES = {
  LOGIN: 'login',
  LOGIN_QR: 'loginQr',
  MODELS_TEST: 'modelsTest',
  SOCKET_TEST: 'socketTest',
  EDIT_ROW: 'editRow',
  MENU: 'menu',
  PRODUCT: 'product',
  ORDER: 'order',
  TABLE: 'table',
  ORDER_DISCOUNT: 'orderDiscount',
  ORDER_EDIT_ROW: 'orderEditRow',
  ORDER_NOTIFY_ROW: 'orderNotifyRow',
  ORDER_DISCOUNT_ROW: 'orderDiscountRow',
  ORDER_DELETE_ROW: 'orderDeleteRow',
  ORDER_MENU: 'orderMenu',
  // ACTIVE_ORDER: 'activeOrder',
  ORDERS: 'orders',
  PREPARATION_ZONE: 'preparationZone',
  PAYMENT: 'payment',
  INVOICE: 'invoice',
  INVOICE_QR: 'invoiceQr',
  CASH_REGISTER_CLOSED: 'cashRegisterClosed',
  CREATE_RESTAURANT: 'createRestaurant',
  CMS: {
    MAIN: 'cmsMain',
    ACTIVITY: 'cmsActivity',
    BUSINESS: {
      MAIN: 'cmsBusiness',
      CURRENT_CASH_REGISTRY: 'cmsCurrentCashRegistry',
      HISTORIC_CASH_REGISTRY: 'cmsHistoricCashRegistry',
      LIQUIDATION_CASH_REGISTRY: 'cmsLiquidationCashRegistry',
      PRODUCT_CASH_REGISTRY: 'cmsProductCashRegistry',
    },
    CLIENTS: 'cmsClients',
    EMPLOYEES: 'cmsEmployees',
    MENU: {
      MAIN: 'cmsMenu',
      CATEGORIES: 'cmsCategories',
      PRODUCTS: 'cmsProducts',
      INGREDIENTS: 'cmsIngredients',
      INGREDIENT_MODIFIERS: 'cmsIngredientModifiers',
      OPTIONS: 'cmsOptionsModifiers',
      OPTION_MODIFIERS: 'cmsOptionModifiers',
      SERVICE_SETTINGS: 'serviceSettings',
    },
    RESTAURANT: {
      MAIN: 'cmsRestaurant',
      PREPARATION_AREAS: 'cmsPreparationAreas',
      SERVICE_ZONES: 'cmsServiceZones',
      TABLE_GROUPS: 'cmsTableGroups',
      TABLES: 'cmsTables',
    },
    SETTINGS: {
      MAIN: 'cmsSettings',
      GENERAL_SETTINGS: 'cmsGeneralSettings',
      PAYMENTS: 'cmsPayments',
    },
  },
}

export const NEW_ORDER = 'new'
