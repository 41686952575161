<template>
  <div class="status-order" :class="[cssClass]">{{ $t(textKey) }}</div>
</template>

<script>
export default {
  props: {
    status: { type: String, require: true },
  },
  computed: {
    cssClass() {
      return this.status.toLowerCase()
    },
    textKey() {
      return 'order_status_' + this.status.toLowerCase()
    },
  },
}
</script>

<style lang="stylus" scoped>
.status-order
  m-font-size(10, 14)
  display: flex
  align-items: center
  padding: vw(2px) vw(6px)
  height: vw(20px)
  white-space: nowrap

  for $i in $status
    &.{$i[0]}
      background-color: rgba($i[1], 0.2)
      color: $i[1]
</style>
