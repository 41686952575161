<template>
  <div class="cms-discounts">
    <ResourceConfigurator
      v-if="discounts"
      :edit-label-key="'cms_edit_discount'"
      :add-label-key="'cms_add_discount'"
      :resources="discounts"
      :resource-label-key="'cms_discounts'"
      :fields-info="fieldsInfo"
      :form-fields="formFields"
      :table-values="tableValues"
      :delete-resource-handler="deleteDiscountHandler"
      :add-resource-handler="addDiscountHandler"
      :cell-click-handler="cellClickHandler"
      :edit-resource-handler="editDiscountHandler"
      @onStartEditing="onStartEditingHandler"
      @onStartAdding="editingResource = null"
    />
  </div>
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator.vue'
import { cmsController } from '../../../services/api'
import { mapActions } from 'vuex'
export default {
  components: { ResourceConfigurator },
  remoteComputed: {
    discounts: {
      model: 'restaurant/discounts',
      method: 'get',
    },
  },
  data() {
    return {
      editingResource: null,
    }
  },
  computed: {
    fieldsInfo() {
      return [
        { type: 'default', name: this.$t('cms_name'), id: 'name' },
        { type: 'default', name: this.$t('cms_description'), id: 'description' },
        { type: 'default', name: this.$t('cms_type'), id: 'discountType' },
        { type: 'percentage', name: this.$t('cms_value'), id: 'value' },
        { type: 'boolean', name: this.$t('cms_active'), id: 'isVisible' },
      ]
    },
    tableValues() {
      return this.discounts?.map(discount => [
        discount.name,
        discount.description,
        discount.discountType,
        discount.value,
        discount.isVisible,
      ])
    },
    formFields() {
      return [
        {
          type: 'string',
          label: this.$t('cms_name'),
          id: 'name',
          default: this.editingResource?.name,
        },
        {
          type: 'string',
          label: this.$t('cms_description'),
          id: 'description',
          default: this.editingResource?.description,
        },
        {
          type: 'select',
          label: this.$t('cms_type'),
          id: 'discountType',
          options: ['PRODUCT', 'RESTAURANT'].map(id => ({ id: id, label: id, value: id })),
          default: this.editingResource?.discountType,
        },
        {
          type: 'percentage',
          label: this.$t('cms_value'),
          id: 'value',
          default: this.editingResource?.value,
        },
        {
          type: 'boolean',
          label: this.$t('cms_active'),
          id: 'isVisible',
          enabledLabel: this.$t('cms_active'),
          disabledLabel: this.$t('cms_not_active'),
          default: (this.editingResource && this.editingResource.isVisible) || false,
        },
      ]
    }
  },
  methods: {
    ...mapActions({
      invalidateDiscounts: `restaurant/discounts/invalidateAll`,
    }),
    deleteDiscountHandler({ id }) {
      return cmsController.deleteResourceById('discounts', id).then(this.invalidateDiscounts)
    },
    onStartEditingHandler(resource) {
      this.editingResource = resource
    },
    setMissingAttributes(discount) {
      return {
        ...discount,
        name: discount.name || '',
        description: discount.description || '',
        isVisible: discount.isVisible || false,
        value: discount.value || 50,
      }
    },
    cellClickHandler(fieldIndex, resource) {
      if (this.fieldsInfo[fieldIndex].type !== 'boolean') {
        return
      }
      const fieldId = this.fieldsInfo[fieldIndex].id
      this.editDiscountHandler({ [fieldId]: !resource[fieldId] }, resource)
    },
    addDiscountHandler(editedDiscount) {
      return cmsController
        .addResource('discounts', this.setMissingAttributes(editedDiscount))
        .then(this.invalidateDiscounts)
    },
    editDiscountHandler(editedDiscount) {
      return cmsController
        .updateResourceById('discounts', this.editingResource.id, editedDiscount)
        .then(this.invalidateDiscounts)
    },
  },
}
</script>
<style lang="stylus" scoped>
</style>