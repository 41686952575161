<template>
  <div
    class="image-thumbnail"
    :class="{ small: size === 'sm', medium: size === 'md' }"
    :style="'background-image: url(' + url + ')'"
  />
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      required: false,
      default: 'lg',
    },
    url: {
      type: String,
      required: true,
    },
  },
}
</script>
<style lang="stylus" scoped>
.image-thumbnail
  margin-bottom: vw(16px)
  width: vw(100px)
  height: vw(100px)
  border-radius: 50%
  background-color: #ccc
  background-position: center center
  background-size: cover
  background-repeat: no-repeat

  &.small
    width: vw(30px)
    height: vw(30px)

  &.medium
    width: vw(65px)
    height: vw(65px)
</style>