<template>
  <div v-if="ingredientModifiers && optionModifiers && products" class="modifiers">
    <Dialog
      :is-open="dialog.isOpen"
      :closable="true"
      :scrollable-body="true"
      @close="toggleDialog"
    >
      <template #dialog-body>
        <DynamicForm
          :title="formTitle"
          :fields="formFields"
          :save-action="handleAddModifier"
          @cancel="toggleDialog()"
        />
      </template>
    </Dialog>
    <div
      v-for="(modifier, i) in modifiers"
      :key="modifier.type"
      class="modifiers__table modifiers-table"
    >
      <div class="modifiers-heading__title modifiers-table-heading">
        <div class="modifiers-table-heading__left">
          <div :class="{ iconIsOpen: openRows.includes(i), iconIsClose: !openRows.includes(i)}" @click="toggleRow(i)" />
          <h5>{{ titles[i] }}</h5>
        </div>
        <a href="#" @click.prevent="addModifierClick(modifier.type)">{{ modifier.type === 'ingredients' ? addIngredientsModifierLabel : addOptionsModifierLabel }}</a>
      </div>
      <Table
        v-if="openRows.includes(i)"
        :is-modifier="true"
        :fields-info="fieldsInfo"
        :rows="getRows(modifier)"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { cmsController } from '@/services/api'
export default {
  components: {
    Table: () => import('./ResourceConfigruratorTable'),
    Dialog: () => import('../../basic/BasicDialog'),
    DynamicForm: () => import('../dynamic-form/DynamicForm'),
  },
  props: {
    productId: {
      type: Number,
      required: true,
    },
  },
  remoteComputed: {
    ingredientModifiers: {
      model: 'restaurant/ingredientModifiers',
      method: 'get',
    },
    optionModifiers: {
      model: 'restaurant/optionModifiers',
      method: 'get',
    },
    products: {
      model: 'cms/products',
      method: 'get',
    },
    options: {
      model: 'cms/options',
      method: 'get',
    },
    ingredients: {
      model: 'restaurant/ingredients',
      method: 'get',
    },
  },
  data() {
    return {
      openRows: [],
      dialog: {
        isOpen: false,
        type: null,
      },
      titles: [this.$t('cms_ingredient_modifier'), this.$t('cms_option_modifier')],
      fieldsInfo: [
        { type: 'default', name: this.$t('cms_name') },
        { type: 'default', name: this.$t('cms_available') },
      ],
    }
  },
  computed: {
    product() {
      return this.products.find(({ id }) => id === this.$props.productId)
    },
    modifiers() {
      return [
        {
          type: 'ingredients',
          modifiers: this.product.ingredientModifiers.map(ingredientModifierId =>
            this.ingredientModifiers.find(({ id }) => id === ingredientModifierId)
          ),
        },
        {
          type: 'options',
          modifiers: this.product.optionModifiers.map(optionModifierId =>
            this.optionModifiers.find(({ id }) => id === optionModifierId)
          ),
        },
      ]
    },
    formTitle() {
      return this.dialog.type === 'ingredients'
        ? this.addIngredientsModifierLabel
        : this.addOptionsModifierLabel
    },
    addOptionsModifierLabel() {
      return this.$t('cms_add_options_modifier')
    },
    addIngredientsModifierLabel() {
      return this.$t('cms_add_ingredients_modifier')
    },
    formFields() {
      const options =
        this.dialog.type === 'ingredients'
          ? this.ingredientModifiers.map(ing => ({
              id: ing.id,
              label: this.$label(ing),
              value: ing.id,
            }))
          : this.optionModifiers.map(opt => ({
              id: opt.id,
              label: this.$label(opt),
              value: opt.id,
            }))
      const label =
        this.dialog.type === 'ingredients'
          ? this.$t('cms_ingredient_modifiers')
          : this.$t('cms_option_modifiers')
      return [
        {
          type: 'select',
          label,
          id: 'modifierId',
          options,
          default: null,
        },
      ]
    },
    modifierResource() {
      return this.dialog.type === 'ingredients' ? 'ingredient-modifier' : 'option-modifier'
    },
  },
  methods: {
    ...mapActions({
      invalidateProducts: `cms/products/invalidateAll`,
    }),
    toggleRow(i) {
      if (this.openRows.includes(i)) {
        this.openRows = this.openRows.filter(index => index !== i)
        return
      }
      this.openRows = [...this.openRows, i]
    },
    toggleDialog() {
      this.dialog = { ...this.dialog, isOpen: !this.dialog.isOpen }
    },
    getRows({ modifiers, type }) {
      return modifiers.map(modifier => ({
        values: [this.$label(modifier), this.parseModifierValues(modifier, type)],
        onDelete: () => this.handleDeleteModifier(modifier.id, type),
      }))
    },
    parseModifierValues(modifier, type) {
      return modifier[type]
        .map(val => {
          switch (type) {
            case 'ingredients':
              return this.$label(this.ingredients.find(({ id }) => id === val))
            case 'options':
              return this.$label(this.options.find(({ id }) => id === val))
          }
        })
        .join(', ')
    },
    addModifierClick(type) {
      this.dialog = { ...this.dialog, type }
      this.toggleDialog()
    },
    async handleAddModifier({ modifierId }) {
      await cmsController.createRelationship(
        'product',
        this.product.id,
        this.modifierResource,
        modifierId
      )
      this.toggleDialog()
      this.invalidateProducts()
    },
    async handleDeleteModifier(modifierId, type) {
      const modifierResource = type === 'ingredients' ? 'ingredient-modifier' : 'option-modifier'
      await cmsController.deleteRelationship(
        'product',
        this.product.id,
        modifierResource,
        modifierId
      )
      this.invalidateProducts()
    },
  },
}
</script>
<style lang="stylus" scoped>
.modifiers-table
  padding-left: vw(20px)

.modifiers-table-heading
  display: flex
  justify-content: space-between
  align-items: center

  .modifiers-table-heading__left
    display: flex
    justify-content: space-between
    align-items: center
    height: vh(48px)
    gap: vw(10px)

    h5
      m-font-size(12, 16)
      color: #4f4f4f
      font-weight: 400
</style>