<template>
  <div class="table-row">
    <basic-checkbox :value="value" @change="$emit('change', $event)" />
    <div class="table-row-name">{{ table.name }}</div>
  </div>
</template>

<script>
import BasicCheckbox from '../../../../basic/BasicCheckbox.vue'

export default {
  components: { BasicCheckbox },
  props: {
    table: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
    },
  },
}
</script>

<style lang="stylus" scoped>
.table-row
  display: flex
  align-items: center

.table-row-name
  m-font-size(14, 20)
</style>