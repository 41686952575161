<template>
  <div class="orders-container">
    <basic-selector
      v-model="selectorValue"
      :options="orderOptions.options"
      key-attribute="key"
      :type="SELECTOR_TYPES.BIG"
    />
    <basic-tabs v-if="haveTabs" v-model="tab" full-width>
      <basic-tab
        v-for="(option, index) in orderTabOptions.options"
        :key="index"
        :value="option.key"
        :name="option.label"
      />
    </basic-tabs>
    <!-- <span v-if="isFetchingOrders">Loading</span> -->
    <basic-loading v-if="isFetchingOrders" />
    <component :is="component" v-else :orders="filteredOrders" />
  </div>
</template>

<script>
import BasicSelector, { SELECTOR_TYPES } from '../basic/BasicSelector.vue'
import MyOrders from '../orders/MyOrders.vue'
import PickUpOrders from './pick-up/'
import TakeawayOrders from './take-away/index.vue'
import {
  ORDER_OPTIONS,
  ORDER_OPTIONS_KEYS,
  ORDER_TABS_KEYS,
  ORDER_TABS_OPTIONS,
} from '../../settings/navigationSettings'
import QueryMixin from '../../mixins/QueryMixin'
import BasicTabs from '../basic/BasicTabs.vue'
import BasicTab from '../basic/BasicTab.vue'
import BasicLoading from '../basic/BasicLoading.vue'
import { mapGetters } from 'vuex'
import OrderListDataMixin from '../../mixins/OrderListDataMixin'

export default {
  components: {
    BasicSelector,
    BasicLoading,
    MyOrders,
    PickUpOrders,
    TakeawayOrders,
    BasicTab,
    BasicTabs,
  },
  mixins: [QueryMixin, OrderListDataMixin],
  data() {
    return { SELECTOR_TYPES }
  },
  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
    }),
    filteredOrders() {
      switch (this.selectorValue) {
        case ORDER_OPTIONS_KEYS.OTHER_ZONES:
          return this.orders.filter(order => order.isVisible)
        case ORDER_OPTIONS_KEYS.MY_ZONE:
          return this.ordersByTables(this.logedUser.tables).filter(order => order.isVisible)
        case ORDER_OPTIONS_KEYS.TAKEAWAY:
          return this.takeAwayOrders.filter(order => order.isVisible)
        default:
          return this.orders.filter(order => order.isVisible)
      }
    },
    tab: {
      set(value) {
        this.setQueryValue(ORDER_TABS_OPTIONS.key, value)
      },
      get() {
        let queryValue = this.getQueryValue(ORDER_TABS_OPTIONS.key)
        if (queryValue) return queryValue
        let options = this.orderTabOptions.options
        return options.length > 0 ? options[0].key : null
      },
    },
    selectorValue: {
      set(value) {
        this.setQueryValue(ORDER_OPTIONS.key, value)
      },
      get() {
        let queryValue = this.getQueryValue(ORDER_OPTIONS.key)
        if (queryValue) return queryValue
        let options = this.orderOptions.options
        return options.length > 0 ? options[0].key : null
      },
    },
    orderOptions() {
      return ORDER_OPTIONS
    },
    orderTabOptions() {
      return ORDER_TABS_OPTIONS
    },
    haveTabs() {
      return this.selectorValue !== ORDER_OPTIONS_KEYS.TAKEAWAY
    },
    component() {
      if (this.selectorValue === ORDER_OPTIONS_KEYS.TAKEAWAY) {
        return TakeawayOrders
      }
      switch (this.tab) {
        case ORDER_TABS_KEYS.ORDERS:
          return MyOrders
        case ORDER_TABS_KEYS.PICKUP:
          return PickUpOrders
      }
      return null
    },
  },
}
</script>

<style lang="stylus">
.orders-container
  position: fixed
  top: $header-height
  left: 0
  display: flex
  flex: 1 1 0%
  flex-direction: column
  overflow: hidden
  width: 100%
  height: "calc(100% - %s)" % $header-height

  .basic-selector-container
    margin-bottom: vw(20px)
</style>
