import { mapActions, mapGetters } from 'vuex'
import { NEW_ORDER } from '../settings/routeNames'
export default {
  props: ['orderId'],
  computed: {
    ...mapGetters({
      activeOrder: 'activeOrder/data',
      newOrder: 'newOrder/data',
    }),
    order () {
      if (this.isActive) return this.activeOrder
      else return this.newOrder
    },
    isActive () {
      return !this.isNew && !!this.orderId
    },
    isNew () {
      return this.$route.params.orderId === NEW_ORDER
    },
  },
  methods: {
    ...mapActions({
      resetNewOrder: 'newOrder/reset',
    }),
    orderDispatch (action, value) {
      let storePrefix = this.isActive ? 'activeOrder' : 'newOrder'
      return this.$store.dispatch(`${storePrefix}/${action}`, value)
    },
  },
  watch: {
    orderId: {
      handler () {
        if (this.isActive) this.$store.dispatch('activeOrder/fetchOrderById', this.orderId)
      },
      immediate: true,
    },
  },
}
