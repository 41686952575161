import dayjs from 'dayjs'

export default {
  data() {
    return {
      date: new Date(),
    }
  },
  mounted() {
    this._timer = setInterval(() => (this.date = new Date()), 1000)
  },
  unmounted() {
    clearInterval(this._timer)
  },
  methods: {
    /**
     *
     * @param {Date | number} date
     * @param {string} format dayjs format
     * @returns {string}
     */
    getDiferentTime(date, format = 'mm:ss') {
      const d = new Date(date)
      return dayjs(new Date(this.date.getTime() - d.getTime())).format(format)
    },
  },
}
