<template>
  <div class="cms-preparation-areas">
    <ResourceConfigurator
      v-if="preparationAreas"
      :edit-label-key="'cms_edit_area'"
      :add-label-key="'cms_add_area'"
      :resources="preparationAreas"
      :resource-label-key="'cms_restaurant_layout'"
      :fields-info="fieldsInfo"
      :form-fields="formFields"
      :table-values="tableValues"
      :delete-resource-handler="deleteAreaHandler"
      :add-resource-handler="addAreaHandler"
      :cell-click-handler="cellClickHandler"
      :edit-resource-handler="editAreaHandler"
      @onStartEditing="r => editingResource = r"
      @onStartAdding="editingResource = null"
    />
  </div>
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator'
import { cmsController } from '../../../services/api'
import { mapActions } from 'vuex'
export default {
  components: {  ResourceConfigurator },
  data() {
    return {
      editingResource: null
    }
  },
  computed: {
    fieldsInfo() {
      return [
        { type: 'default', name: this.$t('cms_name'), id: 'name'},
        { type: 'default', name: this.$t('cms_icon'), id: 'icon'},
        { type: 'boolean', name: this.$t('cms_active'), id: 'isVisible'},
      ]
    },
    formFields() {
      return [
        {
          type: 'string',
          label: this.$t('cms_name'),
          id: 'name',
          default: this.editingResource?.name
        },
        {
          type: 'string',
          label: this.$t('cms_icon'),
          id: 'icon',
          default: this.editingResource?.icon
        },
        {
          type: 'boolean',
          label: this.$t('cms_active'),
          id: 'isVisible',
          enabledLabel: this.$t('cms_active'),
          disabledLabel: this.$t('cms_not_active'),
          default: this.editingResource && this.editingResource.isVisible ||  false
        }
      ]
    },
    tableValues() {
      return this.preparationAreas?.map((preparationArea) => [preparationArea.name, preparationArea.icon, preparationArea.isVisible])
    }
  },
  remoteComputed: {
    preparationAreas: {
      model: 'restaurant/preparationAreas',
      method: 'get',
    },
  },
  methods: {
    ...mapActions({
      invalidateAreas: 'restaurant/preparationAreas/invalidateAll',
    }),
    cellClickHandler(fieldIndex, area) {
      if (this.fieldsInfo[fieldIndex].type !== 'boolean') {
        return
      }
      const fieldId = this.fieldsInfo[fieldIndex].id
      this.editAreaHandler({ [fieldId]: !area[fieldId]}, area)
    },
    deleteAreaHandler({ id }) {
      return cmsController.deleteResourceById('preparation-areas', id).then(this.invalidateAreas)
    },
    addAreaHandler(area) {
      return cmsController.addResource('preparation-areas', area).then(this.invalidateAreas)
    },
    editAreaHandler(area) {
      return cmsController.updateResourceById('preparation-areas', this.editingResource.id, area).then(this.invalidateAreas)
    },
  },
}
</script>
<style lang="stylus" scoped>
</style>