<template>
  <button class="cms-btn" :class="{ 'cms-btn--inverted': inverted === true, 'cms-btn--disabled': disabled === true }" @click="onClick">
    <div v-if="shouldRenderIcon && icon" :class="`icon-${icon}`" />
    <div class="cms-btn__text">{{ $t(labelKey) }}</div>
  </button>
</template>
<script>
export default {
  props: {
    icon: String,
    labelKey: String,
    inverted: Boolean,
    disabled: Boolean,
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
    shouldRenderIcon() {
      return this.icon && this.icon === 'add'
    },
  },
}
</script>
<style lang="stylus" scoped>
.cms-btn
  m-font-size(14, 16)
  display: flex
  justify-content: center
  align-items: center
  padding-top: 0
  padding-bottom: 0
  height: vh(36px)
  border: 1px solid #000
  background-color: #fff
  color: #000
  font-weight: 500
  gap: vw(5px)

  .icon-add
    transform: translateX(-5px)
    m-icon("plus", 20)

.cms-btn__text
  margin-top: -0.1em

.cms-btn--inverted
  background-color: #000
  color: #fff

  .icon-add
    m-icon("plus-inverted", 20)

.cms-btn--disabled
  opacity: 0.5
  pointer-events: none
</style>