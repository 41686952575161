<template>
  <div class="generic-order-container">
    <div v-if="table" class="change-table-icon" @click="onChangeTable" />
    <div class="generic-order-info" @click="handleClickCard">      
      <div class="generic-order-row">
        <template v-if="table && table.name">
          <div class="generic-order-title">{{ `${$t('orders_header_table')}: ${table.name}` }}</div>
        </template>
        <status-order v-if="showStatus && computedStatus" class="generic-order-status" :status="computedStatus" />
      </div>
      <div class="generic-order-row">
        <div v-if="orderIdentifier" class="generic-order-number">{{ orderIdentifier }}</div>
        <div v-if="orderIdentifier && table" class="generic-order-separator" />
        <div v-if="table" class="generic-order-customer">
          <div class="generic-order-customer-icon" />
          <div class="generic-order-customer-number">{{ data.table.totalCustomersAtTable }}</div>
        </div>
        <!-- <div v-if="data.isPaid" class="generic-order-price">{{ $t('order_paid') }}</div>
        <div v-else-if="calculatedPrice" class="generic-order-price">{{ calculatedPrice }}</div> -->
        <div class="generic-order-price">
          <!-- <span v-if="calculatedPrice">{{ calculatedPrice }}</span> -->
          <status-order v-if="data.isPaid" :status="'paid'" />
        </div>
      </div>
    </div>
    <basic-dialog :is-open="changeTableDialogOpen" @close="changeTableDialogOpen=false">
      <template slot="dialog-body">
        <select-table :submit-label="$t('change_table_button')" @selectedTable="handleChangeTable" />
      </template>
    </basic-dialog>
    <warning-dialog
      :is-open="changeTableErrorDialog"
      :description="$t('change_table_dialog_warning_description')"
      :btn-title="$t('change_table_dialog_warning_btn_title')"
      @close="changeTableErrorDialog = false"
      @accept="changeTableErrorDialog = false"
    />
  </div>
</template>

<script>
import { ORDER_STATUS } from '../../settings/orderSettings'
import BasicDialog from '../basic/BasicDialog.vue'
import WarningDialog from '@/components/orders/dialogs/WarningDialog.vue'
import { getOrderStatus } from '@/utils/ordersUtils.js'
import StatusOrder from '../StatusOrder.vue'
import SelectTable from '../SelectTable.vue'
import { getServiceZoneTableById } from '../../utils/serviceZonesUtils'
import { orderController } from '@/services/api'
import { parseCurrency } from '../../utils/ticketUtils'
export default {
  components: { StatusOrder, BasicDialog, WarningDialog, SelectTable },
  props: ['data', 'showStatus'],
  data() {
    return {
      ORDER_STATUS,
      changeTableDialogOpen: false,
      changeTableErrorDialog: false,
    }
  },
  computed: {
    computedStatus() {
      let status = getOrderStatus(this.data)
      return status
    },
    table() {
      if (!this.data.table) return null
      return getServiceZoneTableById(this.serviceZones, this.data.table.id)
    },
    orderIdentifier() {
      if (this.data.name) return `${this.$t('order')}: #${this.data.id} - ${this.data.name}`
      if (this.data.ids)
        return `${this.$t('orders')}: ${this.data.ids.map(id => '#' + id).join(',')}`
      if (!this.data.id) return null
      return `${this.$t('order')}: #${this.data.id}`
    },
    calculatedPrice() {
      if (!this.data.calculatedPrice) return null
      return `${this.data.calculatedPrice?.totalCalculated.toFixed(2)}${parseCurrency(
        this.ticketSettings?.currency
      )}`
    },
  },
  remoteComputed: {
    serviceZones: {
      model: 'restaurant/serviceZones',
      method: 'get',
    },
    ticketSettings: {
      model: 'restaurant/ticketSettings',
      method: 'get',
    },
  },
  methods: {
    handleClickCard() {
      this.$emit('click')
    },
    onChangeTable() {
      this.changeTableDialogOpen = true
    },
    async handleChangeTable({ selectedTable }) {
      this.changeTableDialogOpen = false
      try {
        await orderController.swapTable(this.data.table.id, selectedTable)
        this.$emit('changeTable', selectedTable)
      } catch (err) {
        this.changeTableErrorDialog = true
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.generic-order-container
  display: flex
  align-items: center
  padding: vw(10px)
  border-bottom: 1px solid #e0e0e0

.generic-order-info
  width: 100%

.generic-order-row
  m-font-size(14, 20)
  display: flex
  align-items: center

  &:not(:last-child)
    margin-bottom: vw(5px)

.generic-order-title
  m-font-size(16, 20)
  flex: 1 1 0%
  font-weight: 600

.generic-order-status
  margin-left: auto

.generic-order-number
  m-ellipsis(40%)

.generic-order-number,
.generic-order-separator,
.generic-order-customer
  color: #828282

.generic-order-separator
  margin-right: 0.5em
  margin-left: 0.5em
  width: vw(2px)
  height: vw(2px)
  border-radius: 50%
  background-color: #333

.generic-order-customer
  display: flex
  align-items: center

.generic-order-customer-icon
  m-icon("user", 14)
  margin-right: vw(4px)

.generic-order-price
  display: flex
  align-items: center
  margin-left: auto

  .status-order
    margin-left: vw(10px)

.change-table-icon
  margin-right: vw(5px)
  cursor: pointer
  m-icon("swap-vertical", 40)
</style>
