<template>
  <div class="email-input">
    <input v-model="localValue" type="text">
    <p v-if="!isValid && !!localValue" class="invalid-email-error">
      {{ this.$t('cms_invalid_email_error') }}
    </p>
  </div>
</template>
<script>
import isValidEmail from 'is-valid-email'
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      localValue: this.value,
    }
  },
  computed: {
    isValid() {
      return isValidEmail(this.localValue)
    },
  },
  watch: {
    localValue(newVal) {
      this.isValid ? this.$emit('input', newVal) : this.$emit('input', null)
    },
    value(newValue) {
      this.localValue = newValue
    },
  },
}
</script>
<style lang="stylus" scoped>
.invalid-email-error
  top: vh(60px)
  margin: vh(5px) 0 0
  m-font-size(10, 10)
  color: red
</style>