<template>
  <div class="different-payment">
    <client-payment
      v-for="(client, index) in clients"
      :key="index"
      :client="client"
      :max-price="maxPrice"
      :disabled="disabled"
      @change="equalPriceClient(index,$event)"
      @remove="$emit('remove',client)"
    /> 
    <slot />
  </div>
</template>

<script>
import ClientPayment from './ClientPayment.vue'
import { getEqualPriceClients } from './paymentFunctions'
export default {
  components: { ClientPayment },
  model: {
    prop: 'clients',
    event: 'update:clients',
  },
  props: {
    clients: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    maxPrice: {
      type: Number,
    },
  },
  data() {
    return {
      internalClient: this.clients,
    }
  },
  watch: {
    clients: {
      handler(newClients, oldClients) {
        if (!oldClients) return this.equalPriceClient(0, newClients[0])
        if (oldClients.length !== newClients.length) this.equalPriceClient(0, newClients[0])
      },
      immediate: true,
    },
  },
  methods: {
    equalPriceClient(index, client) {
      const copy = [...this.clients]
      copy.splice(index, 1, client)
      this.$emit('update:clients', getEqualPriceClients(this.maxPrice, copy))
    },
  },
}
</script>

<style lang="stylus" scoped>
.different-payment
  width: 100%
</style>