<template>
  <div class="pick-up-card">
    <basic-collapse-component
      class="collapse"
      :title="collapsableTitle"
    >
      <pick-up-row-group
        v-for="(row) in orderRowsMerged"
        :key="row.id"
        :data="row"
        :order="order"
      />
    </basic-collapse-component>
  </div>
</template>

<script>
import { getMergedTicketRowsWithChildren } from '../../../utils/ticketUtils'
// import BasicCollapsableBar from '../../basic/BasicCollapsableBar.vue'
import BasicCollapseComponent from '../../basic/BasicCollapseComponent.vue'
import PickUpRowGroup from './PickUpRowGroup.vue'
import { getServiceZoneTableById, getTableGroupByTable } from '../../../utils/serviceZonesUtils'

export default {
  components: {
    // BasicCollapsableBar,
    BasicCollapseComponent,
    PickUpRowGroup,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      collapsed: false,
    }
  },
  computed: {
    orderRowsMerged() {
      return getMergedTicketRowsWithChildren(this.order.rows)
    },
    table() {
      if (!this.order.table) return null
      return getServiceZoneTableById(this.serviceZones, this.order.table.id)
    },
    collapsableTitle() {
      if (this.table) {
        const tableGroup = getTableGroupByTable(this.serviceZones, this.table.id)
        return `${this.table && tableGroup ? `${tableGroup.name} - ${this.table.name}` : ''}`
      } else if (this.order.takeAway?.clientName)
        return `${this.$t('orders_header_takeaway')}: ${this.order.takeAway.clientName} - ${
          this.orderIdentifier
        }`
      else if (this.order.takeAway?.platform)
        return `${this.$t('orders_header_takeaway')}: ${this.order.takeAway.platform} #${
          this.order.takeAway.orderNumber
        }`
      return ''
    },
    orderIdentifier() {
      if (!this.order.id) return null
      return `${this.$t('order')} #${this.order.id}`
    },
  },
  remoteComputed: {
    serviceZones: {
      model: 'restaurant/serviceZones',
      method: 'get',
    },
  },
}
</script>

<style lang="stylus" scoped>
.pick-up-card
  display: flex
  flex-direction: column
</style>