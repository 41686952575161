<template>
  <div class="confirm-dialog">
    <div v-if="message" class="confirm-dialog__message">
      <p>{{ $t('cms_confirm_delete_message') }}</p>
    </div>
    <div class="confirm-dialog__buttons">
      <Button :label-key="cancelLabelKey" :inverted="true" @click="cancelCallback" />
      <Button :label-key="confirmLabelKey" @click="confirmCallback" />
    </div>
  </div>
</template>
<script>
import Button from './Button'
export default {
  components: { Button },
  props: {
    message: {
      type: String,
      required: false
    },
    confirmLabelKey: {
      type: String,
      required: true
    },
    cancelLabelKey: {
      type: String,
      required: true
    },
    confirmCallback: {
      type: Function,
      required: true
    },
    cancelCallback: {
      type: Function,
      required: true
    }
  },
}
</script>
<style lang="stylus">
.confirm-dialog__buttons
  width: 100%
  display: flex
  justify-content: flex-end
</style>