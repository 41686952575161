<template>
  <div class="row-alert">
    <div class="row-alert-icon" />
    <div class="row-alert-text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="stylus" scoped>
.row-alert
  display: flex
  align-items: center
  margin-top: vw(10px)
  margin-left: vw(1px)
  width: 100%

.row-alert-icon
  display: flex
  justify-content: center
  align-items: center
  margin-right: vw(10px)
  width: vw(20px)
  height: vw(20px)
  border-radius: 50%
  background-color: #333

  &:before
    m-icon("notification-white", 14)
    display: block
    content: ""

.row-alert-text
  m-font-size(14, 20)
</style>