<template>
  <div class="cms-form-container">
    <form class="cms-form">
      <div class="cms-form__title">{{ title }}</div>
      <div v-for="field in fields" :key="field.id" class="cms-form__input-container">
        <label v-if="field.label" class="cms-form__input-label">{{ `${field.label} ${field.required ? '*' : ''}` }}</label>
        <div v-if="field.type === 'string'" class="cms-form__input">
          <input
            v-model="state[field.id]"
            :minlength="field.minLength"
            :maxlength="field.maxLength"
            type="text"
          >
        </div>
        <div v-if="field.type === 'textarea'" class="cms-form__input">
          <textarea
            v-model="state[field.id]"
            rows="3"
            type="textarea"
          />
        </div>
        <div v-if="field.type === 'date'" class="cms-form__input">
          <FormDate v-model="state[field.id]" />
        </div>
        <div v-else-if="field.type === 'select'" class="cms-form__input">
          <Select v-model="state[field.id]" :options="field.options" />
        </div>
        <div v-else-if="field.type === 'percentage'" class="cms-form__input">
          <input
            v-model="state[field.id]"
            type="number"
            min="1"
            max="100"
            step="any"
          >
        </div>
        <div v-else-if="field.type === 'number'" class="cms-form__input">
          <input v-model="state[field.id]" type="number">
        </div>
        <div v-else-if="field.type === 'image'" class="cms-form__input">
          <ImageInput v-model="state[field.id]" />
        </div>
        <div v-else-if="field.type === 'boolean'" class="cms-form__input">
          <BooleanInput
            v-model="state[field.id]"
            :enabled-label="field.enabledLabel"
            :disabled-label="field.disabledLabel"
          />
        </div>
        <div v-else-if="field.type === 'list'" class="cms-form__input">
          <ListInput
            v-model="state[field.id]"
            :placeholder="field.placeholder"
            :items="field.items"
          />
        </div>
        <div v-else-if="field.type === 'password'" class="cms-form__input">
          <PasswordInput v-model="state[field.id]" :required="field.required" />
        </div>
        <div v-else-if="field.type === 'email'" class="cms-form__input">
          <EmailInput v-model="state[field.id]" />
        </div>
      </div>
    </form>
    <div class="form-buttons">
      <Button :label-key="'cms_cancel'" @click="onCancel" />
      <Button
        :label-key="'cms_save'"
        :inverted="true"
        :disabled="!isValid"
        @click="onSave"
      />
    </div>
  </div>
</template>
<script>
import Select from '../Select'
import Button from '../Button'
import FormDate from '../FormDate'
import ListInput from './ListInput'
import ImageInput from './ImageInput'
import BooleanInput from './BooleanInput'
import PasswordInput from './PasswordInput'
import EmailInput from './EmailInput'
export default {
  components: {
    Select,
    BooleanInput,
    Button,
    ListInput,
    PasswordInput,
    EmailInput,
    ImageInput,
    FormDate,
  },
  props: {
    title: String,
    fields: Array,
    saveAction: Function,
    submittable: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data() {
    return {
      state: this.$props.fields.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.id]: typeof curr.default !== 'undefined' ? curr.default : null,
        }),
        {}
      ),
    }
  },
  computed: {
    requiredInputsFilled() {
      return this.fields
        .filter(({ required }) => required)
        .every(({ type, id }) => {
          if (
            this.state[id] === null ||
            typeof this.state[id] === 'undefined' ||
            this.state[id] === ''
          ) {
            return false
          }
          if (type === 'password') {
            return this.state[id].isValid
          }
          if (type === 'list') {
            return this.state[id].length > 0
          }
          return true
        })
    },
    stateHasChanged() {
      return this.$props.fields.some(field => this.state[field.id] != field.default)
    },
    isValid() {
      return this.requiredInputsFilled && this.stateHasChanged && this.submittable
    },
  },
  methods: {
    onSave() {
      if (this.state.password) {
        this.state.password = this.state.password.password
      }
      if (this.state.password === null) {
        delete this.state.password
      }
      this.$props.saveAction(this.state)
    },
    onCancel() {
      this.$emit('cancel')
    },
  },
}
</script>
<style lang="stylus">
.cms-form-container
  display: flex
  flex-direction: column
  height: 100%

.cms-form
  margin-bottom: vw(40px)

.cms-form__title
  m-font-size(20, 26)
  margin-bottom: vw(10px)
  font-weight: 600

.cms-form__input-container
  &:not(:last-child)
    margin-bottom: vh(20px)

.cms-form__input-label
  m-font-size(12, 14)
  display: block
  margin-bottom: vw(5px)
  color: $gray-4

.cms-form__input
  input
    &:not([type="checkbox"])
      padding: vw(5px) vw(10px)
      min-width: vw(100px)
      width: 100%
      border: 1px solid $gray-5

  textarea
    padding: vw(5px) vw(10px)
    min-width: vw(100px)
    width: 100%
    border: 1px solid $gray-5

  .select__content
    border: 1px solid $gray-5

  .select__option
    border-bottom-color: $gray-5 !important

.form-buttons
  display: flex
  justify-content: flex-end
  align-items: center
  gap: vw(10px)
</style>