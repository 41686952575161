import { login, patchUser } from '@/services/api'

export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = { ...state.user, ...user }
    },
    logout(state) {
      state.user = null
    },
  },
  actions: {
    async login({ commit, dispatch }, data) {
      const { username, password } = data
      await login(username, password).then(user => {
        commit('setUser', {
          ...user,
        })
        dispatch('userQr/logout', {}, { root: true })
      })
    },
    logout({ commit }) {
      commit('logout')
    },
    async updateUser({ commit }, data) {
      await patchUser(data).then(user =>
        commit('setUser', {
          ...user,
        })
      )
    },
  },
  getters: {
    getLogedUser(state) {
      return state.user
    },
    getRestaurantId(state) {
      return state.user ? state.user.restaurantId : null
    },
  },
}
