<template>
  <transition name="slide" appear>
    <div v-if="isOpen" class="basic-dialog" :class="{'fullscreen': isFullscreen}">
      <div class="background" @click="$emit('close')" />
      <dialog v-show="isOpen" class="basic-dialog-container">
        <div v-if="closeable" class="basic-dialog-cross" @click.prevent="$emit('close')" />
        <div v-if="title" class="basic-dialog-header">
          <div class="basic-dialog-title-container">
            <div class="basic-dialog-title">{{ title }}</div>
            <div v-if="subTitle" class="basic-dialog-subtitle">{{ subTitle }}</div>
          </div>
        </div>
        <div :class="['basic-dialog-body', {'scrollable': scrollableBody, isMobile }]">
          <slot name="dialog-body" />
        </div>
      </dialog>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    isFullscreen: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
    scrollableBody: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="stylus">
$transition-duration = 0.5s
$dialog-height = 90%
$dialog-width = 90%
$dialog-translate = "calc(50% - (%s / 2))" % $dialog-height

.basic-dialog
  &.fullscreen
    .basic-dialog-container
      // width: 100%
      height: 100%

  &.slide-enter-active,
  &.slide-leave-active
    transition: all $transition-duration ease-in-out

    .background
      transition: opacity ($transition-duration / 2) ease-in-out

    .basic-dialog-container
      transition: transform $transition-duration ease-in-out

  &.slide-enter,
  &.slide-enter-from,
  &.slide-leave-to
    transition: all $transition-duration ease-in-out

    .background
      opacity: 0
      transition-duration: $transition-duration

    .basic-dialog-container
      transform: translate(-50%, 150%)

  .background
    position: fixed
    top: 0
    left: 0
    z-index: 1
    width: 100vw
    height: 100vh
    background-color: rgba(#333, 0.8)

.basic-dialog-container
  position: fixed
  top: 50%
  left: 50%
  z-index: 99
  display: flex
  flex-direction: column
  overflow-x: hidden
  overflow-y: auto
  padding: vw(25px)
  min-height: vw((25px * 2) + 60px)
  max-height: $dialog-height
  width: $dialog-width
  background-color: #fff
  transform: translate(-50%, -50%)

.basic-dialog-header
  display: flex
  align-items: center
  margin-top: -0.35em
  margin-right: vw(40px)
  margin-bottom: vw(25px)

.basic-dialog-body
  position: relative
  flex: 1 1 0%

  &.scrollable
    overflow-x: hidden
    overflow-y: auto

  .loading
    position: absolute !important

.basic-dialog-title-container
  display: flex
  flex-direction: column
  width: 100%

.basic-dialog-title
  m-font-size(16, 20)
  margin: 0
  font-weight: 600
  font-family: "IBM Plex Sans"

.basic-dialog-subtitle
  margin: 0
  m-font-size(14)

.basic-dialog-cross
  m-icon("close", 40)
  position: absolute
  top: vw(5px)
  right: vw(5px)
  z-index: 1
  flex: 0 0 vw(40px)
  background-color: #fff
  cursor: pointer
</style>