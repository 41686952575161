<template>
  <div class="setup-order">
    <basic-dialog
      :is-open="isOpen"
      scrollable-body
      @close="$emit('close')"
    >
      <template slot="dialog-body">
        <takeaway-form v-if="isTakeAway" />
        <select-table
          v-else
          :filter-by-logged-user="true"
          :quantity-selector="true"
          :submit-label="$t('setup_order_button_take_note')"
          @selectedTable="handleSelectedTable"
        />
      </template>
    </basic-dialog>
  </div>
</template>

<script>
import BasicDialog from '../components/basic/BasicDialog'
import { mapActions } from 'vuex'
import SelectTable from '../components/SelectTable.vue'
import TakeawayForm from '../components/setup-order/setup-takeaway/TakeawayForm'
import { NEW_ORDER, ROUTE_NAMES } from '../settings/routeNames'

export default {
  components: { BasicDialog, TakeawayForm, SelectTable },
  props: {
    isTakeAway: {
      type: Boolean,
      required: false,
      default: false
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  methods: {
    ...mapActions({
      addTableSetup: 'newOrder/addTableSetup',
    }),
    handleSelectedTable({ selectedTable, totalCustomersAtTable }) {
      this.addTableSetup({
        id: selectedTable,
        totalCustomersAtTable: totalCustomersAtTable,
      })
      this.$router.replace({ name: ROUTE_NAMES.MENU, params: { orderId: NEW_ORDER } })
    },
  },
}
</script>
<style lang="stylus" scoped>
>>> .basic-dialog-container
  display: block;
</style>