<template>
  <div class="my-orders">
    <!-- <template v-if="orders">LOADING</template> -->
    <basic-loading v-if="!orders" />
    <template v-else>
      <basic-collapse-component
        v-for="group in groups"
        :key="group.id"
        class="collapse"
        :title="tableGroupTitle(group)"
      >
        <generic-table-order-row
          v-for="(table, index) in group.tables"
          :key="index"
          class="collapse-row"
          :data="table.orders[table.orders.length -1]"
          @click="handleClickTable(table.id)"
        />
      </basic-collapse-component>
      <basic-collapse-component
        v-if="noTableOrdersGroup.orders && noTableOrdersGroup.orders.length > 0"
        class="collapse"
        :title="$t('no_table')"
      >
        <generic-table-order-row
          v-for="(order, index) in noTableOrdersGroup.orders"
          :key="`no-table-${index}`"
          class="collapse-row"
          :data="order"
          @click="handleNoTableOrderClick(order)"
        />
      </basic-collapse-component>
    </template>
    <basic-bottom-actions>
      <basic-button :title="$t('new_order')" @click="onNewOrder" />
    </basic-bottom-actions>
    <setup-order :is-open="showSetupOrder" @close="showSetupOrder = false" />
  </div>
</template>

<script>
// import {getOrders} from '@/services/api'
import GenericTableOrderRow from './GenericTableOrderRow.vue'
import BasicBottomActions from '@/components/basic/BasicBottomActions.vue'
import BasicButton from '@/components/basic/BasicButton.vue'
import { SELECTOR_TYPES } from '../basic/BasicSelector.vue'
import { mapActions, mapGetters } from 'vuex'
import BasicCollapseComponent from '../basic/BasicCollapseComponent.vue'
import { ROUTE_NAMES } from '../../settings/routeNames'
import BasicLoading from '../basic/BasicLoading.vue'
import SetupOrder from '../../views/SetupOrder.vue'

export default {
  components: {
    GenericTableOrderRow,
    BasicBottomActions,
    BasicButton,
    BasicCollapseComponent,
    BasicLoading,
    SetupOrder,
  },
  props: {
    orders: { type: Array, required: true },
  },
  data() {
    return {
      SELECTOR_TYPES,
      showSetupOrder: false,
      selectedZone: this.zone ? this.zone : null,
      selectedTableGroup: this.group ? this.group : null,
      selectedTables: [],
    }
  },
  remoteComputed: {
    serviceZones: {
      model: 'restaurant/serviceZones',
      method: 'get',
      params: function () {
        return [this.logedUser.restaurantId]
      },
    },
  },
  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
    }),
    ...mapActions({
      invalidateOrders: 'orders/orders/invalidateAll',
    }),
    filteredOrders() {
      return this.orders.filter(order => order.isVisible)
    },
    noTableOrdersGroup() {
      return {
        id: 'no-table',
        title: this.$t('no_table'),
        orders: this.orders.filter(o => {
          return o.type === 'NO_TABLE' && (o.status !== 'DELIVERED' || o.isPaid === false)
        }),
      }
    },
    allGroups() {
      return this.serviceZones.reduce((array, zone) => array.concat(zone.tableGroup), [])
    },
    groups() {
      return this.allGroups
        .map(group => {
          return {
            ...group,
            tables: group.tables
              .map(table => {
                return {
                  ...table,
                  orders: this.filteredOrders
                    .filter(order => order.table)
                    .filter(order => order.table.id === table.id),
                }
              })
              .filter(table => table.orders.length > 0),
          }
        })
        .filter(group => group.tables.length > 0)
    },
  },
  methods: {
    // setActiveOrder(id) {
    //   this.$router.push(`/order/${id}`)
    // },
    onNewOrder() {
      this.$store.dispatch('newOrder/reset')
      this.showSetupOrder = true
    },
    handleNoTableOrderClick(order) {
      this.$router.push({
        name: ROUTE_NAMES.TABLE,
        params: { tableId: 'NO_TABLE', orderId: order.id },
      })
    },
    handleClickTable(tableId) {
      this.$router.push({ name: ROUTE_NAMES.TABLE, params: { tableId: tableId } })
    },
    tableGroupTitle(group) {
      return `${this.serviceZoneByGroupId(group.id).name} - ${group.name}`
    },
    serviceZoneByGroupId(groupId) {
      return this.serviceZones.find(serviceZone =>
        serviceZone.tableGroup.find(tableGroup => parseInt(tableGroup.id) === parseInt(groupId))
      )
    },
  },
}
</script>

<style lang="stylus">
.my-orders
  z-index: 1
  flex: 1 1 0%
  overflow-y: auto
  padding-bottom: $basic-bottom-actions-height

.collapse-row
  &:first-child
    padding-top: 0

  &:last-child
    border-bottom: none
</style>
