<template>
  <div id="app" :class="[deviceClass, {'is-client': !logedUser}]" :style="flexDirection">
    <navigation v-if="logedUser" />
    <router-view />
  </div>
</template>


<script>
import device from '@/utils/device'
import Navigation from '@/components/navigation'
import ResponsiveCss from './mixins/ResponsiveCss'
import SocketMixin from './mixins/SocketMixin'
import { mapGetters, mapActions } from 'vuex'
import { ROUTE_NAMES } from './settings/routeNames'

export default {
  components: {
    Navigation,
  },
  mixins: [ResponsiveCss, SocketMixin],
  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
    }),
    deviceClass() {
      return device
    },
    flexDirection() {
      return this.$route.name === ROUTE_NAMES.PREPARATION_ZONE
        ? { 'flex-direction': 'row' }
        : { 'flex-direction': 'column' }
    },
  },
  watch: {
    restaurant(newVal) {
      if (!newVal) return
      this.checkCashRegistry()
    },
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.invalidateRestaurant()
      }
    })
  },
  methods: {
    ...mapActions({
      invalidateRestaurant: 'restaurant/restaurant/invalidateAll',
    }),
    checkCashRegistry() {
      if (
        !this.restaurant.cashRegisterIsOpen &&
        this.$route.name !== ROUTE_NAMES.CASH_REGISTER_CLOSED &&
        this.$route.name !== ROUTE_NAMES.LOGIN &&
        this.$route.name !== ROUTE_NAMES.LOGIN_QR &&
        this.$route.matched[0].name !== ROUTE_NAMES.CMS.MAIN
      ) {
        this.$router.push({
          name: ROUTE_NAMES.CASH_REGISTER_CLOSED,
        })
      }
      if (
        this.restaurant.cashRegisterIsOpen &&
        this.$route.name === ROUTE_NAMES.CASH_REGISTER_CLOSED
      ) {
        this.$router.push({
          name: ROUTE_NAMES.ORDERS,
        })
      }
    },
  },
}
</script>

<style lang="stylus">
@import "./styles/main"

#app
  display: flex
  overflow: hidden
  width: 100vw
  height: 100vh

  .view
    position: fixed
    top: 0
    left: 0
    overflow: hidden
    width: 100%
    height: 100%
</style>
