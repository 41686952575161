<template>
  <div class="add-product-client-payment">
    <div class="chip">{{ quantity }}</div>
    <div class="text-container">
      <div class="title">{{ $label(product) }}</div>
      <div class="subtitle">
        <span v-for="ingredient in addedIngredients" :key="ingredient.id" class="added">{{ $label(ingredient) }}</span>
        <span v-for="ingredient in removedIngredients" :key="ingredient.id" class="removed">{{ $label(ingredient) }}</span>
        {{ $description(product) }}
      </div>
    </div>
    <div class="inputs">
      <div class="icon less-btn" :class="{disabled:productInClient<=0 || disabled}" @click="$emit('change',-1)">-</div>
      <span>{{ productInClient }}</span>
      <div class="icon plus-btn" :class="{disabled:productInClient>=quantity || disabled}" @click="$emit('change',1)">+</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props:{
    product:{type:Object,required:true},
    quantity:{type:Number,required:true},
    productInClient:{type:Number,default:0},
    disabled:{type:Boolean,default:false}
  },
  data(){
    return {
      internalProducts:this.product
    }
  },
  
  computed:{
    ...mapGetters({
      getIngredients:'restaurant/ingredients/getById' // Fetch in Payment
    }),
    addedIngredients(){
      return []
    },
    removedIngredients(){
      return []
    }
  },  
  watch:{
    product(){
      this.internalProducts = this.product  
    }
  },
  mounted(){
    this.internalProducts = this.product  
  },
}
</script>

<style lang="stylus" scoped>
.add-product-client-payment
  width 100%
  display flex
  .inputs
    display flex 
    .icon
      width vw(20px)
      height vw(20px)
      margin-left vw(5px)
      margin-right vw(5px)
      border-radius 100%
      border 1px solid #000
      text-align center
      &.disabled
        pointer-events none
        color grey 
        border-color grey
</style>