<template>
  <form class="takeaway-form-container" @submit="submitForm">
    <label class="takeaway-form-label">
      <div class="fake-checkbox-container">
        <input
          v-model="selected"
          type="checkbox"
          :name="`${$t('take_away_radio_platform_delivery')}`"
        >
        <div class="fake-checkbox" />
      </div>
      <div class="takeaway-form-label-text">{{ `${$t('take_away_radio_platform_delivery')}` }}</div>
    </label>
    <template v-if="selected">
      <basic-input
        v-model="platform"
        :placeholder="$t('takeaway_input_platform_name')"
        :required="true"
      />
      <basic-input
        v-model="orderNumber"
        :placeholder="$t('takeaway_input_order_number')"
        required
      />
    </template>
    <template v-else>
      <basic-input
        v-model="name"
        :placeholder="$t('takeaway_input_client_name')"
        :required="true"
      />
      <basic-input
        v-model="phone"
        :placeholder="$t('takeaway_input_client_phone')"
        type="tel"
        required
      />
      <basic-input
        v-model="email"
        :placeholder="$t('takeaway_input_client_email')"
        type="email"
        :required="false"
      />
    </template>
    <basic-hour-input v-model="date" :title="$t('takeaway_input_hour')" />
    <basic-button :title="$t('takeaway_button_take_note')" type="submit" />
  </form>
</template>

<script>
import BasicButton from '../../basic/BasicButton.vue'
import BasicInput from '../../basic/BasicInput.vue'
import BasicHourInput from '../../basic/BasicHourInput.vue'
import { mapActions } from 'vuex'
import { ROUTE_NAMES } from '../../../settings/routeNames'

export default {
  components: { BasicInput, BasicButton, BasicHourInput },
  data() {
    return {
      name: '',
      phone: '',
      email: '',
      platform: '',
      orderNumber: '',
      date: new Date(),
      selected: false,
    }
  },
  methods: {
    ...mapActions({
      addTakeAway: 'newOrder/addTakeAway',
    }),
    submitForm() {
      const takeAway = this.selected
        ? {
            platform: this.platform,
            created: new Date().getTime(),
            orderNumber: this.orderNumber,
            pickUpDate: this.date,
          }
        : {
            clientName: this.name,
            created: new Date().getTime(),
            phone: this.phone,
            mail: this.email,
            pickUpDate: this.date,
          }
      this.addTakeAway(takeAway)
      this.$router.push({ name: ROUTE_NAMES.MENU })
    },
  },
}
</script>

<style lang="stylus" scoped>
.takeaway-form-label {
  display: flex;
  align-items: center;
  // margin-top: vw(40px)
  margin-bottom: vw(20px);
  width: 100%;
}

.takeaway-form-label-text {
  m-font-size(18, 24);
  margin-top: -0.1em;
  margin-left: vw(15px);
}

.takeaway-forgot-password {
  color: #828282;
}
</style>