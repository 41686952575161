<template>
  <div class="cash-register-closed view">
    <div ref="animationContainer" class="cash-register-closed_animation animation-container" />
    <p>The cash register is closed.</p>
  </div>
</template>

<script>
import lottie from 'lottie-web'

export default {
  mounted() {
    lottie.loadAnimation({
      container: this.$refs.animationContainer,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'https://assets2.lottiefiles.com/packages/lf20_gc3pn97g.json',
    })
  },
}
</script>

<style lang="stylus" scoped>
.cash-register-closed
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center

  &_animation
    height: 60%

  p
    margin-top: -100px
    font-size: 24px
</style>