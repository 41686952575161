<template>
  <div class="cms-ingredients-modifier">
    <ResourceConfigurator
      v-if="ingredientModifiers && ingredients"
      :edit-label-key="'cms_edit_ingredients_modifier'"
      :add-label-key="'cms_add_ingredients_modifier'"
      :resources="ingredientModifiers"
      :resource-label-key="'cms_ingredients_modifier'"
      :fields-info="fieldsInfo"
      :form-fields="formFields"
      :table-values="tableValues"
      :delete-resource-handler="deleteIngredientModifierHandler"
      :add-resource-handler="addIngredientModifierHandler"
      :edit-resource-handler="editIngredientModifierHandler"
      @onStartEditing="r => editingResource = r"
      @onStartAdding="editingResource = null"
    />
    
    <fav-csv-export
      :data="donwloadInfo" 
      :name-file="`${$t('cms_ingredients_modifier')}_${(new Date()).toLocaleDateString()}`"
    />
  </div>
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator'
import CmsResourceConfiguratorI18n from '@/mixins/CmsResourceConfiguratorI18n'
import { cmsController } from '@/services/api'
import { mapActions } from 'vuex'
import FavCsvExport from '../FavCsvExport'
import { createArrayDataCSV } from '../../../utils/csvUtils'
export default {
  components: { ResourceConfigurator, FavCsvExport },
  mixins: [CmsResourceConfiguratorI18n],
  data() {
    return {
      editingResource: null,
    }
  },
  remoteComputed: {
    ingredientModifiers: {
      model: 'restaurant/ingredientModifiers',
      method: 'get',
    },
    ingredients: {
      model: 'restaurant/ingredients',
      moethod: 'get',
    },
  },
  computed: {
    tableValues() {
      return this.ingredientModifiers?.map(modifier => [
        this.$label(modifier),
        this.$t(modifier.isRemove ? 'cms_remove' : 'cms_extra'),
      ])
    },
    fieldsInfo() {
      return [
        { type: 'default', name: this.$t('cms_name') },
        { type: 'default', name: this.$t('cms_type') },
      ]
    },
    formFields() {
      return [
        ...this.localizedFormFields(this.editingResource, 'label', 'cms_name'),
        {
          type: 'select',
          label: this.$t('cms_type'),
          id: 'isRemove',
          options: [true, false].map(isRemove => ({
            id: isRemove,
            label: this.$t(isRemove ? 'cms_remove' : 'cms_extra'),
            value: isRemove,
          })),
          default: this.editingResource?.isRemove,
        },
        {
          type: 'list',
          placeholder: this.$t('cms_ingredients_filter_placeholder'),
          label: this.$t('cms_ingredients'),
          id: 'ingredients',
          items: this.ingredients.map(ing => ({ id: ing.id, label: this.$label(ing) })),
          default: this.editingResource ? this.editingResource.ingredients : [],
        },
      ]
    },
    donwloadInfo() {
      return createArrayDataCSV.bind(this)(this.fieldsInfo, this.tableValues)
    },
  },
  methods: {
    ...mapActions({
      invalidateModifiers: 'restaurant/ingredientModifiers/invalidateAll',
    }),
    deleteIngredientModifierHandler({ id }) {
      return cmsController
        .deleteResourceById('ingredient-modifiers', id)
        .then(this.invalidateModifiers)
    },
    async addIngredientModifierHandler({ ingredients, ...modifier }) {
      const { id } = await cmsController.addResource('ingredient-modifiers', modifier)
      await Promise.allSettled(
        ingredients.map(opt =>
          cmsController.createRelationship('ingredient', opt, 'ingredient-modifier', id)
        )
      )
      this.invalidateModifiers()
    },
    async editIngredientModifierHandler({ i18n, ingredients, ...modifier }) {
      if (ingredients) {
        const ingredientsToAdd = ingredients.filter(
          ing => !this.editingResource.ingredients.includes(ing)
        )
        await ingredientsToAdd.map(ing =>
          cmsController.createRelationship(
            'ingredient',
            ing,
            'ingredient-modifier',
            this.editingResource.id
          )
        )
        const ingredientsToDelete = this.editingResource.ingredients.filter(
          ing => !ingredients.includes(ing)
        )
        await ingredientsToDelete.map(ing =>
          cmsController.deleteRelationship(
            'ingredient',
            ing,
            'ingredient-modifier',
            this.editingResource.id
          )
        )
      }
      await cmsController.updateResourceById('ingredient-modifiers', this.editingResource.id, modifier)
      await this.editI18ns(i18n, this.editingResource, 'ingredient-modifiers'),
        this.invalidateModifiers()
    },
  },
}
</script>
<style lang="stylus" scoped></style>