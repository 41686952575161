<template>
  <!-- <div v-if="!orders || isFetchingOrders" class="loader">Loading</div> -->
  <basic-loading v-if="!orders || isFetchingOrders" />
  <div v-else class="select-ticket-container">
    <div class="select-ticket-title">{{ $t("select_ticket_title") }}</div>
    <ul v-if="visibleOrders.length" class="select-ticketr-list">
      <li v-for="order in visibleOrders" :key="order.id" class="setup-order-item">
        <label :for="order.id">
          <input 
            :id="order.id"
            :checked="selectedTicket === order.id"
            :disabled="false"
            type="radio"
            :name="order.name"
            :value="order.id"
            @input="selectTicket"
          >
          <span class="table-row-title">{{ order.name }}</span>
        </label>
      </li>
    </ul>
    <basic-input
      v-model="newTicketName"
      type="text"
      required
      :title="$t('create_new_ticket')"
      :placeholder="$t('create_new_ticket_placeholder')"
    />
    <div class="footer">
      <basic-button :title="submitLabel" :disabled="!(selectedTicket || newTicketName)" @click.prevent="handleSubmit" />
    </div>
  </div>
</template>

<script>
import OrderListDataMixin from '@/mixins/OrderListDataMixin'
import BasicButton from '@/components/basic/BasicButton.vue'
import BasicLoading from '@/components/basic/BasicLoading.vue'
import BasicInput from '@/components/basic/BasicInput.vue'
import { orderController } from '../services/api'

export default {
  components: { BasicButton, BasicLoading, BasicInput },
  mixins: [OrderListDataMixin],
  props: {
    ticketRowsToMove: {
      type: Array,
      required: true,
    },
    currentOrder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedTicket: null,
      newTicketName: '',
    }
  },
  computed: {
    visibleOrders() {
      return this.orders.filter(order => {
        const isNoTable = order.type === 'NO_TABLE'
        const { isPaid, isVisible } = order
        return isNoTable && !isPaid && isVisible
      })
    },
    submitLabel() {
      return this.$t('move_ticket_submit_button')
    },
  },
  remoteComputed: {
    serviceZones: {
      model: 'restaurant/serviceZones',
      method: 'get',
    },
  },
  watch: {
    newTicketName(val) {
      if (val && val !== '') {
        this.selectedTicket = null
      }
    },
  },
  mounted() {
    console.log('mounted')
  },
  methods: {
    selectTicket(event) {
      const ticketValue = parseInt(event.target.value)
      this.selectedTicket = ticketValue
    },
    isSelected(id) {
      return this.selectedTable === id
    },
    findTableByOrderId(orderId) {
      return this.tables.find(table => table.orderId === orderId)
    },
    handleSubmit() {
      orderController
        .moveTicketRows(
          this.currentOrder.id,
          this.ticketRowsToMove.flatMap(row => row.originalIds),
          this.selectedTicket,
          this.selectedTicket ? null : this.newTicketName
        )
        .then(() => {
          this.$emit('close')
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="stylus" scoped>
.select-ticket-container
  >>> .basic-selector-container
    margin-bottom: vw(30px)

// .setup-order-inner
// overflow-x: hidden
// overflow-y: auto
// max-height: "calc(90vh - (%s * 4))" % vw(25px)
.select-ticket-title
  m-font-size(32, 48)
  margin-top: vw(20px)
  margin-bottom: vw(20px)
  text-align: center
  font-weight: 700

.select-ticket-list
  flex: 1 1 0%
</style>