<template>
  <div class="payment-request">
    <basic-loading v-if="!paymentIntent || !stripeStatus" />
    <stripe-payment
      v-if="paymentIntent && stripeStatus"
      :order="order"
      :payment-intent="paymentIntent"
      :stripe-status="stripeStatus"
      @paymentFailed="$emit('paymentFailed')"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BasicLoading from '../basic/BasicLoading.vue';
import StripePayment from './StripePayment.vue';
import { cmsController, orderController } from '../../services/api';
export default {
  components: { StripePayment, BasicLoading },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  asyncComputed: {
    stripeStatus() {
      if (!this.userQr) return null
      return cmsController.retrieveStripeAccountStatus(this.userQr.restaurantId)
    },
    paymentIntent() {
      return orderController.createStripePaymentIntent({
        ticketId: this.order.id,
        paymentMethods: ['card'],
      })
    },
  },
  computed: {
    ...mapGetters({
      userQr: 'userQr/getLogedQrData',
    }),
  },
}
</script>
<style lang="stylus" scoped>
  
</style>