<template>
  <div class="select" :class="{'select--open': isOpen}">
    <div class="select__content">
      <div class="select__options">      
        <div
          v-for="(option, index) in sortedOptions()"
          :key="option.value"
          class="select__option"
          @click.stop="handleClick(option.id)"
        >
          <span>{{ option.label }}</span>
          <div v-if="index === 0" class="select__arrow">
            <div />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  mounted() {
    window.addEventListener('click', () => {
      this.isOpen = false
    })
    if (!this.value && this.options[0]) {
      this.value = this.options[0].id
      this.$emit('input', this.value)
    }
  },
  methods: {
    toggleSelect() {
      this.isOpen = !this.isOpen
    },
    handleClick(id) {
      this.toggleSelect()
      if (this.value !== id) {
        this.$emit('input', id)
        this.value = id
      }
    },
    sortedOptions() {
      const value = this.$attrs.value
      return this.options.sort((a, b) => {
        if (a.id === value) {
          return -1
        }
        if (b.id === value) {
          return 1
        }
        if (a.fixed && !b.fixed) {
          return -1
        }
        if (b.fixed && !a.fixed) {
          return 1
        }
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
    },
  },
}
</script>
<style lang="stylus" scoped>
.select
  position: relative
  min-height: vw(36px)
  width: 100%

.select__content
  position: absolute
  display: flex
  justify-content: space-between
  overflow: hidden
  min-height: vw(36px)
  width: 100%
  border: 1px solid #000
  border-radius: 1px

.select__options
  width: 100%

.select__arrow
  transition: transform 0.15s linear

  div
    m-icon("chevron-down-black", 15)

.select__option
  display: none
  justify-content: space-between
  align-items: center
  overflow: hidden
  padding: 0 10px
  height: vw(36px)
  background-color: #fff
  cursor: pointer

  &:not(:first-child)
    &:hover
      background-color: $concrete

  &:first-of-type
    display: flex

  span
    m-font-size(14, 12)
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    font-weight: 400

.select--open
  .select__arrow
    transform: rotate(180deg)

  .select__option
    display: flex

    &:not(:last-child)
      border-bottom: 1px solid #000

  .select__content
    z-index: 1
</style>