<template>
  <basic-bottom-actions :class="['order-basic-actions', {'with-cancel-btn': isNew || !isTableView && !order.isPaid}]">
    <template v-if="isTableView">
      <button class="btn-submit btn btn--primary" @click="$emit('newOrder',$event)">{{ $t('new_order') }}</button>
    </template>
    <template v-else>
      <template v-if="isActive">
        <button v-if="userQr && !order.isPaid" class="btn-submit btn btn--primary" @click="$emit('onOpenStripePay', $event)">{{ paymentLabel }}</button>
        <button v-if="!userQr || (userQr && order.isPaid)" class="btn-submit btn btn--primary" @click="handleViewTicket">{{ viewTicketLabel }}</button>
      </template>
      <template v-else-if="!userQr"> 
        <button v-if="!order.calculatedPrice" class="btn-submit btn btn--primary" @click="onSubmitOrder">{{ $t('order_validate_button') }}</button>
        <button v-else class="btn-submit btn btn--primary" @click="onSubmitOrder">{{ confirmOrderLabel }}</button>
      </template>
      <button v-if="!order.isPaid" class="btn-cancel btn btn--primary btn--ghost" @click="$emit('onCancelOrder', $event)">{{ $t('order_cancel_button') }}</button>
    </template>
  </basic-bottom-actions>
</template>

<script>
import { mapGetters } from 'vuex'
import OrderDataMixin from '../../mixins/OrderDataMixin'
import { orderController } from '../../services/api'
import { ROUTE_NAMES } from '../../settings/routeNames'
import { parseCurrency } from '../../utils/ticketUtils'
import BasicBottomActions from '../basic/BasicBottomActions.vue'

export default {
  components: { BasicBottomActions },
  mixins: [OrderDataMixin],
  props: ['orderId', 'isTableView'],
  remoteComputed: {
    ticketSettings: {
      model: 'restaurant/ticketSettings',
      method: 'get',
    },
  },
  computed: {
    ...mapGetters({
      userQr: 'userQr/getLogedQrData',
      logedUser: 'user/getLogedUser',
    }),
    restaurantId() {
      return this.userQr ? this.userQr.restaurantId : this.logedUser.restaurantId
    },
    totalCalculatedPrice() {
      if (this.order.calculatedPrice) return this.order.calculatedPrice.totalCalculated
      else return 0 // TODO: que hacemos cuando no se ha cargado aun el calculated Price
    },
    totalCalculatedPriceWitchCurrency() {
      return `${this.totalCalculatedPrice}${parseCurrency(this.ticketSettings?.currency)}`
    },
    viewTicketLabel() {
      return `${this.$t('order_payment_ticket_button')} - ${this.totalCalculatedPriceWitchCurrency}`
    },
    confirmOrderLabel() {
      return `${this.$t('order_confirm_button')} - ${this.totalCalculatedPriceWitchCurrency}`
    },
    paymentLabel() {
      return `${this.$t('order_payment_button')} ${this.totalCalculatedPriceWitchCurrency}`
    },
  },
  methods: {
    onSubmitOrder() {
      orderController
        .validateOrder(this.restaurantId, this.order)
        .then(
          orderController
            .postOrder(this.restaurantId, { isQR: !!this.userQr, ...this.order })
            // impresionante guarrada porque el backend manda el ID
            .then(id => orderController.getOrderById(id))
            .then(order => {
              if (order.table) {
                this.$router.replace({
                  name: ROUTE_NAMES.TABLE,
                  params: { tableId: order.table.id },
                })
              } else {
                this.$router.replace({
                  name: ROUTE_NAMES.ORDER,
                  params: { orderId: order.id },
                })
              }
              this.resetNewOrder()
            })
        )
        .catch(err => console.error(err))
    },
    handleViewTicket() {
      //TODO, habria que validarlo TAMBIEN , no?
      this.$router.push({
        name: ROUTE_NAMES.INVOICE,
        orderId: this.order.id,
        query: { isCurrentOrder: true },
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.order-basic-actions
  height: $basic-bottom-actions-height

  &.with-cancel-btn
    height: $basic-bottom-actions-height-big

.btn-cancel
  margin-bottom: vw(-15px)
</style>