
export default {
  namespaced: true,
  state: {
    staticTest: 1,
  },
  remoteModels:{
    remoteTest:{
      get: () => new Promise((res)=>{
        setTimeout(()=>{
          res(Math.round(Math.random()*100)/100)
        },Math.random()*5000)
      }),
    }
  },
  mutations:{
    changeTheStaticModel(state){
      state.staticTest = Math.round(Math.random()*100)
    }
  },
  actions:{
    changeTheStaticModel({commit}) {
      commit('changeTheStaticModel')
    }
  }
}