<template>
  <table class="table" :class="{ 'table--modifier': isModifier }">
    <tr>
      <th v-for="(field, i) in fieldsInfo" :key="field.name">
        {{ field.name }}
        <div v-if="isSortable(field.type)" class="order-icon" @click="() => sortBy(i)" />
      </th>
      <th class="actions-cell">{{ isModifier ? '' : $t('cms_actions') }}</th>
    </tr>
    <div v-for="(row, j) in orderedRows" :key="`row_${j}`" class="table-row">
      <tr>
        <td v-for="(value, k) in row.values" :key="`cell_${k}`">
          <div
            v-if="rowCanOpen(row) && k === 0"
            :class="{ iconIsOpen: openRows.includes(j), iconIsClose: !openRows.includes(j) }"
            class="row-toggle-icon"
            @click="toggleRow(j)"
          />
          <span v-if="getTypeFromColumnIndex(k) === 'timestamp'"> {{ formatDate(value) }}</span>
          <span v-else-if="getTypeFromColumnIndex(k) === 'datetime'"> {{ formatDateTime(value) }}</span>
          <span v-else-if="getTypeFromColumnIndex(k) === 'money'">
            {{ formatCurrency(value) }}
          </span>
          <span v-else-if="getTypeFromColumnIndex(k) === 'percentage'"> {{ value + '%' }}</span>
          <span v-else-if="getTypeFromColumnIndex(k) === 'language'">
            {{ getLangNameFromCode(value).native }}
          </span>
          <span v-else-if="getTypeFromColumnIndex(k) === 'image'" class="image-thumbnail" :style="'background-image: url(' + value + ')'" />
          <span
            v-else-if="getTypeFromColumnIndex(k) === 'boolean'"
            @click="handleCellClick(row.onCellClick, k)"
          >
            <div v-if="value" class="check-icon" />
            <div v-else class="uncheck-icon" />
          </span>
          <span v-else-if="getTypeFromColumnIndex(k) === 'redirect'">
            <router-link v-if="value" :to="value.location">{{ value.label }}</router-link>
          </span>
          <span v-else> {{ value }}</span>
        </td>
        <td class="actions-cell">
          <div v-if="!rowHasActions(row)">-</div>
          <div v-if="row.onDownload" class="download-icon" @click="row.onDownload" />
          <div v-if="row.onEdit" class="edit-icon" @click="row.onEdit" />
          <div v-if="row.onDelete" class="delete-icon" @click="row.onDelete" />
        </td>
      </tr>
      <div v-if="row.invoices && openRows.includes(j)" class="table-row__invoices">
        <div v-for="(invoice, i) in row.invoices" :key="`invoice_${i}`" class="table-row__invoice">
          <router-link :to="invoice.location">{{ formatTime(new Date(invoice.created).getTime()) }}</router-link>
          <p>   - {{ formatCurrency(invoice.amountPaid) }} </p>
        </div>
      </div>
      <div v-if="row.productId && openRows.includes(j)" class="table-row__modifiers">
        <ModifiersTable :product-id="row.productId" />
      </div>
    </div>
  </table>
</template>

<script>
import { getLangNameFromCode } from 'language-name-map'
import ModifiersTable from './ModifiersTable'
export default {
  components: { ModifiersTable },
  props: {
    fieldsInfo: {
      type: Array,
      required: true,
      validator: function(arr) {
        return arr.every(
          field =>
            [
              'timestamp',
              'datetime',
              'money',
              'toggle',
              'language',
              'boolean',
              'default',
              'image',
              'redirect',
              'percentage',
            ].indexOf(field.type) !== -1 && typeof field.name === 'string'
        )
      },
    },
    isModifier: {
      type: Boolean,
      required: false,
      default: false,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sort: {
        desc: true,
        column: 0,
      },
      openRows: [],
    }
  },
  computed: {
    orderedRows() {
      return this.rows.slice().sort((a, b) => {
        const col = this.sort.column
        const desc = this.sort.desc
        const type = this.fieldsInfo[col].type
        if (desc) {
          return this.convertForCompare(a.values[col], type) <
            this.convertForCompare(b.values[col], type)
            ? 1
            : -1
        } else {
          return this.convertForCompare(a.values[col], type) <
            this.convertForCompare(b.values[col], type)
            ? -1
            : 1
        }
      })
    },
  },
  methods: {
    convertForCompare(val, type) {
      if (val === null || val === undefined) return val
      if (type === 'default') {
        if (val === null) return ''
        if (val.toLowerCase) {
          return val.toLowerCase()
        }
        return val
      } else if (type === 'percentage') {
        return Number(val)
      }
      return val
    },
    formatTime(timestamp) {
      const pad = val => (val < 10 ? '0' + val : val)
      const h = new Date(timestamp).getHours()
      const m = new Date(timestamp).getMinutes()
      return pad(h) + ':' + pad(m)
    },
    rowCanOpen(row) {
      return row.productId || (row.invoices && row.invoices.length > 0)
    },
    isSortable(type) {
      switch (type) {
        case 'redirect':
          return false
        default:
          return true
      }
    },
    getTypeFromColumnIndex(index) {
      return this.$props.fieldsInfo[index].type
    },
    getLangNameFromCode,
    toggleRow(i) {
      if (this.openRows.includes(i)) {
        this.openRows = this.openRows.filter(index => index !== i)
        return
      }
      this.openRows = [...this.openRows, i]
    },
    handleCellClick(cellClick, index) {
      cellClick(index)
    },
    sortBy(column) {
      this.openRows = []
      if (this.sort.column !== column) {
        this.sort = {
          desc: true,
          column,
        }
      }
      this.sort = {
        desc: !this.sort.desc,
        column,
      }
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('en-gb', { dateStyle: 'short' }).format(new Date(date))
    },
    formatDateTime(date) {
      return date ? `${this.formatDate(date)} - ${this.formatTime(date)}` : '-'
    },
    formatCurrency(amount) {
      return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(amount)
    },
    rowHasActions(row) {
      return row.onEdit || row.onDelete
    },
  },
}
</script>

<style lang="stylus">
.cms-view
  m-font-size(16, 16)

  table
    width: 100%
    table-layout: fixed

    .image-thumbnail
      width: vw(25px)
      height: vw(25px)
      border-radius: 50%
      background-position: center center
      background-size: cover
      background-repeat: no-repeat

  table,
  tr,
  td,
  th
    m-font-size(14, 20)
    padding: vw(10vw)
    text-align: left

  th,
  td
    m-font-size(14, 20)
    display: flex
    justify-content: flex-start
    align-items: center
    padding: vw(10vw)
    width: 100%
    text-align: center
    gap: vw(10px)

    &:last-child
      justify-content: flex-end
      width: auto

      span
        text-align: right

    span
      display: flex
      text-align: left

  td
    a
      color: blue

  tr
    display: flex
    min-height: vw(40px)
    border-bottom: 1px solid $alto

    th
      display: flex
      align-items: center
      width: 100%
      color: $gray-3
      font-weight: 400
      gap: vw(10px)

      &:last-child
        justify-content: flex-end
        width: auto

  .actions-cell
    min-width: vw(100px)

  .order-icon
    cursor: pointer
    m-icon("swap-vertical", 20)
    transform: rotate(90deg)

  .check-icon
    m-icon("check", 25)

  .uncheck-icon
    m-icon("uncheck", 25)

  .download-icon
    cursor: pointer
    m-icon("download", 20)

  .edit-icon
    cursor: pointer
    m-icon("edit", 20)

  .delete-icon
    cursor: pointer
    m-icon("delete", 20)

  .iconIsOpen
    flex-shrink: 0
    cursor: pointer
    m-icon("toggle-close", 20)

  .iconIsClose
    flex-shrink: 0
    cursor: pointer
    m-icon("toggle-open", 20)

  .table--modifier
    background: rgba(0, 0, 0, 0.03)

  .table-row__invoice
    display: flex
    align-items: center
    margin-top: vh(15px)
    margin-bottom: vh(15px)
    margin-left: vw(15px)

    p
      margin: 0

    a
      color: blue
</style>
