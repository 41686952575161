<template>
  <div class="table-row-container">
    <label :for="data.id">      
      <div class="fake-radio-container">
        <input 
          :id="data.id"
          :checked="selected"
          type="radio"
          :name="data.name"
          :value="data.id"
          @click="handleChange"
        >
        <div class="fake-radio" />
      </div>
      <div class="table-row-label">{{ title }}</div>
    </label>
    <div v-if="auxiliarText" class="auxiliar-text" @click="handleClickAuxiliarText">{{ auxiliarText }}</div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'selectedId',
    event: 'change',
  },
  props: {
    titleHeader: {
      type: String,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    selectedId: {
      required: true,
    },
    data: {
      type: Object,
    },
    auxiliarText: {
      type: String,
    },
  },
  computed: {
    title() {
      return this.titleHeader ? `${this.titleHeader} ${this.data.name}` : this.data.name
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', this.selected ? undefined : this.data.id)
    },
    handleClickAuxiliarText() {
      this.$emit('clickAuxiliarText')
    },
  },
}
</script>

<style lang="stylus">
.table-row-container
  display: flex
  align-items: center
  width: 100%

  &:not(:last-child)
    margin-bottom: vw(20px)

  > label
    display: flex
    flex: 1 1 0%
    align-items: center
    margin-right: vw(15px)

    > input[type="radio"]
      margin: 0
      margin-right: vw(15px)

.table-row-label
  m-font-size(14, 20)
  margin-left: vw(10px)

.auxiliar-text
  m-font-size(10, 16)
  margin-left: auto
  text-transform: uppercase
  font-weight: 700
</style>