<template>
  <basic-confirmation-dialog :is-open="isOpen" @close="$emit('close')">
    <template slot="image">
      <img src="../../../assets/logo.png">
    </template>
    <template slot="description">
      <h4>{{ description }}</h4>
    </template>
    <template slot="action-buttons">
      <basic-button :title="btnTitle" @click="$emit('accept')" />
    </template>
  </basic-confirmation-dialog>
</template>

<script>
import BasicButton from '../../basic/BasicButton.vue'
import BasicConfirmationDialog from '../../basic/BasicConfirmationDialog.vue'
export default {
  components: { BasicConfirmationDialog, BasicButton },
  props: {
    isOpen: {
      type: Boolean,
    },
    description: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    btnTitle: {
      type: String,
      required: true,
    },
  },
}
</script>