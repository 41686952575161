<template>
  <div class="pick-up-order">
    <pick-up-card v-for="order in pickUpOrders" :key="order.id" :order="order" />
  </div>
</template>

<script>
import PickUpCard from './PickUpCard'
import { orderListByStatus } from '@/utils/ordersUtils.js'
import { ORDER_STATUS } from '../../../settings/orderSettings'
export default {
  components: { PickUpCard },
  props: {
    orders: {
      type: Array,
      required: true,
    },
  },
  computed: {
    pickUpOrders() {
      //Sort list and shows takeAway first
      return orderListByStatus(ORDER_STATUS.READY.key, this.orders).sort((a, b) =>
        a.takeAway === undefined && b.takeAway !== undefined ? 1 : -1
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
.pick-up-order
  display: flex
  flex: 1 1 0%
  flex-direction: column
  overflow-x: hidden
  overflow-y: auto
</style>