class CashController {
  /**
   *
   * @param {import('axios').AxiosInstance} axiosInstance
   * @param {*} validateResponse
   */
  constructor(axiosInstance, validateResponse) {
    this.instance = axiosInstance
    this.validateResponse = validateResponse
  }
  /**
   *
   * @param {{user:id,cash:number}} param0
   * @returns
   */
  async closeCash(cash) {
    return this.validateResponse(
      await this.instance.post('/restaurants/cash-register/close', cash)
    )
  }
  /**
   *
   * @param {{user:id,cash:number}} param0
   * @returns
   */
  async openCash(cash) {
    return this.validateResponse(
      await this.instance.post('/restaurants/cash-register/open', cash)
    )
  }
}

export default CashController
