<template>
  <div
    class="basic-number-tag-container" 
    :class="{'drop-downable':canDropDown, 'is-open': isOpen, 'inverted-color': invertedColor}"
    @click="onClick"
  >
    <div class="number-tag">{{ value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    canDropDown: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    invertedColor: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  methods: {
    onClick(e) {
      if (this.canDropDown) {
        e.stopPropagation()
        this.$emit('click')
      } else {
        this.$emit('click')
      }
    },
  },
}
</script>

<style lang="stylus">
.basic-number-tag-container
  display: flex
  flex: 0 0 vw(24px)
  justify-content: center
  align-items: center
  margin-top: 0.05em
  width: vw(24px)
  height: vw(24px)
  border-radius: 50%
  background-color: $primary-theme-color
  color: #fff

  &.drop-downable
    &:after
      m-font-size(25)
      position: absolute
      top: 50%
      content: "ˇ"

    &.is-open
      &:after
        content: "^"

  &.inverted-color
    border: solid 1px $primary-theme-color
    border-style: dashed
    background-color: #fff

    .number-tag
      color: $primary-theme-color

  .number-tag
    color: #fff
    m-font-size(14, 14)
    margin-top: -0.1em
</style>