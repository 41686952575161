<template>
  <basic-swipe-actions class="client-payment-swiper" :disabled="disabledSwipe || client.checked">
    <div class="client-payment" :class="{disabled}">
      <div v-if="persons" class="box-wrapper">
        <div class="box-wrapper-icon person" />
        <input
          type="number"
          step="1"
          min="0"
          max="99"
          class="box-wrapper-input"
          :disabled="disabled ||client.checked"
          :value="client.persons"
          @change="$emit('change', { ...client, persons: Number($event.target.value) })"
        >
      </div>
      <slot />
      <div v-if="products!==undefined" class="pick" @click="$emit('click')">{{ products }}</div>
      <div v-if="!editable" class="price">{{ roundedValue.toFixed(2) }}&euro;</div>
      <div v-else class="box-wrapper">
        <div class="box-wrapper-icon euro" />
        <input
          type="number"
          step="0.01"
          min="0"
          class="box-wrapper-input"
          :value="roundedValue"
          :max="maxPrice"
          :disabled="disabled ||client.checked"
          @change="$emit('change', { ...client, toPay: Number($event.target.value) })"
        >
      </div>
      <basic-selector
        class="client-payment-selector"
        :options="optionsSelector"
        :type="SELECTOR_TYPES.SMALL"
        :value="client.payByCard"
        :key-attribute="'id'"
        :disabled="disabled ||client.checked"
        @change="$emit('change', { ...client, payByCard: !client.payByCard })"
      />
      <basic-checkbox
        :value="client.checked"
        :disabled="!checkable || disabled"
        @change="$emit('change', { ...client, checked: Boolean($event) })"
      />
    </div>
    <template #action-1>
      <div class="action-icon delete" @click="$emit('remove',client)" />
    </template>
  </basic-swipe-actions>
</template>

<script>
import BasicCheckbox from '../basic/BasicCheckbox.vue'
import BasicSelector, { SELECTOR_TYPES } from '../basic/BasicSelector.vue'
import BasicSwipeActions from '../basic/BasicSwipeActions.vue'
export default {
  components: { BasicCheckbox, BasicSelector, BasicSwipeActions },
  model: {
    prop: 'client',
    event: 'change',
  },
  props: {
    client: Object,
    persons: Boolean,
    efective: Boolean,
    editable: Boolean,
    products: Number,
    disabledSwipe: Boolean,
    checkable: { type: Boolean, default: true },
    maxPrice: { type: Number },
    disabled: Boolean,
  },
  data() {
    return { SELECTOR_TYPES }
  },
  computed: {
    optionsSelector() {
      return [
        { id: true, label: 'Tarjeta' },
        { id: false, label: 'Efectivo' },
      ]
    },
    roundedValue() {
      return Math.round(this.client.toPay * 100) / 100
    },
  },
}
</script>

<style lang="stylus" scoped>
$size-pick = vw(20px)
$color-pick = $primary-theme-color
$client-height = vw(60px)

.client-payment-swiper
  margin-bottom: vw(10px)
  height: $client-height

  >>> .swipe-actions-over
    padding-right: vw(10px)
    padding-left: vw(10px)
    height: 100%

  >>> .swipe-action
    width: 40px
    height: 40px

  .action-icon
    position: relative
    width: 100%
    height: 100%

    &:before
      position: absolute
      top: 50%
      left: 50%
      display: block
      content: ""
      transform: translate(-50%, -50%)

    &.delete
      background-color: #fff

      &:before
        m-icon("bin", 30px)

.client-payment
  display: flex
  align-items: center
  padding: vw(10px)
  height: 100%
  border: 1px solid rgba(44, 61, 91, 0.15)
  border-radius: 4px

  &.disabled
    background-color: #eee
    opacity: 0.5
    pointer-events: none

  .pick
    m-font-size(12, 14)
    display: flex
    justify-content: center
    align-items: center
    margin-top: 0.1em
    margin-right: vw(10px)
    width: $size-pick
    height: $size-pick
    border-radius: 50%
    background-color: $color-pick
    color: #fff

  .price
    margin-right: auto

  .box-wrapper
    display: flex
    align-items: center
    margin-right: auto
    padding: vw(8px) vw(10px)
    border: none
    border-radius: 4px
    background-color: rgba(#2C3D5B, 0.05)

  .box-wrapper-icon
    margin-right: vw(10px)

    &.euro
      m-icon("euro", 12 16)
      flex: 0 0 vw(12px)

    &.person
      m-icon("user-transparent", 18)
      flex: 0 0 vw(18px)

  .box-wrapper-input
    // m-font-size(18, 24)
    padding: 0
    padding: vw(2px)
    width: 100%
    border: none
    background-color: transparent
    text-align: right
</style>
