<template>
  <ResourceConfigurator
    v-if="orders"
    :edit-label-key="'cms_edit_order'"
    :add-label-key="'cms_add_order'"
    :resources="orders"
    :resource-label-key="'cms_service_order'"
    :fields-info="fieldsInfo"
    :form-fields="formFields"
    :table-values="tableValues"
    :delete-resource-handler="deleteOrderHandler"
    :edit-resource-handler="editOrderHandler"
    :add-resource-handler="addOrderHandler"
    @onStartEditing="r => editingResource = r"
    @onStartAdding="editingResource = null"
  />
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator'
import CmsResourceConfiguratorI18n from '@/mixins/CmsResourceConfiguratorI18n'
import { cmsController } from '../../../services/api'
import { mapActions } from 'vuex'
export default {
  components: { ResourceConfigurator },
  mixins: [CmsResourceConfiguratorI18n],
  data() {
    return {
      editingResource: null,
    }
  },
  computed: {
    fieldsInfo() {
      return [
        ...this.localizedFieldsInfo('cms_name'),
        { type: 'default', name: this.$t('cms_priority') },
      ]
    },
    formFields() {
      return [
        ...this.localizedFormFields(this.editingResource, 'title', 'cms_name'),
        {
          type: 'number',
          label: this.$t('cms_priority'),
          id: 'prior',
          default: this.editingResource ? this.editingResource.prior : 1,
        },
      ]
    },
    tableValues() {
      return this.orders?.map(order => [...this.localizedFieldToRowValues(order, 'title'), order.prior])
    }
  },
  remoteComputed: {
    orders: {
      model: 'restaurant/serviceOrders',
      method: 'get',
    },
  },
  methods: {
    ...mapActions({
      invalidateOrders: 'restaurant/serviceOrders/invalidateAll',
    }),
    deleteOrderHandler({ id }) {
      cmsController.deleteServiceOrderById(id).then(this.invalidateOrders)
    },
    addOrderHandler({ prior, i18n }) {
      return cmsController
        .addServiceOrder(i18n, parseInt(prior))
        .then(this.invalidateOrders)
        .catch(() => console.error('Error adding service order'))
    },
    editOrderHandler({ prior, i18n }) {
      const localesToAdd = i18n.filter(
        ({ restaurantLanguageId }) =>
          !this.editingResource.i18n.find(
            ({ locale }) => restaurantLanguageId === this.getLanguageIdByLocale(locale)
          )
      )
      const localesToEdit = i18n.filter(
        ({ restaurantLanguageId, title }) =>
          this.editingResource.i18n.find(
            ({ locale }) => restaurantLanguageId === this.getLanguageIdByLocale(locale)
          ) &&
          this.editingResource.i18n.find(
            ({ locale }) => restaurantLanguageId === this.getLanguageIdByLocale(locale)
          ).title !== title
      )
      const addNamesPromises = localesToAdd.map(({ restaurantLanguageId, title }) =>
        cmsController.addServiceOrderI18nById(this.editingResource.id, restaurantLanguageId, title)
      )
      let updateOrderPromise = Promise.resolve()
      if (parseInt(prior) !== this.editingResource.prior || localesToEdit.length > 0) {
        updateOrderPromise = cmsController.updateServiceOrderById(
          this.editingResource.id,
          localesToEdit.map(({ restaurantLanguageId, title }) => ({
            id: this.editingResource.i18n.find(
              ({ locale }) => this.getLanguageIdByLocale(locale) === restaurantLanguageId
            ).id,
            title,
          })),
          parseInt(prior)
        )
      }
      return updateOrderPromise
        .then(() => Promise.all(addNamesPromises))
        .then(this.invalidateOrders)
        .catch(() => console.error('Error updating service order'))
    },
  },
}
</script>
<style lang="stylus" scoped></style>