<template>
  <button
    class="basic-button btn btn--primary"
    :type="type"
    :class="{disabled}"
    @click="$emit('click', $event)"
  >
    {{ title }}
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    type: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
}
</script>

<style lang="stylus" scoped>
.basic-button
  width: 100%

  &:not(:only-of-type)
    &:not(:last-child)
      margin-bottom: vw(20px)
</style>
