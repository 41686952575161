<template>
  <div class="boolean-input__container">
    <div :class="['boolean-input__item', {'active': value}]" @click="enable()">
      <div class="boolean-input__button" />
      <div class="boolean-input__text">{{ enabledLabel }}</div>
    </div>
    <div :class="['boolean-input__item', {'active': !value}]" @click="disable()">
      <div class="boolean-input__button" />
      <div class="boolean-input__text">{{ disabledLabel }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Boolean,
    enabledLabel: String,
    disabledLabel: String,
  },
  methods: {
    enable() {
      this.$emit('input', true)
    },
    disable() {
      this.$emit('input', false)
    },
  },
}
</script>
<style lang="stylus" scoped>
.boolean-input__container,
.boolean-input__item
  display: flex
  align-items: center
  gap: vw(10px)

.boolean-input__container
  gap: vw(50px)

.boolean-input__item
  cursor: pointer

  &.active,
  &:hover
    .boolean-input__text
      opacity: 1

  &.active
    cursor: default

    .boolean-input__button
      m-icon("check", 32)

.boolean-input__button
  m-icon("uncheck", 32)

.boolean-input__text
  m-font-size(16, 24)
  opacity: 0.75
</style>