export class HttpError extends Error {
  static UNAUTHORIZED = 401
  static FORBIDEN =  403
  static NOT_FOUND =  404
  
  /**
   * 
   * @param {number} status Status code
   * @param {string} message 
   */
  constructor(status, message, info){
    super(message)
    this.status = status
    this.info = info
  }
}