<template>
  <div class="login-container">
    <div class="login-header">
      <div class="login-logo" />
    </div>
    <div class="login-main">
      <div class="login-welcome">{{ $t("login_wellcome") }}</div>
      <LoginForm />
      <div class="login-footer">Pizzafun</div>
    </div>
  </div>
</template>

<script>
import LoginForm from './LoginForm.vue'
export default {
  components: { LoginForm },
}
</script>

<style lang="stylus" scoped>
.login-container
  display: flex
  flex-direction: column
  padding: vw(30px)
  height: 100%

.login-logo
  width: vw(135px)
  height: vw(135px)
  background-image: url("~@/assets/logo.svg")
  background-position: center center
  background-size: contain
  background-repeat: no-repeat

.login-header
  flex: 1.25 1 0%

.login-main
  display: flex
  flex: 1 1 0%
  flex-direction: column
  justify-content: flex-end

.login-welcome
  m-font-size(50, 65)
  margin-bottom: vw(50px)
  font-weight: 600

.login-footer
  m-font-size(15, 18)
  display: none
  font-weight: 700
</style>