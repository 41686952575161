<template>
  <div :class="['modifier', {'loading': !data}]">
    <template v-if="data">
      <div class="modifier-title">{{ $label(data) }}</div>
      <!-- {{ internalValue }} -->
      <template v-if="!isOptionsUpdating">
        <div v-for="o in options" :key="o.id" class="modifier-option">
          <label class="modifier-option-label" :for="$label(o)+o.id">
            <div class="modifier-option-label-text">{{ $label(o) }}</div>
            <div class="modifier-price">{{ o.price }}€</div> <!-- TODO Add restaurant currency -->
            <div v-if="data.type === 'SINGLE'" class="fake-radio-container">
              <input
                :id="$label(o)+o.id"
                v-model="internalValueSingle"
                :checked="internalValueSingle===o.id"
                :name="data.id"
                :value="o.id"
                type="radio"
                @click="internalValueSingle===o.id && !isRequired ? internalValueSingle=undefined : internalValueSingle=o.id"
              >
              <div class="fake-radio" />
            </div>
            <div v-else class="fake-checkbox-container">
              <input
                :id="$label(o)+o.id"
                v-model="internalValueMulti[o.id]"
                type="checkbox"
              >
              <div class="fake-checkbox" />
            </div>
          </label>
        </div>
      </template>
    </template>
    <template v-else>
      <basic-loading />
    </template>
  </div>
</template>

<script>
import BasicLoading from './basic/BasicLoading.vue'
export default {
  components: { BasicLoading },
  props: ['id', 'value', 'type', 'ingredients'],
  // model:{
  //   prop: 'value',
  //   event: 'change'
  // },
  data() {
    return {
      internalValueSingle: null,
      internalValueMulti: {},
    }
  },
  remoteComputed: {
    data: {
      model: function() {
        return this.type == 'ing' ? 'restaurant/ingredientModifiers' : 'restaurant/optionModifiers'
      },
      method: 'getById',
      params: function() {
        return [this.id]
      },
    },
    options: {
      model: function() {
        return this.type == 'ing' ? 'restaurant/ingredients' : 'restaurant/options'
      },
      method: 'getById',
      params: function() {
        if (this.data) {
          if (this.data.isRemove) {
            return [this.ingredients.map(i => i.id)]
          } else if (this.data.options) return [this.data.options]
          else return []
        }
        return []
      },
      isList: true,
    },
  },
  computed: {
    internalValue() {
      if (this.data?.type === 'SINGLE')
        return this.internalValueSingle ? [this.internalValueSingle] : []
      else return this.convertToArray(this.internalValueMulti)
    },
    isRequired() {
      return this.data && this.data.isRequired
    },
    defaultValue() {
      return (this.data && this.data.default) || this.options[0].id
    },
  },
  watch: {
    //CREO QUE NO, no?!
    // value:{
    //   deep:true,
    //   immediate: true,
    //   handler(){
    //     this.setInternalValue()
    //   }
    // },
    options: {
      immediate: true,
      handler() {
        this.setInternal()
      },
    },
    internalValue: {
      deep: true,
      handler(newValue) {
        this.$emit('change', this.id, newValue, this.type)
      },
      immediate: true,
    },
  },
  methods: {
    convertToArray(dict) {
      return Object.keys(dict).filter(v => dict[v] === true)
    },
    convertToDict(array) {
      return this.options.reduce((res, o) => {
        if (!o) return res //MUY GUARRO ESTO!!!! pero creo qeu solo es culpa de "datos malformados"
        return {
          ...res,
          [o.id]: array.find(id => String(o.id) === String(id)) ? true : false,
        }
      }, {})
    },
    setInternal() {
      if (this.isOptionsUpdating) return
      if (this.data.type === 'SINGLE') this.internalValueSingle = this.value[0] || this.defaultValue
      else this.internalValueMulti = this.convertToDict(this.value)
    },
  },
}
</script>

<style lang="stylus" scoped>
.modifier
  &.loading
    height: 100%

    &:not(:first-child)
      display: none

.modifier-title
  m-font-size(12, 16)
  padding: vw(26px) vw(15px) vw(12px)
  color: #828282
  text-transform: uppercase

.modifier-option
  display: flex
  padding: vw(14px)
  border-bottom: 1px solid #f2f2f2

.modifier-option-label
  m-font-size(16, 20)
  display: flex
  align-items: center
  width: 100%

.modifier-option-label-text
  flex: 1 1 0%
  margin-right: vw(15px)

.modifier-price
  m-font-size(14, 20)
  margin-right: vw(10px)
  color: #828282
</style>