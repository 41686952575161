<template>
  <div :class="['menu-view']">
    <Menu
      class="menu-component"
      :selection-ids="categoryIds"
      :order-id="order.id"
      :search="search"
    />
    <basic-bottom-actions v-if="!userQr || ticketRowsCount > 0" class="show-order-bottom-action">
      <transition-group name="fade-group">
        <basic-input
          v-if="!userQr"
          key="input"
          v-model="search"
          :placeholder="$t('order_menu_search_product')"
        >
          <template #image>
            <transition name="fade">
              <div v-if="search" class="remove-icon" @click="()=>search=''" />
            </transition>
          </template>
        </basic-input>
        <div
          v-if="ticketRowsCount > 0"
          key="btn"
          class="view-order btn btn--primary"
          @click="handleShowOrder"
        >
          {{ $t('see_ticket') }} ({{ ticketRowsCount }})
        </div>
      </transition-group>
    </basic-bottom-actions>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import OrderDataMixin from '@/mixins/OrderDataMixin'
import BasicBottomActions from '../components/basic/BasicBottomActions.vue'
import { mapActions, mapGetters } from 'vuex'
import { NEW_ORDER, ROUTE_NAMES } from '../settings/routeNames'
import BasicInput from '../components/basic/BasicInput.vue'
import { cmsController } from '../services/api'
export default {
  components: { Menu, BasicBottomActions,BasicInput },
  mixins: [OrderDataMixin],
  props: ['categoriesString', 'orderId'],
  data() {
    return {
      search: '',
    }
  },
  asyncComputed: {
    stripeConnectStatus() {
      return cmsController.retrieveStripeAccountStatus(this.userQr ? this.userQr.restaurantId : undefined)
    }
  },
  computed: {
    ...mapGetters({
      userQr: 'userQr/getLogedQrData',
    }),
    ticketRowsCount() {
      return this.order.rows.length
    },
    categoryIds() {
      return (this.categoriesString || '').split('_')
    },
    showBottomActions() {
      return (
        this.ticketRowsCount > 0 && (!this.userQr || (this.stripeConnectStatus && this.stripeConnectStatus.connectStatus === 'stripe_connect_status_verified'))
      )
    }
  },
  watch: {
    $route() {
      this.search = ''
    },
  },
  mounted() {
    this.startOrderIfNeeded()
  },
  methods: {
    ...mapActions({
      addTableSetup: 'newOrder/addTableSetup',
    }),
    async handleShowOrder() {
      const orderId = this.order.id
      const tableId = this.order.table?.id
      if (this.isActive) {
        await this.orderDispatch('invalidate', orderId)
      }
      // Order ya creada y entrando como camarero
      if (!this.userQr && !this.isNew && this.order && tableId){
        this.$router.replace({
          name: ROUTE_NAMES.TABLE,
          params: { tableId: tableId, orderId: this.order.id },
        })
      }
      else{
        this.$router.replace({
          name: ROUTE_NAMES.ORDER,
          params: { orderId: this.isNew ? NEW_ORDER : orderId },
        })
      }
    },
    startOrderIfNeeded() {
      //If access by QR (Client)
      if (this.userQr) {
        this.addTableSetup({
          id: this.userQr.tableId,
          totalCustomersAtTable: 1,
        })
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.menu-view
  position: fixed
  top: $header-height
  left: 0
  display: flex
  flex: 1 1 0%
  flex-direction: column
  justify-content: space-between
  overflow: hidden
  width: 100%
  height: "calc(100% - %s)" % $header-height
  background-color: #fff
  .menu-component
    flex 0 1 100%
    overflow: hidden
  
  .is-client &
    top: 0
    height: 100%

  >>> .header
    .main-category
      margin-bottom: vw(20px)

  >>> .product-content
    margin-top: 0

  .show-order-bottom-action
    position static
    .remove-icon
      m-icon("close", 20)

      &.fade-enter-active,
      &.fade-leave-active
        transition: opacity 0.3s ease

      &.fade-enter,
      .fade-leave-to,
        opacity: 0

.fade-group-item
  display: inline-block
  margin-right: 10px

.fade-group-enter-active,
.fade-group-leave-active
  transition: all .3s

.fade-group-enter,
.fade-group-leave-to /* .list-leave-active below version 2.1.8 */
  opacity: 0
  transform: translateY(10px)
</style>