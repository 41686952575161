<template>
  <div class="basic-collapsable-bar" :class="{dark: isDark}" @click="$emit('collapse', !collapsed)">
    <div class="basic-collapsable-bar-title">{{ title }}</div>
    <div class="align-right">
      <div v-if="rightText" class="right-text">{{ rightText }} </div>
      <div :class="['basic-collapsable-bar-chevron', {'down': collapsed}]" />
    </div>
  </div>
</template>

<script>
//  preparation_zone_order_name: "Orden",
//  preparation_zone_order_num_table: "Nº de Mesa",
export default {
  model: {
    prop: 'collapsed',
    event: 'collapse',
  },
  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    rightText: {
      type: String,
      required: false,
    },
    isDark: {
      type: Boolean,
    },
  },
}
</script>

<style lang="stylus" scoped>
.basic-collapsable-bar
  display: flex
  align-items: center
  padding: vw(10px) vw(15px)

  &.dark
    background-color: $primary-theme-color
    color: #fff

    .basic-collapsable-bar-title,
    .right-text
      m-font-size(14, 20)
      font-weight: 400

    .basic-collapsable-bar-chevron
      m-icon("chevron-down-white", 20)
      // transition: none

  .align-right
    display: flex
    align-items: center
    margin-left: auto

    .right-text
      margin-right: vw(8px)

.basic-collapsable-bar-title
  m-font-size(12, 16)
  flex: 1 1 0%
  margin-right: vw(15px)
  text-transform: uppercase

.basic-collapsable-bar-chevron
  m-icon("chevron-down", 32)
  transition: transform 0.25s ease-in-out

  &.down
    transform: rotate(180deg)
</style>