<template>
  <div class="quantity-selector">
    <div class="less-button" :class="{trashcan:lessIsRemove, disabled}" @click.stop="substract" />
    <div class="quantity">{{ internalValue }}</div>
    <div class="more-button" :class="{disabled}" @click.stop="add" />
  </div>
</template>

<script>
export default {
  model: {
    prop: 'quantity',
    event: 'change',
  },
  props: ['quantity', 'zeroIsRemove', 'disabled'],
  computed: {
    lessIsRemove() {
      if (this.internalValue === 1) return this.zeroIsRemove
      else return false
    },
    internalValue: {
      get() {
        return this.quantity
      },
      set(val) {
        this.$emit('change', val)
      },
    },
  },
  methods: {
    substract() {
      if (!this.disabled) this.internalValue = Math.max(0, this.internalValue - 1)
    },
    add() {
      if (!this.disabled) this.internalValue = this.internalValue + 1
    },
  },
}
</script>

<style lang="stylus">
.quantity-selector
  display: flex
  align-items: center
  color: $primary-theme-color

  .more-button
    m-icon("add", 40)

    &.disabled
      opacity: 0.5

  .less-button
    m-icon("remove", 40)

    &.disabled
      opacity: 0.5

    &.trashcan
      m-icon("bin", 24)

  .quantity
    m-font-size(16, 20)
    flex: 0 0 vw(20px)
    margin-top: 0.25em
    margin-right: vw(10px)
    margin-left: vw(10px)
    width: vw(20px)
    height: vw(20px)
    text-align: center
    font-weight: 600
</style>