<template>
  <div class="basic-selector-container">
    <div class="fake-select basic-selector" :class="[type]">
      <select :id="id" :value="value" @change.stop="(e) => handleChange(e.target.value)">
        <option v-if="nullable" :value="null">{{ selectOptionLabel }}</option>
        <option v-for="option in options" :key="option[keyAttribute]" :value="option[keyAttribute]">{{ getLabel(option) }}</option>
      </select>
      <span class="focus" />
    </div>
    <!-- <hr v-if="type === SELECTOR_TYPES.SMALL"> -->
  </div>
</template>

<script>
/**
 * options -> Object Array
 * value -> Selected value
 * keyAtribute -> Object id property
 */
export const SELECTOR_TYPES = {
  BIG: 'big',
  SMALL: 'small',
}
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    id: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [Number, String, Boolean],
    },
    keyAttribute: {
      type: String,
      required: true,
    },
    type: {
      type: SELECTOR_TYPES.keyAttribute,
      default: SELECTOR_TYPES.SMALL,
    },
    isI18n: {
      type: Boolean,
    },
    nullable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SELECTOR_TYPES,
    }
  },
  computed: {
    selectOptionLabel() {
      return `- ${this.$t('select_option')} -`
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value)
    },
    getLabel(option) {
      if (this.isI18n) {
        return this.$label(option)
      }
      return option.label ? option.label : option.name
    },
  },
}
</script>

<style lang="stylus" scoped></style>