<template>
  <div class="box-info test" :class="{disabled}">
    <div class="income-title">{{ $t(label) }}</div>
    <div class="income-value">{{ amount }}</div>
    <div
      class="income-variation"
      :class="{'income-variation--negative': variation < 0}"
    >
      <span v-if="variation">{{ `${variation > 0 ? '+' : ''}${variation}%` }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    amount: [String, Number],
    variation: [Number],
    disabled: Boolean,
  },
}
</script>

<style lang="stylus" scoped>
.box-info
  display: flex
  flex-direction: column
  padding: vw(10px) vw(20px)
  min-width: vw(200px)
  border: 1px solid $gray-5
  border-radius: 4px

  &.disabled
    opacity: 0.6

  .income-title
    m-font-size(14, 20)
    margin-bottom: vw(5px)
    line-height: vh(20px)

  .income-value
    m-font-size(26, 30)
    margin-top: auto
    font-weight: 700

  .income-variation
    m-font-size(12, 16)
    margin-top: vw(5px)
    height: vw(16px)
    color: #6FCF97

  .income-variation--negative
    color: #EB5757
</style>