<template>
  <div class="order-card-container" :class="{ blink }">
    <basic-collapse-component
      v-model="collapsed"
      :title="title"
      :is-dark="true"
      :right-text="numberOfProductsLabel + ' ' + diferentTime"
    >
      <div
        v-for="serviceOrderId in Object.keys(ordersOrderedByServiceOrder)"
        :key="serviceOrderId"
        class="service-order-content"
      >
        <div class="service-order-header">
          <div v-if="getServiceOrderById(serviceOrderId)">
            {{ $label(getServiceOrderById(serviceOrderId)) }}
          </div>
          <basic-circular-check-button
            class="check"
            :loading="loading"
            @click="handleArrayChangeStatus(ordersOrderedByServiceOrder[serviceOrderId])"
          />
        </div>
        <order-row-group
          v-for="(row, index) in ordersOrderedByServiceOrder[serviceOrderId]"
          :key="index"
          :data="row"
          :order="order"
          :is-recovery="isRecovery"
        />
      </div>
    </basic-collapse-component>

    <!-- <basic-collapsable-bar 
      v-model="collapsed"
      :title="title"
      :is-dark="true"
      :right-text="numberOfProductsLabel"
    />
    <div v-if="!collapsed" class="order-card-content">
      <div v-for="serviceOrderId in Object.keys(ordersOrderedByServiceOrder)" :key="serviceOrderId" class="service-order-content">
        <div class="service-order-header">
          <div v-if="getServiceOrderById(serviceOrderId)">{{ $label(getServiceOrderById(serviceOrderId)) }}</div>
          <basic-circular-check-button class="check" :loading="loading" @click="handleArrayChangeStatus(ordersOrderedByServiceOrder[serviceOrderId])" />
        </div>
        <order-row-group
          v-for="(row, index) in ordersOrderedByServiceOrder[serviceOrderId]"
          :key="index"
          :data="row"
          :order="order"
          :is-recovery="isRecovery"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrderListDataMixin from '../../mixins/OrderListDataMixin'
import TimerMixin from '../../mixins/TimerMixin'

import { orderController } from '../../services/api'
import { ORDER_TYPE } from '../../settings/orderSettings'
import { getServiceZoneTableById } from '../../utils/serviceZonesUtils'
import BasicCircularCheckButton from '../basic/BasicCircularCheckButton.vue'
// import BasicCollapsableBar from '../basic/BasicCollapsableBar.vue'
import BasicCollapseComponent from '../basic/BasicCollapseComponent.vue'
import { getMergedTicketRowsWithChildren } from './../../utils/ticketUtils'
import OrderRowGroup from './OrderRowGroup.vue'
export default {
  components: {
    OrderRowGroup,
    // BasicCollapsableBar,
    BasicCollapseComponent,
    BasicCircularCheckButton,
  },
  mixins: [OrderListDataMixin, TimerMixin],
  props: {
    order: {
      type: Object,
      required: true,
    },
    isRecovery: {
      type: Boolean,
      default: false,
    },
    blink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapsed: false,
      loading: false,
    }
  },
  remoteComputed: {
    serviceOrders: {
      model: 'restaurant/serviceOrders',
    },
    serviceZones: {
      model: 'restaurant/serviceZones',
      method: 'get',
      params: function () {
        return [this.logedUser.restaurantId]
      },
    },
  },

  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
      getServiceOrderById: 'restaurant/serviceOrders/getById',
    }),
    firstTime() {
      const firstRowOrder = new Date(
        this.order.rows.reduce((min, row) => Math.min(row.created, min), new Date().getTime())
      )
      return firstRowOrder
    },
    diferentTime() {
      return this.getDiferentTime(this.firstTime)
    },
    ordersOrderedByServiceOrder() {
      const ordersMapped = {}
      this.orderRowsMerged.forEach(order => {
        const serviceOrderId = order.representant.serviceOrderId
        if (!ordersMapped[serviceOrderId]) ordersMapped[serviceOrderId] = [order]
        else ordersMapped[serviceOrderId].push(order)
      })
      return this.sortServiceOrdersMappedByPrior(ordersMapped)
    },
    orderRowsMerged() {
      return getMergedTicketRowsWithChildren(this.order.rows, ['restaurantDiscountId'])
    },
    table() {
      if (this.order.type === 'NO_TABLE') {
        return null
      }
      return getServiceZoneTableById(this.serviceZones, this.order.table.id)
    },
    title() {
      let titleHeader = `${this.$t('preparation_zone_order_name')} #${this.order.id} · `
      if (this.order.type === ORDER_TYPE.TAKE_AWAY.key)
        return `${titleHeader} ${this.$t('preparation_zone_take_away')}`
      if (this.order.isClient)
        return `${titleHeader} ${this.$t('preparation_zone_qr_client')} · ${
          this.table ? this.table.completeName : ''
        }`
      if (this.order.type === 'NO_TABLE') {
        return `${titleHeader}${this.$t('preparation_zone_order_num_table')}: ${this.$t(
          'no_table'
        )}`
      }
      return `${titleHeader}${this.$t('preparation_zone_order_num_table')}: ${
        this.table ? this.table.completeName : ''
      } · ${this.$t('preparation_zone_customers')}: ${this.order.table.totalCustomersAtTable}`
    },
    numberOfProductsLabel() {
      let countProducts = 0
      Object.values(this.ordersOrderedByServiceOrder).forEach(groups => {
        groups.forEach(group => {
          countProducts += group.children.length
        })
      })
      return `${countProducts} ${this.$t('preparation_zone_products_right_text')}`
    },
  },
  methods: {
    sortServiceOrdersMappedByPrior(serviceOrdersMapped) {
      const orderResult = Object.keys(serviceOrdersMapped)
        .sort(async (a, b) => {
          const serviceOrderA = await this.getServiceOrderById(a)
          const serviceOrderB = await this.getServiceOrderById(b)
          return serviceOrderA?.prior - serviceOrderB?.prior
        })
        .reduce((accumulator, current) => {
          accumulator[current] = serviceOrdersMapped[current]
          return accumulator
        }, {})
      return orderResult
    },
    async handleArrayChangeStatus(data) {
      this.loading = true
      await orderController.patchOrderMergedRowsStatus(
        this.orderById(this.order.id),
        data,
        this.isRecovery
      )
      this.loading = false
    },
  },
}
</script>

<style lang="stylus" scoped>
.blink
  animation: blinker 1.5s ease-in-out infinite

@keyframes blinker
  50%
    opacity: 0.25

.order-card-container
  margin-bottom: vw(6px)
  background-color: #f2f2f2

  .order-card-content
    padding: vw(10px) vw(15px)

  .service-order-content
    &:not(:last-child)
      margin-bottom: vw(10px)

  .service-order-header
    m-font-size(14, 20)
    display: flex
    align-items: center
    padding: vw(10px)
    width: 100%

    .check
      margin-left: auto
</style>