<template>
  <basic-dialog :is-open="isOpen" :closeable="true" @close="$emit('close')">
    <template #dialog-body>
      <transition name="fade">
        <basic-loading v-if="loading" />
        <div v-else-if="success">
          <div v-if="!sendEmail" class="basic-confirmation-dialog">
            <div class="icon-successful-order" />
            <div class="successful-order-text">{{ description }}</div>
            <div class="successful-order-order-number">{{ title }}</div>
            <basic-button :title="$t('order_ticket_button')" @click="handleViewTicket" />
            <basic-button :title="$t('send_invoice')" @click="sendEmail=true" />
          </div>
          <div v-else class="basic-confirmation-dialog full-width">
            <form @submit.prevent="handleSendInvoice">
              <basic-input
                v-model="email"
                type="email"
                required
                :title="$t('email')"
                :placeholder="$t('email')"
              />
              <div class="basic-confirmation-buttons-container">
                <basic-button type="submit" :title="$t('send')" />
                <basic-button :title="$t('back')" @click="sendEmail=false" />
              </div>
            </form>
          </div>
        </div>
        <div v-else class="payment-error">
          <p>{{ $t('payment_error') }}</p>
          <p v-if="paymentErrorCode">{{ paymentErrorCode }}</p>
        </div>
      </transition>
    </template>
  </basic-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { orderController } from '../services/api'
import { ROUTE_NAMES } from '../settings/routeNames'
import BasicButton from './basic/BasicButton.vue'
import BasicDialog from './basic/BasicDialog.vue'
import BasicInput from './basic/BasicInput.vue'
import BasicLoading from './basic/BasicLoading.vue'
export default {
  components: { BasicDialog, BasicLoading, BasicButton, BasicInput },
  props: {
    isOpen: { type: Boolean, required: true },
    loading: { type: Boolean, required: false },
    success: { type: Boolean, required: true },
    paymentErrorCode: { type: String },
    title: { type: [String, Number], required: true },
    description: { type: String, required: true },
    orderId: { type: [Number, String] },
  },
  data() {
    return {
      sendEmail: false,
      email: '',
    }
  },
  computed: {
    ...mapGetters({
      userQr: 'userQr/getLogedQrData',
    }),
  },
  methods: {
    handleSendInvoice() {
      orderController
        .sendEmailWithPurchase(this.orderId, this.email)
        .catch(err => console.error(err))
        .finally(() => {
          this.sendEmail = false
        })
    },
    handleViewTicket() {
      if (this.userQr) {
        this.$router.push({
          name: ROUTE_NAMES.INVOICE,
          orderId: this.orderId,
          query: { isCurrentOrder: true },
        })
      } else {
        this.$router.replace({
          name: ROUTE_NAMES.INVOICE,
          orderId: this.orderId,
          query: { isCurrentOrder: true },
        })
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.fade-enter-active,
.fade-leave-active
  transition: opacity 0.5s

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */
  opacity: 0

.basic-confirmation-dialog
  display: flex
  flex-direction: column
  align-items: center

  &.full-width
    width: 100%

  form
    width: 100%

.basic-input-container
  margin-top: vw(30px)

.basic-confirmation-buttons-container
  display: flex
  align-items: center

  > button
    margin-bottom: 0 !important

    &:not(:last-child)
      margin-right: vw(20px)
</style>