<template>
  <div class="order-row-group">
    <order-row
      v-model="collapsed"
      :data="data.representant"
      :collapsable="true"
      @check="() => handleChangeStatus(data)"
    />
    <div v-if="data.children.length > 1 && !collapsed" class="collapsed-row">
      <order-row
        v-for="(child, indexChild) in data.children"
        :key="indexChild"
        :data="child"
        @check="() => handleChangeStatus(child)"
      />
    </div>
  </div>
</template>

<script>
import OrderListDataMixin from '../../mixins/OrderListDataMixin'
import { orderController } from '../../services/api'
import OrderRow from './OrderRow.vue'

export default {
  components: { OrderRow },
  mixins: [OrderListDataMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    isRecovery: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      collapsed: true,
    }
  },
  methods: {
    handleChangeStatus(data) {
      orderController.patchOrderMergedRowsStatus(
        this.orderById(this.order.id),
        data,
        this.isRecovery
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
.order-row-group
  margin: vw(10px)

  .collapsed-row
    margin-left: auto
    width: 90%
</style>