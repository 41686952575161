<template>
  <div ref="el" class="line-chart">
    <svg ref="svg" />
  </div>
</template>

<script>
import { LineChart } from './LineChart'
import * as d3 from '../../utils/importsD3'
import resizeMixin from '../../mixins/resizeMixin'
import { cmsController } from '@/services/api'
export default {
  mixins: [resizeMixin],
  asyncComputed: {
    async chartData1() {
      return cmsController.getGraphInfo(this.from, this.to)
    },
    async chartData2() {
      return cmsController.getGraphInfo(this.prevYearFrom, this.prevYearTo)
    },
  },
  props: {
    from: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
  computed: {
    today() {
      return new Date()
    },
    prevYearTo() {
      return this.substractYear(this.to)
    },
    prevYearFrom() {
      return this.substractYear(this.from)
    },
    data() {
      return [this.chartData1 ?? [], this.chartData2 ?? []].reduce(
        (array, chart, i) =>
          array.concat(
            chart
              .map(obj => {
                const day = new Date(obj.day)
                day.setFullYear(day.getFullYear() + i)
                return { day, y: obj.sales, z: i }
              })
              .sort((a, b) => a.day.getTime() - b.day.getTime())
          ),
        []
      )
    },
  },
  watch: {
    data() {
      this.draw()
    },
    size() {
      this.draw()
    },
  },
  mounted() {
    this.draw()
  },
  methods: {
    substractYear(date) {
      const temp = date.split('-')
      temp[2] = Number(temp[2] - 1).toString()
      return temp.join('-')
    },
    draw() {
      const fromSplit = this.from.split('-')
      const toSplit = this.to.split('-')
      const firstDay = new Date(`${fromSplit[1]}-${fromSplit[0]}-${fromSplit[2]}`)
      const lastDay = new Date(`${toSplit[1]}-${toSplit[0]}-${toSplit[2]}`)
      LineChart(this.$refs.svg, this.data, {
        scaleX: d3.scaleUtc,
        width: this.size.width,
        height: this.size.height,
        z: e => e.z,
        x: e => e.day,
        zStyle: e => {
          return e == 1 ? { 'stroke-dasharray': '5,5' } : {}
        },
        xDomain: [firstDay, lastDay],
        tooltip: e => e.y + '€',
      })
    },
  },
}
</script>

<style>
.linw-chart {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
svg {
  width: 100%;
  height: 100%;
}
</style>