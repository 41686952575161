import { ORDER_STATUS, INDEX_OF_ORDERS } from '../settings/orderSettings'

function computeMergingKey(ticketRow, disableKeys) {
  let key = ''
  if (!disableKeys || !disableKeys['productId']) {
    key += ticketRow.productId + '_'
  }
  if (!disableKeys || !disableKeys['ingredientModifiers']) {
    key += ticketRow.ingredientModifiers.map(o => o.id + '$' + o.value.join('_')).join('_') + '_'
  }
  if (!disableKeys || !disableKeys['optionModifiers']) {
    key += ticketRow.optionModifiers.map(o => o.id + '$' + o.value.join('_')).join('_') + '_'
  }
  if (!disableKeys || !disableKeys['serviceOrderId']) {
    key += ticketRow.serviceOrderId + '_'
  }
  if (!disableKeys || !disableKeys['restaurantDiscountId']) {
    key += ticketRow.restaurantDiscountId + '_'
  }
  if (!disableKeys || !disableKeys['status']) {
    key += ticketRow.status + '_'
  }
  if (!disableKeys || !disableKeys['comment']) {
    if (ticketRow.comment) {
      key += ticketRow.comment.replace(' ', '') + '_'
    }
  }
  if (!disableKeys || !disableKeys['unitPrice']) {
    if (ticketRow.unitPrice) {
      key += ticketRow.unitPrice + '_'
    }
  }
  return key
}
/**
 * @typedef MergedRow
 * @property {String} id
 * @property {T[]} children
 * @property {T & {quantity:Number,haceComment:boolean}}  representant
 *
 * @template T
 */
/**
 *
 * @param {T[]} ticketRows
 * @template T
 * @return {MergedRow<T>}
 */
function _mergeTicketRows(ticketRows, disableKeys) {
  let out = Object.values(
    ticketRows.reduce((dict, row) => {
      let key = computeMergingKey(row, disableKeys)
      if (!dict[key])
        dict[key] = {
          id: key,
          children: [],
          representant: {
            ...JSON.parse(JSON.stringify(row)),
            id: 'mrg', //row.id.toString(),
            quantity: 0,
            haveComment: false,
            haveTicketRowAlertComment: false,
            haveTicketRowDiscount: false,
            orderOfTicket: row.orderOfTicket,
          },
        }
      dict[key].representant.quantity += 1
      dict[key].representant.id += `-${row.id}`
      if (!dict[key].representant.originalIds) {
        dict[key].representant.originalIds = []
      }
      dict[key].representant.originalIds = [...dict[key].representant.originalIds, row.id]
      dict[key].children.push(row)
      if (row.comment) dict[key].representant.haveComment = true
      if (row.ticketRowAlertComment || row.ticketRowAlertId)
        dict[key].representant.haveTicketRowAlertComment = true
      if (row.restaurantDiscountId) dict[key].representant.haveTicketRowDiscount = true
      return dict
    }, {})
  )
  // console.log('out', out)
  return out
}
export function mergeTicketRows(ticketRows) {
  let groups = getMergedTicketRows(ticketRows)
  if (groups.length > 1) throw 'Those rows are not mergable!'
  else return groups[0]
}

export function mergeTicketRowsWithChildren(ticketRows) {
  let groups = getMergedTicketRowsWithChildren(ticketRows)
  if (groups.length > 1) throw 'Those rows are not mergable!'
  else return groups[0]
}
/**
 *
 * @param {T} ticketRows
 * @return {MergedRow<T extends any ? {} : T>["representant"][]}
 * @template T
 */
export function getMergedTicketRows(ticketRows) {
  return _mergeTicketRows(ticketRows, ['comment']).map(group => group.representant)
}

export function getMergedTicketRowsWithChildren(ticketRows, disableKeys) {
  return _mergeTicketRows(ticketRows, disableKeys)
}

export function getNextOrderStatus(order) {
  const nextStatus = ORDER_STATUS[order.status].nextStatus
  return nextStatus
}

export function getPreviousOrderStatus(order) {
  const currentStatus = ORDER_STATUS[order.status].key
  const previousStatus = Object.values(ORDER_STATUS).find(st => {
    return st.nextStatus === currentStatus
  })
  return previousStatus.key
}

export function parseCurrency(currency) {
  switch (currency) {
    case 'eur':
      return '€'
    default:
      return '$'
  }
}

export function getCommitedStatus(order, isRecovery) {
  let status = null
  if (isRecovery && order.status.toUpperCase() === ORDER_STATUS.READY.key.toUpperCase()) {
    status = getPreviousOrderStatus(order)
  } else {
    status = getNextOrderStatus(order)
  }
  return status
}

export function sortTicketRows(ticketRows) {
  let sorted = [...ticketRows]
  sorted.sort((b, a) => {
    a = a.representant || a
    b = b.representant || b
    if (INDEX_OF_ORDERS[a.orderOfTicket] > INDEX_OF_ORDERS[b.orderOfTicket]) return 1
    else if (INDEX_OF_ORDERS[a.orderOfTicket] < INDEX_OF_ORDERS[b.orderOfTicket]) return -1
    else {
      if (new Date(a.created) < new Date(b.create)) return 1
      else if (new Date(a.created) > new Date(b.create)) return -1
      else {
        if (a.id < b.id) return 1
        else return -1
      }
    }
  })
  // console.log(
  //   'sorted rows',
  //   sorted.map(
  //     (r) => r.representant.orderOfTicket + ' ' + r.representant.created
  //   )
  // )
  return sorted
}
