<template>
  <div class="cms-service-zones">
    <ResourceConfigurator
      v-if="serviceZones"
      :edit-label-key="'cms_edit_zone'"
      :add-label-key="'cms_add_zone'"
      :resources="serviceZones"
      :resource-label-key="'cms_service_zones'"
      :fields-info="fieldsInfo"
      :form-fields="formFields"
      :table-values="tableValues"
      :delete-resource-handler="deleteZoneHandler"
      :add-resource-handler="addZoneHandler"
      :cell-click-handler="cellClickHandler"
      :edit-resource-handler="editZoneHandler"
      @onStartEditing="r => editingResource = r"
      @onStartAdding="editingResource = null"
    />
  </div>
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator'
import { cmsController } from '../../../services/api'
import { mapActions } from 'vuex'
export default {
  components: { ResourceConfigurator },
  data() {
    return {
      editingResource: null
    }
  },
  computed: {
    fieldsInfo() {
      return [
        { type: 'default', name: this.$t('cms_name'), id: 'name' },
        { type: 'default', name: this.$t('cms_prefix'), id: 'prefix' },
        { type: 'boolean', name: this.$t('cms_active'), id: 'isVisible' },
      ]
    },
    formFields() {
      return [
        {
          type: 'string',
          label: this.$t('cms_name'),
          id: 'name',
          default: this.editingResource ? this.editingResource.name : null,
        },
        {
          type: 'string',
          label: this.$t('cms_prefix'),
          minLength: 2,
          maxLength: 10,
          id: 'prefix',
          default: this.editingResource ? this.editingResource.prefix : null,
        },
        {
          type: 'boolean',
          label: this.$t('cms_active'),
          id: 'isVisible',
          enabledLabel: this.$t('cms_active'),
          disabledLabel: this.$t('cms_not_active'),
          default: (this.editingResource && this.editingResource.isVisible) || false,
        },
      ]
    },
    tableValues() {
      return this.serviceZones?.map(zone => [zone.name, zone.prefix, zone.isVisible])
    }
  },
  remoteComputed: {
    serviceZones: {
      model: 'cms/serviceZones',
      method: 'get',
    },
  },
  methods: {
    ...mapActions({
      invalidateZones: 'cms/serviceZones/invalidateAll',
    }),
    cellClickHandler(fieldIndex, resource) {
      if (this.fieldsInfo[fieldIndex].type !== 'boolean') {
        return
      }
      const fieldId = this.fieldsInfo[fieldIndex].id
      this.editZoneHandler({ [fieldId]: !resource[fieldId] }, resource)
    },
    deleteZoneHandler({ id }) {
      return cmsController.deleteResourceById('service-zones', id).then(this.invalidateZones)
    },
    addZoneHandler(zone) {
      return cmsController
        .addResource('service-zones', { ...zone, tableGroup: [] })
        .then(this.invalidateZones)
    },
    editZoneHandler(zone) {
      return cmsController
        .updateResourceById('service-zones', this.editingResource.id, zone)
        .then(this.invalidateZones)
    },
  },
}
</script>
<style lang="stylus" scoped></style>