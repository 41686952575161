export function getServiceZoneTableById(serviceZones, tableId) {
  if (!serviceZones) return null
  const tables = serviceZones.reduce((accumulator, current) => {
    current.tableGroup.forEach(tableGroup => {
      accumulator.push(tableGroup.tables)
      accumulator.flat()
    })
    return accumulator.flat()
  }, [])
  const table = tables.find(table => table.id === tableId)
  return table
}

export function getTableGroupByTable(serviceZones, tableId) {
  if (!serviceZones) return null
  const tableGroups = serviceZones.reduce((accumulator, current) => {
    current.tableGroup.forEach(tableGroup => {
      accumulator.push(tableGroup)
      accumulator.flat()
    })
    return accumulator.flat()
  }, [])
  return tableGroups.find(group => group.tables.find(table => table.id === tableId))
}
