import { mapActions, mapGetters } from 'vuex'
export default {
  watch: {
    restaurant: {
      handler() {
        this.setRestaurantTopic()
      },
      immediate: true,
    },
  },
  remoteComputed: {
    restaurant: {
      model: 'restaurant/restaurant',
      method: 'get',
      params: function() {
        return this.logedUser ? [this.logedUser.restaurantId] : []
      },
    },
  },
  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
    }),
  },
  methods: {
    ...mapActions({
      setTopicAction: 'orders/setTopicAction',
    }),
    setRestaurantTopic() {
      if (this.logedUser && this.restaurant && this.restaurant.topic) {
        this.setTopicAction(this.restaurant.topic)
      }
    },
  },
}
