<template>
  <div class="orders-column-container">
    <div class="orders-column-header">
      <div class="orders-column-title">{{ title }}</div>
      <div class="order-icon" @click="sort" />
    </div>
    <div class="cards-container">
      <order-card
        v-for="(order, index) in filteredOrders"
        :key="index"
        :order="order"
        :blink="blinking.includes(order.id)"
        :is-recovery="isRecovery"
      />
    </div>
  </div>
</template>

<script>
import {
  orderListByAttributes,
  //orderListByStatus
} from '../../utils/ordersUtils'
import OrderCard from './OrderCard.vue'

export default {
  components: { OrderCard },
  props: {
    orders: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    status: {
      type: Object,
      required: true,
    },
    isRecovery: {
      type: Boolean,
    },
  },
  data() {
    return {
      descOrder: true,
      blinking: [],
    }
  },
  computed: {
    filteredOrders() {
      return orderListByAttributes({ status: [this.status.key] }, this.orders).sort((a, b) => {
        if (a.created > b.created) {
          return this.descOrder ? 1 : -1
        } else {
          return this.descOrder ? -1 : 1
        }
      })
    },
  },
  watch: {
    orders(newVal, oldVal) {
      if (!oldVal || oldVal.length === 0) {
        return
      }
      const newOrders = newVal
        .filter(({ id }) => !oldVal.find(order => order.id === id))
        .map(({ id }) => id)
      this.blinking = [...this.blinking, ...newOrders]
      setTimeout(() => {
        this.blinking = this.blinking.filter(id => !newOrders.includes(id))
      }, 5000)
    },
  },
  methods: {
    sort() {
      this.descOrder = !this.descOrder
    },
  },
}
</script>

<style lang="stylus" scoped>
.orders-column-container
  display: flex
  flex: 1 1 0%
  flex-direction: column
  overflow: hidden

  &:not(:first-child)
    margin-left: vw(20px)

  .cards-container
    overflow-y: auto
    height: 100%

.orders-column-header
  display: flex
  gap: vw(5px)
  margin-bottom: vw(10px)

.orders-column-title
  m-font-size(18, 24)

.order-icon
  cursor: pointer
  m-icon("swap-vertical", 20)
  transform: rotate(90deg)
</style>