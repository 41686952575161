import axios from 'axios'
import store from '../../store'
import { HttpError } from '../../utils/HttpError'
import CmsController from './controllers/CmsController'
import CashController from './controllers/CashController'
import OrderController from './controllers/OrderController'
import handleError from './handleError'

let instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export const loginInterceptor = function(config) {
  if (config.url.indexOf('./fake_data') >= 0) config.baseURL = ''
  const user = store.getters['user/getLogedUser']
  const uuid = store.getters['userQr/getUuid']
  if (user && user.token) config.headers.Authorization = `Bearer ${user.token}`
  else if (uuid) config.headers.Authorization = uuid
  else config.headers.Authorization = null
  return config
}

instance.interceptors.request.use(loginInterceptor, function(error) {
  return error
})
instance.interceptors.response.use(function(config) {
  return config
}, handleError)

function validateResponse(response) {
  if (response.status >= 200 && response.status < 300) {
    return response.data
  }
  throw new HttpError(response.status, response.statusText, response.message)
}

export const orderController = new OrderController(instance, validateResponse)
export const cashController = new CashController(instance, validateResponse)
export const cmsController = new CmsController(instance, validateResponse)

//User
export async function login(username, password) {
  const response = await instance.post('/login', { username, password })
  return validateResponse(response)
}

export async function patchUser(body) {
  const response = await instance.patch('/users', body)
  return validateResponse(response)
}

export async function getRestaurantById(id) {
  const response = await instance.get(`restaurants/${id}`)
  return validateResponse(response)
}

export async function getLanguages() {
  const response = await instance.get('restaurants/languages')
  return validateResponse(response)
}

export async function getCategories() {
  const response = await instance.get('categories')
  return validateResponse(response)
}

export async function getProducts() {
  const response = await instance.get('products')
  return validateResponse(response)
}

//Card
export function getMenu(restaurantId) {
  return instance.get(`/restaurants/${restaurantId}/categories`).then(response => {
    return { children: response.data }
  })
  // .get('./fake_data/menu.json')
  // .then(response => {
  //   return new Promise(res => {
  //     setTimeout(() => {
  //       res({ children: response.data })
  //     }, Math.random() * 500)
  //   })
  // })
}

export function getIngredientModifiers(restaurantId) {
  return instance.get(`/restaurants/${restaurantId}/ingredient-modifiers`).then(response => {
    return response.data
  })
  // .get('./fake_data/ingredientModifiers.json')
  // .then(response => {
  //   return new Promise(res => {
  //     setTimeout(() => {
  //       res(response.data)
  //     }, Math.random() * 500)
  //   })
  // })
}

export function getIngredients(restaurantId) {
  return instance.get(`/restaurants/${restaurantId}/ingredients`).then(response => {
    return response.data
  })
  // return (
  //   instance
  //     // .get(process.env.VUE_APP_API_URL + `/menu??`)
  //     .get('./fake_data/ingredients.json')
  //     .then(response => {
  //       return new Promise(res => {
  //         setTimeout(() => {
  //           res(response.data)
  //         }, Math.random() * 500)
  //       })
  //     })
  // )
}

export function getOptionModifiers(restaurantId) {
  return instance.get(`/restaurants/${restaurantId}/option-modifiers`).then(response => {
    return response.data
  })
  // return (
  //   instance
  //     // .get(process.env.VUE_APP_API_URL + `/menu??`)
  //     .get('./fake_data/optionModifiers.json')
  //     .then(response => {
  //       return new Promise(res => {
  //         setTimeout(() => {
  //           res(response.data)
  //         }, Math.random() * 500)
  //       })
  //     })
  // )
}

export function getCashRegister() {
  return instance.get('/restaurants/cash-register').then(response => {
    return response.data
  })
}

export function getOptions(restaurantId) {
  return instance.get(`/restaurants/${restaurantId}/options`).then(response => {
    return response.data
  })
  // return (
  //   instance
  //     // .get(process.env.VUE_APP_API_URL + `/menu??`)
  //     .get('./fake_data/options.json')
  //     .then(response => {
  //       return new Promise(res => {
  //         setTimeout(() => {
  //           res(response.data)
  //         }, Math.random() * 500)
  //       })
  //     })
  // )
}

//tables
// export function getTables () {
//   return (
//     instance
//       // .get(process.env.VUE_APP_API_URL + `/menu??`)
//       .get('./fake_data/tables.json')
//       .then(response => {
//         return new Promise(res => {
//           setTimeout(() => {
//             res(response.data)
//           }, Math.random() * 500)
//         })
//       })
//   )
// }

//Service zones
export async function getServiceZones(restaurantId) {
  const response = await instance.get(`/restaurants/${restaurantId}/service-zones`)
  return validateResponse(response)
}

//Orders
// NO SE USA, xk VA POR EL SOCKET! no!?
// export function getOrders () {
//   return (
//     instance
//       // .get(process.env.VUE_APP_API_URL + `/menu??`)
//       .get('./fake_data/orders.json')
//       .then(response => {
//         return new Promise(res => {
//           setTimeout(() => {
//             res(response.data)
//           }, Math.random() * 500)
//         })
//       })
//   )
// }

//Tickets
export async function getTicketSettings(restaurantId) {
  const response = await instance.get(`/restaurants/${restaurantId}/ticket-settings`)
  return validateResponse(response)
}

export function getOrderById(ticketId) {
  return orderController.getOrderById(ticketId)
}

//TakeAway
export async function getTakeAwayById(id) {
  const response = await instance.get(`/take-away/${id}`)
  return validateResponse(response)
}

//Alerts
export async function getAlertsByRestaurant(restaurantId) {
  const response = await instance.get(`restaurants/${restaurantId}/alerts`)
  return validateResponse(response)
}

//PreparationAreas
export async function getPreparationAreasByRestaurant() {
  const response = await instance.get(`restaurants/preparation-areas`)
  return validateResponse(response)
}

//Discounts
export async function getDiscountsByRestaurant(restaurantId) {
  const response = await instance.get(`restaurants/${restaurantId}/discounts`)
  return validateResponse(response)
}

//Service orders
export async function getServiceOrdersByRestaurant(restaurantId) {
  const response = await instance.get(`restaurants/${restaurantId}/service-orders`)
  return validateResponse(response)
}

export function createRestaurant(restInfo) {
  return instance.post('restaurants', restInfo)
}
