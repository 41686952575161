<template>
  <div class="payment">
    <div class="go-back">
      <div class="go-back-icon" />
      <div class="go-back-text">Volver a la orden</div>
    </div>
    <div class="main-title">Realizar cobro</div>
    <basic-tabs
      class="tabs"
      :value="tab"
      :disabled="someChecked"
      @change="handleChangeTab"
    >
      <basic-tab :value="'equal'" :name="'Iguales'" />
      <basic-tab :value="'diferent'" :name="'Diferentes'" />
      <basic-tab :value="'comensal'" :name="'Por Comensales'" />
      <basic-tab v-if="!hasRestaurantDiscount" :value="'per-product'" :name="'Por producto'" />
    </basic-tabs>
    <div class="lolo">
      <transition :name="transition" mode="out-in">
        <component
          :is="`${tab}-payment`"
          v-model="clients"
          class="clients-container"
          :clients="clients"
          :max-price="totalPrice"
          :disabled="restClient.checked"
          :products-ticket="productsTicket"
          :rest="restClient"
          :disabled-swipe="someChecked"
          @remove="handleRemoveClient"
        >
          <div
            class="btn btn--primary btn--ghost add-client"
            :class="{ disabled: someChecked }"
            @click="handleAddClient"
          >
            <div class="icon-add" />
            Agregar cliente
          </div>
        </component>
      </transition>
      <div v-if="!restToPay !== 0" class="clients-container">
        <div class="clients-title">{{ $t('rest') }}</div>
        <client-payment
          v-model="restClient"
          class="clients-container"
          :products="restProducts"
          :disabled-swipe="true"
          :disabled="restClient.toPay <= 0"
        />
      </div>
    </div>
    <div class="payment-footer">
      <div class="btn btn--primary" :class="{ disabled: !isValidPay }" @click="handleAllPay">
        <div>Confirmar pago: {{ totalPrice }}€</div>
        <div v-if="!isValidPay">
          {{ restToPay > 0 ? $t('missing') : $t('surplus') }}: {{ restToPay }}€
        </div>
      </div>
    </div>
    <confirm-payment-dialog
      :is-open="paying"
      :order-id="orderId"
      :loading="!payFinished"
      :success="paySuccess"
      :description="$t('pay_done')"
      :title="totalPriceString"
      :btn-title="$t('turn_order')"
      @close="handleFinishPay"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getMergedTicketRows, parseCurrency } from '../../utils/ticketUtils'
import BasicTab from '../basic/BasicTab.vue'
import BasicTabs from '../basic/BasicTabs.vue'
import BasicCheckbox from '../basic/BasicCheckbox.vue'
import ClientPayment from './ClientPayment.vue'
import ComensalPayment from './ComensalPayment.vue'
import DiferentPayment from './DiferentPayment.vue'
import EqualPayment from './EqualPayment.vue'
import PerProductPayment from './PerProductPayment.vue'
import { orderController } from '../../services/api'
import { ROUTE_NAMES } from '../../settings/routeNames'
import BasicConfirmationDialog from '../basic/BasicConfirmationDialog.vue'
import BasicButton from '../basic/BasicButton.vue'
import BasicLoading from '../basic/BasicLoading.vue'
import ConfirmPaymentDialog from '../ConfirmPaymentDialog.vue'
import OrderDataMixin from '../../mixins/OrderDataMixin'
const tabsSorted = ['equal', 'diferent', 'comensal', 'per-product']
export default {
  components: {
    ComensalPayment,
    BasicTabs,
    BasicCheckbox,
    BasicTab,
    ClientPayment,
    DiferentPayment,
    PerProductPayment,
    EqualPayment,
    BasicConfirmationDialog,
    BasicButton,
    BasicLoading,
    ConfirmPaymentDialog,
  },
  mixins: [OrderDataMixin],
  props: {
    orderId: { type: [String, Number], required: true },
  },
  remoteComputed: {
    products: {
      model: 'restaurant/products',
      params: function () {
        return [this.$route.params.restaurantId]
      },
    },
    ingredients: {
      model: 'restaurant/ingredients',
      params: function () {
        return [this.$route.params.restaurantId]
      },
    },
    ticketSettings: {
      model: 'restaurant/ticketSettings',
      method: 'get',
    },
  },
  data() {
    return {
      tab: 'diferent',
      transition: 'left-to-right',
      rest: {
        checked: false,
        payByCard: true,
      },
      paying: false,
      payFinished: false,
      paySuccess: false,
      clients: [
        {
          toPay: this.totalPrice,
          checked: false,
          payByCard: true,
          tickets: {},
          persons: 1,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      activeOrder: 'activeOrder/data',
      getProductById: 'restaurant/products/getById',
    }),
    loading() {
      return !this.activeOrder
    },
    totalPrice() {
      if (this.activeOrder && this.activeOrder.calculatedPrice)
        return this.activeOrder.calculatedPrice.totalCalculated
      return 0
    },
    hasRestaurantDiscount() {
      return !!this.order.restaurantDiscountId
    },
    totalPriceString() {
      if (
        this.activeOrder &&
        this.activeOrder.calculatedPrice &&
        parseCurrency(this.ticketSettings?.currency)
      )
        return this.totalPrice + parseCurrency(this.ticketSettings?.currency)
      else {
        return this.totalPrice + ''
      }
    },
    restProducts() {
      if (this.tab === 'per-product')
        return Object.keys(this.restClient.tickets).reduce(
          (sum, id) => sum + (this.restClient.tickets[id] || 0),
          0
        )
      return undefined
    },
    restToPay() {
      return Number(
        (
          this.totalPrice -
          this.clients
            .filter(client => client.checked)
            .reduce((sum, client) => sum + client.toPay, 0)
        ).toFixed(2)
      )
    },
    restTickets() {
      return this.productsTicket
        .map(ticket => ({
          ...ticket,
          quantity:
            ticket.quantity -
            this.clients
              .filter(client => client.checked)
              .reduce((sum, client) => (client.tickets[ticket.id] || 0) + sum, 0),
        }))
        .reduce((obj, ticket) => {
          if (!obj[ticket.id]) obj[ticket.id] = ticket.quantity
          return obj
        }, {})
    },
    restClient: {
      get() {
        return {
          ...this.rest,
          toPay: this.restToPay,
          tickets: this.restTickets,
        }
      },
      set(data) {
        this.rest = data
      },
    },
    productsTicket() {
      if (this.activeOrder)
        return getMergedTicketRows(this.activeOrder.rows).map(e => ({
          ...e,
          product: this.getProductById(e.productId) ?? { id: e.productId },
        }))
      return []
    },
    isValidPay() {
      if (this.rest.checked) return true
      return this.restToPay === 0
    },
    someChecked() {
      return this.clients.some(client => client.checked) || this.rest.checked
    },
    clientAndRest() {
      return this.clients.concat([this.rest])
    },
  },
  watch: {
    activeOrder: {
      handler() {
        if (!this.isValidPay) {
          this.clients.forEach(c => (c.toPay = 0))
          this.clients[0].toPay = this.totalPrice
          if (this.activeOrder)
            this.clients[0].tickets = getMergedTicketRows(this.activeOrder.rows).reduce(
              (obj, row) => {
                obj[row.id] = row.quantity
                return obj
              },
              {}
            )
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.fetchOrder(this.orderId) // TODO
  },
  methods: {
    ...mapActions({
      fetchOrder: 'activeOrder/fetchOrderById',
    }),
    handleChangeTab(value) {
      if (tabsSorted.indexOf(value) < tabsSorted.indexOf(this.tab))
        this.transition = 'right-to-left'
      else this.transition = 'left-to-right'

      this.tab = value
    },
    handleAddClient() {
      const newClient = {
        toPay: 0,
        checked: false,
        payByCard: true,
        persons: 1,
        tickets: {},
      }
      this.clients = [...this.clients, newClient]
    },
    handleRemoveClient(client) {
      if (client.checked || this.clients.length === 1) return
      const copyClient = [...this.clients]
      const index = copyClient.indexOf(client)
      if (index == -1) {
        console.error(new Error('Client not found'))
      }
      copyClient.splice(copyClient.indexOf(client), 1)
      this.clients = copyClient
    },

    /**
     * payed by card if param is true
     * @param {boolean} byCard
     */
    payedByCard(byCard = true) {
      return this.clientAndRest
        .filter(e => e.checked)
        .filter(e => e.payByCard === byCard)
        .reduce((sum, client) => sum + client.toPay, 0)
    },

    handleAllPay() {
      this.paying = true
      orderController
        .payTicket(this.orderId, this.payedByCard(true), this.payedByCard(false))
        .then(() => {
          this.paySuccess = true
          this.orderDispatch('invalidate', this.orderId)
        })
        .catch(err => {
          this.paySuccess = false
          console.error(err)
        })
        .finally(() => {
          this.payFinished = true
        })
    },
    handleFinishPay() {
      this.payFinished = false
      if (!this.activeOrder.takeAway && this.order.table) {
        this.$router.replace({ name: ROUTE_NAMES.TABLE, params: { tableId: this.order.table.id } })
      } else {
        this.$router.push({ name: ROUTE_NAMES.ORDERS })
      }
    },
  },
}
</script>

<style lang="stylus">
.payment
  top: $header-height !important
  display: flex
  flex-direction: column
  height: "calc(100% - %s)" % $header-height !important

  .add-client
    display: inline-flex
    margin-left: vw(10px)
    padding: 0

    .icon-add
      m-icon("add", 24)
      margin-top: 0.1em
      margin-right: vw(8px)

.go-back
  display: flex
  align-items: center
  margin-bottom: vw(15px)
  padding-right: vw(15px)
  padding-left: vw(12px)
  color: $primary-theme-color

.go-back-icon
  m-icon("clipboard-theme-color", 18)
  margin-right: vw(5px)

.go-back-text
  m-font-size(14, 20)
  font-weight: 500

.main-title
  m-font-size(32, 36)
  margin-bottom: vw(30px)
  padding-right: vw(15px)
  padding-left: vw(15px)
  font-weight: 700

.lolo
  flex: 1 1 0%
  overflow-x: hidden
  overflow-y: auto

.clients-container
  margin-top: vw(10px)
  margin-bottom: vw(20px)

  &.left-to-right-enter-active,
  &.left-to-right-leave-active,
  &.right-to-left-enter-active,
  &.right-to-left-leave-active
    transition: all 0.3s

  &.right-to-left-leave-to,
  &.left-to-right-enter
    opacity: 0
    transform: translateX(100vw)

  &.right-to-left-enter,
  &.left-to-right-leave-to
    opacity: 0
    transform: translateX(-100vw)

.clients-title
  m-font-size(16, 20)
  margin-bottom: vw(10px)
  padding-right: vw(15px)
  padding-left: vw(15px)
  color: #828282
  text-transform: uppercase

.payment-footer
  padding: vw(18px) vw(15px)
  background-color: #fff
  box-shadow: 0 -2px 10px 0 rgba(#000, 0.2)

  .btn
    flex-direction: column

    > div
      &:not(:last-child)
        margin-bottom: vw(5px)
</style>
