<template>
  <div class="preparation-header-container">
    <div class="preparation-header">
      <button :class="['preparation-header-icon', 'menu-icon', 'btn-fake', {'open': isShowNavigation} ]" @click="switchShowNavigation" />
      <button class="preparation-header-icon btn-fake recovery-icon" @click="handleClickShowRecoveryColumn" />
      <div class="preparation-header-bottom">
        <button class="preparation-header-icon btn-fake orders-icon" @click="handleClickOrders" />
        <button class="preparation-header-icon btn-fake settings-icon" @click="handleClickAdmin" />
      </div>
    </div>
    <div :class="['preparation-zone-navigation-background', {'open': isShowNavigation}]" />
    <navigation v-if="isShowNavigation" v-model="isShowNavigation" /> 
  </div>
</template>

<script>
import QueryMixin from '../../../mixins/QueryMixin'
import { RECOVERY_COLUMN } from '../../../settings/navigationSettings'
import { ROUTE_NAMES } from '../../../settings/routeNames'
import Navigation from './Navigation.vue'

export default {
  components: { Navigation },
  mixins: [QueryMixin],
  data() {
    return {
      isShowNavigation: false,
    }
  },
  methods: {
    switchShowNavigation() {
      this.isShowNavigation = !this.isShowNavigation
    },
    handleClickShowRecoveryColumn() {
      const recovery = this.getQueryValue(RECOVERY_COLUMN)?.toLowerCase() === 'true'
      this.setQueryValue(RECOVERY_COLUMN, !recovery)
    },
    handleClickOrders() {
      this.$router.push('/' + ROUTE_NAMES.ORDERS)
    },
    handleClickAdmin() {
      this.$router.push({ name: ROUTE_NAMES.CMS.MENU })
    },
  },
}
</script>

<style lang="stylus" scoped>
.preparation-zone-navigation-background
  position: fixed
  top: 0
  z-index: 0
  overflow: hidden
  width: 100vw
  height: 100vh
  background-color: rgba(#333, 0.8)
  opacity: 0
  transition: opacity 0.25s ease-in-out
  pointer-events: none

  &.open
    opacity: 1
    pointer-events: all

.preparation-header-container
  overflow: hidden
  width: vw(60px)
  height: 100%

.preparation-header
  position: relative
  z-index: 2
  display: flex
  flex-direction: column
  align-items: center
  padding: vw(15px)
  height: 100%
  border-right: 1px solid #e0e0e0
  background-color: white

  .orders-shortcut
    m-font-size(14, 16)
    padding: 0.5em 0.75em
    padding-top: 0.45em
    width: auto

.preparation-header-icon
  display: flex
  justify-content: center
  align-items: center
  margin-bottom: vw(15px)
  width: vw(32px)
  height: vw(32px)

  &.menu-icon
    transform: rotate(90deg)
    m-icon("hamburger-vertical", 32)

    &.open
      m-icon("close", 32)

  &.recovery-icon
    m-icon("swap-vertical", 32)

  &.orders-icon
    m-icon("orders", 32)
  &.settings-icon
    m-icon("settings", 32)

.preparation-header-bottom
  margin-top: auto
</style>