
export default {
  namespaced: true,
  state: {

  },
  remoteModels:{
    list:{
      get: () => new Promise((res)=>{
        setTimeout(()=>{
          res([1,2,3,5,6].map((n)=>({id:n,value:n*10})))
        },Math.random()*6000)
      }),
      getById: ({getters}, id, ...args)=>new Promise((res)=>{
        console.log("getting",getters,id,args)
        res({id: id,value:id*10})
      },Math.random()*500)
    }
  },
  mutations:{
  
  },
  actions:{

  }
}