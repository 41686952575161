<template>
  <div class="fake-checkbox-container basic-checkbox">
    <input
      :class="{ disabled }"
      :checked="value"
      :indeterminate.prop="value !== true && value !== false"
      type="checkbox"
      :required="required"
      @input.stop="e => $emit('change', e.target.checked)"
    >
    <div class="fake-checkbox" />
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: Boolean,
    required: Boolean,
    disabled: Boolean,
  },
}
</script>

<style lang="stylus" scoped>
.basic-checkbox
  &:not(:las-child)
    margin-right: vw(15px)

  &:last-child
    margin-left: vw(15px)
</style>
