<template>
  <div class="user-avatar-container">
    <!-- <img class="user-image" :src="user.image.url"> -->
    <div v-if="userImage" class="user-image" :style="'background-image: url(' + userImage + ')'" />
    <div class="user-name">{{ `${user.firstName} ${user.lastName}` }}</div>
    <ul class="roles-list">
      <li v-for="(rol, index) in user.roles" :key="index" class="roles-item">{{ getRolName(rol) }}</li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ROLE_PREFIX } from '../../../settings/roles'

export default {
  computed: {
    user() {
      return this.getLogedUser()
    },
    userImage() {
      return this.user.image ? this.user.image.url : 'https://www.floresdevida.org/wp-content/uploads/2018/06/default-user-thumbnail-1.png'
    }
  },
  methods: {
    ...mapGetters({
      getLogedUser: 'user/getLogedUser',
    }),
    getRolName(rol) {
      return rol.replace(ROLE_PREFIX, '').toLowerCase()
    },
  },
}
</script>

<style lang="stylus">
.user-avatar-container
  display: flex
  flex-direction: column
  align-items: center
  margin-top: vw(36px)
  margin-bottom: vw(56px)

.user-image
  margin-bottom: vw(14px)
  width: vw(100px)
  height: vw(100px)
  border-radius: 50%
  background-color: #ccc
  background-position: center center
  background-size: cover
  background-repeat: no-repeat

.user-name
  m-font-size(20, 26)
  margin-bottom: vw(5px)
  font-weight: 600

.roles-list
  display: flex
  flex-wrap: wrap
  justify-content: center
  align-items: center
  margin-right: vw(20px)
  margin-left: vw(20px)
  text-transform: capitalize

.roles-item
  m-font-size(16, 24)

  &:not(:last-child)
    &:after
      margin-right: 0.5em
      margin-left: 0.5em
      content: "/"
</style>