import { ROUTE_NAMES } from '../../settings/routeNames'

export default [
  {
    id: 'categories',
    routeName: ROUTE_NAMES.CMS.MENU.CATEGORIES,
    labelKey: 'cms_categories',
  },
  {
    id: 'products',
    routeName: ROUTE_NAMES.CMS.MENU.PRODUCTS,
    labelKey: 'cms_products',
  },
  {
    id: 'ingredientModifiers',
    routeName: ROUTE_NAMES.CMS.MENU.INGREDIENT_MODIFIERS,
    labelKey: 'cms_ingredient_modifier_title',
  },
  {
    id: 'optionModifiers',
    routeName: ROUTE_NAMES.CMS.MENU.OPTION_MODIFIERS,
    labelKey: 'cms_option_modifier_title',
  },
  {
    id: 'ingredients',
    routeName: ROUTE_NAMES.CMS.MENU.INGREDIENTS,
    labelKey: 'cms_ingredients',
  },
  {
    id: 'optioins',
    routeName: ROUTE_NAMES.CMS.MENU.OPTIONS,
    labelKey: 'cms_options',
  },
  {
    id: 'service-settings',
    routeName: ROUTE_NAMES.CMS.MENU.SERVICE_SETTINGS,
    labelKey: 'cms_service_settings',
  },
]
