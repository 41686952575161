export function orderListByStatus(status, orders) {
  return orders.reduce((accumulator, currentOrder) => {
    let rows = []
    currentOrder.rows.forEach(row => {
      if (row.status === status) rows.push(row)
    })
    if (rows.length > 0) accumulator.push({ ...currentOrder, rows })
    return accumulator
  }, [])
}

/**
 * @params options: {attributeId: [value]}
 */
export function orderListByAttributes(options, orders) {
  return orders.reduce((accumulator, currentOrder) => {
    let rows = []
    currentOrder.rows.forEach(row => {
      Object.keys(options).forEach(key => {
        if (options[key].includes(row[key])) rows.push(row)
      })
      if (row.status === status) rows.push(row)
    })
    if (rows.length > 0) accumulator.push({ ...currentOrder, rows })
    return accumulator
  }, [])
}

import { ORDER_STATUS } from '@/settings/orderSettings'

// No se como recuperar el "onlyShow" aqui en el utils...
// if (this.onlyShow.indexOf(status.toUpperCase()) > -1) return status
// ['PAID', 'READY', 'TODO', 'DELIVERED', 'WIP']

function findStatus(order, method, status) {
  const key = ORDER_STATUS[status].key
  return order.rows[method](row => row.status === key)
}
export function getOrderStatus(order) {
  switch (true) {
    case findStatus(order, 'every', ORDER_STATUS.PAID.key):
      return 'paid'
    case findStatus(order, 'every', ORDER_STATUS.READY.key):
      return 'ready'
    case findStatus(order, 'some', ORDER_STATUS.TODO.key):
      return 'todo'
    case findStatus(order, 'every', ORDER_STATUS.DELIVERED.key):
      return 'delivered'
    case findStatus(order, 'some', ORDER_STATUS.WIP.key):
      return 'wip'
    default:
      return ''
  }
}
