import { ROUTE_NAMES } from '../../settings/routeNames'
export default [
  {
    id: 'current',
    routeName: ROUTE_NAMES.CMS.BUSINESS.CURRENT_CASH_REGISTRY,
    labelKey: 'cms_current',
  },
  {
    id: 'historic',
    routeName: ROUTE_NAMES.CMS.BUSINESS.HISTORIC_CASH_REGISTRY,
    labelKey: 'cms_historic',
  },
  {
    id: 'liquidation',
    routeName: ROUTE_NAMES.CMS.BUSINESS.LIQUIDATION_CASH_REGISTRY,
    labelKey: 'cms_liquidation',
  },
  {
    id: 'products',
    routeName: ROUTE_NAMES.CMS.BUSINESS.PRODUCT_CASH_REGISTRY,
    labelKey: 'cms_products',
  },
]
