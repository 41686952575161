import { getCommitedStatus } from '../../../utils/ticketUtils'

class OrderController {
  /**
   *
   * @param {import('axios').AxiosInstance} axiosInstance
   * @param {*} validateResponse
   */
  constructor(axiosInstance, validateResponse) {
    this.instance = axiosInstance
    this.validateResponse = validateResponse
  }
  async getOrdersByTable(tableId) {
    return this.validateResponse(await this.instance.get(`/tickets/table/${tableId}`))
  }
  async getOrderById(id) {
    return this.validateResponse(await this.instance.get(`tickets/${id}`))
  }
  /**
   *
   * @return {Promise<number>}
   */
  async postOrder(restaurantId, body) {
    let parsedBody = {
      rows: body.rows,
      isQR: body.isQR,
      restaurantDiscountId: body.restaurantDiscountId,
      table: body.table,
      takeAway: body.takeAway,
    }
    const response = await this.instance.post(`/restaurants/${restaurantId}/tickets`, parsedBody)
    return this.validateResponse(response)
  }

  async deleteOrder(ticketId) {
    const response = await this.instance.delete(`/tickets/${ticketId}`)
    return this.validateResponse(response)
  }

  async createStripePaymentIntent(body) {
    const response = await this.instance.post(`/stripe/payment_intent/create`, body)
    return this.validateResponse(response)
  }

  async patchOrder(id, body) {
    const response = await this.instance.patch(`/tickets/${id}`, body)
    return this.validateResponse(response)
  }

  async moveTicketRows(oldTicketId, rows, targetTicketId, name) {
    const response = await this.instance.post(`/tickets/move`, {
      oldTicketId,
      rows,
      targetTicketId,
      name,
    })
    return this.validateResponse(response)
  }

  async swapTable(oldTable, newTable) {
    const response = await this.instance.put(`/tables/swap`, { oldTable, newTable })
    return this.validateResponse(response)
  }

  patchOrderMergedRowsStatus(order, mergedRows, isRecovery) {
    let rows = [...order.rows]
    const mergedRowsGrouped = Array.isArray(mergedRows) ? [...mergedRows] : [{ ...mergedRows }]

    mergedRowsGrouped.forEach(mergedRowGrouped => {
      if (mergedRowGrouped.children) {
        mergedRowGrouped.children.forEach(mergedRow => {
          let index = rows.findIndex(row => {
            return parseInt(row.id) === parseInt(mergedRow.id)
          })
          if (index !== -1) {
            rows[index] = { ...rows[index], status: getCommitedStatus(mergedRow, isRecovery) }
          }
        })
      }
      //Is child (dont have children)
      else {
        let index = rows.findIndex(row => row.id === mergedRowGrouped.id)
        if (index !== -1) {
          rows[index] = { ...rows[index], status: getCommitedStatus(mergedRowGrouped, isRecovery) }
        }
      }
    })

    return this.patchOrder(order.id, { rows })
  }

  async patchTicketRowStatusByMergedRows(mergedRows, isRecovery) {
    if (mergedRows.children) {
      await Promise.all(
        mergedRows.children.map(row => {
          return this.patchTicketRowStatus(row.id, { status: getCommitedStatus(row, isRecovery) })
        })
      )
    }
  }

  async postTicketRow(ticketId, body) {
    const response = await this.instance.post(`/tickets/${ticketId}/ticket-row`, body)
    return this.validateResponse(response)
  }
  async deleteTicketRow(id) {
    const response = await this.instance.delete(`/ticket-row/${id}`)
    return this.validateResponse(response)
  }
  async patchTicketRow(id, body) {
    const response = await this.instance.patch(`/ticket-row/${id}`, body)
    return this.validateResponse(response)
  }
  async patchTicketRowStatus(id, body) {
    const response = await this.instance.patch(`/ticket-row/${id}/status`, body)
    return this.validateResponse(response)
  }

  async validateOrder(restaurantId, orderData) {
    const response = await this.instance.post(
      `/restaurants/${restaurantId}/tickets/validate`,
      orderData
    )
    return this.validateResponse(response)
  }
  /**
   * check a ticket as a payed by a waiter
   * @param {number} orderId ticket id
   * @param {number} card payed by card
   * @param {number} cash payed by cash
   */
  async payTicket(orderId, card, cash) {
    return await this.instance.post(`/tickets/${orderId}/pay`, { card, cash })
  }
  async sendEmailWithPurchase(ticketId, mail) {
    return this.validateResponse(
      await this.instance.post(`/tickets/${ticketId}/send-invoice-by-mail`, { mail })
    )
  }

  async getInvoiceByTicketId(ticketId) {
    return this.validateResponse(await this.instance.get(`/tickets/${ticketId}/invoice`))
  }
}

export default OrderController
