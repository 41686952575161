<template>
  <div class="stripe-payment">
    <basic-loading v-if="!elementReady" />
    <stripe-element-payment
      v-if="stripeStatus"
      ref="paymentRef"
      :pk="pk"
      :stripe-account="stripeStatus.stripeAccountId"
      :elements-options="elementsOptions"
      :confirm-params="confirmParams"
      @error="onError"
      @element-ready="elementReady = true"
    />
    <button v-if="elementReady" class="btn-submit btn btn--primary stripe-pay-button" @click="pay">{{ $t('pay') }}</button>
  </div>
</template>
<script>
import { StripeElementPayment } from '@vue-stripe/vue-stripe'
import BasicLoading from '../basic/BasicLoading.vue'
import { STRIPE_PAY_SUCCESS_QUERY } from '../../settings/orderSettings'
import { mapGetters } from 'vuex'
import QueryMixin from '../../mixins/QueryMixin'
export default {
  components: {
    StripeElementPayment,
    BasicLoading
  },
  mixins: [QueryMixin],
  props: {
    order: {
      type: Object,
      required: true,
    },
    paymentIntent: {
      type: Object,
      required: true,
    },
    stripeStatus: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      elementReady: false,
      confirmParams: {
        return_url: this.getReturnUrl(),
      },
    }
  },
  computed: {
    ...mapGetters({
      userQr: 'userQr/getLogedQrData',
    }),
    pk() {
      return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    },
    elementsOptions() {
      return {
        clientSecret: this.paymentIntent.clientSecret,
      }
    },
  },
  methods: {
    onError(err) {
      this.$emit('paymentFailed', err)
    },
    pay() {
      this.$refs.paymentRef.submit()
    },
    getReturnUrl() {
      const returnUrl = location.href + `?${STRIPE_PAY_SUCCESS_QUERY}=true`
      return returnUrl
    },
  },
}
</script>
<style lang="stylus" scoped>
.stripe-payment
  margin-top: vw(25px)
  >>> .loading
    position: absolute !important
    top: 0
.stripe-pay-button
  margin-top: vw(25px)
  width: 100%
</style>