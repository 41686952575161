import { cmsController, getProducts } from '@/services/api'

const remoteModels = {
  summary: {
    get: (state, id, from, to) => {
      return cmsController.getDashboardSummary(from, to)
    },
  },
  ticketSettings: {
    get: () => {
      return cmsController.getTicketSettings()
    },
  },
  ticketRowAlerts: {
    get: () => {
      return cmsController.getTicketRowAlerts()
    },
  },
  serviceZones: {
    get: () => {
      return cmsController.getServiceZones()
    },
  },
  products: {
    get: () => {
      return getProducts()
    },
  },
  users: {
    get: () => {
      return cmsController.getUsers()
    },
  },
  clients: {
    get: () => {
      return cmsController.getResource('clients')
    },
  },
  roles: {
    get: () => {
      return cmsController.getRoles()
    },
  },
  options: {
    get: () => {
      return cmsController.getOptions()
    },
  },
  ingredients: {
    get: () => {
      return cmsController.getIngredients()
    },
  },
  tableGroups: {
    get: () => {
      return cmsController.getResource('table-group')
    },
  },
  deliveryRates: {
    get: () => {
      return cmsController.getResource('delivery-rates')
    },
  },
  timeZones: {
    get: () => {
      return cmsController.getResource('time-zones')
    },
  },
  stripeStatus: {
    get: () => {
      return cmsController.retrieveStripeAccountStatus()
    },
  },
  tables: {
    get: () => {
      return cmsController.getResource('tables')
    },
  },
  actualCashRegister: {
    get: () => {
      return cmsController.getRestaurantCashRegister('actual')
    },
  },
  historicCashRegister: {
    get: () => {
      return cmsController.getRestaurantCashRegister('historic')
    },
  },
  invoice: {
    get: (context, id) => cmsController.getInvoiceById(id),
  },
  liquidationCashRegister: {
    get: (context, from, to) => cmsController.getRestaurantCashRegisterLiquidation(from, to),
  },
  dashboardChart: {
    get: (state, from, to) => {
      return cmsController.getGraphInfo(from, to)
    },
  },
}

export default {
  namespaced: true,
  state: {},
  remoteModels,
  mutations: {},
  actions: {
    invalidateAll({ dispatch }) {
      Object.keys(remoteModels).forEach(key => {
        dispatch(`cms/${key}/invalidateAll`)
      })
    },
  },
}
