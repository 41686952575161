<template>
  <div class="cms-view">
    <CMSNavigation />
    <router-view />
  </div>
</template>
<script>
import CMSNavigation from '../../components/navigation/CMSNavigation.vue'
export default {
  components: { CMSNavigation },
}
</script>
<style lang="stylus">
.cms-view
  display: flex
  margin: 0 auto
  width: 100%
  height: 100%

  .basic-dialog-container
    // left: 0
    padding: vw(60px) vw(40px) vw(30px)
    width: vw(600px)
    // transform: translateY(-50%)

  .basic-dialog
    &.slide-enter-active,
    &.slide-leave-active
      transition: none

      .background
        transition: none

    &.slide-enter,
    &.slide-enter-from,
    &.slide-leave-to
      transition: none

    .background
      left: 0

.cms-section
  width: 100%

.cms-section__header
  display: flex
  justify-content: space-between
  align-items: flex-end

.cms-section__title
  margin: 0
  margin-top: vh(30px)
  m-font-size(26, 26)
  word-spacing: vw(-5px)
  font-weight: 700

.cms-section__controls
  display: flex
  gap: vw(15px)

.cms-section__content
  margin-top: vh(30px)

.cms-view-container
  overflow: auto
  padding: vh(50px) vw(60px)
  width: 100%

h1
  m-font-size(26, 26)

h2
  m-font-size(20, 20)

$transition-duration = 0.5s
</style>
