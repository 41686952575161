import { t } from '../i18n'
import { ROLES } from './roles'
import { ROUTE_NAMES } from './routeNames'

export const ORDER_TABS_KEYS = {
  ORDERS: '1',
  PICKUP: '2',
}

export const ORDER_TABS_OPTIONS = {
  key: 'tab_orders',
  options: [
    {
      key: ORDER_TABS_KEYS.ORDERS,
      label: t('orders_tabs_orders'),
    },
    {
      key: ORDER_TABS_KEYS.PICKUP,
      label: t('orders_tabs_pick_up'),
    },
  ],
}
export const ORDER_OPTIONS_KEYS = {
  MY_ZONE: '1',
  OTHER_ZONES: '2',
  TAKEAWAY: '3',
}

export const ORDER_OPTIONS = {
  key: 'order_type',
  options: [
    {
      key: ORDER_OPTIONS_KEYS.MY_ZONE,
      label: t('order_option_label_my_zone'),
    },
    {
      key: ORDER_OPTIONS_KEYS.OTHER_ZONES,
      label: t('order_option_label_other_zones'),
    },
    {
      key: ORDER_OPTIONS_KEYS.TAKEAWAY,
      label: t('order_option_label_takeaway'),
    },
  ],
}
export const NAVIGATIONS_KEYS = {
  ORDERS: 'ORDERS',
  PICK_UP: 'PICK_UP',
  TAKEAWAY: 'TAKEAWAY',
  PREPARATION_ZONE: 'PREPARATION_ZONE',
  ADMIN: 'ADMIN',
}
export const NAVIGATIONS = [
  {
    key: NAVIGATIONS_KEYS.ORDERS,
    name: t('navigation_button_orders'),
    roles: [ROLES.MANAGER, ROLES.WAITER],
    route: {
      name: ROUTE_NAMES.ORDERS,
      query: {
        [ORDER_OPTIONS.key]: ORDER_OPTIONS_KEYS.MY_ZONE,
        [ORDER_TABS_OPTIONS.key]: ORDER_TABS_KEYS.ORDERS,
      },
    },
  },
  {
    key: NAVIGATIONS_KEYS.PICK_UP,
    name: t('navigation_button_pick_up'),
    roles: [ROLES.MANAGER, ROLES.WAITER, ROLES.RUNNER],
    route: {
      name: ROUTE_NAMES.ORDERS,
      query: {
        [ORDER_OPTIONS.key]: ORDER_OPTIONS_KEYS.MY_ZONE,
        [ORDER_TABS_OPTIONS.key]: ORDER_TABS_KEYS.PICKUP,
      },
    },
  },
  {
    key: NAVIGATIONS_KEYS.TAKEAWAY,
    name: t('navigation_button_takeaways'),
    roles: [ROLES.MANAGER, ROLES.WAITER],
    route: {
      name: ROUTE_NAMES.ORDERS,
      query: {
        [ORDER_OPTIONS.key]: ORDER_OPTIONS_KEYS.TAKEAWAY,
      },
    },
  },
  {
    key: NAVIGATIONS_KEYS.PREPARATION_ZONE,
    name: t('navigation_button_cook'),
    roles: [ROLES.MANAGER, ROLES.COOKER, ROLES.WAITER], //TODO Remove waiter role from here
    route: {
      name: ROUTE_NAMES.PREPARATION_ZONE,
    },
  },
  {
    name: t('navigation_button_admin'),
    roles: [ROLES.ADMIN],
    route: {
      name: ROUTE_NAMES.CMS.ACTIVITY, // TODO
    },
  },
]

export const PREPARATION_ZONE_QUERY_KEY = 'preparation'
export const RECOVERY_COLUMN = 'recovery'
