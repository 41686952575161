import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      orders: 'orders/orders',
      orderById: 'orders/getOrderById',
      ordersByTables: 'orders/ordersByTables',
      takeAwayOrders: 'orders/takeAwayOrders',
      isFetchingOrders: 'orders/isFetching',
    }),
  },
}
