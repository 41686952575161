<template>
  <div class="product-row" @click="$emit('click',data)">
    <!-- {{ data }} -->
    <basic-product-icon :product="data" :count="count" :inverted-color="true" />
    <div class="label">{{ $label(data) }}</div>
    <div class="price">{{ data.price || 'xx€' }}€</div>
  </div>
</template>

<script>
import BasicProductIcon from './basic/BasicProductIcon.vue'
export default {
  components: { BasicProductIcon },
  props: ['data', 'count'],
  computed: {},
}
</script>

<style lang="stylus" scoped>
.product-row
  display: flex
  align-items: center

  .label
    m-font-size(16, 20)
    flex: 1 1 0%
    margin-right: vw(30px)
    font-weight: 500
    font-family: "IBM Plex Sans"

  .price
    m-font-size(14, 20)
    width: vw(90px)
    text-align: right
</style>