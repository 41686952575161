<template>
  <div class="ticket-row-group">
    <ticket-row
      :data="data.representant"
      :is-swipeable="true" 
      :is-open="open" 
      :is-representant="true"
      :is-active-order="isActiveOrder"
      :editable="editable"
      :is-paid="isPaid"
      :disable-order-actions="disableOrderActions"
      @toggleOpen="onParentToggleOpen"
      @click="onActionClick('click')"
      @clickNotify="onActionClick('clickNotify')"
      @clickDeleteRow="onActionClick('clickDelete')"
      @clickAddOne="addOrDeleteOne('clickAddOne')"
      @clickDeleteOne="addOrDeleteOne('clickDeleteOne')"
      @moveTicketRow="moveTicketRow"
      @clickDiscountRow="onActionClick('clickDiscount')"
    />
    <div v-if="data.children.length>1 && open" class="children">
      <ticket-row
        v-for="child in data.children"
        :key="child.id"
        :data="child"
        :is-swipeable="true"
        :editable="editable"
        :show-quantity="false"
        :is-active-order="isActiveOrder"
        :is-paid="isPaid"
        :disable-order-actions="disableOrderActions"
        @click="onActionClick('click', child)"
        @clickNotify="onActionClick('clickNotify', child)"
        @clickDeleteRow="onActionClick('clickDelete', child)"
        @clickDiscountRow="onActionClick('clickDiscount', child)"
      />
    </div>
  </div>
</template>

<script>
import TicketRow from './TicketRow.vue'

export default {
  components: { TicketRow },
  props: {
    data: { type: Object, required: true },
    isActiveOrder: { type: Boolean },
    editable: { type: Boolean, default: true },
    isPaid: { type: Boolean, default: false },
    disableOrderActions: { type: Boolean, required: true },
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    onParentToggleOpen() {
      this.open = !this.open
    },
    onActionClick(action, child) {
      if (child) this.$emit(action, child.id)
      else this.$emit(action, this.data.children.map(c => c.id).join(','))
    },
    addOrDeleteOne(action) {
      this.$emit(action, this.data.children[0].id)
    },
    moveTicketRow(row) {
      this.$emit('moveTicketRow', row)
    },
  },
}
</script>

<style lang="stylus" scoped>
.ticket-row-group
  .children
    margin-left: vw(50px)
</style>