<template>
  <div class="table-row-container">
    <label :for="table.id">
      <input 
        :id="table.id"
        :checked="selected || !!order"
        :disabled="!!order"
        type="radio"
        :name="table.name"
        :value="table.id"
        @input="$emit('click', $event)"
      >
      <span class="table-row-title">{{ `${$t("setup_order_table_name")} ${table.name}` }}</span>
    </label>
    <span v-if="order" class="view-order-text" @click="handleClickOrder">VER ORDEN</span>
  </div>
</template>

<script>
import { ROUTE_NAMES } from '@/settings/routeNames'
export default {
  props: {
    table: {
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
    },
    order: {
      type: Object,
    },
  },
  methods: {
    handleClickOrder() {
      if (this.order)
        this.$router.push({ name: ROUTE_NAMES.TABLE, params: { tableId: this.table.id } })
    },
  },
}
</script>

<style lang="stylus">
.table-row-container
  display: flex
  align-items: center
  width: 100%

  > label
    display: flex
    flex: 1 1 0%
    align-items: center
    margin-right: vw(15px)

    > input[type="radio"]
      margin: 0
      margin-right: vw(15px)

  .table-row-title
    m-font-size(14, 20)

  .view-order-text
    font-weight: 700
    m-font-size(10, 16)
    margin-left: auto
    text-transform: uppercase
</style>