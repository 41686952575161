import { ROUTE_NAMES } from '../../settings/routeNames'
export default [
  {
    id: 'preparation_areas',
    routeName: ROUTE_NAMES.CMS.RESTAURANT.PREPARATION_AREAS,
    labelKey: 'cms_preparation_areas',
  },
  {
    id: 'service_zones',
    routeName: ROUTE_NAMES.CMS.RESTAURANT.SERVICE_ZONES,
    labelKey: 'cms_service_zones',
  },
  {
    id: 'table_groups',
    routeName: ROUTE_NAMES.CMS.RESTAURANT.TABLE_GROUPS,
    labelKey: 'cms_table_groups',
  },
  {
    id: 'tables',
    routeName: ROUTE_NAMES.CMS.RESTAURANT.TABLES,
    labelKey: 'cms_tables',
  },
]
