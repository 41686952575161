<template>
  <div class="take-away-orders">
    <div class="cards-container">
      <take-away-card v-for="order in orders" :key="order.id" :order="order" />
    </div>
    <basic-bottom-actions>
      <basic-button :title="$t('new_order')" @click="onNewOrder" />
    </basic-bottom-actions>
    <setup-order :is-take-away="true" :is-open="showSetupOrder" @close="showSetupOrder = false" />
  </div>
</template>

<script>
import BasicBottomActions from '@/components/basic/BasicBottomActions.vue'
import BasicButton from '@/components/basic/BasicButton.vue'
import TakeAwayCard from './TakeAwayCard.vue'
import SetupOrder from '../../../views/SetupOrder.vue'
export default {
  components: { BasicBottomActions, BasicButton, TakeAwayCard, SetupOrder },
  props: {
    orders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showSetupOrder: false,
    }
  },
  methods: {
    onNewOrder() {
      this.$store.dispatch('newOrder/reset')
      this.showSetupOrder = true
    },
  },
}
</script>

<style lang="stylus">
.take-away-orders
  flex: 1 1 0%
  overflow-y: auto
  margin-bottom: $basic-bottom-actions-height
  padding-top: vw(10px)
  border-top: 1px solid #cfd8dc
</style>