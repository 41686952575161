<template>
  <div :class="['loading', type]">
    <div class="loading-background" />
    <div class="loading-content">
      <div class="loading-spinner">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
    },
  },
}
</script>

<style lang="stylus" scoped>
.loading
  position: relative
  overflow: hidden
  min-height: vw(60px)
  width: 100%
  height: 100%

  &.small
    .loading-spinner
      width: vw(25px)
      height: vw(25px)

      > div
        width: vw(25px)
        height: vw(25px)
        border-width: 4px

.loading-background
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(#fff, 0.8)

.loading-content
  position: absolute
  top: 50%
  left: 50%
  display: flex
  flex-direction: column
  align-items: center
  transform: translate(-50%, -50%)

.loading-spinner
  position: relative
  width: vw(50px)
  height: vw(50px)

  > div
    position: absolute
    width: vw(50px)
    height: vw(50px)
    border: 8px solid #333
    border-color: #333 transparent transparent transparent
    border-radius: 50%
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite

    &:nth-child(1)
      animation-delay: -0.45s

    &:nth-child(2)
      animation-delay: -0.3s

    &:nth-child(3)
      animation-delay: -0.15s

  @keyframes spinner
    0%
      transform: rotate(0)

    100%
      transform: rotate(360deg)
</style>