<template>
  <div class="side-menu">
    <div class="swipeat-logo" @click="goHome">
      <img src="../../assets/logo-cms.svg">
    </div>
    <nav class="menu">
      <ul class="menu__list">
        <li class="menu__item">
          <router-link class="menu__item__link" :to="{ name: mainRoute.name }">
            <div class="dashboard-icon" />
            <div class="menu__item__link__text">{{ $t(mainRoute.label) }}</div>
          </router-link>
        </li>
      </ul>
      <div class="menu__title-separator">{{ $t('cms_navigation_administration') }}</div>
      <ul class="menu__list with-indicator">
        <li
          v-for="(route, i) in routes"
          :key="`route_${i}`"
          class="menu__item"
        >
          <router-link class="menu__item__link" :to="{ name: route.name }">
            <div class="dashboard-icon" />
            <div class="menu__item__link__text">{{ $t(route.label) }}</div>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import { ROUTE_NAMES } from '../../settings/routeNames'
export default {
  props: {},
  data() {
    return {
      mainRoute: {
        label: 'cms_navigation_activity',
        name: ROUTE_NAMES.CMS.ACTIVITY,
      },
      routes: [
        {
          label: 'cms_navigation_business',
          name: ROUTE_NAMES.CMS.BUSINESS.MAIN,
        },
        {
          label: 'cms_navigation_restaurant',
          name: ROUTE_NAMES.CMS.RESTAURANT.MAIN,
        },
        {
          label: 'cms_navigation_menu',
          name: ROUTE_NAMES.CMS.MENU.MAIN,
        },
        {
          label: 'cms_navigation_employees',
          name: ROUTE_NAMES.CMS.EMPLOYEES,
        },
        {
          label: 'cms_navigation_clients',
          name: ROUTE_NAMES.CMS.CLIENTS,
        },
        {
          label: 'cms_navigation_settings',
          name: ROUTE_NAMES.CMS.SETTINGS.MAIN,
        },
      ],
    }
  },
  methods: {

    goHome() {
      this.$router.push('/')
    },
  },
}
</script>

<style lang="stylus" scoped>
.side-menu
  position: relative
  flex-shrink: 0
  padding: vh(30px) 0 0 vw(30px)
  width: vw(200px)
  height: 100%
  border-right: 1px solid $alto

.menu
  position: relative
  margin-top: vh(70px)

.menu__title-separator
  m-font-size(10, 20)
  margin-bottom: vw(20px)
  margin-left: vw(-10px)
  color: $gray-4
  font-family: "Titillium Web"

.menu__item
  margin-bottom: vh(30px)
  height: vw(20px)

.menu__item__link
  m-font-size(12, 14)
  display: flex
  align-items: center
  font-weight: 400
  gap: vw(10px)

  &.router-link-active
    font-weight: 600

    .dashboard-icon
      opacity: 1

  .dashboard-icon
    opacity: 0.5

.menu-indicator
  position: absolute
  right: 0
  margin-right: vw(-1px)
  width: 4px
  height: vw(20px)
  background-color: #000
  transition: top 0.25s ease-in-out

.dashboard-icon
  m-icon("dashboard", 20)

.swipeat-logo
  width: vw(90px)
  height: vh(80px)
  cursor: pointer

  img
    width: vw(90px)
</style>
