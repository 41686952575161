/**
 * @typedef Client
 * @property {number} toPay
 * @property {boolean} payByCard
 * @property {{[id:number]:number}} tickets key is id of product and value is the count
 * @property {number} persons
 *
 */
/**
 * @typedef Ticket
 * @property {number} id
 * @property {number} quantity
 * @property {number} price IMPORTANT!!! total price of the ticket (individualPrice*quantity)
 */
/**
 * @param {number} totalPrice
 * @param {Client[]} clients
 * @param {number} index
 * @param {boolean} sumPersons default false
 */

export function getEqualPriceClients(totalPrice, clients, sumPersons = false) {
  const newClients = [...clients]
  const numberClientPay = sumPersons
    ? newClients.reduce((number, client) => number + client.persons, 0)
    : newClients.length
  return newClients.map(clientMap => ({
    ...clientMap,
    toPay: sumPersons
      ? (totalPrice / numberClientPay) * clientMap.persons
      : totalPrice / numberClientPay,
  }))
}
/**
 *
 * @param {Client[]} clients
 * @param {number} index
 * @param {Client} client
 *
 * @throws {PaymentError}
 * @deprecated
 */
export function getDividePriceClient(totalPrice, clients, index = 0, client = null) {
  const sum = clients.reduce((sum, client) => sum + client.toPay, 0)

  const newClients = [...clients]
  if (sum > totalPrice) {
    console.error('Sum of client is greater than total to pay')
  }
  if (sum < totalPrice) {
    const last = newClients[newClients.length - 1]
    newClients[newClients.length - 1] = {
      ...last,
      toPay: last.toPay + totalPrice - sum,
    }
  }
  const length = newClients.length
  const toPayClient = client ? client.toPay : 0
  var delta = newClients[index].toPay - toPayClient
  if (client) newClients.splice(index, 1, client)

  for (let i = index + 1; i < newClients.length + index; i++) {
    const indexMod = i % length // modulo de i
    const thisClient = Object.assign(newClients[indexMod], {}) // Es el Client del padre... no muy bien hecho
    const newToPay = thisClient.toPay + delta
    if (newToPay < 0) delta = newToPay
    else {
      thisClient.toPay = newToPay
      delta = 0
    }
    newClients.splice(indexMod, 1, thisClient)
  }
  const newSum = newClients.reduce((sum, client) => sum + client.toPay, 0)
  if (newSum > totalPrice) {
    throw new PaymentError(`total to pay ${newSum} is > to price = ${totalPrice}`)
  }
  return newClients
}
/**
 *
 * @param {Client[]} clients
 * @return {{[id:number]:number}}
 */
function getUsedClientTickets(clients) {
  return clients.reduce((tikets, client) => {
    Object.keys(client.tickets).forEach(id => {
      if (tikets[id]) tikets[id] += client.tickets[id]
      else tikets[id] = client.tickets[id]
    })
    return tikets
  }, {})
}

function mergeJSONs(json1, json2) {
  const getNumber = n => (n ? n : 0)
  const allKeys = Object.keys({ ...json1, ...json2 })
  return allKeys.reduce((obj, key) => {
    obj[key] = getNumber(json1[key]) + getNumber(json2[key])
    return obj
  }, {})
}

/**
 *
 * @param {{[id:number]:number}} allTickets
 * @param {Client[]} clients
 * @return {{[id:number]:number}}
 */
function getTicketDiffereces(allTickets, clients) {
  const usedTickets = getUsedClientTickets(clients)
  const mapedTickets = Object.keys(usedTickets).reduce(
    (newTickets, id) => {
      newTickets[id] -= usedTickets[id]
      return newTickets
    },
    { ...allTickets }
  )
  const filterTickets = Object.keys(mapedTickets).reduce((obj, id) => {
    if (mapedTickets[id] > 0) obj[id] = mapedTickets[id]
    return obj
  }, {})
  return filterTickets
}
/**
 *
 * @param {Ticket[]} tickets
 * @param {keyof Ticket} field
 */
function getJSONTicket(tickets, field = 'quantity') {
  return tickets.reduce(
    (obj, ticket) => ({
      ...obj,
      [ticket.id]: ticket[field],
    }),
    {}
  )
}
/**
 * @param {Ticket[]} allTickets
 * @param {Client[]} clients
 * @param {number | null} index
 * @param {{[id:number]:number}} productTicketJSON
 * @deprecated
 */
export function perProductClient(allTickets, clients, index = 0, productTicketJSON = {}) {
  // debería dar 0 todos los tickets si todos los clientes ya tienen asociado lo que tiene que pagar
  var diffenentTicketJSON = getTicketDiffereces(getJSONTicket(allTickets), clients)
  var ticketsJSON = mergeJSONs(diffenentTicketJSON, productTicketJSON)
  const tickets = Object.keys(ticketsJSON).map(key => ({
    id: key,
    quantity: ticketsJSON[key],
  }))
  const lastClient = clients[index]
  const copyClients = [...clients]
  tickets.forEach(ticket => {
    const lastQuantityClient = lastClient.tickets[ticket.id] ?? 0
    const length = clients.length
    copyClients[index].tickets[ticket.id] = ticket.quantity
    var delta = lastQuantityClient - ticket.quantity
    for (let i = index + 1; i < length + index; i++) {
      // not modified. it's the clients or clients
      const thisClient = { ...clients[i % length] }
      const quantityClient = thisClient.tickets[ticket.id] ?? 0
      const newQuantityClient = quantityClient + delta
      if (newQuantityClient < 0) {
        thisClient.tickets[ticket.id] = 0
        delta = newQuantityClient
      } else {
        thisClient.tickets[ticket.id] = newQuantityClient
        delta = 0
      }
      copyClients[i % length] = thisClient
    }
  })
  return calculatePay(allTickets, copyClients)
}
/**
 *
 * @param {Client[]} clients
 * @param {Ticket[]} productsTicket
 */
export function calculatePay(productsTicket, clients) {
  const copyClient = JSON.parse(JSON.stringify(clients))
  copyClient.forEach(client => {
    client.toPay = Object.keys(client.tickets).reduce((total, productId) => {
      const ticket = productsTicket.find(ticket => ticket.id === productId)
      if (!ticket)
        console.error(
          'ticket with product ' + productId + ' not found in order!?',
          productsTicket,
          client.tickets
        )
      const productPrice = ticket ? ticket.price / ticket.quantity : 0
      return total + client.tickets[productId] * productPrice
    }, 0)
  })
  return copyClient
}
export class PaymentError extends Error {}
