import { orderController } from '../../../services/api'
import { RECEIVED_SOCKET_DATA, SET_TOPIC } from '../../mutationTypes/socketMutationTypes'
const SET_ISFETCHING = 'setFetching'
export default {
  namespaced: true,
  state: {
    orders: [],
    topic: '',
    isFetching: false,
  },
  mutations: {
    [RECEIVED_SOCKET_DATA] (state, payload) {
      state.orders = [...payload]
      state.isFetching = false
    },
    [SET_TOPIC] (state, payload) {
      if (state.topic !== payload) {
        state.topic = payload
        state.isFetching = true
      }
    },
    [SET_ISFETCHING] (state, payload) {
      state.isFetching = payload
    },
  },
  actions: {
    setTopicAction ({ commit }, topic) {
      commit(SET_TOPIC, topic)
    },
    fetchOrdersByTable ({ commit }, tableId) {
      commit(SET_ISFETCHING, true)
      orderController.getOrdersByTable(tableId).then(orders => {
        commit(RECEIVED_SOCKET_DATA, orders)
      })
    },
    setFetchingAction ({ commit }, isFetching) {
      commit(SET_ISFETCHING, isFetching)
    },
  },
  getters: {
    orders (state) {
      return state.orders
    },
    /**
     *
     * @param {State} state
     * @return {{[tableId:number]:{id:number,totalCustomersAtTable:number, orders:Array}}}
     */
    tablesIndex (state) {
      return state.orders.reduce((obj, order) => {
        if (obj[order.table.id]) {
          obj[order.table.id].orders.push(order)
        } else {
          obj[order.table.id] = {
            ...order.table,
            orders: [order],
          }
        }
        return obj
      }, {})
    },
    /**
     * @return {{id:number,totalCustomersAtTable:number, orders:Array}[]}
     */
    tables (state, getters) {
      return Object.values(getters.tablesIndex)
    },
    getOrderById: state => id => {
      return state.orders.find(order => String(order.id) === String(id))
    },
    ordersByTables: state => tables => {
      return state.orders.filter(order => {
        return order.table && tables.includes(order.table.id)
      })
    },
    takeAwayOrders (state) {
      return state.orders.filter(order => order.takeAwayId !== null)
    },
    isFetching (state) {
      return state.isFetching
    },
  },
}
