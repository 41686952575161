<template>
  <div ref="swipeActions" class="swipe-actions-container">
    <div ref="over" class="swipe-actions-over">
      <slot />
    </div>
    <div ref="under" class="swipe-actions-under">
      <div class="swipe-actions">
        <div class="swipe-action">
          <slot name="action-1" class="action" />
        </div>
        <div class="swipe-action">
          <slot name="action-2" class="action" />
        </div>
        <div class="swipe-action">
          <slot name="action-3" class="action" />
        </div>
        <div class="swipe-action">
          <slot name="action-4" class="action" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    completeSwipe: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      size: { width: 0, height: 0 },
    }
  },
  computed: {
    actionSlots() {
      const slots = []
      if (this.$slots['action-1']) slots.push(this.$slots['action-1'])
      if (this.$slots['action-2']) slots.push(this.$slots['action-2'])
      if (this.$slots['action-3']) slots.push(this.$slots['action-3'])
      if (this.$slots['action-4']) slots.push(this.$slots['action-4'])
      return slots.flat()
    },
  },
  updated() {
    this.$slots.actions &&
      this.$slots.actions.forEach(el => {
        el.style.width = this.size.width + 'px'
        el.style.height = this.size.height + 'px'
      })
  },
  mounted() {
    if (this.disabled) return
    let rects = this.$refs.under.getClientRects()
    this.size = {
      width: rects[0] ? rects[0].width : 0,
      height: rects[0] ? rects[0].height : 0,
    }
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        // console.log("contentRect",entry.contentRect)
        this.size = {
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        }
      })
    })
    resizeObserver.observe(this.$el)

    let self = this
    let posibleAnchors = [0, -this.actionSlots.length * this.size.height]
    let active = false
    let x = 0
    let v = 0
    let a = 0
    let force = 0.1
    let friction = 0.7
    let threshold = 1
    let anchor = 0
    let initialX
    let initialY
    let wasScroll
    let wasSwipe
    let wasCompleteSwipe

    let dragItem = this.$refs.swipeActions
    let $over = this.$refs.over
    function dragStart(e) {
      if (e.type === 'touchstart') initialX = e.touches[0].clientX - anchor
      else initialX = e.clientX - anchor

      if (e.type === 'touchstart') initialY = e.touches[0].clientY
      else initialY = e.clientY
      active = true
      wasScroll = false
      wasSwipe = false
    }

    function dragEnd() {
      initialX = x
      active = false
      // posibleAnchors = [0, -self.actionSlots.length * self.size.height]
      relax()
    }

    function drag(e) {
      if (active) {
        // e.preventDefault()
        let prevX = x
        let ex, ey
        if (e.type === 'touchmove') ex = e.touches[0].clientX - initialX
        else ex = e.clientX - initialX

        if (e.type === 'touchmove') ey = e.touches[0].clientY - initialY
        else ey = e.clientY - initialY

        if (!wasScroll && Math.abs(ex) > Math.abs(ey)) x = ex
        else wasScroll = true

        let overSwipe = 0
        // Stop x if scroll so much
        if (x < posibleAnchors[posibleAnchors.length - 1] - overSwipe) {
          // if is enabled the complete swipe, then we enable that x can be more that "posibleAnchors"
          if (self.completeSwipe) wasCompleteSwipe = true
          else x = posibleAnchors[posibleAnchors.length - 1] - overSwipe
        } else wasCompleteSwipe = false

        if (x > posibleAnchors[0] + overSwipe) x = posibleAnchors[0] + overSwipe
        v = x - prevX
        // if velocity is more than .. is a complete swipe
        if (self.completeSwipe)
          if (v < -5) wasCompleteSwipe = true
          else wasCompleteSwipe = false
        if (v > 5) {
          anchor = posibleAnchors[0]
          wasSwipe = true
        } else if (v < -5) {
          anchor = posibleAnchors[1]
          wasSwipe = true
        } else if (Math.abs(v) < 2) {
          wasScroll = false
        }
        setTranslate(x, 0, dragItem)
      }
    }
    function relax() {
      // if was complete swipe, anchos is the width and the sum of actions button widths
      if (wasCompleteSwipe)
        anchor = -self.$el.clientWidth + posibleAnchors.reduce((sum, pa) => sum + pa, 0)
      else if (!wasSwipe) {
        anchor = posibleAnchors.reduce((best, pa) => {
          if (Math.abs(x - pa) < Math.abs(x - best)) return pa
          else return best
        }, posibleAnchors[0])
      }
      if (x != anchor) {
        if (Math.abs(anchor - x) > threshold) {
          a = (anchor - x) * force
        } else {
          a = 0
          v = 0
          x = anchor
        }
        v += a
        v *= friction
        x += v
        setTranslate(x, 0, dragItem)
        if (wasCompleteSwipe && x > anchor / 2)
          // start opacity in the middle of swipe
          setOpacity(1 - (x - anchor / 2) / (anchor / 2), dragItem)
        requestAnimationFrame(relax)
      } else if (wasCompleteSwipe) {
        finsihCompleteSwipe()
      }
    }
    /**
     * Finish complete swipe. Set all to init status
     */
    function finsihCompleteSwipe() {
      setTranslate(0, 0, dragItem)
      setOpacity(0, dragItem)
      x = 0
      anchor = 0
      wasCompleteSwipe = false
      wasSwipe = false
      wasScroll = false
      setTimeout(() => {
        requestAnimationFrame(relaxOpacity.bind({ x: 0, opacity: 1, v: 0.1, a: 0 }))
      }, 500)
      self.finsihCompleteSwipe()
    }

    /**
     * This funcicion need bind data. then we create a "custom data for a transition"
     */
    function relaxOpacity() {
      if (!this) console.error('please bind a object like this', { x: 0, v: 1, a: 0, opacity: 0 })
      if (this.x != this.opacity) {
        if (Math.abs(this.opacity - this.x) > threshold / 100) {
          this.a = (this.opacity - this.x) * force
        } else {
          this.a = 0
          this.v = 0
          this.x = this.opacity
        }
        this.v += this.a
        this.v *= friction
        this.x += this.v
        setOpacity(this.x, dragItem)
        requestAnimationFrame(relaxOpacity.bind(this))
      }
    }

    function setOpacity(opacity, el) {
      el.style.opacity = opacity
    }
    function setTranslate(xPos, yPos, el) {
      el.style.transform = 'translate3d(' + xPos + 'px, ' + yPos + 'px, 0)'
    }

    $over.addEventListener('touchstart', dragStart, false)
    $over.addEventListener('touchend', dragEnd, false)
    $over.addEventListener('touchmove', drag, false)

    $over.addEventListener('mousedown', dragStart, false)
    $over.addEventListener('mouseup', dragEnd, false)
    $over.addEventListener('mousemove', drag, false)
  },
  methods: {
    finsihCompleteSwipe() {
      this.$emit('swipe')
    },
  },
}
</script>

<style lang="stylus" scoped>
.swipe-actions-container
  display: flex
  flex: 0 0 100%
  align-items: flex-end
  align-items: center

.swipe-actions-over
  flex: 0 0 100%

.swipe-actions
  display: flex
  align-items: center

  .swipe-action
    width: 70px
    height: 70px

    &:empty
      display: none
</style>