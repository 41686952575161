<template>
  <div v-if="order.calculatedPrice" class="invoice-table-body">
    <div class="invoice-table">
      <div v-if="invoice" class="invoice-table-title">{{ invoice.fiscalNumber }}</div>
      <div v-if="invoice && invoice.tableName" class="invoice-table-name"><span>{{ $t('setup_order_table_name') }}: </span> {{ invoice.tableName }}</div>
      <basic-table>
        <tr class="basic-table-header">
          <template v-for="header in headers">
            <th v-if="header.id !== 'discount' || addDiscountColumn" :key="header.id" :class="header.align">{{ header.label }}</th>
          </template> 
        </tr>
        <template>
          <invoice-table-row
            v-for="(row, index) in rows"
            :key="index"
            :order="order"
            :row="row"
          />
        </template>
      </basic-table>
    </div>
    <div class="calculated-price">
      <basic-price-summary :order="order" />
    </div>
    <invoice-footer />
  </div>
  <basic-loading v-else />
</template>

<script>
import BasicTable from '../basic/BasicTable.vue'
import { getMergedTicketRows } from '@/utils/ticketUtils'
import BasicPriceSummary from '../basic/BasicPriceSummary.vue'
import BasicLoading from '../basic/BasicLoading.vue'
import InvoiceTableRow from './InvoiceTableRow.vue'
import InvoiceFooter from './InvoiceFooter.vue'

export default {
  components: { BasicTable, BasicPriceSummary, BasicLoading, InvoiceTableRow, InvoiceFooter },
  props: {
    order: {
      type: Object,
      required: true,
    },
    invoice: {
      type: Object,
      required: false,
    },
    isCurrentOrder: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    rows() {
      return getMergedTicketRows(this.order.rows)
    },
    headers() {
      return [
        {
          id: 'uds',
          label: this.$t('invoice_table_header_uds'),
          align: 'left',
        },
        {
          id: 'description',
          label: this.$t('invoice_table_header_description'),
          align: 'left',
        },
        {
          id: 'discount',
          label: this.$t('invoice_table_header_discount'),
          align: 'right',
        },
        {
          id: 'price',
          label: this.$t('invoice_table_header_price'),
          align: 'right',
        },
        {
          id: 'total',
          label: this.$t('invoice_table_header_total'),
          align: 'right',
        },
      ]
    },
    addDiscountColumn() {
      return this.order.rows.find(row => !!row.restaurantDiscountId)
    },
  },
}
</script>

<style lang="stylus" scoped>
.loading
  height: 50%

.invoice-table-body
  overflow: hidden

  >>> .basic-price-summary
    margin: 0
    box-shadow: none

.invoice-table-title,
.invoice-table-title
  m-font-size(16, 20)
  margin-bottom: vw(5px)
  font-weight: 600

.invoice-table-name
  margin: vh(30px) 0
  m-font-size(14, 14)
  font-weight: bold

.invoice-table
  flex: 1 1 0%
  padding: vw(20px) vw(16px)
  padding-bottom: vw(20px)
  border-bottom: 1px solid #e0e0e0
</style>