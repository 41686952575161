<template>
  <div class="edit-zone">
    <div class="edit-zone-list">
      <table-group-row
        v-for="(group, index) in tableGroups"
        :key="index"
        :group="group"
        @edit="$emit('edit', $event)"
      />
    </div>
    <button class="btn-fake btn-add" @click="$emit('add', $event)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TableGroupRow from './TableGroupRow.vue'
export default {
  components: { TableGroupRow },
  remoteComputed: {
    serviceZones: {
      model: 'restaurant/serviceZones',
      method: 'get',
      params: function() {
        return [this.logedUser.restaurantId]
      },
    },
  },
  computed: {
    ...mapGetters({
      logedUser: 'user/getLogedUser',
    }),
    tableGroups() {
      const groups = {}
      this.serviceZones.forEach(serviceZone => {
        serviceZone.tableGroup.forEach(tableGroup => {
          tableGroup.tables.forEach(table => {
            if (this.logedUser.tables.includes(table.id)) {
              if (!groups[tableGroup.id]) {
                groups[tableGroup.id] = {
                  ...tableGroup,
                  tables: [table],
                }
              } else {
                groups[tableGroup.id].tables = [...groups[tableGroup.id].tables, table]
              }
            }
          })
        })
      })
      return Object.values(groups)
    },
  },
}
</script>

<style lang="stylus" scoped>
.edit-zone
  display: flex
  flex: 1 1 0%
  flex-direction: column
  overflow: hidden
  height: 100%

  .edit-zone-list
    flex: 1 1 0%
    overflow-y: auto
    margin-bottom: vw(25px)

  .table-group-row
    &:not(:last-child)
      margin-bottom: vw(10px)

  .btn-add
    m-icon("add-big", 80)
    position: relative
    margin-right: auto
    margin-left: auto
</style>