<template>
  <basic-loading v-if="loading" :type="'small'" />
  <button v-else class="circular-check btn-fake" @click="(ev) => $emit('click', ev)" />
</template>

<script>
import BasicLoading from './BasicLoading.vue'
export default {
  components: { BasicLoading },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="stylus" scoped>
.loading
  min-height: vw(36px)
  width: vw(36px)
  height: vw(36px)

  >>> .loading-background
    display: none

.circular-check
  m-icon("circular-check", 36)
</style>