<template>
  <div class="cms-products">
    <ResourceConfigurator
      v-if="dataAvailable"
      :edit-label-key="'cms_edit_product'"
      :add-label-key="'cms_add_product'"
      :cell-click-handler="cellClickHandler"
      :resources="products"
      :resource-label-key="'cms_products'"
      :fields-info="fieldsInfo"
      :form-fields="formFields"
      :table-values="tableValues"
      :delete-resource-handler="deleteResourceHandler"
      :add-resource-handler="addResourceHandler"
      :edit-resource-handler="editResourceHandler"
      :is-products="true"
      @onStartEditing="onStartEditingHandler"
      @onStartAdding="editingResource = null"
    />
    
    <fav-csv-export
      v-if="products"
      :data="donwloadInfo" 
      :name-file="`${$t('cms_products')}_${(new Date()).toLocaleDateString()}`"
    />
  </div>
</template>
<script>
import ResourceConfigurator from '../resource-configurator/ResourceConfigurator.vue'
import { findResourceById } from '@/utils/cmsUtils'
import CmsResourceConfiguratorI18n from '@/mixins/CmsResourceConfiguratorI18n'
import convert from 'image-file-resize'
import { cmsController } from '@/services/api'
import { mapActions } from 'vuex'
import FavCsvExport from '../FavCsvExport'
import { createArrayDataCSV } from '../../../utils/csvUtils'
export default {
  components: { ResourceConfigurator, FavCsvExport },
  mixins: [CmsResourceConfiguratorI18n],
  remoteComputed: {
    products: {
      model: 'cms/products',
      method: 'get',
    },
    ingredients: {
      model: 'restaurant/ingredients',
      method: 'get',
    },
    serviceOrders: {
      model: 'restaurant/serviceOrders',
      method: 'get',
    },
    categories: {
      model: 'restaurant/categories',
      method: 'get',
    },
    preparationAreas: {
      model: 'restaurant/preparationAreas',
      method: 'get',
    },
    ingredientModifiers: {
      model: 'restaurant/ingredientModifiers',
      method: 'get',
    },
    optionModifiers: {
      model: 'restaurant/optionModifiers',
      method: 'get',
    },
  },
  data() {
    return {
      editingResource: null,
    }
  },
  computed: {
    allCategories() {
      function flatten(a) {
        return a.reduce(function(flattened, { children, ...rest }) {
          return flattened.concat([rest]).concat(children ? flatten(children) : [])
        }, [])
      }
      return flatten(this.categories)
    },
    fieldsInfo() {
      return [
        { type: 'default', name: this.$t('cms_description'), id: 'description' },
        { type: 'default', name: this.$t('cms_category'), id: 'categoryId' },
        { type: 'default', name: this.$t('cms_service_order'), id: 'serviceOrderId' },
        {
          type: 'default',
          name: this.$t('cms_preparation_area'),
          id: 'restaurantPreparationAreaId',
        },
        { type: 'money', name: this.$t('cms_price'), id: 'price' },
        { type: 'image', name: this.$t('cms_image'), id: 'image' },
        { type: 'boolean', name: this.$t('cms_active'), id: 'isEnabled' },
        { type: 'boolean', name: this.$t('cms_age_restricted'), id: 'isAgeRestricted' },
      ]
    },
    dataAvailable() {
      return this.products && this.serviceOrders && this.preparationAreas && this.categories
    },
    tableValues() {
      return this.dataAvailable && this.products.map(product => [
        product.i18n && this.$label(product),
        product.category && this.$label(product.category),
        product.serviceOrderId
          ? this.$label(findResourceById(this.serviceOrders, product.serviceOrderId))
          : null,
        product.restaurantPreparationAreaId
          ? findResourceById(this.preparationAreas, product.restaurantPreparationAreaId).name
          : null,
        product.price,
        product.imageSm,
        product.isEnabled,
        product.isAgeRestricted
      ])
    },
    formFields() {
      return [
        ...this.localizedFormFields(this.editingResource, 'name', 'cms_name'),
        ...this.localizedFormFields(this.editingResource, 'description', 'cms_description'),
        {
          type: 'select',
          label: this.$t('cms_category'),
          id: 'categoryId',
          options: this.allCategories
            .filter(cat => cat.parentId !== null)
            .map(category => ({
              id: category.id,
              label: this.$label(category),
              value: category.id,
            })),
          default: this.editingResource && this.editingResource.category ? this.editingResource.category.id : null,
        },
        {
          type: 'select',
          label: this.$t('cms_service_order'),
          id: 'serviceOrderId',
          options: this.serviceOrders.map(serviceOrder => ({
            id: serviceOrder.id,
            label: this.$label(serviceOrder),
            value: serviceOrder.id,
          })),
          default: this.editingResource
            ? this.editingResource.serviceOrderId
              ? findResourceById(this.serviceOrders, this.editingResource.serviceOrderId).id
              : null
            : null,
        },
        {
          type: 'image',
          label: this.$t('cms_image'),
          id: 'image',
          default: null,
        },
        {
          type: 'select',
          label: this.$t('cms_preparation_area'),
          id: 'restaurantPreparationAreaId',
          options: this.preparationAreas.map(preparationArea => ({
            id: preparationArea.id,
            label: preparationArea.name,
            value: preparationArea.id,
          })),
          default: this.editingResource
            ? this.editingResource.restaurantPreparationAreaId
              ? findResourceById(
                  this.preparationAreas,
                  this.editingResource.restaurantPreparationAreaId
                ).id
              : null
            : null,
        },
        {
          type: 'number',
          label: this.$t('cms_price'),
          id: 'price',
          default: this.editingResource ? this.editingResource.price : 0,
        },
        {
          type: 'boolean',
          label: this.$t('cms_active'),
          id: 'isEnabled',
          enabledLabel: this.$t('cms_active'),
          disabledLabel: this.$t('cms_not_active'),
          default: (this.editingResource && this.editingResource.isEnabled) || false,
        },
        {
          type: 'boolean',
          label: this.$t('cms_age_restricted'),
          id: 'isAgeRestricted',
          enabledLabel: this.$t('cms_active'),
          disabledLabel: this.$t('cms_not_active'),
          default: (this.editingResource && this.editingResource.isAgeRestricted) || false,
        },
        {
          type: 'list',
          placeholder: this.$t('cms_ingredients_filter_placeholder'),
          label: this.$t('cms_ingredients'),
          id: 'ingredients',
          required: false,
          items: this.ingredients.map(ing => ({ id: ing.id, label: this.$label(ing) })),
          default: this.editingResource ? this.editingResource.ingredients : [],
        },
      ]
    },
    donwloadInfo() {
      return createArrayDataCSV.bind(this)(this.fieldsInfo, this.tableValues)
    },
  },
  methods: {
    ...mapActions({
      invalidateCmsProducts: `cms/products/invalidateAll`,
      invalidateMenuProducts: `restaurant/products/invalidateAll`,
      invalidateMenu: `restaurant/menu/invalidateAll`,
    }),
    invalidateProducts() {
      this.invalidateCmsProducts()
      this.invalidateMenuProducts
    },
    onStartEditingHandler(product) {
      this.editingResource = product
    },
    cellClickHandler(fieldIndex, resource) {
      if (this.fieldsInfo[fieldIndex].type !== 'boolean') {
        return
      }
      const fieldId = this.fieldsInfo[fieldIndex].id
      this.editResourceHandler({ [fieldId]: !resource[fieldId] }, resource)
    },
    deleteResourceHandler({ id }) {
      return cmsController.deleteResourceById('products', id).then(this.invalidateProducts)
    },
    async addResourceHandler({ i18n, ingredients, image, ...product }) {
      const { id } = await cmsController.addResource('products', {
        ...product,
        i18ns: i18n,
        price: Number(product.price) || 0,
      })
      await Promise.all(
        ingredients.map(ingredientId =>
          cmsController.createRelationship('product', id, 'ingredient', ingredientId)
        )
      )
      if (image) {
        await this.patchProductImage(image, id)
      }
      this.invalidateProducts()
    },
    async editResourceHandler({ i18n, image, ingredients, ...editedResource }) {
      if (image) {
        await this.patchProductImage(image, this.editingResource.id)
      }
      const editedIngredients = ingredients
      if (editedIngredients) {
        const ingredientsToDelete = this.editingResource.ingredients.filter(
          ing => !editedIngredients.includes(ing)
        )
        const ingredientsToAdd = editedIngredients.filter(
          ing => !this.editingResource.ingredients.includes(ing)
        )
        await Promise.allSettled(
          ingredientsToAdd.map(ingredientId =>
            cmsController.createRelationship(
              'product',
              this.editingResource.id,
              'ingredient',
              ingredientId
            )
          )
        )
        await Promise.allSettled(
          ingredientsToDelete.map(ingredientId =>
            cmsController.deleteRelationship(
              'product',
              this.editingResource.id,
              'ingredient',
              ingredientId
            )
          )
        )
      }
      if (i18n) {
        await this.editI18ns(i18n, this.editingResource, 'products')
      }
      if (editedResource.price) {
        editedResource.price = Number(editedResource.price)
      }
      await cmsController.updateResourceById('products', this.editingResource.id, editedResource)
      this.invalidateProducts()
      this.invalidateMenu()
    },
    patchProductImage(image, productId) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onload = e => {
          const img = new Image()
          img.src = e.target.result
          img.onload = async function () {
            const height = 200
            const width = Math.round((this.width * height) / this.height)
            let smFile
            try {
              smFile = await convert({
                file: image,
                width,
                height,
                type: 'jpeg',
              })
              formData.append('imageLgFile', image)
              formData.append('imageSmFile', smFile)
              await cmsController.patchResourceImage('products', productId, formData)
              resolve()
            } catch (e) {
              reject(e)
            }
          }
        }
      })
    },
  },
}
</script>
<style lang="stylus" scoped></style>