<template>
  <div class="preparation-zone-order-row-container">
    <div class="info-container">
      <div class="title-container">
        <basic-number-tag :value="data.quantity ? data.quantity : 1" @click="handleCollapse" />
        <div class="order-title">{{ product && $label(product) }}</div>
      </div>
      <div class="modifiers-container">
        <ticket-row-modifier
          v-for="(ing, index) in data.ingredientModifiers"
          :id="ing.id"
          :key="index"
          type="ing"
          :value="ing.value"
        />
      </div>
      <div class="modifiers-container">
        <ticket-row-modifier
          v-for="(opt, index) in data.optionModifiers"
          :id="opt.id"
          :key="index"
          type="opt"
          :value="opt.value"
        />
      </div>
      <div v-if="data.comment" class="note">{{ `${$t('preparation_zone_note_title')}: ${data.comment}` }}</div>
      <row-alert v-if="data.ticketRowAlertComment" :text="data.ticketRowAlertComment" />
      <row-alert v-if="data.ticketRowAlertId && getAlertById(parseInt(data.ticketRowAlertId))" :text="getAlertById(parseInt(data.ticketRowAlertId)).name" />
    </div>
    <div class="check">
      <div class="timer">
        {{ diferentTime }}
      </div>
      <basic-circular-check-button @click="$emit('check')" />
    </div>
  </div>
</template>

<script>
import TicketRowModifier from './../ticket-row/TicketRowModifier'
import BasicCircularCheckButton from './../basic/BasicCircularCheckButton.vue'
import BasicNumberTag from './../basic/BasicNumberTag.vue'
import RowAlert from './RowAlert.vue'
import { mapGetters } from 'vuex'
import TimerMixin from '../../mixins/TimerMixin'
export default {
  components: { BasicNumberTag, BasicCircularCheckButton, TicketRowModifier, RowAlert },
  mixins: [TimerMixin],
  model: {
    prop: 'collapsed',
    event: 'collapse',
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    collapsed: {
      type: Boolean,
    },
    collapsable: {
      type: Boolean,
    },
  },
  remoteComputed: {
    product: {
      model: 'restaurant/products',
      method: 'getById',
      params: function () {
        return [this.data.productId]
      },
    },
    alerts: {
      model: 'restaurant/alerts',
      method: 'get',
    },
  },
  computed: {
    ...mapGetters({
      getAlertById: 'restaurant/alerts/getById',
    }),
    diferentTime() {
      return this.getDiferentTime(this.data.created)
    },
  },
  methods: {
    handleCollapse() {
      if (this.collapsable) this.$emit('collapse', !this.collapsed)
    },
  },
}
</script>

<style lang="stylus" scoped>
.preparation-zone-order-row-container
  display: flex
  // align-items: center
  padding: vw(10px)
  border: 1px solid #E0E0E0
  background-color: #fff

  .info-container
    display: flex
    flex-direction: column
    margin-right: vw(15px)

    .title-container
      display: flex

      .order-title
        m-font-size(18, 24)
        margin-top: -0.05em
        margin-left: vw(10px)

    .modifiers-container
      display: flex
      align-items: center
      margin-top: vw(5px)
      margin-left: vw(34px)

      &:empty
        display: none

      .ticket-row-modifier
        &:not(:last-child)
          &:after
            margin-right: 0.5em
            margin-left: 0.5em
            content: "·"

    .note
      color: #828282

  .check
    display: flex
    align-items: center
    margin-left: auto

    .timer
      margin-right: vw(10px)
</style>