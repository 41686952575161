<template>
  <div class="preparation-zone">
    <orders-column
      :title="$t('preparation_zone_incoming_orders_title')"
      :status="ORDER_STATUS.TODO"
      :orders="filteredOrdersByPreparationZone"
      :sound="true"
    />
    <orders-column
      :title="$t('preparation_zone_preparation_orders_title')"
      :status="ORDER_STATUS.WIP"
      :orders="filteredOrdersByPreparationZone"
    />
    <orders-column
      v-if="openRecoveryColumn"
      :title="$t('preparation_zone_ready_orders_title')"
      :status="ORDER_STATUS.READY"
      :orders="filteredOrdersByPreparationZone"
      :is-recovery="true"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import doorBell from '../assets/audios/door-bell.wav'
// import { mapActions, mapGetters } from 'vuex'
import OrdersColumn from '../components/preparation-zone/OrdersColumn.vue'
import OrderListDataMixin from '../mixins/OrderListDataMixin'
import QueryMixin from '../mixins/QueryMixin'
import { PREPARATION_ZONE_QUERY_KEY, RECOVERY_COLUMN } from '../settings/navigationSettings'
import { ORDER_STATUS } from '../settings/orderSettings'

export default {
  components: { OrdersColumn },
  mixins: [OrderListDataMixin, QueryMixin],
  data() {
    var audio = new Audio(doorBell)
    return {
      ORDER_STATUS,
      audio,
    }
  },
  remoteComputed: {
    product: {
      model: 'restaurant/products',
      method: 'getById',
    },
    preparationAreas: {
      model: 'restaurant/preparationAreas',
      method: 'get',
      params: function () {
        return [this.$route.params.restaurantId]
      },
    },
  },
  computed: {
    ...mapGetters({
      getProductById: 'restaurant/products/getById',
    }),
    filteredOrdersByPreparationZone() {
      let filteredOrders
      if (!this.orders) return []
      const preparationArea = this.getQueryValue(PREPARATION_ZONE_QUERY_KEY)
      console.log(preparationArea)
      if (!preparationArea) {
        filteredOrders = this.orders
      } else {
        filteredOrders = this.orders.reduce((ordersAccumulator, currentOrder) => {
          const rows = currentOrder.rows.reduce((rowsAccumulator, currentRow) => {
            const product = this.getProductById(currentRow.productId)
            if (product.restaurantPreparationAreaId.toString() === preparationArea.toString()) {
              rowsAccumulator.push({ ...currentRow })
            }
            return rowsAccumulator
          }, [])
          if (rows?.length > 0) {
            ordersAccumulator.push({ ...currentOrder, rows })
          }
          return ordersAccumulator
        }, [])
      }
      console.log({ filteredOrders })
      return filteredOrders.filter(({ isClient, isPaid }) => !isClient || isPaid)
    },
    openRecoveryColumn() {
      return this.getQueryValue(RECOVERY_COLUMN)?.toLowerCase() === 'true'
    },
  },
  watch: {
    filteredOrdersByPreparationZone: {
      handler(newItem, oldItem) {
        const newRows = newItem.reduce((accumulator, currentValue) => {
          accumulator.push([...currentValue.rows])
          return accumulator.flat()
        }, [])

        const oldRows = oldItem.reduce((accumulator, currentValue) => {
          accumulator.push([...currentValue.rows])
          return accumulator.flat()
        }, [])

        if (newRows.length > oldRows.length) this.audio.play()
      },
    },
  },
}
</script>

<style lang="stylus" scoped>
.preparation-zone
  display: flex
  flex: 1 1 0%
  padding: vw(20px)

  .recovery-button
    position: absolute
    top: vw(30px)
    right: vw(30px)
    width: vw(120px)
</style>