export function downloadFile(text, filename) {
  var element = document.createElement('a')
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

export function convertToCSV(arr) {
  const array = [Object.keys(arr[0])].concat(arr)
  return array
    .map(it => {
      return Object.values(it).toString()
    })
    .join('\n')
}
/**
 * FOR BOOLEAN REMEMBER BIND Vue ELEMENT (this)!! createArrayDataCSV.bind(this)(...)
 * maybe this sould be a mixin :/
 *
 * @param {{id:string,name:string}[]} fields
 * @param {any[][]} values
 * @returns {object[]}
 */
export function createArrayDataCSV(fields, values) {
  if (!values || !fields) return []
  return values.map(value =>
    fields.reduce((obj, field, index) => {
      if (
        ['boolean', 'default', 'percentage', 'money', 'datetime', 'timestamp'].includes(field.type)
      )
        return {
          ...obj,
          [field.name]: (() => {
            const v = value[index]
            // Switch for change the format of each type
            switch (field.type) {
              case 'boolean':
                if (!this) console.error('THIS is not binded!\n Put .bind(this) please :)')
                return v ? this.$t('yes') : this.$t('no')
              case 'percentage':
                return v + '%'
              case 'timestamp':
              case 'datetime':
                return new Date(v).toLocaleString().replace(/,/g, ' ')
              default:
                return v?.toString().replace(/,/g, ' ') ?? ''
            }
          })(),
        }
      else return obj
    }, {})
  )
}
