<template>
  <div class="per-product-payment">
    <client-payment
      v-for="(client, index) in clients"
      :key="index"
      :client="client"
      :products="calculateProduct(client)"
      :disabled="disabled"
      @click="handleClickClient(index,client)"
      @change="handleChangeClient(index,$event)"
      @remove="$emit('remove',client)"
    />
    <slot />
    <basic-dialog :is-open="isDialogOpen" @close="switchIsDialogOpen">
      <template #dialog-body>
        <add-product-client-payment
          v-for="ticket in productsTicket"
          :key="ticket.id"
          :product="ticket.product"
          :quantity="notAssignedProducts[ticket.id]+(selectedClientObj.tickets[ticket.id] || 0)"
          :product-in-client="internalSelectedTickets[ticket.id]"
          :disabled="!rest.tickets[ticket.id]"
          @change="changeProduct(ticket.id, $event)"
        />
        
        <div class="btn" @click="handleAddClient">
          Agregar al Cliente {{ clientPay }}
        </div>
      </template>
    </basic-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BasicDialog from '../basic/BasicDialog.vue'
import AddProductClientPayment from './AddProductClientPayment.vue'
import ClientPayment from './ClientPayment.vue'
export default {
  components: { BasicDialog, AddProductClientPayment, ClientPayment },
  model: {
    prop: 'clients',
    event: 'update:clients',
  },
  props: {
    productsTicket: {
      type: Array,
      required: true,
    },
    clients: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    rest: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      internalSelectedTickets: {},
      isDialogOpen: false,
      indexSelectedClient: 0,
    }
  },
  computed: {
    ...mapGetters({
      activeOrder: 'activeOrder/data',
    }),
    internalDisabled() {
      //TODO
      return false
    },
    clientPay() {
      return this.calculatedPriceByTickets(this.internalSelectedTickets)
    },
    selectedClientObj() {
      return this.clients[this.indexSelectedClient]
    },
    notAssignedProducts() {
      return this.productsTicket
        .map(ticket => ({
          ...ticket,
          quantity:
            ticket.quantity -
            this.clients.reduce((sum, client) => (client.tickets[ticket.id] || 0) + sum, 0),
        }))
        .reduce((obj, ticket) => {
          if (!obj[ticket.id]) obj[ticket.id] = ticket.quantity
          return obj
        }, {})
    },
  },
  mounted() {
    const copyClient = JSON.parse(JSON.stringify(this.clients))
    copyClient.forEach(client => {
      client.toPay = this.calculatedPriceByTickets(client.tickets)
    })
    this.$emit('update:clients', copyClient)
  },
  methods: {
    calculatedPriceByTickets(tickets) {
      return Object.keys(tickets).reduce((sum, key) => {
        const ticket = this.productsTicket.find(ticket => ticket.id === key)
        if (!ticket) {
          console.error('TICKET WITH ID ' + key + ' not found')
          return sum
        }
        return sum + tickets[key] * ticket.calculatedPrice.price
      }, 0)
    },
    handleAddClient() {
      const newClients = [...this.clients]
      // TO IMPROVE: se hace aliassing... quizá es mejor hacer un copy de todo el objeto...
      newClients[this.indexSelectedClient].tickets = this.internalSelectedTickets
      newClients[this.indexSelectedClient].toPay = this.clientPay
      this.$emit('update:clients', newClients)
      this.switchIsDialogOpen()
    },
    changeProduct(ticketId, delta) {
      if (!this.internalSelectedTickets[ticketId]) {
        this.internalSelectedTickets = {
          ...this.internalSelectedTickets,
          [ticketId]: 0,
        }
      }
      this.internalSelectedTickets[ticketId] += delta
    },
    switchIsDialogOpen() {
      this.isDialogOpen = !this.isDialogOpen
    },
    calculateProduct(client) {
      return Object.keys(client.tickets).reduce((sum, id) => sum + client.tickets[id], 0)
    },
    handleClickClient(index, client) {
      this.switchIsDialogOpen()
      this.internalSelectedTickets = client.tickets
      this.indexSelectedClient = index
    },
    handleChangeClient(index, client) {
      const newClients = [...this.clients]
      newClients.splice(index, 1, client)
      this.$emit('update:clients', newClients)
    },
  },
}
</script>

<style lang="stylus" scoped>
.per-product-payment
  width: 100%
</style>
