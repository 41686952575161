<template>
  <div
    :class="['basic-tab', { selected: tabValue === value }]"
    @click="() => handletabChange(value)"
  >
    <div class="basic-tab-inner">
      <slot v-bind="{ selected: tabValue === value }">
        <div :class="['basic-tab-icon', value]" />
        <div class="basic-tab-text">{{ name }}</div>
      </slot>
    </div>
    <div class="basic-tab-line" />
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    value: [String, Number],
  },
  computed: {
    tabValue() {
      return this.basicTabs.value
    },
  },
  methods: {
    handletabChange(value) {
      this.basicTabs.handleChange(value)
    },
  },
  inject: ['basicTabs'],
}
</script>

<style lang="stylus" scoped>
$gap = vw(30px)
$gap-negative = vw(-30px)
$margin-bottom = vw(10px)
$margin-bottom-negative = vw(-10px)

.basic-tab
  m-font-size(16, 20)
  position: relative
  z-index: 1
  padding: 0 $gap $margin-bottom
  white-space: nowrap
  opacity: 0.75

  &.selected
    opacity: 1

    .basic-tab-line
      &:after
        transform: translateX(0)

.basic-tab-inner
  display: flex
  justify-content: center
  align-items: center

.basic-tab-icon
  $icons = "diferent", "equal", "comensal", "per-product"
  display: none
  margin-right: vw(10px)

  for $icon in $icons
    &.{$icon}
      m-icon($icon, 20)
      display: block

.basic-tab-line
  position: relative
  bottom: $margin-bottom-negative
  left: $gap-negative
  z-index: 1
  overflow: hidden
  width: "calc(100% + (%s * 2))" % $gap
  height: 5px

  &:after
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: $primary-theme-color
    content: ""
    transition: transform 0.25s ease-in-out
    transform: translateX(-110%)
</style>
